/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { connectRouter } from "connected-react-router";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import history from "./utils/history";
// import languageProviderReducer from "containers/LanguageProvider/reducer";
// import languageProviderReducer from "./containers/LanguageProvider/reducer";
// import signUpReducer from './components/SignupForm/reducer';
import signUpReducer from "./components/SignupForm/reducer";
import signInReducer from "./components/Login/reducer";
import homePageReducer from "./containers/Homepage/homePageReducer";
import operatorSignUpReducer from "./components/StoreRegisterForm/storeRegisterReducer";
import userProfileReducer from "./containers/UserProfile/userProfileReducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

const persistConfig = {
  key: "root",
  whitelist: ["homePageFav"],
  storage: storage,
};

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    // language: languageProviderReducer,
    signup: signUpReducer,
    signIn: signInReducer,
    homePageFav: homePageReducer,
    operatorSignUp: operatorSignUpReducer,
    userProfile: userProfileReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  return persistedReducer;
}
