/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon, Modal } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive/src";
import history from "../../../utils/history";
import { shopGallaryResource } from "../../../Helpers/api";
import { promoModalSubmit } from "../../../assets/images";
import "./offersNewsPopup.scss";

function OffersNewsPopup(props) {
  const [showOfferModal, setShowOfferModal] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const getDay = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = +date.getMonth() + 1;
    return `${day}/${month}`;
  };

  // useEffect(() => {
  //   if (props.modalData.store && props.modalData.store.tags) {
  //     const str = props.modalData.store.tags;
  //     const resTags = str.split(',');
  //     setStoreTags(resTags);
  //   }
  //   console.log(props.isPromoImageFailed);
  // }, [props.modalData.store]);
  return (
    <Modal
      style={{
        // width: '554px',
        // height: '600px',
        width: isTabletOrMobile ? "80vw" : "60vw",
        // height: isTabletOrMobile ? "40vw" : "25vw",
        borderRadius: "8px",
        boxShadow: "0 0 34px 0 rgba(0, 0, 0, 0.5)",
        backgroundColor: "#ffffff",
        // paddingBottom: '30px'
      }}
      closeIcon={
        <Icon name="close" id="modal-close-icon" className="modal_close_btn" />
      }
      color="#ffffff"
      name="close"
      open={props.showOfferModal}
      onClose={() => props.setShowOfferModal(false)}
    >
      {/* {props.modalData.offer && (
        <Modal.Header>
          <div className="offer_modal_header">
            <img src={props?.modalData?.offer?.imageUrl} className="offer_modal_header_img" alt="" />
          </div>
        </Modal.Header>
      )} */}
      <Modal.Content
        style={{ textAlign: "center", padding: 0, height: "100%" }}
      >
        {props.modalData && props.modalData.store && props.modalData.offer ? (
          <Modal.Description style={{ height: "100%" }}>
            <div className="offer_text_container">
              <div
                className="promo_image_div"
                style={{
                  backgroundImage: `url(${props.modalData.offer.imageUrl})`,
                }}
              ></div>
              {/* <img
                src={props.modalData.offer.imageUrl}
                alt=""
                className="offer_modal_image"
              /> */}
              <div className="offer_modal_main_content">
                {/* {storeTags.length > 0 &&
                storeTags.map(tag => (
                  <p className="promo_modal_food">{` #${tag.trim()}`}</p>
                ))} */}
                {/* <p className="promo_modal_food">#alimentari</p> */}
                <span className="promo_modal_shop">
                  {props.modalData.store.name}
                </span>
                <p className="modal_product_description">
                  {props.modalData.offer.title}
                </p>
                <p className="product_desctrition_second">
                  {props.modalData.offer.body}
                </p>
                <p className="promo_valid_date">
                  {`*Promozione valida dal ${getDay(
                    props.modalData.offer.validFrom
                  )} al ${getDay(props.modalData.offer.validTo)}`}
                </p>

                {/* <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    outline: "none",
                  }}
                  type="button"
                  onClick={() => {
                    history.push(`/shopDetails/${props.modalData.store.id}`);
                    props.setShowOfferModal(false);
                  }}
                >
                  <img
                    src={promoModalSubmit}
                    alt="modal submit"
                    style={{ margin: "20px auto" }}
                  />
                </button> */}
              </div>
            </div>
          </Modal.Description>
        ) : // : props.modalData && props.modalData.store && props.modalData.offer ? (
        // <Modal.Description>
        //   <div className="promo_modal_main_content">
        //     {/* {storeTags.length > 0 &&
        //     storeTags.map(tag => (
        //       <p className="promo_modal_food">{` #${tag.trim()}`}</p>
        //     ))} */}
        //     {/* <p className="promo_modal_food">#alimentari</p> */}
        //     <span className="promo_modal_shop">
        //       {props.modalData.store.name}
        //     </span>
        //     <p className="modal_product_description">
        //       {props.modalData.offer.title}
        //     </p>
        //     <p className="product_desctrition_second">
        //       {props.modalData.offer.body}
        //     </p>
        //     <p className="promo_valid_date">
        //       {`*Promozione valida dal ${getDay(
        //         props.modalData.offer.validFrom,
        //       )} al ${getDay(props.modalData.offer.validTo)}`}
        //     </p>
        //     <button
        //       style={{
        //         background: 'none',
        //         border: 'none',
        //         cursor: 'pointer',
        //         outline: 'none',
        //       }}
        //       type="button"
        //       onClick={() => {
        //         history.push(`/shopDetails/${props.modalData.store.id}`)
        //         props.setShowOfferModal(false)
        //       }
        //       }
        //     >
        //       <img
        //         src={promoModalSubmit}
        //         alt="modal submit"
        //         style={{margin: '20px auto'}}
        //       />
        //     </button>
        //   </div>
        // </Modal.Description>
        null}
      </Modal.Content>
    </Modal>
  );
}

OffersNewsPopup.propTypes = {
  showOfferModal: PropTypes.bool,
  setShowOfferModal: PropTypes.func,
  modalData: PropTypes.object,
};

export default OffersNewsPopup;
