/**
Sign up user
 */

import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { userDetails } from "../../Helpers/UserDetails";
import * as API from "../../Helpers/api";
import {
  getFavouritStores,
  createFavouritStores,
  deleteFavouritStores,
  getMalllsAndStores,
  searchMalllsAndStoresSuccess,
  searchMalllsAndStoresFailed,
  searchMalllsAndStoresLaoding,
  getCities,
} from "./actions";

// Get all cities
function getCitiesAPI() {
  return axios(`${API.getCities}?allowZipRange=true`, {
    method: "GET",
  });
}
/** function that returns an axios call */
function getFavStores() {
  return axios(API.getFavStores, {
    method: "GET",
    auth: {
      username: userDetails().email,
      password: userDetails().password,
    },
  });
}
function createFavStores(payload) {
  return axios(API.createFavStores, {
    method: "POST",
    auth: {
      username: userDetails().email,
      password: userDetails().password,
    },
    data: payload,
    // data: {
    //   storeId: payload,
    // },
  });
}
function delFavStores(payload) {
  return axios(API.deleteFavStores, {
    method: "POST",
    auth: {
      username: userDetails().email,
      password: userDetails().password,
    },
    data: {
      storeId: payload.storeId,
      type: payload.type,
    },
  });
}

function getMallsStores(payload) {
  console.log(payload);
  let api = API.getMallsStores;
  if (payload.zipRange) {
    api = `${API.getMallsStores}?allowZipRange=true&istat=${payload.istat}&s=999`;
  } else {
    api = `${API.getMallsStores}?zipCode=${payload.zipCode}&s=999`;
  }
  return axios(api, {
    method: "GET",
  });
}

function searchMallsStoresAPI(payload) {
  let api = API.searchMallsStores;
  if (payload.zipRange) {
    if (
      payload.searchText &&
      !payload.istat &&
      !payload.categories &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&s=999`;
    }
    if (
      payload.categories &&
      !payload.istat &&
      !payload.searchText &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?categories=${payload.categories}&s=999`;
    }
    if (payload.istat && !payload.categories && !payload.distance) {
      api = `${API.searchMallsStores}?allowZipRange=true&istat=${payload.istat}&s=999`;
    }

    if (
      payload.searchText &&
      payload.istat &&
      !payload.categories &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&allowZipRange=true&istat=${payload.istat}&s=999`;
    }
    if (
      payload.searchText &&
      payload.istat &&
      payload.categories &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&categories=${payload.categories}&allowZipRange=true&istat=${payload.istat}&s=999`;
    }
    if (
      !payload.searchText &&
      payload.istat &&
      payload.categories &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?categories=${payload.categories}&allowZipRange=true&istat=${payload.istat}&s=999`;
    }
    if (
      payload.searchText &&
      !payload.istat &&
      payload.categories &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&categories=${payload.categories}&s=999`;
    }
    // Distance
    if (
      payload.searchText &&
      !payload.istat &&
      payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&lat=${payload.lat}&lon=${payload.long}&categories=${payload.categories}&maxDistance=${payload.distance}&s=999`;
    }
    if (
      !payload.searchText &&
      !payload.istat &&
      payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?lat=${payload.lat}&lon=${payload.long}&categories=${payload.categories}&maxDistance=${payload.distance}&s=999`;
    }
    if (
      !payload.searchText &&
      !payload.istat &&
      !payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?lat=${payload.lat}&lon=${payload.long}&maxDistance=${payload.distance}&s=999`;
    }

    if (
      !payload.searchText &&
      payload.istat &&
      !payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?&lat=${payload.lat}&lon=${payload.long}&maxDistance=${payload.distance}&allowZipRange=true&istat=${payload.istat}&s=999`;
    }

    if (
      !payload.searchText &&
      payload.istat &&
      payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?&lat=${payload.lat}&lon=${payload.long}&categories=${payload.categories}&maxDistance=${payload.distance}&allowZipRange=true&istat=${payload.istat}&s=999`;
    }

    if (
      payload.searchText &&
      payload.istat &&
      payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&lat=${payload.lat}&lon=${payload.long}&categories=${payload.categories}&maxDistance=${payload.distance}&allowZipRange=true&istat=${payload.istat}&s=999`;
    }

    if (
      payload.searchText &&
      !payload.istat &&
      !payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&lat=${payload.lat}&lon=${payload.long}&maxDistance=${payload.distance}&s=999`;
    }
    if (payload.searchText && payload.istat && payload.distance) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&allowZipRange=true&istat=${payload.istat}&lat=${payload.lat}&lon=${payload.long}&maxDistance=${payload.distance}&s=999`;
    }
    // const api =
    //   'https://retapps-dev.demos.classicinformatics.com/api/cli/search?zipCode=20100';
    return axios(api, {
      method: "GET",
    });
  } else {
    if (
      payload.searchText &&
      !payload.zipCode &&
      !payload.categories &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&s=999`;
    }
    if (
      payload.categories &&
      !payload.zipCode &&
      !payload.searchText &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?categories=${payload.categories}&s=999`;
    }
    if (payload.zipCode && !payload.categories && !payload.distance) {
      api = `${API.searchMallsStores}?zipCode=${payload.zipCode}&s=999`;
    }

    if (
      payload.searchText &&
      payload.zipCode &&
      !payload.categories &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&zipCode=${payload.zipCode}&s=999`;
    }
    if (
      payload.searchText &&
      payload.zipCode &&
      payload.categories &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&categories=${payload.categories}&zipCode=${payload.zipCode}&s=999`;
    }
    if (
      !payload.searchText &&
      payload.zipCode &&
      payload.categories &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?categories=${payload.categories}&zipCode=${payload.zipCode}&s=999`;
    }
    if (
      payload.searchText &&
      !payload.zipCode &&
      payload.categories &&
      !payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&categories=${payload.categories}&s=999`;
    }
    // Distance
    if (
      payload.searchText &&
      !payload.zipCode &&
      payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&lat=${payload.lat}&lon=${payload.long}&categories=${payload.categories}&maxDistance=${payload.distance}&s=999`;
    }
    if (
      !payload.searchText &&
      !payload.zipCode &&
      payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?lat=${payload.lat}&lon=${payload.long}&categories=${payload.categories}&maxDistance=${payload.distance}&s=999`;
    }
    if (
      !payload.searchText &&
      !payload.zipCode &&
      !payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?lat=${payload.lat}&lon=${payload.long}&maxDistance=${payload.distance}&s=999`;
    }

    if (
      !payload.searchText &&
      payload.zipCode &&
      !payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?&lat=${payload.lat}&lon=${payload.long}&maxDistance=${payload.distance}&zipCode=${payload.zipCode}&s=999`;
    }

    if (
      !payload.searchText &&
      payload.zipCode &&
      payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?&lat=${payload.lat}&lon=${payload.long}&categories=${payload.categories}&maxDistance=${payload.distance}&zipCode=${payload.zipCode}&s=999`;
    }

    if (
      payload.searchText &&
      payload.zipCode &&
      payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&lat=${payload.lat}&lon=${payload.long}&categories=${payload.categories}&maxDistance=${payload.distance}&zipCode=${payload.zipCode}&s=999`;
    }

    if (
      payload.searchText &&
      !payload.zipCode &&
      !payload.categories &&
      payload.distance
    ) {
      api = `${API.searchMallsStores}?q=${payload.searchText}&lat=${payload.lat}&lon=${payload.long}&maxDistance=${payload.distance}&s=999`;
    }
    // const api =
    //   'https://retapps-dev.demos.classicinformatics.com/api/cli/search?zipCode=20100';
    return axios(api, {
      method: "GET",
    });
  }
}

export function* getFavStoresSaga(action) {
  try {
    const favStores = yield call(getFavStores, action.payload);
    if (favStores) {
      yield put(getFavouritStores(favStores));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* createFavStoresSaga(action) {
  try {
    const favStoreCreate = yield call(createFavStores, action.payload);
    if (favStoreCreate) {
      yield put(createFavouritStores(favStoreCreate));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* deleteFavStoresSaga(action) {
  try {
    const favStoreDel = yield call(delFavStores, action.payload);
    if (favStoreDel) {
      yield put(deleteFavouritStores(favStoreDel));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getMallsStoresSaga(action) {
  try {
    const mallsStores = yield call(getMallsStores, action.payload);
    if (mallsStores) {
      yield put(getMalllsAndStores(mallsStores));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* searchMallsStoresSaga(action) {
  yield put(searchMalllsAndStoresLaoding(true));
  try {
    const mallsStores = yield call(searchMallsStoresAPI, action.payload);
    if (mallsStores) {
      yield put(searchMalllsAndStoresLaoding(false));
      yield put(searchMalllsAndStoresSuccess(mallsStores));
      yield put(searchMalllsAndStoresFailed({}));
    }
  } catch (err) {
    yield put(searchMalllsAndStoresLaoding(false));
    yield put(searchMalllsAndStoresFailed(err));
    yield put(searchMalllsAndStoresSuccess({}));
  }
}

export function* getCitiesSaga(action) {
  try {
    const cities = yield call(getCitiesAPI, action.payload);
    if (cities) {
      const cityArray = [];
      if (cities && cities.data && cities.data.data) {
        // cities.data.data.sort((a, b) => a.id - b.id);
        cities.data.data.forEach((item) => {
          cityArray.push({
            // key: item.id,
            value: item.id,
            istat: item.istat,
            zipRange: item.zipRange,
            label: item.zipRange
              ? `${item.city} [Tutti i cap]`
              : `${item.city} [${item.id}]`,
          });
        });
      }
      cityArray.sort((a, b) => a.label - b.label);
      yield put(getCities(cityArray));
    }
  } catch (err) {
    console.log(err);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* signupData() {
  // Watches for LOGGED_IN actions and calls loginSaga when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest("GET_FAVOURITE_STORES", getFavStoresSaga);
  yield takeLatest("CREATE_FAVOURITE_STORE", createFavStoresSaga);
  yield takeLatest("DELETE_FAVOURITE_STORES", deleteFavStoresSaga);
  yield takeLatest("GET_MALLS_STORES", getMallsStoresSaga);
  yield takeLatest("SEARCH_MALLS_STORES", searchMallsStoresSaga);
  yield takeLatest("GET_CITIES", getCitiesSaga);
}
