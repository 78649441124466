import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import history from "../../../utils/history";
import { useSnackbar } from "react-simple-snackbar";
import { contactUs } from "../../../Helpers/api";

import "./business-contact-us.styles.scss";

const recaptchaRef = React.createRef();

function BusinessContactUs(props) {
    const gtag = window.gtag;
    const fbq = window.fbq;
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
        type1: false,
        type2: false,
        type3: false,
        type4: false,
        messageText: '',
        accept1: false,
        accept2: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [disableButton, setDisableButton] = useState(true);

    const [openSnackbarRed] = useSnackbar({
        style: {
            position: "absolute",
            backgroundColor: "red",
            border: "2px solid lightgreen",
            color: "lightblue",
            fontFamily: "Menlo, monospace",
            fontSize: "20px",
            textAlign: "center",
            bottom: "100%",
            left: 0,
        },
        closeStyle: {
            color: "lightcoral",
            fontSize: "16px",
        },
    });

    const onSubmit = (e) => {
        e.preventDefault();
        gtag('event', 'Click sul bottone', {
            'event_category': 'Bottoni CTA',
            'event_label': 'Invia richiesta'
        });
        fbq('trackCustom', 'Conversione', {
            content_name: 'Invia_Richiesta'
        });
        const errors = validateFormData(formData);
        if (Object.keys(errors).length === 0) {
            const recaptcha = recaptchaRef.current.getValue();
            let config = {
                headers: {
                    "g-recaptcha-response": recaptcha,
                }
            }
            const businessType = [];
            if (formData.type1)
                businessType.push("store")
            if (formData.type2)
                businessType.push("catering")
            if (formData.type3)
                businessType.push("service")
            if (formData.type4)
                businessType.push("other")
            axios.post(contactUs, {
                "name": formData.firstName,
                "surname": formData.lastName,
                "email": formData.email,
                "mobilePhone": formData.mobilePhone,
                "notes": formData.messageText,
                "privacyAccept": formData.accept1,
                "newsletterAccept": formData.accept2,
                "businessType": businessType,
            }, config).then(
                res => {
                    console.log(res.data)
                    if (res.data === "OK") {
                        history.push("/thank-you-page");
                    } else {
                        openSnackbarRed("Errore durante l'invio del messaggio");
                    }
                },
                err => {
                    console.log(err)
                },
            );
        }
    };

    const handleChangeForms = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangeCheckbox = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
    };

    const validateFormData = formValues => {
        const error = {};
        const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (formValues.email) {
            if (!regEmail.test(formValues.email)) {
                error.email = 'Email non valida';
            }
        } else {
            error.email = "Email obbligatoria";
        }
        const phoneRegex = /^[0-9]{8,12}$/;
        if (formValues.mobilePhone) {
            if (!phoneRegex.test(formValues.mobilePhone)) {
                error.mobilePhone = 'Telefono non valido';
            }
        } else {
            error.mobilePhone = "Telefono obbligatorio";
        }
        if (!formValues.messageText) {
            error.messageText = 'Messaggio obbligatorio';
        }
        if (!formValues.accept1) {
            error.accept1 = 'Campo obbligatorio'
        }
        setFormErrors(error);
        return error;
    };

    return (
        <div className="business-contact-us" id="business-contact-us">
            <div className="title">
                VUOI SAPERNE DI PIÙ SU MY-STORES?
            </div>
            <div className="subtitle">
                Compila il nostro form e ti ricontatteremo al più presto!
            </div>
            <div className="business-contact-us-form">
                <form autoComplete="off" onSubmit={onSubmit}>
                    <div className="grid">
                        <div className="grid-input">
                            <input
                                name="firstName"
                                placeholder="Nome"
                                type="text"
                                className=""
                                value={formData.firstName}
                                onChange={handleChangeForms}
                            />
                        </div>
                        <div className="grid-input">
                            <input
                                name="lastName"
                                placeholder="Cognome"
                                type="text"
                                className=""
                                value={formData.lastName}
                                onChange={handleChangeForms}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid-input">
                            <input
                                name="email"
                                placeholder="Email*"
                                type="text"
                                className=""
                                value={formData.email}
                                onChange={handleChangeForms}
                            />
                            {formErrors && formErrors.email && (
                                <span className="message_error">{formErrors.email}</span>
                            )}
                        </div>
                        <div className="grid-input">
                            <input
                                name="mobilePhone"
                                placeholder="Telefono*"
                                type="number"
                                className=""
                                value={formData.mobilePhone}
                                onChange={handleChangeForms}
                            />
                            {formErrors && formErrors.mobilePhone && (
                                <span className="message_error">{formErrors.mobilePhone}</span>
                            )}
                        </div>
                    </div>
                    <div className="activity">
                        <div className="text">Tipo di attività</div>
                        <div className="activity-checkbox">
                            <input
                                type="checkbox"
                                id="type1"
                                name="type1"
                                value="type1"
                                className="checkbox_tick"
                                checked={formData.type1}
                                onChange={handleChangeCheckbox}
                            />
                            <label htmlFor="type1" className="checkbox_label">
                                Negozio
                            </label>
                        </div>
                        <div className="activity-checkbox">
                            <input
                                type="checkbox"
                                id="type2"
                                name="type2"
                                value="type2"
                                className="checkbox_tick"
                                checked={formData.type2}
                                onChange={handleChangeCheckbox}
                            />
                            <label htmlFor="type2" className="checkbox_label">
                                Bar / Ristoranti
                            </label>
                        </div>
                        <div className="activity-checkbox">
                            <input
                                type="checkbox"
                                id="type3"
                                name="type3"
                                value="type3"
                                className="checkbox_tick"
                                checked={formData.type3}
                                onChange={handleChangeCheckbox}
                            />
                            <label htmlFor="type3" className="checkbox_label">
                                Servizi
                            </label>
                        </div>
                        <div className="activity-checkbox">
                            <input
                                type="checkbox"
                                id="type4"
                                name="type4"
                                value="type4"
                                className="checkbox_tick"
                                checked={formData.type4}
                                onChange={handleChangeCheckbox}
                            />
                            <label htmlFor="type4" className="checkbox_label">
                                Altro
                            </label>
                        </div>
                    </div>
                    <div className="message-box">
                        <textarea
                            name="messageText"
                            rows="8"
                            placeholder="Lascia un messaggio*"
                            value={formData.messageText}
                            onChange={handleChangeForms}
                        />
                        {formErrors && formErrors.messageText && (
                            <span className="message_error">{formErrors.messageText}</span>
                        )}
                    </div>
                    <div className="accept-checkbox">
                        <div className="accept-checkbox-content">
                            <input
                                type="checkbox"
                                id="accept1"
                                name="accept1"
                                value="accept1"
                                className="checkbox_tick"
                                checked={formData.accept1}
                                onChange={handleChangeCheckbox}
                            />
                            <label htmlFor="accept1" className="checkbox_label">
                                Ho letto e accetto la policy del sito*
                            </label>
                        </div>
                        {formErrors && formErrors.accept1 && (
                            <div className="message_error">{formErrors.accept1}</div>
                        )}
                    </div>
                    <div className="accept-checkbox">
                        <div className="accept-checkbox-content">
                            <input
                                type="checkbox"
                                id="accept2"
                                name="accept2"
                                value="accept2"
                                className="checkbox_tick"
                                checked={formData.accept2}
                                onChange={handleChangeCheckbox}
                            />
                            <label htmlFor="accept2" className="checkbox_label">
                                Desidero ricevere newsletter e/o messaggi promozionali
                            </label>
                        </div>
                    </div>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        ref={recaptchaRef}
                        onChange={() => setDisableButton(false)}
                        onExpired={() => setDisableButton(true)}
                    />
                    <button
                        type="submit"
                        disabled={disableButton}
                        className="contact-button">
                        Invia richiesta
                    </button>
                </form>
            </div>
        </div>
    );
}

export default BusinessContactUs;



