const fidelityColors = [
  "#8d1731",
  "#17768d",
  "#178d67",
  "#17438d",
  "#d1cf25",
  "#25bad1",
  "#178d18",
  "#4e7de0",
  "#4164d6",
  "#178d67",
];

const getFidelityColor = (index, offset = 0) => {
  const colorsLength = fidelityColors.length;
  let position = index + offset;

  while (position >= colorsLength) {
    position -= colorsLength;
  }

  return fidelityColors[position];
};

export { getFidelityColor };
