/* eslint-disable no-restricted-globals */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useCallback, memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ItemsCarousel from "react-items-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Map, Marker, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";
import { connect } from "react-redux";
import { compose } from "redux";
import injectSaga from "../../utils/injectSaga";
import { DAEMON } from "../../utils/constants";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { Loader, Button } from "semantic-ui-react";
import { useSnackbar } from "react-simple-snackbar";
import moment from "moment";
import saga from "../Homepage/saga";
import {
  getShopDetail,
  getShopGallary,
  shopGallaryResource,
  getShopProducts,
  getStoreOffers,
  shopBanners,
  getToken,
} from "../../Helpers/api";
import Footer from "../../components/footer/Footer";
import { userDetails } from "../../Helpers/UserDetails";
// import history from '../../utils/history';

import {
  promoItemImage,
  leftArrowMall,
  rightArrowMall,
  locator,
  // mallImage1,
  // mallImage2,
  // mallImage3,
  // mallImage4,
  // mallImage5,
  // mallImage6,
  // mallImage7,
  // mallImage8,
  mapLocator,
  heartCard,
  // shopShoes,
  heartFilled,
  productImagePlaceholder,
  aboutArrowLeft,
  aboutArrowRight,
  mallHeaderFallback,
} from "../../assets/images";
import "./ShopDetails.scss";
import ShopImageSlider from "./ShopImageSlider";
import OffersNewsPopup from "./offersNewsPopup/offersNewsPopup";
import ProductCard from "../../common/ProductCard/ProductCard";
import PromoProductModal from "../promoPage/promoProductModal/promoProductModal";

function ShopDetails(props) {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  // const [mapCentre, setMapCentre] = useState([45.323321536272049, -75.3372987731628]);
  const [mapCentre, setMapCentre] = useState([]);
  const [isCreateLoding, setIsCreateLoding] = useState(false);
  const [shopBanner, setShopBanners] = useState([
    {
      image: "",
    },
  ]);
  const [storeData, setStoreData] = useState();
  const [isStoreLoading, setIsStoreLoading] = useState(false);
  const [storeTags, setStoreTags] = useState([]);
  const [timeTable, setTimeTable] = useState("");
  const [shopGallary, setShopGallary] = useState([]);
  const [shopProducts, setShopProducts] = useState([]);
  const [shopOffers, setShopOffers] = useState([]);
  const [favStoresIds, setFavStoresId] = useState([]);
  const [isBannerImagePresent, setIBannerImagePresent] = useState(true);
  // const [isMallImagePresent, setIsMallImagePresent] = useState(true);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };
  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };
  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const chevronWidth = 40;
  const [openSnackbar] = useSnackbar({
    // position: 'top-center',
    style: {
      position: "absolute",
      backgroundColor: " baby blue",
      // border: '2px solid lightgreen',
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });

  useEffect(() => {
    const shopId = props.match.params.id;
    const apiUrl = `${getShopDetail}/${shopId}`;
    setIsStoreLoading(true);
    axios.get(apiUrl).then(
      (res) => {
        if (res.data && res.data.data && res.data.data.timetableforstore) {
          setTimeTable(res.data.data.timetableforstore.split("\r\n"));
        }
        setStoreData(res.data.data);
        setIsStoreLoading(false);
        if (
          res.data &&
          res.data.data &&
          res.data.data.address &&
          res.data.data.address.latitude
        ) {
          setMapCentre([
            res.data.data.address.latitude,
            res.data.data.address.longitude,
          ]);
        }
        setIsStoreLoading(false);
        const shopTimingArray = [];
        if (
          res.data &&
          res.data.data &&
          res.data.data.shopTimetables &&
          res.data.data.shopTimetables.length
        ) {
          res.data.data.shopTimetables.forEach((timing) => {
            const timingObj = {};
            timingObj.day = `${timing.startday} ${
              timing.endday ? `- ${timing.endday}` : ""
            }`;
            let timeString = "";
            if (timing && timing.timings && timing.timings.length) {
              timing.timings.forEach((dayTiming) => {
                if (dayTiming.timeOpen || dayTiming.timeClosed) {
                  const timeOpen = new Date(dayTiming.timeOpen);
                  const timeClose = new Date(dayTiming.timeClosed);
                  const secondes = timeClose.getMilliseconds();
                  const isValid = !isNaN(secondes);
                  if (isValid) {
                    timeString += `${moment(timeOpen).format(
                      "HH.mm"
                    )} - ${moment(timeClose).format("HH.mm")}`;
                  }
                }
              });
            }
            timingObj.dayTime = timeString;
            shopTimingArray.push(timingObj);
          });
          // setTimeTable(shopTimingArray);
        }
        // const timeArray = [];
        // if (res.data.data.timetables) {
        //   res.data.data.timetables.forEach(item => {
        //     const splitArray = item.split(/[ ,]+/);
        //     timeArray.push({
        //       day: splitArray[0],
        //       time: `${splitArray[1]}${splitArray[2]}${
        //         splitArray[3] ? splitArray[3] : ''
        //       }, ${splitArray[4] ? splitArray[4] : ''}`,
        //     });
        //   });
        //   setTimeTable(timeArray);
        //   console.log(shopGallary);
        // }
        // Set store tags
        if (res.data.data && res.data.data.tags) {
          const str = res.data.data.tags;
          const resTags = str.split(",");
          setStoreTags(resTags);
        }
      },
      (err) => {
        console.log(err);
        setIsStoreLoading(false);
      }
    );

    // Get Shop Image Gallary

    // const shopGallQueryUrl = `${getShopGallary}?type=store&storeId=001`;
    // console.log(shopGallQueryUrl);
    // axios.get(shopGallQueryUrl).then(
    //   res => {
    //     console.log(res);
    //     const imageArray = [];
    //     res.data.data.forEach(item => {
    //       const imgeUrl = `${shopGallaryResource}${item.image}`;
    //       const imgeUrlEncode = encodeURI(imgeUrl);
    //       console.log(imgeUrlEncode);
    //       imageArray.push(imgeUrlEncode);
    //       setShopGallary(imageArray);
    //     });

    //     // setShopGallary()
    //   },
    //   err => {
    //     console.log(err);
    //   },
    // );

    // Get the products in the shop

    axios({
      method: "get",
      url: `${getShopProducts}?store=${shopId}`,
      // auth: {
      //   username: userDetails().email,
      //   password: userDetails().password,
      // },
    }).then(
      (res) => {
        if (res.data && res.data?.data?.length) {
          setShopProducts(res.data.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );

    //  Get Shop offers
    axios({
      method: "get",
      url: `${getStoreOffers}?id=${shopId}&type=STORE`,
      // auth: {
      //   username: userDetails().email,
      //   password: userDetails().password,
      // },
    }).then(
      (res) => {
        if (res.data && res.data.data && res.data.data.offer) {
          const validOffers = res.data.data.offer.filter(
            (item) =>
              new Date(item.publishDate).valueOf() <= new Date().valueOf() &&
              new Date(item.validTo).valueOf() >= new Date().valueOf() &&
              item.enabled === true
          );
          setShopOffers(validOffers);
          // setShopOffers(res.data.data.offer);
        }
      },
      (err) => {
        console.log(err);
      }
    );

    // Get the shop banners
    let bannerUrl = `${shopBanners}?category=WEB&shopid=${shopId}`;
    if (isTabletOrMobile) {
      bannerUrl = `${shopBanners}?category=TABLET&shopid=${shopId}`;
    }

    axios({
      method: "get",
      url: bannerUrl,
      // url: `${shopBanners}?category=APP-HOME&storeId=014`,
    }).then(
      (res) => {
        console.log(res);
        if (res.data && res.data.length) {
          setShopBanners(res.data);
          // setShopBanners(res.data.data[res.data.data.length - 1].image);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    props.getFavStores();
    const shopId = props.match.params.id;
    const shopGallQueryUrl = `${getShopGallary}?type=Store&storeId=${shopId}`;
    // axios.get(shopGallQueryUrl)
    axios({
      method: "GET",
      url: shopGallQueryUrl,
      // auth: {
      //   username: 'g.scirea',
      //   password: 'testtest',
      //   // username: userDetails().email,
      //   // password: userDetails().password,
      // },
    }).then(
      (res) => {
        const imageArray = [];
        res.data.data.forEach((item) => {
          imageArray.push({ src: item.imageUrl, width: 4, height: 3 });
        });
        setTimeout(() => setShopGallary(imageArray), 1000);

        // setShopGallary()
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const handleModalOpen = (payload) => {
    setShowOfferModal(true);
    setModalData(payload);
    console.log(payload);
  };

  const craeteFavStore = (storeId, type) => {
    const payload = {
      storeId,
      type,
    };
    setIsCreateLoding(true);
    props.createFavStores(payload);
    setTimeout(() => props.getFavStores(), 1000);
  };
  const deleteFavStore = (storeId, type) => {
    setIsCreateLoding(true);
    props.deleteFavStores({ storeId, type });
    setTimeout(() => props.getFavStores(), 100);
  };

  const openUrl = (url) => {
    // Check if url include my-stores domain
    if (url.includes("my-stores")) {
      // if user is logged
      if (localStorage.getItem("token")) {
        // Get token
        axios({
          method: "get",
          url: getToken,
          auth: {
            username: userDetails().email,
            password: userDetails().password,
          },
        }).then(
          (res) => {
            if (res.data.data && res.data.data.token) {
              window.open(`${url}?token=${res.data.data.token}`);
            }
          },
          (err) => {
            window.open(url);
          }
        );
      } else {
        window.open(url);
      }
    } else {
      window.open(url);
    }
  };

  // Get Fav stores and mall Ids

  useEffect(() => {
    if (props.favStores) {
      const filterFavStoresId = [];
      if (
        props.favStores &&
        props.favStores.data &&
        props.favStores.data.data &&
        props.favStores.data.data.stores
      ) {
        props.favStores.data.data.stores.forEach((item) =>
          filterFavStoresId.push(item.id)
        );
      }
      setFavStoresId(filterFavStoresId);
    }
  }, [props.favStores]);

  useEffect(() => {
    // Update the document title using the browser API
    document.addEventListener("scroll", () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
    return () => {
      document.removeEventListener("scroll", () => {});
    };
  });
  useEffect(() => {
    setIsCreateLoding(false);
  }, [props.createFavStoresSuccess, props.deletedFavStoreSuccess]);

  const skater = new Icon({
    iconUrl: mapLocator,
    iconSize: [28, 35],
  });
  return (
    <div
      className={
        shopBanner && shopBanner.length > 1
          ? "mall_details_main_continer"
          : "mall_details_main_continer mall_details_dots"
      }
    >
      <header>
        <div className="nav_bar_container">
          {shopBanner && shopBanner.length > 0 && (
            <div className="home_carousae_container">
              <div className="home_page_background">
                <Carousel
                  autoPlay
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  infiniteLoop
                  interval={5000}
                  transitionTime={2000}
                  selectedItem={currentSlide}
                  onChange={updateCurrentSlide}
                >
                  {shopBanner.map((banner) => (
                    <div
                      className="first_photo"
                      style={{
                        backgroundImage:
                          isBannerImagePresent && banner && banner.image
                            ? `url(${banner.imageUrl})`
                            : `url(${mallHeaderFallback})`,
                      }}
                    >
                      {isBannerImagePresent && banner && banner.image && (
                        <img
                          style={{
                            width: "0",
                            visibility: "hidden",
                          }}
                          src={banner.imageUrl}
                          alt="default"
                          onError={(e) => {
                            console.log(e);
                            setIBannerImagePresent(false);
                          }}
                        />
                      )}
                      {banner.link && banner.link.length && (
                        <Button
                          className="landing_login_button homePageDiscover"
                          type="submit"
                          onClick={() => window.open(`http://${banner.link}`)}
                        >
                          {banner.buttonLabel !== ""
                            ? banner.buttonLabel
                            : "SCOPRI"}
                        </Button>
                      )}
                    </div>
                  ))}
                </Carousel>
                {shopBanner.length > 1 && (
                  <button
                    className="corousal_left_button"
                    type="button"
                    onClick={prev}
                  >
                    <img src={aboutArrowLeft} alt="left button" />
                  </button>
                )}
                {shopBanner.length > 1 && (
                  <button
                    className="corousal_right_button"
                    type="button"
                    onClick={next}
                  >
                    <img src={aboutArrowRight} alt="right arrow" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </header>
      {storeData && !isStoreLoading ? (
        <div className="mall_detail_content">
          <div className="mall_detail_content_description">
            {storeTags.map((tag) => (
              <span className="shop_tags">
                {tag ? `#${tag.trimStart()}` : ""}
              </span>
            ))}
            {/* <span className="shop_tags">#food</span> */}
            <div className="mall_name_container">
              <p className="mall_detail_content_description_name">
                {storeData.name}
              </p>
              {/* <img src={heartCard} alt="" className="mall_heart_image" /> */}
              {favStoresIds.includes(storeData.id) &&
              localStorage.getItem("token") ? (
                <button
                  className="mall_heart_button"
                  type="button"
                  onClick={() =>
                    !isCreateLoding && deleteFavStore(storeData.id, "store")
                  }
                >
                  <img src={heartFilled} alt="heart" />
                </button>
              ) : (
                <button
                  className="mall_heart_button"
                  type="button"
                  onClick={() => {
                    if (localStorage.getItem("token")) {
                      if (!isCreateLoding) {
                        craeteFavStore(storeData.id, "store");
                      }
                    } else {
                      openSnackbar(t("notLogged"));
                    }
                  }}
                >
                  <img src={heartCard} alt="heart" />
                </button>
              )}
            </div>
            <p
              className="mall_detail_content_description_content"
              dangerouslySetInnerHTML={{
                __html: storeData?.notes?.replace(/\n/gi, "<br />"),
              }}
            />
            <div
              className="shop-details-btn-div"
              style={{ display: "flex", marginBottom: "15px" }}
            >
              {storeData && storeData.webUrl && (
                <button
                  style={{ marginRight: "30px" }}
                  className="mall_view_go_to_site"
                  type="button"
                  id="button-id"
                  onClick={() => window.open(`${storeData.webUrl}`)}
                >
                  VAI AL SITO
                </button>
              )}
              {storeData && storeData.ecommerceUrl && (
                <button
                  style={{ marginRight: "30px" }}
                  className="mall_view_go_to_site"
                  type="button"
                  id="button-id"
                  onClick={() => openUrl(storeData.ecommerceUrl)}
                >
                  VAI ALL’ECOMMERCE
                </button>
              )}
              {storeData && storeData.bookingUrl && (
                <button
                  className="mall_view_go_to_site"
                  type="button"
                  id="button-id"
                  onClick={() => openUrl(storeData.bookingUrl)}
                >
                  PRENOTA UN SERVIZIO
                </button>
              )}
            </div>
          </div>
          {shopGallary && shopGallary.length > 0 && (
            <div className="shop_gallary">
              <ShopImageSlider images={shopGallary} />
            </div>
          )}

          {shopOffers && shopOffers.length > 0 && (
            <div className="mall_cards_carousal_container">
              <div>
                <div className="shop_offers_container">
                  <p className="mall_cards_carousal_container_heading">
                    <div className="shop_offers_container_title">
                      OFFERTE / NEWS
                    </div>
                  </p>
                  <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    // infiniteLoop={true}
                    numberOfCards={isTabletOrMobile ? 3 : 4}
                    gutter={20}
                    rightChevron={
                      <img src={rightArrowMall} alt="promo right icon" />
                    }
                    leftChevron={
                      <img
                        src={leftArrowMall}
                        //style={{ transform: 'rotate(180deg)' }}
                        alt="left"
                      />
                    }
                    //alwaysShowChevrons
                    classes={{
                      wrapper: "main_wrapper_cards_mall",
                      //itemsWrapper: 'mall_cards_item_wrapper',
                      itemWrapper: "mall_card_item_wrapper",
                      rightChevronWrapper: "right_button_mall",
                      leftChevronWrapper: "left_button_mall",
                    }}
                    chevronWidth={chevronWidth}
                  >
                    {shopOffers.map((item) => (
                      <div
                        className="shoppping_promo_card shoppping_promo_card_mall"
                        // eslint-disable-next-line react/no-array-index-key
                        key={item.id}
                        onClick={() =>
                          handleModalOpen({ offer: item, store: storeData })
                        }
                      >
                        <div className="img_card">
                          <img
                            src={item.imageUrl}
                            onError={(e) => {
                              e.target.src = promoItemImage;
                            }}
                            alt="shopping centre"
                            className="promo_mage shop_Offer_Image"
                          />
                        </div>
                        <p className="promo_name">{item.title}</p>
                      </div>
                    ))}
                  </ItemsCarousel>
                  <OffersNewsPopup
                    showOfferModal={showOfferModal}
                    setShowOfferModal={setShowOfferModal}
                    modalData={modalData}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="malls_shops" style={{ marginTop: "35px" }}>
            <section className="shopping_center_products">
              {shopProducts && shopProducts.length > 0 && (
                <p className="shopping_center_heading">PRODOTTI IN EVIDENZA</p>
              )}
              <div className="shopping_center_details_container_products malls_shops_container">
                {shopProducts.length > 0
                  ? shopProducts.map((product) => (
                      <ProductCard product={product} />
                      //   <div
                      //     className="shoppping_centre_product shoppping_centre_product_card"
                      //     key={product.id}
                      //   >
                      //     <img
                      //       src={
                      //         product.imageUrl
                      //           ? product.imageUrl
                      //           : productImagePlaceholder
                      //       }
                      //       alt="shopping centre"
                      //       onError={(e) => {
                      //         e.target.src = productImagePlaceholder;
                      //       }}
                      //     />
                      //     <p className="shop_product_name">
                      //       {product.description}
                      //     </p>
                      //     {parseInt(product.rowDiscount, 10) > 0 && (
                      //       <p className="promo_text">
                      //         {`Promozione - ${product.rowDiscount}%`}
                      //       </p>
                      //     )}
                      //     <div className="old_new_price_container">
                      //       {parseInt(product.rowDiscount, 10) > 0 && (
                      //         <span className="old_price">€{product.price}</span>
                      //       )}
                      //       <span className="new_price">€{product.netPrice}</span>
                      //     </div>
                      //   </div>
                    ))
                  : null}
              </div>
            </section>
          </div>
          <div className="mall_mapview_container">
            <div className="mall_mapview_card">
              <div className="mall_mapview_card_details">
                <p className="mall_mapview_card_details_heading shop_map_heading">
                  INFORMAZIONI UTILI
                </p>
                {timeTable && (
                  <div>
                    <p className="mall_mapview_card_details_sub_heading">
                      ORARI DI APERTURA
                    </p>
                    {timeTable.map((item) => (
                      <div>
                        <div className="mall_timing_details_container">
                          <span className="mall_timing_details_name">
                            {item}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <p className="mall_mapview_card_details_sub_heading">
                  DOVE SIAMO
                </p>
                {storeData && storeData.address && storeData.address.address && (
                  <div className="mall_timing_address_container">
                    <img src={locator} alt="" />
                    <p className="mall_centre_address">
                      {`${storeData.address.address},  ${storeData.address.city},  ${storeData.address.state},  ${storeData.address.zip}, ${storeData.address.county}`}
                    </p>
                  </div>
                )}
              </div>
              {mapCentre &&
                mapCentre.length > 0 &&
                mapCentre[0] &&
                mapCentre[1] && (
                  <div className="malll_mapview_card_map">
                    <Map
                      center={mapCentre}
                      zoom={14}
                      zoomControl={false}
                      scrollWheelZoom={false}
                      doubleClickZoom={false}
                      dragging={false}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      />
                      <Marker
                        key={1}
                        position={[mapCentre[0], mapCentre[1]]}
                        icon={skater}
                        onClick={(e) =>
                          window.open(
                            `/mall-map?lat=${e.latlng.lat}&lng=${e.latlng.lng}`
                          )
                        }
                      />
                    </Map>
                  </div>
                )}
            </div>
          </div>
        </div>
      ) : (
        <Loader active inline className="shop_loader" />
      )}
    </div>
  );
}

ShopDetails.propTypes = {
  match: PropTypes.object,
  favStores: PropTypes.object,
  getFavStores: PropTypes.object,
  deleteFavStores: PropTypes.object,
  createFavStores: PropTypes.object,
  createFavStoresSuccess: PropTypes.object,
  deletedFavStoreSuccess: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
  return {
    getMallsStores: (payload) =>
      dispatch({ type: "GET_MALLS_STORES", payload }),
    searchMallsStores: (payload) =>
      dispatch({ type: "SEARCH_MALLS_STORES", payload }),
    getFavStores: (payload) =>
      dispatch({ type: "GET_FAVOURITE_STORES", payload }),
    deleteFavStores: (payload) =>
      dispatch({ type: "DELETE_FAVOURITE_STORES", payload }),
    createFavStores: (payload) =>
      dispatch({ type: "CREATE_FAVOURITE_STORE", payload }),
  };
}
export function mapStateToProps(state) {
  return {
    mallsAndStores: state.homePageFav.mallsStores,
    favStores: state.homePageFav.favStores,
    searchedMallsAndStores: state.homePageFav,
    createFavStoresSuccess: state.homePageFav.createdFavStore,
    deletedFavStoreSuccess: state.homePageFav.deletedFavStore,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: "mallsStores", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(ShopDetails);
