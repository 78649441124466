/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// /* eslint-disable prettier/prettier */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-template */
/* eslint-disable operator-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, memo, useEffect } from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  Grid,
  Icon,
  Modal,
  Loader,
  Dropdown,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import ItemsCarousel from "react-items-carousel";
import { useMediaQuery } from "react-responsive";
import { connect } from "react-redux";
import { compose } from "redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import injectSaga from "../../utils/injectSaga";
import { DAEMON } from "../../utils/constants";
import history from "../../utils/history";
import {
  getCities,
  setPrefferedCity,
  updateUserPassword,
  getPrivacyPolicy,
  getAllOrders,
  cancelOrder,
  deletePassword,
  shopGallaryResource,
} from "../../Helpers/api";
import { userDetails } from "../../Helpers/UserDetails";
import saga from "./saga";
import {
  filter,
  promoRightIcon,
  profileOrderShoe,
  printer,
  orderCalendar,
  closeModal,
} from "../../assets/images";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/footer/Footer";
import CityDropdown from "../../common/CityDropdown";
import "./UserProfile.scss";
function UserProfile(props) {
  const { t } = useTranslation();
  const [cardNavPosition, setCardNavPosition] = useState(1);
  const [productSearchName, setProductSearchName] = useState("");
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [showNewAddressForm4, setShowNewAddressForm4] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelOrderLoading, setIsCancelOrderLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [newShippingAddress, setNewShippingAddress] = useState({});
  const [newBillingAdressDetails, setNewBillingAdressDetails] = useState({});
  const [privacyPolicy, setPrivacyPolicy] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [initialOrderDetails, setInitialOrderDetails] = useState([]);
  const [isOrderIdDetailsLoading, setIsOrderIdDetailsLoading] = useState(false);
  const [printDetails, setPrintDetails] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);

  const [selectedCity, setSlectedCity] = useState({ value: "", label: "" });

  const [openSnackbar] = useSnackbar({
    style: {
      position: "absolute",
      backgroundColor: "green",
      border: "2px solid lightgreen",
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });
  const [openSnackbarRed] = useSnackbar({
    style: {
      position: "absolute",
      backgroundColor: "red",
      border: "2px solid lightgreen",
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });

  const [editShippingFormDetails, setEditShippingFormDetails] = useState({});
  const [editBillingAdressDetails, setEditBillingAdressDetails] = useState({});
  const [deletePasswordError, setDeletePasswordError] = useState({});
  const [isDeleteUserLoading, setIsDeleteUserLoading] = useState(false);
  const [isShippDeleted, setIsShippDeleted] = useState(false);
  const [isBillingDeleted, setIsBillingDeleted] = useState(false);
  const [profileDeletePassword, setProfileDeletePassword] = useState("");
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [orderStatusFilter, setOrderStatusFilter] = useState({
    toBeprepared: false,
    aborted: false,
    closed: false,
    showAll: false,
  });
  const orderStatus = {
    TO_BE_PREPARED: "To be prepared",
    OPEN: "Open",
    FROZEN: "Frozen",
    ABORTED: "Aborted",
    PAYMENT_PENDING: "Payment pending",
    PAYMENT_COMPLETED: "Payment completed",
    TO_BE_ASSIGNED: "To be assigned",
    ASSIGNED: "Assigned",
    IN_PREPARATION: "In preparation",
    PREPARED: "Prepared",
    TO_BE_COLLECTED: "To be collected",
    TO_BE_DELIVERED: "To be delivered",
    TO_BE_SHIPPED: "To be shipped",
    ISSUING_RECEIPT: "Issuing receipt",
    COLLECTED: "Collected",
  };
  let mandIndex = 0;
  let optIndex = 2;

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const [isScrolled, setIsScrolled] = useState(false);
  const [dateValue, setDateValue] = useState({
    dateFrom: "",
    dateTill: "",
  });

  const componentRef = useRef();
  const chevronWidth = 40;
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    date: "",
    gender: "",
    homePhone: "",
    city: "",
    type0: false,
    dateFrom: "",
    email: "",
    shippingAddresses: [],
    billingAddresses: [],
  });
  const options = [
    { key: "m", text: "Male", value: "male" },
    { key: "f", text: "Female", value: "female" },
    { key: "o", text: "Other", value: "other" },
  ];
  const handleCardNavigation = (postion) => {
    setCardNavPosition(postion);
  };
  // const handleModalDateChange = (event, { name, value }) => {
  //   setDateValue({ ...dateValue, [name]: value });
  // };
  const onSelectPrivacyPoints = (e) => {
    setValues({ ...values, [e.target.name]: e.target.checked });
  };
  const onSelectStatusPoints = (e) => {
    setOrderStatusFilter({
      ...orderStatusFilter,
      [e.target.name]: e.target.checked,
    });
  };
  const handlePersonalDetailChange = (event, { name, value }) => {
    setValues({ ...values, [name]: value });
  };

  const handleShippingAddressChanges = (event, { name, value }) => {
    setEditShippingFormDetails({ ...editShippingFormDetails, [name]: value });
  };

  const handleNewShippingAddressChanges = (event, { name, value }) => {
    setNewShippingAddress({ ...newShippingAddress, [name]: value });
  };
  const handleBillAddressChange = (event, { name, value }) => {
    setEditBillingAdressDetails({ ...editBillingAdressDetails, [name]: value });
  };

  const handleNewBillAddressChange = (event, { name, value }) => {
    setNewBillingAdressDetails({ ...newBillingAdressDetails, [name]: value });
  };

  const validateData = (data, type) => {
    const errors = {};
    if (type == "basicDetail") {
      if (!data.firstName) errors.firstName = t("userSignupValidation.name");
      if (!data.lastName) errors.lastName = t("userSignupValidation.lastName");
      if (!data.gender) errors.gender = t("userSignupValidation.gender");
      if (
        data.homePhone &&
        (data.homePhone.length < 8 || data.homePhone.length > 14)
      ) {
        errors.homePhone = t("userSignupValidation.phoneValidation");
      }
      if (!selectedCity.value)
        errors.selectedCity = t("userSignupValidation.city");
      if (data.date) {
        const today = new Date();
        const birthDate = new Date(data.date);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age -= 1;
        }
        const finalAge = age;
        if (finalAge < 18) errors.date = t("userSignupValidation.dobValidate");
      }
      return errors;
    }
    if (type == "credentials") {
      const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (data.email) {
        if (!regEmail.test(data.email)) {
          errors.email = t("userSignupValidation.emailInvalid");
        }
      } else {
        errors.email = t("userSignupValidation.emptyEmail");
      }
      if (!data.password)
        errors.password = t("userSignupValidation.passwordEmpty");

      if (data.confirmPassword !== data.password)
        errors.confirmPassword = t(
          "userSignupValidation.confrmPasswordNotSame"
        );
      return errors;
    }

    if (type == "shipping") {
      if (!data.description) {
        errors.description = t("userSignupValidation.requiredField");
      }
      if (!data.firstName) {
        errors.firstName = t("userSignupValidation.requiredField");
      }
      if (!data.phone) {
        errors.phone = t("userSignupValidation.requiredField");
      }
      if (!data.address) {
        errors.address = t("userSignupValidation.requiredField");
      }
      if (!data.streetNumber) {
        errors.streetNumber = t("userSignupValidation.requiredField");
      }
      if (!data.zip) {
        errors.zip = t("userSignupValidation.requiredField");
      }
      if (!data.city) {
        errors.city = t("userSignupValidation.requiredField");
      }
      if (!data.province) {
        errors.province = t("userSignupValidation.requiredField");
      }
      return errors;
    }

    if (type == "billing") {
      if (!data.description) {
        errors.description = t("userSignupValidation.requiredField");
      }
      if (!data.vatCode) {
        errors.vatCode = t("userSignupValidation.requiredField");
      }
      if (!data.phone) {
        errors.phone = t("userSignupValidation.requiredField");
      }
      if (!data.address) {
        errors.address = t("userSignupValidation.requiredField");
      }
      if (!data.streetNumber) {
        errors.streetNumber = t("userSignupValidation.requiredField");
      }
      if (!data.zip) {
        errors.zip = t("userSignupValidation.requiredField");
      }
      if (!data.city) {
        errors.city = t("userSignupValidation.requiredField");
      }
      if (!data.province) {
        errors.province = t("userSignupValidation.requiredField");
      }
      if (!data.destinationCode) {
        errors.destinationCode = t("userSignupValidation.requiredField");
      }
      return errors;
    }
  };
  useEffect(() => {
    // Update the document title using the browser API
    document.addEventListener("scroll", () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 1 && !isScrolled) {
        setIsScrolled(true);
      } else if (isScrolled && scrolled < 1) {
        setIsScrolled(false);
      }
    });

    return () => {
      document.removeEventListener("scroll", () => {});
    };
  });

  const updatePersonalDetails = (addressType) => {
    setIsUpdated(values);
    if (addressType === "basicDetail") {
      const basicDetailErrors = validateData(values, "basicDetail");
      setFormErrors({ ...formErrors, basicDetailErrors });
      if (Object.keys(basicDetailErrors).length === 0) {
        const updateCityUrl = `${setPrefferedCity}/${selectedCity.value}`;
        axios(updateCityUrl, {
          method: "PUT",
          auth: {
            username: userDetails().email,
            password: userDetails().password,
          },
        }).then(
          (res) => {
            if (
              res &&
              res.data &&
              res.data.data &&
              res.data.data.preferredcityid
            ) {
              // localStorage.setItem("zipCode", res.data.data.preferredcityid);
              localStorage.setItem(
                "homeCity",
                JSON.stringify({
                  zipCode: res.data.data.preferredcityid,
                  zipRange: false,
                  istat: "",
                })
              );
              props.updateUserProfile({
                ...values,
                birthDate: moment(values.date).format("YYYY-MM-DD"),
                addresses: values.shippingAddresses,
                billingAddresses: values.billingAddresses,
              });
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
    if (addressType === "shipping") {
      const errors = validateData(editShippingFormDetails, "shipping");
      setFormErrors({ ...formErrors, editShippingError: errors });
      if (Object.keys(errors).length === 0) {
        const shippIndex = values.shippingAddresses.findIndex(
          (item) => item.id === editShippingFormDetails.id
        );
        values.shippingAddresses[shippIndex] = editShippingFormDetails;
        props.updateUserProfile({
          ...values,
          birthDate: moment(values.date).format("YYYY-MM-DD"),
          addresses: values.shippingAddresses,
          billingAddresses: values.billingAddresses,
        });
      }
    }

    if (addressType === "billing") {
      const errors = validateData(editBillingAdressDetails, "billing");
      setFormErrors({ ...formErrors, editBillingAdressDetails: errors });
      if (Object.keys(errors).length === 0) {
        const billIndex = values.billingAddresses.findIndex(
          (item) => item.id === editBillingAdressDetails.id
        );
        values.billingAddresses[billIndex] = editBillingAdressDetails;
        props.updateUserProfile({
          ...values,
          birthDate: moment(values.date).format("YYYY-MM-DD"),
          addresses: values.shippingAddresses,
          billingAddresses: values.billingAddresses,
        });
      }
    }

    // const updateCityUrl = `${setPrefferedCity}/${selectedCity.value}`;
    // axios(updateCityUrl, {
    //   method: "PUT",
    //   auth: {
    //     username: userDetails().email,
    //     password: userDetails().password,
    //   },
    // }).then(
    //   (res) => {
    //     if (res && res.data && res.data.data && res.data.data.preferredcityid) {
    //       localStorage.setItem("zipCode", res.data.data.preferredcityid);
    //       props.updateUserProfile({
    //         ...values,
    //         birthDate: moment(values.date, "DD/MM/YYYY").format(
    //           "YYYY-MM-DD[T]HH:mm:ss"
    //         ),
    //         addresses: values.shippingAddresses,
    //         billingAddresses: values.billingAddresses,
    //       });
    //     }
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  };

  // Add new address

  const updateNewAddressDetails = (addressType) => {
    if (addressType === "shipping") {
      const errors = validateData(newShippingAddress, "shipping");
      setFormErrors({ ...formErrors, shippingErrors: errors });
      if (Object.keys(errors).length === 0) {
        values.shippingAddresses.push(newShippingAddress);
        props.updateUserProfile({
          ...values,
          birthDate: moment(values.date).format("YYYY-MM-DD"),
          addresses: values.shippingAddresses,
          billingAddresses: values.billingAddresses,
        });
      }
    }
    if (addressType === "billing") {
      const errors = validateData(newBillingAdressDetails, "billing");
      setFormErrors({ ...formErrors, billingErrors: errors });
      if (Object.keys(errors).length === 0) {
        values.billingAddresses.push(newBillingAdressDetails);
        props.updateUserProfile({
          ...values,
          birthDate: moment(values.date).format("YYYY-MM-DD"),
          addresses: values.shippingAddresses,
          billingAddresses: values.billingAddresses,
        });
      }
    }

    // props.updateUserProfile({
    //   ...values,
    //   birthDate: moment(values.date, "DD/MM/YYYY").format(
    //     "YYYY-MM-DD[T]HH:mm:ss"
    //   ),
    //   addresses: values.shippingAddresses,
    //   billingAddresses: values.billingAddresses,
    // });
  };

  //  Delete shipping address

  const deleteAddressDetails = (addressId, addressType) => {
    let filteredShippingAddress = [];
    let filteredBillingAddress = [];
    if (addressType === "shipping") {
      setIsShippDeleted(true);
      filteredShippingAddress = values.shippingAddresses.filter(
        (item) => item.id !== addressId
      );
    }
    if (addressType === "billing") {
      setIsBillingDeleted(true);
      filteredBillingAddress = values.billingAddresses.filter(
        (item) => item.id !== addressId
      );
    }

    props.updateUserProfile({
      ...values,
      birthDate: moment(values.date).format("YYYY-MM-DD"),
      addresses: filteredShippingAddress,
      billingAddresses: filteredBillingAddress,
    });
  };

  const setShippFormData = (address) => {
    setEditShippingFormDetails({
      ...address,
    });
  };
  const setBillFormData = (address) => {
    setEditBillingAdressDetails({
      ...address,
    });
  };
  useEffect(() => {
    setIsFirstLoading(true);
    props.getUserProfile();
    // Get the order details
    axios
      .get(getAllOrders, {
        auth: {
          username: userDetails().email,
          password: userDetails().password,
        },
      })
      .then(
        (res) => {
          setOrderDetails(res.data.data);
          setInitialOrderDetails(res.data.data);
        },
        (err) => {
          console.log(err);
        }
      );
  }, []);

  // GET UPDATE PROFILE INFO

  useEffect(() => {
    if (props.userProfileInfo.updateUserProfileData && isUpdated) {
      // setIsLoading(props.userProfileInfo.updateUserLoading);
      setIsLoading(props.userProfileInfo.updateUserLoading);
      if (
        !props.userProfileInfo.updateUserLoading &&
        props.userProfileInfo.updateUserProfileData
      ) {
        // openSnackbar('Record Updated Successfully');
        openSnackbar(t("recordUpdateSuccess"));
        setNewShippingAddress({
          description: "",
          firstName: "",
          lastName: "",
          phone: "",
          address: "",
          streetNumber: "",
          zip: "",
          city: "",
          province: "",
        });
        setNewBillingAdressDetails({
          description: "",
          vatCode: "",
          fiscalCode: "",
          phone: "",
          address: "",
          streetNumber: "",
          zip: "",
          city: "",
          province: "",
          fax: "",
          pec: "",
          destinationCode: "",
          email: "",
        });
        setShowNewAddressForm(false);
        setShowNewAddressForm4(false);
      } else if (
        !props.userProfileInfo.updateUserProfileData &&
        props.userProfileInfo.updateUserProfileError
      ) {
        openSnackbarRed(t("somethingWentWrong"));
      }
      const pattern = /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/gi;
      if (
        props.userProfileInfo &&
        props.userProfileInfo.updateUserProfileData &&
        props.userProfileInfo.updateUserProfileData.data &&
        props.userProfileInfo.updateUserProfileData
      ) {
        setValues({
          ...props.userProfileInfo.updateUserProfileData.data.data,
          firstName:
            props.userProfileInfo.updateUserProfileData.data.data.firstName,
          lastName:
            props.userProfileInfo.updateUserProfileData.data.data.lastName,
          date: props.userProfileInfo.updateUserProfileData.data.data.birthDate
            ? new Date(
                props.userProfileInfo.updateUserProfileData.data.data.birthDate
              )
            : "",
          homePhone:
            props.userProfileInfo.updateUserProfileData.data.data.homePhone,
          gender: props.userProfileInfo.updateUserProfileData.data.data.gender,
          email: props.userProfileInfo.updateUserProfileData.data.data.email,
          shippingAddresses:
            props.userProfileInfo.updateUserProfileData.data.data.addresses,
          billingAddresses:
            props.userProfileInfo.updateUserProfileData.data.data
              .billingAddresses,
        });
      }
    }
  }, [
    props.userProfileInfo.updateUserLoading,
    props.userProfileInfo.updateUserProfileData,
    props.userProfileInfo.updateUserProfileError,
  ]);

  useEffect(() => {
    if (
      props.userProfile &&
      props.userProfile.data &&
      props.userProfile.data.data
    ) {
      setIsFirstLoading(false);
      const pattern = /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/gi;
      setValues({
        ...props.userProfile.data.data,
        firstName: props.userProfile.data.data.firstName,
        lastName: props.userProfile.data.data.lastName,
        date: props.userProfile.data.data.birthDate
          ? new Date(props.userProfile.data.data.birthDate)
          : // .match(pattern)[0]
            // .split("-")
            // .reverse()
            // .join("-")
            "",
        homePhone: props.userProfile.data.data.homePhone,
        gender: props.userProfile.data.data.gender,
        email: props.userProfile.data.data.email,
        shippingAddresses: props.userProfile.data.data.addresses,
        billingAddresses: props.userProfile.data.data.billingAddresses,
        accept1: props.userProfile.data.data.accept1,
        accept2: props.userProfile.data.data.accept2,
        accept3: props.userProfile.data.data.accept3,
        accept4: props.userProfile.data.data.accept4,
      });
      // setSlectedCity(props.userProfile.data.data.preferredcityid);

      // setSlectedCity(localStorage.getItem("zipCode"));
      const homeCity = JSON.parse(localStorage.getItem("homeCity"));
      if (homeCity && homeCity.zipCode) {
        const defaultCity = citiesOptions.find(
          (city) => city.value === homeCity.zipCode
        );
        setSlectedCity(defaultCity);
      }
      // if (localStorage.getItem("zipCode")) {
      //   const defaultCity = citiesOptions.find(
      //     (city) => city.value === localStorage.getItem("zipCode")
      //   );
      //   setSlectedCity(defaultCity);
      // }
    }
  }, [props.userProfile]);

  useEffect(() => {
    // Get the cities options

    // axios.get(getCities).then(
    //   (res) => {
    //     const cityArray = [];
    //     res.data.data.forEach((item) => {
    //       cityArray.push({
    //         key: item.id,
    //         value: item.id,
    //         text: `${item.city} [${item.id}]`,
    //       });
    //     });
    //     setCitiesOptions(cityArray);
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
    if (props.allCities && props.allCities.length) {
      const cityWithoutAllZips = props.allCities.filter(
        (city) => !city.zipRange
      );
      setCitiesOptions(cityWithoutAllZips);
      // setCitiesOptions(props.allCities);
    }

    // Get Privacy policy
    axios.get(getPrivacyPolicy).then(
      (res) => {
        setPrivacyPolicy(res.data.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  // Update passeword if values is there
  const updatePassword = () => {
    const credentialErrors = validateData(values, "credentials");
    setFormErrors({ ...formErrors, credentialErrors });
    if (Object.keys(credentialErrors).length === 0) {
      if (values.password && values.confirmPassword) {
        axios(updateUserPassword, {
          method: "POST",
          auth: {
            username: userDetails().email,
            password: userDetails().password,
          },
          data: {
            oldPassword: userDetails().password,
            newPassword: values.confirmPassword,
          },
        }).then(
          (res) => {
            console.log(res);
            setValues({
              ...values,
              password: "",
              confirmPassword: "",
            });
            props.updateUserProfile({
              ...values,
              birthDate: moment(values.date).format("YYYY-MM-DD"),
              // birthDate: moment(values.date, "DD/MM/YYYY").format(
              //   "YYYY-MM-DD[T]HH:mm:ss"
              // ),
            });
            openSnackbar(t("passworedUpdated"));
          },
          (err) => {
            console.log(err);
            openSnackbarRed(t("somethingWentWrong"));
          }
        );
      }
    }
  };
  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return {
      dt,
      month,
      year,
    };
  };

  const setPrintOrderDetaild = (orderId) => {
    setIsOrderIdDetailsLoading(true);
    axios(`${getAllOrders}/${orderId}`, {
      method: "GET",
      auth: {
        username: userDetails().email,
        password: userDetails().password,
      },
    }).then(
      (res) => {
        setIsOrderIdDetailsLoading(false);
        setPrintDetails(res.data.data);
      },
      (err) => {
        setIsOrderIdDetailsLoading(false);
        console.log(err);
      }
    );
  };

  const handleCancelOrder = (id) => {
    setIsCancelOrderLoading(true);
    axios
      .post(
        cancelOrder,
        {
          orderId: id,
          event: "PROFILE",
        },
        {
          auth: {
            username: userDetails().email,
            password: userDetails().password,
          },
        }
      )
      .then(
        (resPonse) => {
          console.log(resPonse);
          setIsCancelOrderLoading(false);
          openSnackbar(t("OrderCanceledSuccess"));
          // Get the New order details
          axios
            .get(getAllOrders, {
              auth: {
                username: userDetails().email,
                password: userDetails().password,
              },
            })
            .then(
              (res) => {
                setOrderDetails(res.data.data);
              },
              (err) => {
                console.log(err);
              }
            );
        },
        (err) => {
          // openSnackbarRed('Something went wrong !');
          openSnackbarRed(t("somethingWentWrong"));
          setIsCancelOrderLoading(false);
          console.log(err);
        }
      );
  };

  const validateDeletePasswordData = () => {
    const error = {};
    if (profileDeletePassword) {
      if (userDetails().password !== profileDeletePassword) {
        error.password = "Please enter correct password !";
      }
    } else {
      error.password = "Password can't be empty";
    }
    setDeletePasswordError(error);
    return error;
  };

  const handleDelteAccount = () => {
    const errors = validateDeletePasswordData();
    if (Object.keys(errors).length === 0) {
      setIsDeleteUserLoading(true);
      axios
        .post(
          deletePassword,
          {
            profileId: "aaa",
          },
          {
            auth: {
              username: userDetails().email,
              password: userDetails().password,
            },
          }
        )
        .then(
          (res) => {
            setIsDeleteUserLoading(false);
            console.log(res);
            // openSnackbar('Your profile Profile has been deleted !');
            openSnackbar(t("profileDeleteSuccess"));
            setProfileDeletePassword("");
            history.push("/");
            localStorage.removeItem("token");
            localStorage.removeItem("zipCode");
            localStorage.removeItem("searchHistory");
          },
          (err) => {
            openSnackbarRed(t("somethingWentWrong"));
            setIsDeleteUserLoading(false);
            console.log(err);
          }
        );
    }
  };

  const serachProduct = () => {
    const newOrderProductDetails = initialOrderDetails;
    const newObj = newOrderProductDetails.filter((item) =>
      item.code.startsWith(productSearchName.toLowerCase())
    );
    setOrderDetails(newObj);
  };

  const handleOrderFilter = () => {
    // date filters
    // const mydate = moment(dateValue.dateFrom, "DD/MM/YYYY");
    const dateFrom = moment(dateValue.dateFrom).format("MM/DD/YYYY");

    // const mydate2 = moment(dateValue.dateTill, "DD/MM/YYYY");
    const dateTill = moment(dateValue.dateTill).format("MM/DD/YYYY");

    // format that date into a different format
    // moment(mydate).format("MM/DD/YYYY");

    const newDateFrom = new Date(dateFrom).getTime();
    const newDateTill = new Date(dateTill).getTime();

    let filteredOrdersArray = [];
    const newOrderProductDetails = initialOrderDetails;
    if (orderStatusFilter.showAll) {
      if (dateValue.dateFrom && dateValue.dateFrom) {
        filteredOrdersArray = newOrderProductDetails.filter((item) => {
          const dateStr = new Date(item.updated);
          const newDateStr = dateStr.toLocaleDateString();
          const time = new Date(newDateStr).getTime();
          return newDateFrom <= time && time <= newDateTill;
        });
      } else {
        filteredOrdersArray = initialOrderDetails;
      }
      // setOrderStatusFilter({
      //   toBeprepared: false,
      //   aborted: false,
      //   closed: false,
      //   showAll: false,
      // });
      // setDateValue({
      //   dateFrom: "",
      //   dateTill: "",
      // });
    } else {
      if (
        !orderStatusFilter.toBeprepared &&
        !orderStatusFilter.closed &&
        !orderStatusFilter.open &&
        dateValue.dateFrom &&
        dateValue.dateFrom
      ) {
        filteredOrdersArray = newOrderProductDetails.filter((item) => {
          const dateStr = new Date(item.updated);
          const newDateStr = dateStr.toLocaleDateString();
          const time = new Date(newDateStr).getTime();
          return newDateFrom <= time && time <= newDateTill;
        });
      } else if (
        orderStatusFilter.closed &&
        !orderStatusFilter.open &&
        !orderStatusFilter.toBeprepared
      ) {
        filteredOrdersArray = newOrderProductDetails.filter((item) => {
          const dateStr = new Date(item.updated);
          const newDateStr = dateStr.toLocaleDateString();
          const time = new Date(newDateStr).getTime();
          return (
            item.status === "CLOSED" &&
            newDateFrom <= time &&
            time <= newDateTill
          );
        });
      } else if (orderStatusFilter.open) {
        filteredOrdersArray = newOrderProductDetails.filter(
          (item) => item.status === "OPEN"
        );
      } else if (
        orderStatusFilter.toBeprepared &&
        !orderStatusFilter.closed &&
        !orderStatusFilter.open &&
        dateValue.dateFrom &&
        dateValue.dateFrom
      ) {
        filteredOrdersArray = newOrderProductDetails.filter((item) => {
          const dateStr = new Date(item.updated);
          const newDateStr = dateStr.toLocaleDateString();
          const time = new Date(newDateStr).getTime();
          return (
            item.status === "TO_BE_PREPARED" &&
            newDateFrom <= time &&
            time <= newDateTill
          );
        });
      } else if (
        orderStatusFilter.toBeprepared &&
        !orderStatusFilter.closed &&
        !orderStatusFilter.open &&
        !dateValue.dateFrom &&
        !dateValue.dateFrom
      ) {
        filteredOrdersArray = newOrderProductDetails.filter(
          (item) => item.status === "TO_BE_PREPARED"
        );
      } else if (
        orderStatusFilter.toBeprepared &&
        orderStatusFilter.closed &&
        !orderStatusFilter.open
      ) {
        filteredOrdersArray = newOrderProductDetails.filter(
          (item) => item.status === "TO_BE_PREPARED" || item.status === "CLOSED"
        );
      } else if (
        orderStatusFilter.toBeprepared &&
        !orderStatusFilter.closed &&
        orderStatusFilter.open
      ) {
        filteredOrdersArray = newOrderProductDetails.filter(
          (item) => item.status === "TO_BE_PREPARED" || item.status === "OPEN"
        );
      } else if (
        orderStatusFilter.toBeprepared &&
        orderStatusFilter.closed &&
        orderStatusFilter.open
      ) {
        filteredOrdersArray = newOrderProductDetails.filter(
          (item) =>
            item.status === "TO_BE_PREPARED" ||
            (item.status === "CLOSED" && item.status === "OPEN")
        );
      } else {
        filteredOrdersArray = initialOrderDetails;
      }
    }
    setOrderDetails(filteredOrdersArray);
    // setOrderStatusFilter({
    //   toBeprepared: false,
    //   aborted: false,
    //   closed: false,
    //   showAll: false,
    // });
    // setDateValue({
    //   dateFrom: "",
    //   dateTill: "",
    // });
    setShowFilterModal(false);
  };

  return (
    <div>
      {/* <NavBar isScrolled={isScrolled} profileView /> */}
      <div
        className={
          cardNavPosition === 5
            ? "user_details_content user_detailed_second_section_pdf"
            : "user_details_content"
        }
      >
        <div className="user_details_navigation_card">
          <div className="user_details_username_container">
            <p className="user_details_username">Ciao, {userDetails().name}!</p>
          </div>
          <button
            className={
              cardNavPosition === 1
                ? "user_details_navigation_card_section_one card_naviation_active"
                : "user_details_navigation_card_section_one"
            }
            type="button"
            onClick={() => handleCardNavigation(1)}
          >
            {cardNavPosition === 1 && <span className="selected_scroll" />}
            ANAGRAFICA
          </button>
          <button
            className={
              cardNavPosition === 2
                ? "user_details_navigation_card_section_one card_naviation_active"
                : "user_details_navigation_card_section_one"
            }
            type="button"
            onClick={() => handleCardNavigation(2)}
          >
            {cardNavPosition === 2 && <span className="selected_scroll" />}
            IL TUO ACCOUNT
          </button>
          <button
            className={
              cardNavPosition === 3
                ? "user_details_navigation_card_section_one card_naviation_active"
                : "user_details_navigation_card_section_one"
            }
            type="button"
            onClick={() => handleCardNavigation(3)}
          >
            {cardNavPosition === 3 && <span className="selected_scroll" />}
            INDIRIZZI DI SPEDIZIONE
          </button>
          <button
            className={
              cardNavPosition === 4
                ? "user_details_navigation_card_section_one card_naviation_active"
                : "user_details_navigation_card_section_one"
            }
            type="button"
            onClick={() => handleCardNavigation(4)}
          >
            {cardNavPosition === 4 && <span className="selected_scroll" />}
            INDIRIZZI DI FATTURAZIONE
          </button>
          <button
            className={
              cardNavPosition === 5
                ? "user_details_navigation_card_section_one card_naviation_active"
                : "user_details_navigation_card_section_one"
            }
            type="button"
            onClick={() => handleCardNavigation(5)}
            style={{ border: "0" }}
          >
            {cardNavPosition === 5 && <span className="selected_scroll" />}I
            TUOI ORDINI
          </button>
          <button
            className="profile_logut"
            type="button"
            onClick={() => {
              history.push("/");
              localStorage.removeItem("token");
              localStorage.removeItem("zipCode");
            }}
          >
            LOGOUT
          </button>
        </div>
        <div className="user_detailed_section">
          {cardNavPosition === 1 && !isFirstLoading ? (
            <div className="user_detailed_first_section">
              <p className="user_detailed_first_section_heading">ANAGRAFICA</p>
              <Form autoComplete="off">
                <Grid>
                  <Grid.Column width={8} style={{ paddingBottom: "100px" }}>
                    <div style={{ position: "relative", marginBottom: 10 }}>
                      <Form.Input
                        name="firstName"
                        placeholder="Nome*"
                        value={values.firstName}
                        onChange={handlePersonalDetailChange}
                        className="user_info_inputs"
                      />
                      {formErrors.basicDetailErrors &&
                        formErrors.basicDetailErrors.firstName && (
                          <span className="user_profile_validation_message">
                            {formErrors.basicDetailErrors &&
                              formErrors.basicDetailErrors.firstName}
                          </span>
                        )}
                    </div>
                    <div className="user_dob">
                      <DatePicker
                        name="date"
                        placeholderText="Data di nascita*"
                        selected={values.date ? values.date : new Date()}
                        maxDate={new Date()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="dd/MM/yyyy"
                        onChange={(dateOfBirth) => {
                          setValues({
                            ...values,
                            date: dateOfBirth,
                          });
                        }}
                        locale="it"
                      />
                      <img
                        src={orderCalendar}
                        alt=""
                        className="user_dob_calendar"
                      />
                      {/* <DateInput
                        name="date"
                        placeholder="Data di nascita*"
                        value={values.date}
                        iconPosition="right"
                        onChange={handlePersonalDetailChange}
                        closeOnMouseLeave
                        animation="none"
                        closable
                        icon="calendar alternate outline"
                        maxDate={new Date()}
                        className="user_profile_dob"
                      /> */}
                      {formErrors.basicDetailErrors &&
                        formErrors.basicDetailErrors.date && (
                          <span className="user_profile_validation_message">
                            {formErrors.basicDetailErrors &&
                              formErrors.basicDetailErrors.date}
                          </span>
                        )}
                    </div>

                    <div style={{ position: "relative", marginBottom: 10 }}>
                      <Form.Input
                        name="homePhone"
                        className="user_profile_phone"
                        placeholder="Numero di telefono"
                        value={values.homePhone}
                        type="number"
                        onChange={handlePersonalDetailChange}
                      />
                      {formErrors.basicDetailErrors &&
                        formErrors.basicDetailErrors.homePhone && (
                          <span className="user_profile_validation_message">
                            {formErrors.basicDetailErrors &&
                              formErrors.basicDetailErrors.homePhone}
                          </span>
                        )}
                    </div>
                    {/* <Dropdown
                      placeholder="Seleziona Città o Comune"
                      fluid
                      search
                      selection
                      clearable
                      value={selectedCity}
                      className="user_profile_city"
                      options={citiesOptions}
                      onChange={(e, { value }) => {
                        setSlectedCity(value);
                      }}
                    /> */}
                    <div
                      style={{
                        position: "relative",
                        marginLeft: 10,
                        marginBottom: 10,
                        width: "100%",
                      }}
                    >
                      <CityDropdown
                        cities={citiesOptions}
                        selectedOption={
                          selectedCity && selectedCity.value
                            ? selectedCity
                            : null
                        }
                        handleChange={(selectedOption) => {
                          setSlectedCity(selectedOption);
                        }}
                        placeholder="Seleziona Città o Comune"
                        onClearCity={() =>
                          setSlectedCity({ value: "", label: "" })
                        }
                      />
                      {formErrors.basicDetailErrors &&
                        formErrors.basicDetailErrors.selectedCity && (
                          <span className="user_profile_validation_message">
                            {formErrors.basicDetailErrors &&
                              formErrors.basicDetailErrors.selectedCity}
                          </span>
                        )}
                    </div>
                    <Button
                      className="user_profile_first_section_left"
                      type="submit"
                    >
                      ANNULLA
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <div style={{ position: "relative", marginBottom: 10 }}>
                      <Form.Input
                        name="lastName"
                        placeholder="Cognome*"
                        value={values.lastName}
                        className="user_profile_lastname"
                        onChange={handlePersonalDetailChange}
                      />
                      {formErrors.basicDetailErrors &&
                        formErrors.basicDetailErrors.lastName && (
                          <span className="user_profile_validation_message">
                            {formErrors.basicDetailErrors &&
                              formErrors.basicDetailErrors.lastName}
                          </span>
                        )}
                    </div>

                    <Form.Select
                      fluid
                      options={options}
                      placeholder="Sesso"
                      icon="angle down"
                      className="gender_dropdown gender_dropdown_user_profile"
                      value={
                        values.gender ? values.gender.toLocaleLowerCase() : ""
                      }
                      onChange={(e, { value }) => {
                        setValues({
                          ...values,
                          gender: value.toLocaleUpperCase(),
                        });
                      }}
                    />
                    <Button
                      className="user_profile_first_section_right"
                      type="submit"
                      onClick={() => updatePersonalDetails("basicDetail")}
                    >
                      {isLoading ? (
                        <Loader active inline className="confirmation_loader" />
                      ) : (
                        "SALVA"
                      )}
                    </Button>
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
          ) : (
            <Loader
              active={isFirstLoading}
              inline
              className="confirmation_loader_middle"
            />
          )}
          {cardNavPosition === 2 && (
            <div className="user_detailed_second_section">
              <p className="user_detailed_first_section_heading">
                IL TUO ACCOUNT
              </p>
              <Form autoComplete="off">
                <div className="user_detailed_second_section_container">
                  <Form.Input
                    name="email"
                    placeholder="Email*"
                    value={values.email}
                    type="text"
                    // onChange={handleChangeForms}
                  />
                  <div className="name_sirname_container">
                    <div style={{ position: "relative", width: "50%" }}>
                      <Form.Input
                        name="password"
                        placeholder="Password"
                        type="password"
                        value={values.password}
                        onChange={handlePersonalDetailChange}
                      />
                      {formErrors.credentialErrors &&
                        formErrors.credentialErrors.password && (
                          <span className="user_profile_validation_message_password">
                            {formErrors.credentialErrors &&
                              formErrors.credentialErrors.password}
                          </span>
                        )}
                    </div>
                    <div style={{ position: "relative", width: "50%" }}>
                      <Form.Input
                        name="confirmPassword"
                        type="password"
                        placeholder="Conferma password"
                        value={values.confirmPassword}
                        onChange={handlePersonalDetailChange}
                      />
                      {formErrors.credentialErrors &&
                        formErrors.credentialErrors.confirmPassword && (
                          <span className="user_profile_validation_message_password">
                            {formErrors.credentialErrors &&
                              formErrors.credentialErrors.confirmPassword}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="user_profile_checkbox_points_delete">
                    <input
                      type="checkbox"
                      id="type0"
                      name="type0"
                      value="type0"
                      className="checkbox_tick"
                      onChange={onSelectPrivacyPoints}
                    />
                    <label htmlFor="type0">
                      {" "}
                      Vuoi eliminare il tuo account?
                    </label>
                  </div>
                  {values.type0 && (
                    <div>
                      <p className="account_delete_warning">
                        Procedendo con la cancellazione dell&apos;account i tuoi
                        dati verranno definitivamente cancellati. Questa azione
                        è irreversibile. Per proseguire inserici la tua password
                      </p>
                      <div className="delete_input_button">
                        <div style={{ position: "relative" }}>
                          <Form.Input
                            name="password"
                            placeholder="Password"
                            type="password"
                            value={profileDeletePassword}
                            onChange={(e) =>
                              setProfileDeletePassword(e.target.value)
                            }
                          />
                          {deletePasswordError &&
                            deletePasswordError.password && (
                              <span className="ladingFormError delteUserError">
                                {deletePasswordError.password}
                              </span>
                            )}
                        </div>

                        <div className="delte_button_container">
                          <Button
                            type="submit"
                            className="account_delete_button"
                            onClick={() => handleDelteAccount()}
                          >
                            {isDeleteUserLoading ? (
                              <Loader
                                active
                                inline
                                className="login_loader ladingFormLoader"
                              />
                            ) : (
                              "ELIMINA ACCOUNT"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                  <p className="privacy_points">
                    Privacy e condizioni di utilizzo
                  </p>
                  <p className="legal_info">
                    Leggi l&apos; informativa al trattamento dei dati personali
                    prima di procedere con la registrazione.
                    {/* <span className="privacy_policy">
                      INFORMATIVA SULLA PRIVACY
                    </span> */}
                  </p>
                  {privacyPolicy &&
                    privacyPolicy.length > 0 &&
                    privacyPolicy.map((policy) => {
                      if (policy.mandatory) {
                        mandIndex = mandIndex + 1;
                        return (
                          <div className="checkbox_points">
                            <input
                              type="checkbox"
                              id={`accept${mandIndex}`}
                              name={`accept${mandIndex}`}
                              value={`accept${mandIndex}`}
                              className="checkbox_tick"
                              defaultChecked={values[`accept${mandIndex}`]}
                              onChange={onSelectPrivacyPoints}
                              disabled={true}
                            />
                            <label
                              onClick={() =>
                                window.open(`/policy/${policy.privacyId}`)
                              }
                            >
                              {policy.text}
                            </label>
                          </div>
                        );
                      }
                      return null;
                    })}

                  {privacyPolicy &&
                    privacyPolicy.length > 0 &&
                    privacyPolicy.map((policy) => {
                      if (!policy.mandatory) {
                        optIndex = optIndex + 1;
                        return (
                          <div className="checkbox_points">
                            <input
                              type="checkbox"
                              id={`accept${optIndex}`}
                              name={`accept${optIndex}`}
                              value={`accept${optIndex}`}
                              className="checkbox_tick"
                              defaultChecked={values[`accept${optIndex}`]}
                              onChange={onSelectPrivacyPoints}
                            />
                            <label
                              onClick={() =>
                                window.open(`/policy/${policy.privacyId}`)
                              }
                            >
                              {policy.text}
                            </label>
                          </div>
                        );
                      }
                      return null;
                    })}

                  <Button
                    className="user_profile_first_section_left"
                    type="submit"
                  >
                    ANNULLA
                  </Button>

                  <Button
                    className="user_profile_first_section_right"
                    type="submit"
                    onClick={() => updatePassword()}
                  >
                    {isLoading ? (
                      <Loader active inline className="confirmation_loader" />
                    ) : (
                      "SALVA"
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          )}

          {/* Shipping Address Section */}

          {cardNavPosition === 3 && (
            <div className="user_detailed_second_section">
              <p className="user_detailed_first_section_heading">
                INDIRIZZI DI SPEDIZIONE
              </p>
              <p className="address_name">I tuoi indirizzi</p>
              <div className="adderess_cards_container">
                {values.shippingAddresses.length > 0 &&
                  values.shippingAddresses.map((address, index) => (
                    <div className="address_card" key={address.id}>
                      <div className="adrress_edit">
                        <p className="address_card_heading">{`INDIRIZZO ${
                          index + 1
                        }`}</p>
                        <button
                          className="edit_text"
                          onClick={() => {
                            setShowNewAddressForm(true);
                            setShippFormData(address);
                          }}
                          type="button"
                        >
                          Modifica
                        </button>
                      </div>
                      <p className="holder_name">
                        {" "}
                        {`${address.firstName}  ${address.lastName}`}
                      </p>
                      <div className="address_first_line">
                        {`${address.address}, ${address.streetNumber}`}
                      </div>
                      <div className="address_first_line">{`${address.zip}  ${address.city} `}</div>
                      <div className="address_first_line">{address.county}</div>
                      <div className="address_telephone">
                        {`Tel: ${address.phone}`}
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                {showNewAddressForm ? (
                  <div className="address_form_container">
                    {/* Form to edit existing address card */}
                    <p className="new_address_text">Aggiungi nuovo indirizzo</p>
                    <Form autoComplete="off">
                      <div className="user_detailed_second_section_container">
                        <div style={{ position: "relative" }}>
                          <Form.Input
                            name="description"
                            placeholder="Descrizione indirizzo*"
                            value={editShippingFormDetails.description}
                            type="text"
                            onChange={handleShippingAddressChanges}
                          />
                          {formErrors.editShippingError &&
                            formErrors.editShippingError.description && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.editShippingError.description}
                              </span>
                            )}
                        </div>
                        <div className="name_sirname_container">
                          <div style={{ position: "relative", width: "50%" }}>
                            <Form.Input
                              name="firstName"
                              placeholder="Nome*"
                              value={editShippingFormDetails.firstName}
                              onChange={handleShippingAddressChanges}
                            />
                            {formErrors.editShippingError &&
                              formErrors.editShippingError.firstName && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.editShippingError.firstName}
                                </span>
                              )}
                          </div>

                          <Form.Input
                            name="lastName"
                            placeholder="Cognome"
                            value={editShippingFormDetails.lastName}
                            onChange={handleShippingAddressChanges}
                          />
                        </div>
                        <div style={{ position: "relative" }}>
                          <Form.Input
                            name="phone"
                            placeholder="Telefono*"
                            type="number"
                            value={editShippingFormDetails.phone}
                            onChange={handleShippingAddressChanges}
                            className="telephone"
                          />
                          {formErrors.editShippingError &&
                            formErrors.editShippingError.phone && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.editShippingError.phone}
                              </span>
                            )}
                        </div>
                        <div className="profile_address_container">
                          <div className="address_shipping">
                            <Form.Input
                              name="address"
                              placeholder="Indirizzo*"
                              className="address"
                              value={editShippingFormDetails.address}
                              onChange={handleShippingAddressChanges}
                            />
                            {formErrors.editShippingError &&
                              formErrors.editShippingError.address && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.editShippingError.address}
                                </span>
                              )}
                          </div>
                          <div className="address_shipping">
                            <Form.Input
                              name="streetNumber"
                              placeholder="Civico*"
                              value={editShippingFormDetails.streetNumber}
                              onChange={handleShippingAddressChanges}
                              className="street_number"
                            />
                            {formErrors.editShippingError &&
                              formErrors.editShippingError.streetNumber && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.editShippingError.streetNumber}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="city_detail_container">
                          <div className="city_detail_item">
                            <Form.Input
                              name="zip"
                              placeholder="Cap*"
                              value={editShippingFormDetails.zip}
                              onChange={handleShippingAddressChanges}
                              className="postal_code"
                            />
                            {formErrors.editShippingError &&
                              formErrors.editShippingError.zip && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.editShippingError.zip}
                                </span>
                              )}
                          </div>
                          <div className="city_detail_item">
                            <Form.Input
                              name="city"
                              placeholder="Città*"
                              value={editShippingFormDetails.city}
                              onChange={handleShippingAddressChanges}
                              className="city"
                            />
                            {formErrors.editShippingError &&
                              formErrors.editShippingError.city && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.editShippingError.city}
                                </span>
                              )}
                          </div>
                          <div className="city_detail_item">
                            <Form.Input
                              name="province"
                              placeholder="Provincia*"
                              value={editShippingFormDetails.province}
                              onChange={handleShippingAddressChanges}
                              className="province"
                            />
                            {formErrors.editShippingError &&
                              formErrors.editShippingError.province && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.editShippingError.province}
                                </span>
                              )}
                          </div>
                        </div>
                        <Button
                          className="user_profile_first_section_left"
                          type="submit"
                          onClick={() => setShowNewAddressForm(false)}
                        >
                          ANNULLA
                        </Button>
                        <div className="del_button_container">
                          <Button
                            className="delete_button"
                            type="submit"
                            // onClick={() => setShowNewAddressForm(false)}
                            onClick={() =>
                              deleteAddressDetails(
                                editShippingFormDetails.id,
                                "shipping"
                              )
                            }
                          >
                            {isLoading && isShippDeleted ? (
                              <Loader
                                active
                                inline
                                className="confirmation_loader"
                              />
                            ) : (
                              "ELIMINA"
                            )}
                          </Button>
                          <Button
                            className="user_profile_first_section_right"
                            type="submit"
                            onClick={() => updatePersonalDetails("shipping")}
                          >
                            {isLoading && !isShippDeleted ? (
                              <Loader
                                active
                                inline
                                className="confirmation_loader"
                              />
                            ) : (
                              "SALVA"
                            )}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                ) : (
                  <div className="address_form_container">
                    {/* Form to addrsss new shipping address */}
                    <p className="new_address_text">Aggiungi nuovo indirizzo</p>
                    <Form autoComplete="off">
                      <div className="user_detailed_second_section_container">
                        <div style={{ position: "relative" }}>
                          <Form.Input
                            name="description"
                            placeholder="Descrizione indirizzo*"
                            value={newShippingAddress.description}
                            type="text"
                            onChange={handleNewShippingAddressChanges}
                          />
                          {formErrors.shippingErrors &&
                            formErrors.shippingErrors.description && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.shippingErrors.description}
                              </span>
                            )}
                        </div>
                        <div className="name_sirname_container">
                          <div style={{ position: "relative", width: "50%" }}>
                            <Form.Input
                              name="firstName"
                              placeholder="Nome*"
                              value={newShippingAddress.firstName}
                              onChange={handleNewShippingAddressChanges}
                            />
                            {formErrors.shippingErrors &&
                              formErrors.shippingErrors.firstName && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.shippingErrors.firstName}
                                </span>
                              )}
                          </div>
                          <Form.Input
                            name="lastName"
                            placeholder="Cognome"
                            value={newShippingAddress.lastName}
                            onChange={handleNewShippingAddressChanges}
                          />
                        </div>
                        <div style={{ position: "relative" }}>
                          <Form.Input
                            name="phone"
                            placeholder="Telefono*"
                            type="number"
                            value={newShippingAddress.phone}
                            onChange={handleNewShippingAddressChanges}
                            className="telephone"
                          />
                          {formErrors.shippingErrors &&
                            formErrors.shippingErrors.phone && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.shippingErrors.phone}
                              </span>
                            )}
                        </div>
                        <div className="profile_address_container">
                          <div className="address_shipping">
                            <Form.Input
                              name="address"
                              placeholder="Indirizzo*"
                              className="address"
                              value={newShippingAddress.address}
                              onChange={handleNewShippingAddressChanges}
                            />
                            {formErrors.shippingErrors &&
                              formErrors.shippingErrors.address && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.shippingErrors.address}
                                </span>
                              )}
                          </div>
                          <div className="shipping_street">
                            <Form.Input
                              name="streetNumber"
                              placeholder="Civico*"
                              value={newShippingAddress.streetNumber}
                              onChange={handleNewShippingAddressChanges}
                              className="street_number"
                            />
                            {formErrors.shippingErrors &&
                              formErrors.shippingErrors.streetNumber && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.shippingErrors.streetNumber}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="city_detail_container">
                          <div className="city_detail_item">
                            <Form.Input
                              name="zip"
                              placeholder="Cap*"
                              value={newShippingAddress.zip}
                              onChange={handleNewShippingAddressChanges}
                              className="postal_code"
                            />
                            {formErrors.shippingErrors &&
                              formErrors.shippingErrors.zip && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.shippingErrors.zip}
                                </span>
                              )}
                          </div>
                          <div className="city_detail_item">
                            <Form.Input
                              name="city"
                              placeholder="Città*"
                              value={newShippingAddress.city}
                              onChange={handleNewShippingAddressChanges}
                              className="city"
                            />
                            {formErrors.shippingErrors &&
                              formErrors.shippingErrors.city && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.shippingErrors.city}
                                </span>
                              )}
                          </div>
                          <div className="city_detail_item">
                            <Form.Input
                              name="province"
                              placeholder="Provincia*"
                              value={newShippingAddress.province}
                              onChange={handleNewShippingAddressChanges}
                              className="province"
                            />
                            {formErrors.shippingErrors &&
                              formErrors.shippingErrors.province && (
                                <span className="user_profile_validation_message_password">
                                  {formErrors.shippingErrors.province}
                                </span>
                              )}
                          </div>
                        </div>
                        <Button
                          className="user_profile_first_section_left"
                          type="submit"
                          // onClick={() => setShowNewAddressForm(false)}
                        >
                          ANNULLA
                        </Button>

                        <Button
                          className="user_profile_first_section_right"
                          type="submit"
                          onClick={() => updateNewAddressDetails("shipping")}
                        >
                          {isLoading ? (
                            <Loader
                              active
                              inline
                              className="confirmation_loader"
                            />
                          ) : (
                            "SALVA INDIRIZZO"
                          )}
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Billing Address */}

          {cardNavPosition === 4 && (
            <div className="user_detailed_second_section">
              <p className="user_detailed_first_section_heading">
                INDIRIZZI DI FATTURAZIONE
              </p>
              <p className="address_name">I tuoi indirizzi di fatturazione</p>
              <div className="adderess_cards_container">
                {values.billingAddresses &&
                  values.billingAddresses.length > 0 &&
                  values.billingAddresses.map((address, index) => (
                    <div className="address_card" key={address.id}>
                      <div className="adrress_edit">
                        <p className="address_card_heading">{`INDIRIZZO ${
                          index + 1
                        }`}</p>
                        <button
                          className="edit_text"
                          onClick={() => {
                            setBillFormData(address);
                            setShowNewAddressForm4(true);
                          }}
                          type="button"
                        >
                          Modifica
                        </button>
                      </div>
                      <div className="section_4_address_container">
                        <div className="address_key_cotainer">
                          <div className="address_detail_name">P.Iva:</div>
                          <div className="address_detail_name">C.F:</div>
                          <div className="address_detail_name">
                            Ragione Sociale:
                          </div>
                          <div className="address_detail_name">Telefono:</div>
                          <div className="address_detail_name">ndirizzo:</div>
                          <div className="address_detail_name">PEC:</div>
                          <div className="address_detail_name">Fax:</div>
                          <div className="address_detail_name">
                            C. Destinatario:
                          </div>
                        </div>
                        <div className="address_values_container">
                          <div className="address_detail_value">
                            {address.vatCode}
                          </div>
                          <div className="address_detail_value">
                            XXXXXXXXXXX
                          </div>
                          <div className="address_detail_value">
                            XXXXXXXXXXX
                          </div>
                          <div className="address_detail_value">
                            {address.phone}
                          </div>
                          <div className="address_detail_value">
                            {address.address}
                            {/* {`${address.description} ${address.streetNumber} ${
                              address.city
                            } ${address.fiscalCode}`} */}
                          </div>

                          <div className="address_detail_value">
                            {address.pec}
                          </div>

                          <div className="address_detail_value">
                            {address.fax ? address.fax : "-"}
                          </div>

                          <div className="address_detail_value">
                            {address.destinationCode}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {showNewAddressForm4 ? (
                <div className="address_form_container">
                  <p className="new_address_text">
                    Aggiungi nuovo indirizzo di fatturazione
                  </p>
                  <Form autoComplete="off">
                    <div className="user_detailed_second_section_container">
                      <div style={{ position: "relative" }}>
                        <Form.Input
                          name="description"
                          placeholder="Descrizione indirizzo*"
                          value={editBillingAdressDetails.description}
                          type="text"
                          autoComplete="off"
                          onChange={handleBillAddressChange}
                        />
                        {formErrors.editBillingAdressDetails &&
                          formErrors.editBillingAdressDetails.description && (
                            <span className="user_profile_validation_message_password">
                              {formErrors.editBillingAdressDetails.description}
                            </span>
                          )}
                      </div>
                      <div className="name_sirname_container">
                        <div style={{ position: "relative", width: "50%" }}>
                          <Form.Input
                            name="vatCode"
                            placeholder="P. Iva*"
                            value={editBillingAdressDetails.vatCode}
                            onChange={handleBillAddressChange}
                          />
                          {formErrors.editBillingAdressDetails &&
                            formErrors.editBillingAdressDetails.vatCode && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.editBillingAdressDetails.vatCode}
                              </span>
                            )}
                        </div>
                        <div style={{ position: "relative", width: "50%" }}>
                          <Form.Input
                            name="fiscalCode"
                            placeholder="Codice Fiscale"
                            value={editBillingAdressDetails.fiscalCode}
                            onChange={handleBillAddressChange}
                          />
                          {formErrors.editBillingAdressDetails &&
                            formErrors.editBillingAdressDetails.fiscalCode && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.editBillingAdressDetails.fiscalCode}
                              </span>
                            )}
                        </div>
                      </div>
                      <div style={{ position: "relative", width: "100%" }}>
                        <Form.Input
                          name="phone"
                          placeholder="Telefono*"
                          type="number"
                          value={editBillingAdressDetails.phone}
                          onChange={handleBillAddressChange}
                          className="telephone"
                        />
                        {formErrors.editBillingAdressDetails &&
                          formErrors.editBillingAdressDetails.phone && (
                            <span className="user_profile_validation_message_password">
                              {formErrors.editBillingAdressDetails.phone}
                            </span>
                          )}
                      </div>

                      <div className="profile_address_container">
                        <div className="address_shipping">
                          <Form.Input
                            name="address"
                            placeholder="Indirizzo*"
                            className="address"
                            value={editBillingAdressDetails.address}
                            onChange={handleBillAddressChange}
                          />
                          {formErrors.editBillingAdressDetails &&
                            formErrors.editBillingAdressDetails.address && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.editBillingAdressDetails.address}
                              </span>
                            )}
                        </div>
                        <div className="address_shipping">
                          <Form.Input
                            name="streetNumber"
                            placeholder="Civico*"
                            value={editBillingAdressDetails.streetNumber}
                            onChange={handleBillAddressChange}
                            className="street_number"
                          />
                          {formErrors.editBillingAdressDetails &&
                            formErrors.editBillingAdressDetails
                              .streetNumber && (
                              <span className="user_profile_validation_message_password">
                                {
                                  formErrors.editBillingAdressDetails
                                    .streetNumber
                                }
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="city_detail_container">
                        <div className="city_detail_item">
                          <Form.Input
                            name="zip"
                            placeholder="Cap*"
                            value={editBillingAdressDetails.zip}
                            onChange={handleBillAddressChange}
                            className="postal_code"
                          />
                          {formErrors.editBillingAdressDetails &&
                            formErrors.editBillingAdressDetails.zip && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.editBillingAdressDetails.zip}
                              </span>
                            )}
                        </div>
                        <div className="city_detail_item">
                          <Form.Input
                            name="city"
                            placeholder="Città*"
                            value={editBillingAdressDetails.city}
                            onChange={handleBillAddressChange}
                            className="city"
                          />
                          {formErrors.editBillingAdressDetails &&
                            formErrors.editBillingAdressDetails.city && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.editBillingAdressDetails.city}
                              </span>
                            )}
                        </div>
                        <div className="city_detail_item">
                          <Form.Input
                            name="province"
                            placeholder="Provincia*"
                            value={editBillingAdressDetails.province}
                            onChange={handleBillAddressChange}
                            className="province"
                          />
                          {formErrors.editBillingAdressDetails &&
                            formErrors.editBillingAdressDetails.province && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.editBillingAdressDetails.province}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="name_sirname_container">
                        <Form.Input
                          name="fax"
                          placeholder="Fax"
                          value={editBillingAdressDetails.fax}
                          onChange={handleBillAddressChange}
                        />
                        <Form.Input
                          name="pec"
                          placeholder="Pec"
                          value={editBillingAdressDetails.pec}
                          onChange={handleBillAddressChange}
                        />
                      </div>
                      <div style={{ position: "relative" }}>
                        <Form.Input
                          name="destinationCode"
                          placeholder="Codice destinatario*"
                          value={editBillingAdressDetails.destinationCode}
                          onChange={handleBillAddressChange}
                          className="telephone"
                        />
                        {formErrors.editBillingAdressDetails &&
                          formErrors.editBillingAdressDetails
                            .destinationCode && (
                            <span className="user_profile_validation_message_password">
                              {
                                formErrors.editBillingAdressDetails
                                  .destinationCode
                              }
                            </span>
                          )}
                      </div>

                      <Button
                        className="user_profile_first_section_left"
                        type="submit"
                      >
                        ANNULLA
                      </Button>

                      <div className="del_button_container">
                        <Button
                          className="delete_button"
                          type="submit"
                          // onClick={() => setShowNewAddressForm(false)}
                          onClick={() =>
                            deleteAddressDetails(
                              editBillingAdressDetails.id,
                              "billing"
                            )
                          }
                        >
                          {isLoading && isBillingDeleted ? (
                            <Loader
                              active
                              inline
                              className="confirmation_loader"
                            />
                          ) : (
                            "ELIMINA"
                          )}
                        </Button>
                        <Button
                          className="user_profile_first_section_right"
                          type="submit"
                          onClick={() => updatePersonalDetails("billing")}
                        >
                          {isLoading && !isBillingDeleted ? (
                            <Loader
                              active
                              inline
                              className="confirmation_loader"
                            />
                          ) : (
                            "SALVA"
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              ) : (
                <div className="address_form_container">
                  <p className="new_address_text">
                    Aggiungi nuovo indirizzo di fatturazione
                  </p>
                  <Form autoComplete="off">
                    <div className="user_detailed_second_section_container">
                      <div style={{ position: "relative" }}>
                        <Form.Input
                          name="description"
                          placeholder="Descrizione indirizzo*"
                          value={newBillingAdressDetails.description}
                          type="text"
                          onChange={handleNewBillAddressChange}
                        />
                        {formErrors.billingErrors &&
                          formErrors.billingErrors.description && (
                            <span className="user_profile_validation_message_password">
                              {formErrors.billingErrors.description}
                            </span>
                          )}
                      </div>
                      <div className="name_sirname_container">
                        <div style={{ position: "relative", width: "50%" }}>
                          <Form.Input
                            name="vatCode"
                            placeholder="P. Iva*"
                            value={newBillingAdressDetails.vatCode}
                            onChange={handleNewBillAddressChange}
                          />
                          {formErrors.billingErrors &&
                            formErrors.billingErrors.vatCode && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.billingErrors.vatCode}
                              </span>
                            )}
                        </div>

                        <Form.Input
                          name="fiscalCode"
                          placeholder="Codice Fiscale"
                          value={newBillingAdressDetails.fiscalCode}
                          onChange={handleNewBillAddressChange}
                        />
                      </div>
                      <div style={{ position: "relative", width: "100%" }}>
                        <Form.Input
                          name="phone"
                          placeholder="Telefono*"
                          type="number"
                          value={newBillingAdressDetails.phone}
                          onChange={handleNewBillAddressChange}
                          className="telephone"
                        />
                        {formErrors.billingErrors &&
                          formErrors.billingErrors.phone && (
                            <span className="user_profile_validation_message_password">
                              {formErrors.billingErrors.phone}
                            </span>
                          )}
                      </div>
                      <div className="profile_address_container">
                        <div className="address_shipping">
                          <Form.Input
                            name="address"
                            placeholder="Indirizzo*"
                            className="address"
                            value={newBillingAdressDetails.address}
                            onChange={handleNewBillAddressChange}
                          />
                          {formErrors.billingErrors &&
                            formErrors.billingErrors.address && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.billingErrors.address}
                              </span>
                            )}
                        </div>
                        <div className="address_shipping">
                          <Form.Input
                            name="streetNumber"
                            placeholder="Civico*"
                            value={newBillingAdressDetails.streetNumber}
                            onChange={handleNewBillAddressChange}
                            className="street_number"
                          />
                          {formErrors.billingErrors &&
                            formErrors.billingErrors.streetNumber && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.billingErrors.streetNumber}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="city_detail_container">
                        <div className="city_detail_item">
                          <Form.Input
                            name="zip"
                            placeholder="Cap*"
                            value={newBillingAdressDetails.zip}
                            onChange={handleNewBillAddressChange}
                            className="postal_code"
                          />
                          {formErrors.billingErrors &&
                            formErrors.billingErrors.zip && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.billingErrors.zip}
                              </span>
                            )}
                        </div>
                        <div className="city_detail_item">
                          <Form.Input
                            name="city"
                            placeholder="Città*"
                            value={newBillingAdressDetails.city}
                            onChange={handleNewBillAddressChange}
                            className="city"
                          />
                          {formErrors.billingErrors &&
                            formErrors.billingErrors.city && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.billingErrors.city}
                              </span>
                            )}
                        </div>
                        <div className="city_detail_item">
                          <Form.Input
                            name="province"
                            placeholder="Provincia*"
                            value={newBillingAdressDetails.province}
                            onChange={handleNewBillAddressChange}
                            className="province"
                          />
                          {formErrors.billingErrors &&
                            formErrors.billingErrors.province && (
                              <span className="user_profile_validation_message_password">
                                {formErrors.billingErrors.province}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="name_sirname_container">
                        <Form.Input
                          name="fax"
                          placeholder="Fax"
                          value={newBillingAdressDetails.fax}
                          onChange={handleNewBillAddressChange}
                        />
                        <Form.Input
                          name="pec"
                          placeholder="Pec"
                          value={newBillingAdressDetails.pec}
                          onChange={handleNewBillAddressChange}
                        />
                      </div>
                      <div style={{ position: "relative" }}>
                        <Form.Input
                          name="destinationCode"
                          placeholder="Codice destinatario*"
                          value={newBillingAdressDetails.destinationCode}
                          onChange={handleNewBillAddressChange}
                          className="telephone"
                        />
                        {formErrors.billingErrors &&
                          formErrors.billingErrors.destinationCode && (
                            <span className="user_profile_validation_message_password">
                              {formErrors.billingErrors.destinationCode}
                            </span>
                          )}
                      </div>

                      <Button
                        className="user_profile_first_section_left"
                        type="submit"
                      >
                        ANNULLA
                      </Button>

                      <Button
                        className="user_profile_first_section_right"
                        type="submit"
                        onClick={() => updateNewAddressDetails("billing")}
                      >
                        {isLoading ? (
                          <Loader
                            active
                            inline
                            className="confirmation_loader"
                          />
                        ) : (
                          "SALVA INDIRIZZO"
                        )}
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          )}

          {cardNavPosition === 5 && (
            <div className="user_detailed_second_section">
              <p className="order_summary_heading">I TUOI ORDINI</p>
              <p className="order_list_text">Lista ordini</p>
              <div className="order_search_header">
                <span className="total_orders">{`Totale ordini ${orderDetails.length}`}</span>
                <div className="search_order_container">
                  <input
                    type="text"
                    onChange={(e) => setProductSearchName(e.target.value)}
                  />
                  <Icon
                    className="search search_field_order"
                    onClick={() => {
                      serachProduct();
                    }}
                  />
                </div>
                {showFilterModal && (
                  <div
                    // open={showFilterModal}
                    // onClose={() => setShowFilterModal(false)}
                    className="order_search__modal"
                  >
                    <div className="modal_search_header">
                      <span className="total_orders">{`Totale ordini ${orderDetails.length}`}</span>
                      <div className="search_order_container">
                        <input type="text" />
                        <Icon className="search search_field_order" />
                      </div>
                      <div className="order_icon_text_container">
                        <button
                          type="button"
                          className="profile_modal_open"
                          onClick={() => setShowFilterModal(false)}
                        >
                          <img src={closeModal} alt="Filter" />
                        </button>

                        <span className="order_filter_text">Filtri</span>
                      </div>
                    </div>
                    <div className="details_divider" />
                    <div className="filter_content">
                      <div className="order_search_modal_column1">
                        <div className="checkbox_heading">STATO RODINE</div>
                        <div className="order_filter_checbox">
                          <input
                            type="checkbox"
                            id="closed"
                            name="closed"
                            value="closed"
                            defaultChecked={orderStatusFilter.closed}
                            className="order_checkbox_tick"
                            onChange={onSelectStatusPoints}
                          />
                          <label
                            htmlFor="type1"
                            className="order_checkbox_text"
                          >
                            {" "}
                            Chiuso
                          </label>
                        </div>
                        <div className="order_filter_checbox">
                          <input
                            type="checkbox"
                            id="open"
                            name="open"
                            value="open"
                            defaultChecked={orderStatusFilter.open}
                            className="order_checkbox_tick"
                            onChange={onSelectStatusPoints}
                          />
                          <label
                            htmlFor="type1"
                            className="order_checkbox_text"
                          >
                            {" "}
                            Aperto
                          </label>
                        </div>
                        <div className="order_filter_checbox">
                          <input
                            type="checkbox"
                            id="toBeprepared"
                            name="toBeprepared"
                            value="toBeprepared"
                            defaultChecked={orderStatusFilter.toBeprepared}
                            className="order_checkbox_tick"
                            onChange={onSelectStatusPoints}
                          />
                          <label
                            htmlFor="type1"
                            className="order_checkbox_text"
                          >
                            {" "}
                            In preparazione
                          </label>
                        </div>
                        <div className="order_filter_checbox">
                          <input
                            type="checkbox"
                            id="showAll"
                            name="showAll"
                            value="showAll"
                            defaultChecked={orderStatusFilter.showAll}
                            className="order_checkbox_tick"
                            onChange={onSelectStatusPoints}
                          />
                          <label
                            htmlFor="type1"
                            className="order_checkbox_text"
                          >
                            {" "}
                            Visualizza tutti
                          </label>
                        </div>
                      </div>
                      <div className="order_search_modal_column2">
                        <div className="checkbox_heading">DATA</div>
                        <div className="date_selector_container">
                          <div className="order_date_container">
                            <span className="orderFromDate">Dal</span>
                            <DatePicker
                              name="dateFrom"
                              placeholderText="Dal"
                              selected={dateValue.dateFrom}
                              maxDate={new Date()}
                              isClearable
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              dateFormat="dd/MM/yyyy"
                              onChange={(dateOfBirth) => {
                                setDateValue({
                                  ...dateValue,
                                  dateFrom: dateOfBirth,
                                });
                              }}
                              locale="it"
                            />
                            {/* <DateInput
                              name="dateFrom"
                              // placeholder="Data di nascita*"
                              value={dateValue.dateFrom}
                              iconPosition="right"
                              onChange={handleModalDateChange}
                              closeOnMouseLeave
                              animation="none"
                              closable
                              icon={null}
                              // icon="calendar alternate outline"
                              maxDate={new Date()}
                              autoComplete="off"
                            /> */}
                            <img
                              src={orderCalendar}
                              alt=""
                              className="order_date_calendar"
                            />
                          </div>
                          <div className="order_date_container order_date_container_second">
                            <span className="orderTillDate">al</span>
                            <DatePicker
                              name="dateTill"
                              placeholderText="al"
                              selected={dateValue.dateTill}
                              maxDate={new Date()}
                              isClearable
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              dateFormat="dd/MM/yyyy"
                              onChange={(dateOfBirth) => {
                                setDateValue({
                                  ...dateValue,
                                  dateTill: dateOfBirth,
                                });
                              }}
                              locale="it"
                            />
                            {/* <DateInput
                              name="dateTill"
                              // placeholder="Data di nascita*"
                              value={dateValue.dateTill}
                              iconPosition="right"
                              onChange={handleModalDateChange}
                              closeOnMouseLeave
                              animation="none"
                              closable
                              icon={null}
                              // icon="calendar alternate outline"
                              maxDate={new Date()}
                              autoComplete="off"
                            /> */}
                            <img
                              src={orderCalendar}
                              alt=""
                              className="order_date_calendar"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="details_divider" />

                    <Button
                      className="section_5_right_button order_modal_button"
                      type="submit"
                      onClick={() => handleOrderFilter()}
                    >
                      APPLICA
                    </Button>
                  </div>
                )}

                <div className="order_icon_text_container">
                  <button
                    type="button"
                    className="profile_modal_open"
                    onClick={() => setShowFilterModal(true)}
                  >
                    <img src={filter} alt="Filter" />
                  </button>
                  <span className="order_filter_text">Filtri</span>
                </div>
              </div>
              {orderDetails && orderDetails.length > 0 ? (
                <div className="order_carousel_container">
                  <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    // numberOfCards={2}
                    numberOfCards={isTabletOrMobile ? 1 : 2}
                    gutter={20}
                    rightChevron={
                      <img
                        style={{ width: 30, height: 30 }}
                        src={promoRightIcon}
                        alt="promo right icon"
                      />
                    }
                    leftChevron={
                      <img
                        src={promoRightIcon}
                        style={{
                          transform: "rotate(180deg)",
                          width: 30,
                          height: 30,
                        }}
                        alt="left"
                      />
                    }
                    alwaysShowChevrons
                    classes={{
                      wrapper: "main_wrapper_cards_orders",
                      //   itemsWrapper: 'mall_cards_item_wrapper',
                      itemWrapper: "order_card_item_wrapper",
                      rightChevronWrapper: "right_button_order",
                      leftChevronWrapper: "left_button_order",
                    }}
                    outsideChevron
                    chevronWidth={chevronWidth}
                  >
                    {orderDetails.map((order, j) => (
                      <div
                        className="order_detail_card"
                        key={j}
                        onClick={() => setPrintOrderDetaild(order.id)}
                      >
                        <span
                          className={
                            order.status === "ABORTED"
                              ? "red_bar"
                              : order.status === "TO_BE_PREPARED"
                              ? "yellow_bar"
                              : "green_bar"
                          }
                        />
                        <div className="section_4_address_container order_key_value">
                          <div className="address_key_cotainer">
                            <div className="address_detail_name">Negozio:</div>
                            <div className="address_detail_name">
                              Ordine numero:
                            </div>
                            <div className="address_detail_name">
                              Data ordine:
                            </div>
                            <div className="address_detail_name">
                              Tipologia:
                            </div>
                            <div className="address_detail_name">
                              Stato Ordine:
                            </div>
                            <div className="address_detail_name">Totale:</div>
                          </div>
                          <div className="address_values_container">
                            <div className="address_detail_value">
                              {order.cart.storeDescription}
                            </div>
                            <div className="address_detail_value">
                              {order.cart.code}
                            </div>
                            <div className="address_detail_value">
                              {`${dateFormat(order.created).dt}/${
                                dateFormat(order.created).month
                              }/${dateFormat(order.created).year}`}
                            </div>
                            <div className="address_detail_value">
                              {order.cart.terminalType}
                            </div>
                            <div className="address_detail_value">
                              {orderStatus[order.status]}
                            </div>
                            <div className="address_detail_value">{`€ ${order.cart.netTotal} `}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </ItemsCarousel>
                </div>
              ) : (
                <div className="no_orders_found"> No orders found</div>
              )}
              {orderDetails && orderDetails.length > 0 && (
                <>
                  {printDetails && printDetails.cart && (
                    <p className="order_detail_text">Dettaglio ordine</p>
                  )}

                  {printDetails &&
                    printDetails.cart &&
                    !isOrderIdDetailsLoading && (
                      <div className="order_history_print">
                        <div ref={componentRef} className="printable_content">
                          <div className="order_number_container">
                            <div>
                              <span className="order_number_key">
                                Ordine Numero:
                              </span>
                              <span className="order_number_value">
                                {printDetails.cart.code}
                              </span>
                            </div>
                            <div>
                              <ReactToPrint
                                trigger={() => (
                                  <button
                                    className="print_button"
                                    type="button"
                                  >
                                    <img src={printer} alt="" />
                                  </button>
                                )}
                                content={() => componentRef.current}
                              />
                            </div>
                          </div>
                          <div className="section_4_address_container order_key_value">
                            <div className="address_key_cotainer">
                              <div className="address_detail_name_order">
                                Negozio:
                              </div>
                              <div className="address_detail_name_order">
                                Ordine numero:
                              </div>
                              <div className="address_detail_name_order">
                                Data ordine:
                              </div>
                              <div className="address_detail_name_order">
                                Tipologia:
                              </div>
                              <div className="address_detail_name_order">
                                Indirizzo di spedizione:
                              </div>
                              <div className="address_detail_name_order">
                                Stato ordine:
                              </div>
                              <div className="address_detail_name_order">
                                Modalità di pagamento:
                              </div>
                              <div className="address_detail_name_order">
                                Totale prodotti:
                              </div>
                              <div className="address_detail_name_order">
                                Costo dei prodotti:
                              </div>
                              <div className="address_detail_name_order">
                                Spese di spedizione:
                              </div>
                              <div className="address_detail_name_order">
                                Totale costo:
                              </div>
                              <div className="address_detail_name_order">
                                Fattura:
                              </div>
                              <div className="address_detail_name_order">
                                Note per la consegna;
                              </div>
                            </div>
                            <div className="address_values_container">
                              <div className="address_detail_value_order">
                                {printDetails.cart.storeDescription}
                              </div>
                              <div className="address_detail_value_order">
                                {printDetails.cart.code}
                              </div>
                              <div className="address_detail_value_order">
                                {`${dateFormat(printDetails.cart.created).dt}/${
                                  dateFormat(printDetails.cart.created).month
                                }/${
                                  dateFormat(printDetails.cart.created).year
                                }`}
                              </div>
                              <div className="address_detail_value_order">
                                {printDetails.cart.terminalType}
                              </div>
                              <div className="address_detail_value_order">
                                {" "}
                                Via Nome della Strada 21, Milano (MI) - 20100
                              </div>
                              <div className="address_detail_value_order">
                                {" "}
                                {orderStatus[printDetails.status]}
                              </div>
                              <div className="address_detail_value_order">
                                {" "}
                                {printDetails.cart.paymentMode}
                              </div>
                              <div className="address_detail_value_order">
                                {" "}
                                {printDetails.cart.itemsCount}
                              </div>
                              <div className="address_detail_value_order">
                                {" "}
                                {`€ ${printDetails.cart.netTotal}`}
                              </div>
                              <div className="address_detail_value_order">
                                {" "}
                                {`€ ${printDetails.cart.feesTotal}`}
                              </div>
                              <div className="address_detail_value_order">
                                {" "}
                                {`€ ${printDetails.cart.netTotal}`}
                              </div>
                              <div className="address_detail_value_order">
                                _
                              </div>
                              <div className="address_detail_value_order notes_for_deleivery">
                                {printDetails.cart.deliveryMode}
                              </div>
                            </div>
                          </div>
                          <div className="details_divider" />
                          {printDetails.cart.items.map((product, j) => (
                            <div className="profile_order_card_details" key={j}>
                              <div className="profile_order_image_cotaner">
                                <img
                                  src={product.imageUrl}
                                  alt="Product"
                                  onError={(e) => {
                                    e.target.src = profileOrderShoe;
                                  }}
                                />
                              </div>
                              <div className="profile_order_description">
                                <p className="profile_order_description_heading">
                                  {product.description}
                                </p>
                                <span className="size_name">Quantità:</span>
                                <span className="size_value">
                                  {" "}
                                  {product.amount}
                                </span>
                                {printDetails &&
                                  printDetails.saveVariantResponse &&
                                  printDetails.saveVariantResponse.length &&
                                  printDetails.saveVariantResponse.map(
                                    (variant) => {
                                      if (variant.barcode === product.barcode) {
                                        return (
                                          <div>
                                            <span className="size_name">
                                              {variant.type}
                                            </span>
                                            <span
                                              className="size_value"
                                              style={{ marginLeft: "5px" }}
                                            >
                                              {variant.value}
                                            </span>
                                          </div>
                                        );
                                      }
                                      return null;
                                    }
                                  )}

                                <div className="promo_discount_profile">
                                  {`Promozione -${product.rowDiscount}%`}
                                </div>
                                <div>
                                  <span className="profile_old_price">{`€${product.rowPrice}`}</span>
                                  <span className="profile_new_price">{` €${product.rowNetPrice}`}</span>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="details_divider" />
                        </div>
                        <div className="section_5_button_container">
                          {printDetails.status === "TO_BE_PREPARED" && (
                            <Button
                              className="section_5_left_button"
                              type="submit"
                              onClick={() => handleCancelOrder(printDetails.id)}
                            >
                              {isCancelOrderLoading ? (
                                <Loader
                                  active
                                  inline
                                  className="login_loader ladingFormLoader"
                                />
                              ) : (
                                "ANNULLA ORDINE"
                              )}
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                </>
              )}

              {isOrderIdDetailsLoading && (
                <Loader active inline className="orderIdLoader" />
              )}
            </div>
          )}
        </div>
      </div>
      {/* <div className="profile_footer_container">
        <Footer />
      </div> */}
    </div>
  );
}

export function mapDispatchToProps(dispatch) {
  return {
    getUserProfile: (payload) =>
      dispatch({ type: "GET_USER_PROFILE", payload }),
    updateUserProfile: (payload) =>
      dispatch({ type: "UPDATE_USER_PROFILE", payload }),
  };
}
export function mapStateToProps(state) {
  return {
    userProfile: state.userProfile.getUserProfileData,
    userProfileInfo: state.userProfile,
    allCities: state.homePageFav.cities,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: "userProfile", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(UserProfile);
