/* eslint-disable */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ItemsCarousel from "react-items-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Map, Marker, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";
import axios from "axios";
import { connect } from "react-redux";
import { compose } from "redux";
import { useMediaQuery } from "react-responsive";
import { Loader, Button } from "semantic-ui-react";
import { useSnackbar } from "react-simple-snackbar";
import Gallery from "react-photo-gallery";
import moment from "moment";
import injectSaga from "../../utils/injectSaga";
import { DAEMON } from "../../utils/constants";
import saga from "../Homepage/saga";
import StoreCard from "../../common/StoreCard/StoreCard";
import OffersNewsPopup from "../ShopDetails/offersNewsPopup/offersNewsPopup";
import history from "../../utils/history";
import {
  getMallDetail,
  getStoreOffers,
  getShopGallary,
  shopGallaryResource,
  shopBanners,
} from "../../Helpers/api";
import Footer from "../../components/footer/Footer";
import {
  promoItemImage,
  leftArrowMall,
  rightArrowMall,
  heartCard,
  aboutArrowLeft,
  aboutArrowRight,
  // store1,
  heartFilled,
  // store2,
  // store3,
  // store4,
  // store5,
  store6,
  // store7,
  locator,
  // mallImage1,
  // mallImage2,
  // mallImage3,
  // mallImage4,
  // mallImage5,
  // mallImage6,
  // mallImage7,
  // mallImage8,
  mapLocator,
  mallHeaderFallback,
} from "../../assets/images";
import "./MallDetails.scss";
import ShopImageSlider from "../ShopDetails/ShopImageSlider";
import NavBar from "../../components/NavBar/NavBar";
// import { userDetails } from '../Helpers/UserDetails';

function MallDetails(props) {
  const { t } = useTranslation();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isCreateLoding, setIsCreateLoding] = useState(false);
  const [shopBanner, setShopBanners] = useState([
    {
      image: "",
    },
  ]);
  const [isMallLoading, setIsMallLoading] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [mallOffers, setMallOffers] = useState([]);
  const [favMallsIds, setFavMallsIds] = useState([]);
  const [favStoresIds, setFavStoresId] = useState([]);
  const [mallTags, setMallTags] = useState([]);
  const [mallGallary, setMallGallary] = useState([]);
  const [timeTable, setTimeTable] = useState([]);
  const [isMallImagePresent, setIsMallImagePresent] = useState(true);
  const [isBannerImagePresent, setIBannerImagePresent] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const [mallData, setMallData] = useState({});
  const [mapCentre, setMapCentre] = useState([
    45.323321536272049,
    -75.3372987731628,
  ]);
  // const [mapCentre,setMapCentre] = useState([45.323321536272049, -75.3372987731628]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const chevronWidth = 40;

  const [openSnackbar] = useSnackbar({
    // position: 'top-center',
    style: {
      position: "absolute",
      backgroundColor: " baby blue",
      // border: '2px solid lightgreen',
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });

  const craeteFavStore = (storeId, type) => {
    const payload = {
      storeId,
      type,
    };
    setIsCreateLoding(true);
    props.createFavStores(payload);
    setTimeout(() => props.getFavStores(), 1000);
  };
  const deleteFavStore = (storeId, type) => {
    setIsCreateLoding(true);
    props.deleteFavStores({ storeId, type });
    setTimeout(() => props.getFavStores(), 100);
  };

  useEffect(() => {
    props.getFavStores();
    const mallId = props.match.params.id;
    const apiUrl = `${getMallDetail}/${mallId}`;
    setIsMallLoading(true);
    axios.get(apiUrl).then(
      (res) => {
        setMallData(res.data.data);
        if (res.data && res.data.data && res.data.data.timetableformall) {
          setTimeTable(res.data.data.timetableformall.split("\r\n"));
        }
        if (
          res.data &&
          res.data.data &&
          res.data.data.address &&
          res.data.data.address.latitude
        ) {
          setMapCentre([
            res.data.data.address.latitude,
            res.data.data.address.longitude,
          ]);
        }
        setIsMallLoading(false);

        // Set Mall tags
        if (res.data.data && res.data.data.tags) {
          const str = res.data.data.tags;
          const resTags = str.split(",");
          setMallTags(resTags);
        }
        // Set mall timing
        const shopTimingArray = [];
        if (
          res.data &&
          res.data.data &&
          res.data.data.shopTimetables &&
          res.data.data.shopTimetables.length
        ) {
          res.data.data.shopTimetables.forEach((timing) => {
            const timingObj = {};
            timingObj.day = `${timing.startday} ${
              timing.endday ? `- ${timing.endday}` : ""
            }`;
            let timeString = "";
            if (timing && timing.timings && timing.timings.length) {
              timing.timings.forEach((dayTiming) => {
                if (dayTiming.timeOpen || dayTiming.timeClosed) {
                  const timeOpen = new Date(dayTiming.timeOpen);
                  const timeClose = new Date(dayTiming.timeClosed);
                  const secondes = timeClose.getMilliseconds();
                  const isValid = !isNaN(secondes);
                  if (isValid) {
                    timeString += `${moment(timeOpen).format(
                      "HH.mm"
                    )} - ${moment(timeClose).format("HH.mm")}`;
                  }
                }
              });
            }
            timingObj.dayTime = timeString;
            shopTimingArray.push(timingObj);
          });
          // setTimeTable(shopTimingArray);
        }
      },
      (err) => {
        console.log(err);
        setIsMallLoading(false);
      }
    );

    // TO get the store under the mall
    axios.get(getMallDetail).then(
      (res) => {
        const mallObj = res?.data?.data?.find((item) => item.id === mallId);
        if (mallObj && mallObj.storeList.length) {
          setStoreList(mallObj.storeList);
        }
        // setMallData(res.data.data);
        // setMapCentre([
        //   res.data.data.address.latitude,
        //   res.data.data.address.longitude,
        // ]);
      },
      (err) => {
        console.log(err);
      }
    );

    //  Get Mall offers
    axios({
      method: "get",
      url: `${getStoreOffers}?id=${mallId}&type=MALL`,
    }).then(
      (res) => {
        if (res && res.data && res.data.data && res.data.data.offer) {
          const validOffers = res.data.data.offer.filter(
            (item) =>
              new Date(item.publishDate).valueOf() <= new Date().valueOf() &&
              new Date(item.validTo).valueOf() >= new Date().valueOf() &&
              item.enabled === true
          );
          setMallOffers(validOffers);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    // Update the document title using the browser API
    document.addEventListener("scroll", () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
    return () => {
      document.removeEventListener("scroll", () => {});
    };
  });

  // Get Fav stores and mall Ids

  useEffect(() => {
    if (props.favStores) {
      const filterFavStoresId = [];
      const filterFavMallsId = [];
      if (
        props.favStores &&
        props.favStores.data &&
        props.favStores.data.data &&
        props.favStores.data.data.stores
      ) {
        props.favStores.data.data.stores.forEach((item) =>
          filterFavStoresId.push(item.id)
        );
      }
      if (
        props.favStores &&
        props.favStores.data &&
        props.favStores.data.data &&
        props.favStores.data.data.malls
      ) {
        props.favStores.data.data.malls.forEach((item) =>
          filterFavMallsId.push(item.id)
        );
      }
      setFavStoresId(filterFavStoresId);
      setFavMallsIds(filterFavMallsId);
    }
  }, [props.favStores]);

  // Mall Gallary

  useEffect(() => {
    const mallId = props.match.params.id;
    const shopGallQueryUrl = `${getShopGallary}?type=mall&storeId=${mallId}`;
    // axios.get(shopGallQueryUrl)
    axios({
      method: "GET",
      url: shopGallQueryUrl,
      // auth: {
      //   username: 'g.scirea',
      //   password: 'testtest',
      //   // username: userDetails().email,
      //   // password: userDetails().password,
      // },
    }).then(
      (res) => {
        const imageArray = [];
        res.data.data.forEach((item) => {
          // const imgeUrl = `${shopGallaryResource}${item.image}`;
          // const imgeUrlEncode = encodeURI(imgeUrl);
          imageArray.push({ src: item.imageUrl, width: 4, height: 3 });
        });
        setMallGallary(imageArray);

        // setMallGallary()
      },
      (err) => {
        console.log(err);
      }
    );
    // Banner API
    let bannerUrl = `${shopBanners}?category=WEB&shopid=${mallId}`;
    if (isTabletOrMobile) {
      bannerUrl = `${shopBanners}?category=TABLET&shopid=${mallId}`;
    }

    axios({
      method: "get",
      url: bannerUrl,
      // url: `${shopBanners}?category=APP-HOME&storeId=014`,
    }).then(
      (res) => {
        console.log(res);
        if (res.data && res.data.length) {
          setShopBanners(res.data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };
  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };
  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };
  useEffect(() => {
    setIsCreateLoding(false);
  }, [props.createFavStoresSuccess, props.deletedFavStoreSuccess]);

  const skater = new Icon({
    iconUrl: mapLocator,
    iconSize: [28, 35],
  });
  const formImageUrl = (imageName) => {
    const imgeUrl = `${shopGallaryResource}${imageName}`;
    const imgeUrlEncode = encodeURI(imgeUrl);
    return imgeUrlEncode;
  };
  const handleModalOpen = (payload) => {
    setShowOfferModal(true);
    setModalData(payload);
  };
  return (
    <div
      className={
        shopBanner && shopBanner.length > 1
          ? "mall_details_main_continer"
          : "mall_details_main_continer mall_details_dots"
      }
    >
      <header>
        <div className="nav_bar_container">
          {/* <div>
            <NavBar isScrolled={isScrolled} mallDetailView />
          </div> */}
          {shopBanner && shopBanner.length > 0 && (
            <div className="home_carousae_container">
              <div className="home_page_background">
                <Carousel
                  autoPlay
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  infiniteLoop
                  interval={5000}
                  transitionTime={2000}
                  selectedItem={currentSlide}
                  onChange={updateCurrentSlide}
                >
                  {shopBanner.map((banner) => (
                    <div
                      className="first_photo"
                      style={{
                        backgroundImage:
                          isBannerImagePresent && banner && banner.image
                            ? `url(${banner.imageUrl})`
                            : `url(${mallHeaderFallback})`,
                        // backgroundImage: `url(${defaultBannerImage})`,
                      }}
                    >
                      {/*<div className="text_container">*/}
                      {/*  <p className="carousal_text_heading">{banner.title}</p>*/}

                      {/*  <p className="carousal_text_content">{banner.body}</p>*/}
                      {/*</div>*/}
                      {isBannerImagePresent && banner && banner.image && (
                        <img
                          style={{
                            width: "0",
                            visibility: "hidden",
                          }}
                          src={banner.imageUrl}
                          alt="default"
                          onError={(e) => {
                            console.log(e);
                            setIBannerImagePresent(false);
                          }}
                        />
                      )}
                      {banner.link && banner.link.length && (
                        <Button
                          className="landing_login_button homePageDiscover"
                          type="submit"
                          onClick={() => window.open(`http://${banner.link}`)}
                        >
                          {banner.buttonLabel !== ""
                            ? banner.buttonLabel
                            : "SCOPRI"}
                        </Button>
                      )}
                    </div>
                  ))}
                </Carousel>
                {shopBanner.length > 1 && (
                  <button
                    className="corousal_left_button"
                    type="button"
                    onClick={prev}
                  >
                    <img src={aboutArrowLeft} alt="left button" />
                  </button>
                )}
                {shopBanner.length > 1 && (
                  <button
                    className="corousal_right_button"
                    type="button"
                    onClick={next}
                  >
                    <img src={aboutArrowRight} alt="right arrow" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </header>

      {/* Image to set background image */}
      {isMallImagePresent && shopBanner && (
        <img
          style={{ width: "0", height: "0", visibility: "hidden" }}
          src={formImageUrl(shopBanner)}
          alt="store"
          onError={(e) => {
            console.log(e);
            setIsMallImagePresent(false);
          }}
        />
      )}
      {mallData && !isMallLoading ? (
        <div className="mall_detail_content">
          <div className="mall_detail_content_description">
            {mallTags &&
              mallTags.length > 0 &&
              mallTags.map((tag, index) => (
                <span key={index} className="shop_tags">
                  {tag ? `#${tag}` : null}
                </span>
              ))}
            <div className="mall_name_container">
              <p className="mall_detail_content_description_name">
                {mallData.name}
                {/* Nome del centro commerciale (name) completo */}
              </p>
              {favMallsIds.includes(mallData.id) &&
              localStorage.getItem("token") ? (
                <button
                  className="mall_heart_button"
                  type="button"
                  onClick={() =>
                    !isCreateLoding && deleteFavStore(mallData.id, "mall")
                  }
                >
                  <img src={heartFilled} alt="heart" />
                </button>
              ) : (
                <button
                  className="mall_heart_button"
                  type="button"
                  onClick={() => {
                    if (localStorage.getItem("token")) {
                      if (!isCreateLoding) {
                        craeteFavStore(mallData.id, "mall");
                      }
                    } else {
                      openSnackbar(t("notLogged"));
                    }
                  }}
                >
                  <img src={heartCard} alt="heart" />
                </button>
              )}
              {/* <img src={heartFilled} alt="" className="mall_heart_image" /> */}
            </div>

            <p className="mall_detail_content_description_content">
              {mallData.description}
              {/* Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
              sem. Nulla consequat massa quis enim. Donec pede justo, fringilla
              vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
              imperdiet a, venenatis vitae, justo. */}
            </p>
            <button
              className="mall_view_go_to_site"
              type="button"
              id="button-id"
              onClick={() => window.open(mallData.webSite)}
            >
              VAI AL SITO
            </button>
          </div>

          {/* {mallGallary && mallGallary.length > 0 && (
            <div className="shop_gallary">
              <Gallery photos={mallGallary} direction="row" />
            </div>
          )} */}
          {mallGallary && mallGallary.length > 0 && (
            <div className="shop_gallary">
              <ShopImageSlider images={mallGallary} />
            </div>
          )}

          {/* <div className="mall_images_carousel">
            <div className="vertical corusal_iamge">
              <img src={mallImage1} alt="" />
            </div>
            <div className="horizontal corusal_iamge">
              <img src={mallImage2} alt="" />
            </div>
            <div className="small corusal_iamge">
              <img src={mallImage3} alt="" />
            </div>
            <div className="horizontal corusal_iamge">
              <img src={mallImage4} alt="" />
            </div>
            <div className="vertical corusal_iamge">
              <img src={mallImage5} alt="" />
            </div>
            <div className="big corusal_iamge">
              <img src={mallImage6} alt="" />
            </div>
            <div className="medium corusal_iamge">
              <img src={mallImage7} alt="" />
            </div>
            <div className="small corusal_iamge">
              <img src={mallImage8} alt="" />
            </div>
          </div> */}

          {mallOffers && mallOffers.length > 0 && (
            <div className="mall_cards_carousal_container">
              <p className="mall_cards_carousal_container_heading">
                OFFERTE / NEWS
              </p>
              <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={isTabletOrMobile ? 3 : 4}
                gutter={20}
                rightChevron={
                  <div>
                    <img src={rightArrowMall} alt="promo right icon" />
                  </div>
                }
                leftChevron={
                  <div>
                    <img
                      src={leftArrowMall}
                      // style={{ transform: 'rotate(180deg)' }}
                      alt="left"
                    />
                  </div>
                }
                alwaysShowChevrons
                classes={{
                  wrapper: "main_wrapper_cards_mall",
                  //   itemsWrapper: 'mall_cards_item_wrapper',
                  itemWrapper: "mall_card_item_wrapper",
                  rightChevronWrapper: "right_button_mall",
                  leftChevronWrapper: "left_button_mall",
                }}
                outsideChevron
                chevronWidth={chevronWidth}
              >
                {mallOffers.map((item) => (
                  <div
                    className="shoppping_promo_card shoppping_promo_card_mall"
                    // eslint-disable-next-line react/no-array-index-key
                    key={item.id}
                    onClick={() =>
                      handleModalOpen({ offer: item, store: mallData })
                    }
                    // onClick={() => setShowPromoModal(true)}
                  >
                    <div className="img_card">
                      <img
                        src={item.imageUrl}
                        onError={(e) => {
                          e.target.src = promoItemImage;
                        }}
                        alt="shopping centre"
                        className="promo_mage"
                      />
                    </div>
                    <p className="promo_name">{item.title}</p>
                  </div>
                ))}
              </ItemsCarousel>
              <OffersNewsPopup
                showOfferModal={showOfferModal}
                setShowOfferModal={setShowOfferModal}
                modalData={modalData}
              />
            </div>
          )}

          {storeList && storeList.length > 0 && (
            <div className="malls_shops">
              <section className="shopping_center shopping_center_div_for_mall">
                <p className="shopping_center_heading">NEGOZI</p>
                <div className="shopping_center_details_container malls_shops_container">
                  {storeList.map((store) => (
                    <StoreCard
                      key={store.id}
                      shopId={store.id}
                      isCreateFav
                      shop={store}
                      isCreateLoding={isCreateLoding}
                      deleteFavStore={deleteFavStore}
                      type="store"
                    />
                  ))}
                </div>
              </section>
            </div>
          )}

          <div className="mall_mapview_container mall_mapview_padding">
            <div className="mall_mapview_card">
              <div className="mall_mapview_card_details">
                <p className="mall_mapview_card_details_heading">
                  INFORMAZIONI UTILI
                </p>
                <p className="mall_mapview_card_details_sub_heading">
                  ORARI DI APERTURA
                </p>
                {timeTable.map((item) => (
                  <div>
                    <div className="mall_timing_details_container">
                      <span className="mall_timing_details_name">{item}</span>
                      {/* <span className="mall_timing_details_value">
                        {item.dayTime}
                      </span> */}
                    </div>
                  </div>
                ))}
                {/* <div className="mall_timing_details_container">
                  <span className="mall_timing_details_name">lunEDì</span>
                  <span className="mall_timing_details_value">
                    10:30 - 21:30
                  </span>
                </div>
                <div className="mall_timing_details_container">
                  <span className="mall_timing_details_name">
                    martedì - venerdì
                  </span>
                  <span className="mall_timing_details_value">
                    9:30 - 21:30
                  </span>
                </div>
                <div className="mall_timing_details_container">
                  <span className="mall_timing_details_name">
                    sabato - domenica
                  </span>
                  <span className="mall_timing_details_value">
                    9:30 - 22:30
                  </span>
                </div> */}
                <p className="mall_mapview_card_details_sub_heading">
                  DOVE SIAMO
                </p>
                {mallData && mallData.address && mallData.address.address && (
                  <div className="mall_timing_address_container">
                    <img src={locator} alt="" />
                    <p className="mall_centre_address">
                      {`${mallData.address.address},  ${mallData.address.city},  ${mallData.address.state},  ${mallData.address.zip}, ${mallData.address.county}`}
                      {/* Via A. Manzoni 20, 00000, Chieri (TO) */}
                    </p>
                  </div>
                )}
                {/* <div className="mall_timing_address_container">
                  <img src={locator} alt="" />
                  <p className="mall_centre_address">
                    Via A. Manzoni 20, 00000, Chieri (TO)
                  </p>
                </div> */}
              </div>
              {mapCentre && mapCentre.length > 0 && (
                <div className="malll_mapview_card_map">
                  <Map
                    center={mapCentre}
                    zoom={14}
                    zoomControl={false}
                    scrollWheelZoom={false}
                    doubleClickZoom={false}
                    dragging={false}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker
                      key={1}
                      position={[mapCentre[0], mapCentre[1]]}
                      icon={skater}
                      // onclick={e => {
                      //   history.push({
                      //     pathname: '/mall-map',
                      //     search: `lat=${e.latlng.lat}&lng=${e.latlng.lng}`,
                      //   });
                      // }}
                      onClick={(e) =>
                        window.open(
                          `/mall-map?lat=${e.latlng.lat}&lng=${e.latlng.lng}`
                        )
                      }
                    />
                  </Map>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader active inline className="mall_loader" />
      )}

      {/* <Footer /> */}
    </div>
  );
}

MallDetails.propTypes = {
  match: PropTypes.object,
  favStores: PropTypes.object,
  getFavStores: PropTypes.object,
  deleteFavStores: PropTypes.object,
  createFavStores: PropTypes.object,
  createFavStoresSuccess: PropTypes.object,
  deletedFavStoreSuccess: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
  return {
    getMallsStores: (payload) =>
      dispatch({ type: "GET_MALLS_STORES", payload }),
    searchMallsStores: (payload) =>
      dispatch({ type: "SEARCH_MALLS_STORES", payload }),
    getFavStores: (payload) =>
      dispatch({ type: "GET_FAVOURITE_STORES", payload }),
    deleteFavStores: (payload) =>
      dispatch({ type: "DELETE_FAVOURITE_STORES", payload }),
    createFavStores: (payload) =>
      dispatch({ type: "CREATE_FAVOURITE_STORE", payload }),
  };
}
export function mapStateToProps(state) {
  return {
    mallsAndStores: state.homePageFav.mallsStores,
    favStores: state.homePageFav.favStores,
    searchedMallsAndStores: state.homePageFav,
    createFavStoresSuccess: state.homePageFav.createdFavStore,
    deletedFavStoreSuccess: state.homePageFav.deletedFavStore,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: "mallsStores", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(MallDetails);
