import React from "react";
import { store6 } from "../../assets/images";
import "./ProductCard.scss";

function StoreCard(props) {
  const { product } = props;

  return (
    <div className="product-card">
      <div className="store-card-container">
        <div
          style={{
            backgroundImage: `url(${product.imageUrl}),url(${store6})`,
          }}
          className="store-image"
        ></div>
        <div className="name-description">
          <span>{product.description}</span>
        </div>
        {parseInt(product.rowDiscount, 10) > 0 && (
          <p className="promo_text_card">{`Promozione - ${product.rowDiscount}%`}</p>
        )}
        <div className="old_new_price_container_card">
          {parseInt(product.rowDiscount, 10) > 0 && (
            <span className="old_price">€{product.price}</span>
          )}
          <span className="new_price">€{product.netPrice}</span>
        </div>
      </div>
    </div>
  );
}

export default StoreCard;
