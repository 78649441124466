/* eslint-disable no-unneeded-ternary */
/* eslint-disable operator-assignment */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import "./FidelityPage.scss";
import axios from "axios";
import { fidelityCard } from "../../assets/images";
import ShopDetailModal from "./ShopDetailModal/ShopDetailModal";
import { fidelityShopPoints, lastFiveTrans } from "../../Helpers/api";
import { userDetails } from "../../Helpers/UserDetails";
import { getFidelityColor } from "../../Helpers/functions";
// const QRCode = require('qrcode.react');

function FidelityPage() {
  const [showShopDetailModal, setShowShopDetailModal] = useState(false);
  const [totalCardPoints, setTotalCardPoints] = useState(0);
  const [lastFiveTransactions, setLastFiveTransactions] = useState([]);
  const [shopId, sendShopId] = useState("");
  const [shopData, setShopData] = useState({});
  const [shopPoints, setShopPoints] = useState([]);
  useEffect(() => {
    const url = `${fidelityShopPoints}?userId=${userDetails().userId}`;
    axios(url, {
      method: "GET",
      auth: {
        username: userDetails().email,
        password: userDetails().password,
      },
    })
      .then((res) => {
        let totalPoints = 0;
        res.data.data.forEach((item) => {
          if (item.points_remaining) {
            totalPoints = totalPoints + parseInt(item.points_remaining, 10);
          }
        });
        setTotalCardPoints(totalPoints);
        setShopPoints(res.data.data);
        // setLastFiveTransactions(res.data.data.slice(0, 5));
      })
      .catch((error) => {
        console.log(error);
      });
    // Get last 5 transaction by a user
    const lastFiveTransacions = `${lastFiveTrans}?userId=${
      userDetails().userId
    }`;
    axios(lastFiveTransacions, {
      method: "GET",
      auth: {
        username: userDetails().email,
        password: userDetails().password,
      },
    }).then(
      (res) => {
        setLastFiveTransactions(res.data.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return {
      dt,
      month,
      year,
    };
  };

  return (
    <div className="fidelity_page">
      <header className="fidelity_header">
        <div className="fidelity_header_text">La tua fidelity card</div>
      </header>
      <div className="fidelity_content_section">
        <div className="your_card_column">
          <div className="your_card_image_container">
            <p className="your_card_text">La tua card</p>
            <div className="fidelity_user_card">
              <img
                src={fidelityCard}
                alt="your card"
                className="your_card_image"
              />
              <div className="fidelity_main_container">
                <div className="fidelity_user_card_name">
                  {userDetails().name}
                </div>
                {/* <div className="fidelity_user_card_id_name">ID CARD:</div>
              <div className="fidelity_user_card_id_value">
                xxxxxxxxxxxxxxxx
              </div> */}
                <div className="point_cash_main_container">
                  <div className="ponit_key_value_container">
                    <div className="fidelity_user_card_point_name">
                      Saldo punti
                    </div>
                    <div className="fidelity_user_card_point_value">
                      {totalCardPoints ? totalCardPoints : 0}
                    </div>
                  </div>
                  <div className="cash_key_value_container">
                    <div className="fidelity_user_card_cash_name">Cashback</div>
                    <div className="fidelity_user_card_cash_value">
                      {totalCardPoints ? `${totalCardPoints / 10} €` : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="qr_conatainer qr_conatainer_bigger_size">
              <QRCode
                // value="http://facebook.github.io/react/"
                value={`${userDetails().userId}:::${
                  userDetails().firstName
                }:::${userDetails().lastName}`}
                size={80}
                bgColor="#ffffff"
                fgColor="#000000"
                level="L"
                includeMargin={false}
                renderAs="svg"
              />
            </div>
            <div className="qr_conatainer qr_conatainer_smaller_size">
              <QRCode
                // value="http://facebook.github.io/react/"
                value={`${userDetails().userId}:::${
                  userDetails().firstName
                }:::${userDetails().lastName}`}
                size={50}
                bgColor="#ffffff"
                fgColor="#000000"
                level="L"
                includeMargin={false}
                renderAs="svg"
              />
            </div> */}
            {/* <img
              src={fidelityCard}
              alt="your card"
              className="your_card_image"
            /> */}
          </div>
          <div className="last_five_transactions">
            ULTIME {lastFiveTransactions.length} TRANSAZIONI
          </div>
          <div className="transaction_header">
            <span className="transaction_date_header header_data_flex_size">
              DATA
            </span>
            <span className="transaction_date_header header_price_flex_size">
              SPESA
            </span>
            <span className="transaction_date_header header_points_flex_size">
              SALDO
            </span>
          </div>
          {lastFiveTransactions.map((item, i) => (
            <div
              className="transaction_data"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              style={(i + 1) % 2 === 0 ? { backgroundColor: "#ffffff" } : null}
            >
              <span className="transaction_data_date">
                {`${dateFormat(item.updated).dt}/${
                  dateFormat(item.updated).month
                }/${dateFormat(item.updated).year}`}
              </span>
              {item.mallId && item.mallId !== "" ? (
                <span className="transaction_data_price">CC</span>
              ) : (
                <span className="transaction_data_price">
                  {item.points_type === "earned"
                    ? item.net_cart_amount.toFixed(0)
                    : +item.net_cart_amount - +item.points / 10}{" "}
                  €
                </span>
              )}

              <span
                style={{ color: item.points_type !== "earned" ? "red" : "" }}
                className="transaction_data_points transaction_data_points_new"
              >
                {item.points_type !== "earned" && "-"} {item.points.toFixed(0)}{" "}
                pt
              </span>
              {/* <span className="transaction_data_price">
                {item.points / 10} €
              </span>
              {item.points_type === 'earned' ? (
                <span
                  className="transaction_data_points"
                  // style={{ color: 'red' }}
                >
                  {item.points} pt
                </span>
              ) : (
                <span
                  className="transaction_data_points"
                  style={{ color: 'red' }}
                >
                  -{item.points} pt
                </span>
              )} */}
            </div>
          ))}
        </div>
        {/* <div className="other_cards_section">
            <div className="most_used_section_container">
              <p className="most_used_heading">Le carte più usate</p>
              <div className="most_used_section">
                {[...Array(4)].map((e, i) => (
                  <div
                    className="other_card_shape"
                    key={i}
                    style={
                      i === 1
                        ? { backgroundColor: '#17768d' }
                        : i === 2
                        ? { backgroundColor: '#178d67' }
                        : i === 3
                        ? { backgroundColor: '#17438d' }
                        : null
                    }
                    onClick={() => setShowShopDetailModal(true)}
                  >
                    <p className="other_card_text">
                      NOME NEGOZIO ANCHE SU DUE O TRE RIGHE
                    </p>
                    <div className="points_on_card">
                      <span className="point_number">345</span>{' '}
                      <span className="pt_text">pt</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}

        <div className="other_cards_section">
          <div className="most_used_section_container">
            <p className="most_used_heading">Tutte le card</p>
            <div className="most_used_section">
              {shopPoints &&
                shopPoints.length > 0 &&
                shopPoints.map((shop, index) => (
                  <div
                    className="other_card_shape"
                    key={shop.id}
                    style={
                      { backgroundColor: getFidelityColor(index) }

                      // index % parseInt(Math.random() * (10 - 1)) === 0
                      //   ? { backgroundColor: "#17768d" }
                      //   : (index + 1) % parseInt(Math.random() * (10 - 1)) === 0
                      //   ? { backgroundColor: "#178d67" }
                      //   : (index + 2) % parseInt(Math.random() * (10 - 1)) === 0
                      //   ? { backgroundColor: "#17438d" }
                      //   : null
                    }
                    onClick={() => {
                      sendShopId(shop.id);
                      setShopData(shop);
                      setShowShopDetailModal(true);
                    }}
                  >
                    <p className="other_card_text">{shop.shopName}</p>
                    <div className="points_on_card">
                      <span className="point_number">
                        {shop.points_remaining
                          ? shop.points_remaining.toFixed(0)
                          : 0}
                      </span>{" "}
                      <span className="pt_text">pt</span>
                    </div>
                    {/* {showShopDetailModal && (
                        <ShopDetailModal
                          showShopDetailModal={showShopDetailModal}
                          closeShopDetailModal={setShowShopDetailModal}
                          shopId={shopId}
                          shopData={shop}
                        />
                      )} */}
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* <div className="other_cards_section">
            <div className="most_used_section_container">
              <p className="most_used_heading">Tutte le card</p>
              <div className="most_used_section">
                {[...Array(8)].map((e, i) => (
                  <div
                    className="other_card_shape"
                    key={i}
                    onClick={() => setShowShopDetailModal(true)}
                    style={
                      i === 0
                        ? { backgroundColor: '#d1cf25' }
                        : i === 1
                        ? { backgroundColor: '#25bad1' }
                        : i === 2
                        ? { backgroundColor: '#178d18' }
                        : i === 3
                        ? { backgroundColor: '#4e7de0' }
                        : i === 4
                        ? { backgroundColor: '#4164d6' }
                        : i === 5
                        ? { backgroundColor: '#178d67' }
                        : i === 6
                        ? { backgroundColor: '#8d1731' }
                        : i === 7
                        ? { backgroundColor: '#25bad1' }
                        : null
                    }
                  >
                    <p className="other_card_text">
                      NOME NEGOZIO ANCHE SU DUE O TRE RIGHE
                    </p>
                    <div className="points_on_card">
                      <span className="point_number">345</span>{' '}
                      <span className="pt_text">pt</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
      </div>
      {shopData && shopData.id && (
        <ShopDetailModal
          showShopDetailModal={showShopDetailModal}
          closeShopDetailModal={setShowShopDetailModal}
          shopId={shopId}
          shopData={shopData}
        />
      )}
    </div>
  );
}

export default FidelityPage;
