import React from "react";
import Popup from "reactjs-popup";
import { Translation } from "react-i18next";

import 'reactjs-popup/dist/index.css';

export default (props) => (
    <Translation>
        {t =>
            <Popup lockScroll={true} trigger={props.trigger} contentStyle={popupStyle} modal>
                {close => (
                    <div className="popup">
                        <div className="close" onClick={close}>
                            &times;
                        </div>
                        <div className="popup-header">{props.title}</div>
                        <div className="content text-center">
                            {props.description}
                        </div>
                        <div className="actions">
                            <button className="ui button landing_login_button" onClick={() => { close(); }}>
                                <span>Chiudi</span>
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        }
    </Translation>
);

const popupStyle = {
    borderRadius: "8px",
    border: "none",
    backgroundColor: "#ffffff",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.27)",
    padding: "unset"
}