import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import _ from "lodash";
import { useMediaQuery } from "react-responsive";
import history from "../../utils/history";

import { merchantGif, offerte, mystores_puoi } from "../../assets/images";

import "../LandingPageContainer/new-landing-page.styles.scss";
import "./merchant-page.styles.scss";

import LoginForm from "../../components/Login/Login";
import SignupForm from "../../components/SignupForm";
import EmailVerificationModal from "../../components/LandingPage/EmailVerificationModal";
import RegistrationModal from "../LandingPageContainer/RegistrationModal/RegistrationModal";
import ResetPasswordModal from "../LandingPageContainer/ResetPasswordModal/ResetPasswordModal";
import LandingNavbar from "../LandingPageContainer/LandingNavbar/LandingNavbar";
import LandingGif from "../LandingPageContainer/LandingGif/LandingGif";
import LandingWriteUs from "../LandingPageContainer/LandingWriteUs/LandingWriteUs";

export function MerchantPage(props) {
    const fbq = window.fbq;
    const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [showSignUpSuccesModal, setShowSignUpSuccesModal] = useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [showResetPassordModal, setShowResetPassordModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const closeLoginModal = () => {
        setShowLoginModal(false);
    };
    const closeSignUpModal = () => {
        setShowSignUpModal(false);
    };
    const onSuccessfulSignUp = () => {
        setShowSignUpModal(false);
        setShowSignUpSuccesModal(true);
    };
    const closeSuccessfulSignUp = () => {
        setShowSignUpModal(false);
        setShowSignUpSuccesModal(false);
    };

    return (
        <div className="new-landing">
            <LandingNavbar
                setShowRegistrationModal={setShowRegistrationModal}
                setShowLoginModal={setShowLoginModal}
            />
            <LandingGif
                style={{ maxWidth: "80%", margin: "auto" }}
                title="Competi sul mercato ad armi pari.">
                <img src={merchantGif} alt="" />
            </LandingGif>
            <div className="merchant-container">
                <div className="merchant-container-block1">
                    <div className="text-block">
                        Con <b>My-Stores</b> hai a disposizione strumenti digitali efficaci e semplici da
                        utilizzare per <b>continuare a vendere ai tuoi clienti di sempre, trovarne nuovi e
                        <span className="pink"> costruire un vero e proprio centro commerciale di prossimità, </span></b>
                        insieme alle attività della tua zona.
                    </div>
                    <div className="text-block" style={{ margin: "20px 0" }}>
                        <b>Inizia subito a dare più visibilità al tuo negozio!</b>
                    </div>
                    <div className="text-center">
                        <button className="yellow-button" style={{ marginTop: "unset" }}
                            onClick={() => {
                                fbq('trackCustom', 'Conversione', {
                                    content_name: 'Entra_in_MyStores'
                                });
                                history.push("/register-store")
                            }}>
                            Registrati gratis
                        </button>
                    </div>
                </div>
                <div className="merchant-content bg-yellow">
                    {/* <div className="text-block" style={{ marginBottom: "30px", textTransform: "uppercase" }}>
                        <b>La registrazione è sempre gratuita</b>
                    </div> */}
                    <div className="landing-content reverse" style={{ marginBottom: "40px" }}>
                        <div className="content1">
                            <div className="text-title" style={{ fontSize: isMobileView ? "" : "34px", lineHeight: 1.4, whiteSpace: isMobileView ? "" : "nowrap" }}>
                                {/* <nobr> */}
                                <span className="pink">La registrazione è sempre gratuita</span>
                                {/* </nobr> */}
                            </div>
                            <p className="subtext">
                                ma puoi acquistare quando vuoi i servizi aggiuntivi per promuovere e gestire
                                la tua attività.
                            </p>
                            <div>
                                <div className="subtext pink">
                                    <b>Con i moduli personalizzabili potrai:</b>
                                </div>
                                <ul>
                                    <li className="subtext">
                                        Consentire ai tuoi clienti di
                                    <b> prenotare ed acquistare prodotti e servizi direttamente da casa. </b>
                                    </li>
                                    <li className="subtext">
                                        <b>Restare in contatto con i tuoi clienti.</b>
                                    </li>
                                    <li className="subtext">
                                        Inviare <b>offerte e promozioni</b> e creare la tua <b>fidelity card.</b>
                                    </li>
                                    <li className="subtext">
                                        Creare la tua <b>carta fedeltà.</b>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="content2">
                            <img src={offerte} style={{ width: "100%" }} alt="" />
                        </div>
                    </div>
                    {/* <div className="landing-content">
                        <div className="content1">
                            <img src={mystores_puoi} alt="" />
                        </div>
                        <div className="content2 d-flex h-80">
                            <div className="subtext">
                                <b>Costruire un vero e proprio centro commerciale di prossimità </b>
                                insieme alle attività commerciali della tua strada o del tuo quartiere.
                            </div>
                        </div>
                    </div> */}
                    <div className="text-block">
                        <b><span className="pink"> Vuoi attivare altri servizi per potenziare la tua attività?</span></b>
                        <div className="subtext">
                            Scopri i moduli opzionali a partire da <b>9,90€</b> al mese.
                        </div>
                        <button className="yellow-button" style={{ marginTop: "20px" }}
                            onClick={() => {
                                window.open("/pricing", "_blank")
                                // history.push("/pricing")
                            }}>
                            Scopri di più
                        </button>
                    </div>
                    {/* <div className="button-container">
                        <button className="orange-button">Scopri di più</button>
                    </div> */}
                </div>
                <div className="merchant-content" style={{ backgroundColor: "#faebc1" }}>
                    <div className="pink-title" style={{ marginBottom: "20px" }}>
                        L’UNIONE CHE FA LA FORZA
                    </div>
                    <div className="landing-content">
                        <div className="content1">
                            <div className="subtext">
                                <b>Costruire un vero e proprio centro commerciale di prossimità </b>
                                insieme alle attività commerciali della tua strada o del tuo quartiere.
                            </div>
                        </div>
                        <div className="content2">
                            <img src={mystores_puoi} alt="" />
                        </div>
                    </div>
                    <div className="subtext text-center" style={{ backgroundColor: "#faebc1", marginBottom: "20px" }}>
                        Insieme agli esercenti della tua zona puoi costruire un vero e proprio centro commerciale di prossimità: più attività sono coinvolte,
                        più clienti saranno attirati dalle vostre offerte commerciali. E ricorda che con My-Stores aumenti gratuitamente la visibilità del tuo negozio.
                    </div>
                    <div className="text-center">
                        <button className="yellow-button"
                            onClick={() => {
                                fbq('trackCustom', 'Conversione', {
                                    content_name: 'Entra_in_MyStores'
                                });
                                history.push("/register-store")
                            }}>
                            Registrati subito, è gratis!
                        </button>
                    </div>
                    {/* <div className="text-center" style={{ marginTop: "20px" }}>
                        <button className="pink-button"
                        onClick={() => {
                            window.location.href = "mailto:user@example.com?subject=My-Stores&body=Ciao. Volevo segnalarti questa bella iniziativa.";
                        }}
                        >
                            Invita un altro negozio in My-Stores
                        </button>
                    </div> */}
                </div>
                <div className="merchant-content bg-yellow">
                    <div className="text-title pink" style={{ marginBottom: "40px" }}>
                        Con My-Stores hai:
                    </div>
                    <div className="landing-content" style={{ alignItems: "unset", marginBottom: "unset" }}>
                        <div className="content1">
                            <p className="subtext">
                                Una piattaforma completa che ti supporta nello <b>sviluppo della strategia digitale </b>
                                più adatta alle tue esigenze.
                            </p>
                            <p className="subtext">
                                Un <b>sito Web responsivo e sempre aggiornato:</b> il tuo negozio online sarà raggiungibile
                                da PC e Smartphone.
                            </p>
                        </div>
                        <div className="content2">
                            <p className="subtext">
                                <b>APP native iOS e Android: </b>
                                una per gestire la tua attività e una dedicata ai tuoi clienti.
                            </p>
                            <p className="subtext">
                                Una console Web per la <b>gestione e l'amministrazione: </b>
                                così hai tutto sotto controllo con un unico strumento.
                            </p>
                        </div>
                    </div>
                    {/* <div className="button-container">
                        <button className="orange-button">Scopri di più</button>
                    </div> */}
                </div>
                <div className="merchant-content" style={{ backgroundColor: "#faebc1" }}>
                    <LandingWriteUs />
                </div>
                <div className="text-center" style={{ marginBottom: "60px" }}>
                    <button className="yellow-button d-tablet"
                        onClick={() => {
                            fbq('trackCustom', 'Conversione', {
                                content_name: 'Entra_in_MyStores'
                            });
                            history.push("/register-store")
                        }}>
                        Entra subito in My-Stores, è gratis!
                    </button>
                </div>
            </div>
            <LoginForm
                showLoginModal={showLoginModal}
                closeModal={closeLoginModal}
                setShowResetPassordModal={setShowResetPassordModal}
                setShowLoginModal={setShowLoginModal}
            />
            <SignupForm
                showSignUpModal={showSignUpModal}
                closeModal={closeSignUpModal}
                onSuccessfulSignUp={onSuccessfulSignUp}
            />
            <EmailVerificationModal
                showSignUpSuccesModal={showSignUpSuccesModal}
                closeSuccessfulSignUp={closeSuccessfulSignUp}
            />
            <RegistrationModal
                showRegistrationModal={showRegistrationModal}
                setShowRegistrationModal={setShowRegistrationModal}
                closeSuccessfulSignUp={closeSuccessfulSignUp}
                setShowSignUpModal={setShowSignUpModal}
            />
            <ResetPasswordModal
                showResetPassordModal={showResetPassordModal}
                setShowResetPassordModal={setShowResetPassordModal}
            />
        </div>
    );
}

export default MerchantPage;
