/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable operator-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-useless-escape */
/**
 *
 * SignupForm
 *
 */

import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Modal, Form, Grid, Dropdown, Loader } from "semantic-ui-react";
// import { DateInput } from 'semantic-ui-calendar-react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";

import "./SignupForm.scss";
// import isEmail from 'validator/lib/isEmail';
import { connect } from "react-redux";
import { compose } from "redux";
import injectSaga from "../../utils/injectSaga";
import axios from "axios";
import { DAEMON } from "../../utils/constants";
import { calendar } from "../../assets/images";
import { getPrivacyPolicy } from "../../Helpers/api";
// import history from '../../utils/history';
import saga from "./saga";
import { Link } from "react-router-dom";
import CityDropdown from "../../common/CityDropdown";
registerLocale("it", it);

// import PropTypes from 'prop-types';
// import styled from 'styled-components';

// import { FormattedMessage } from 'react-intl';
// import messages from './messages';

const SignupForm = (props) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    showModal: false,
    name: "",
    lastName: "",
    gender: "",
    date: "",
    homePhone: "",
    email: "",
    password: "",
    confirmPassword: "",
    errors: {
      name: "",
    },
    showPassword: false,
    showConfirmPassword: false,
    type1: false,
    type2: false,
    type3: false,
    type4: false,
  });

  const [selectedCity, setSlectedCity] = useState({ value: "", label: "" });
  const [isNewsLoading, setIsNewsLoading] = useState(false);
  const [citiesOptions, setCitiesOptions] = useState([
    {
      key: "00010",
      text: "Casape [00010]",
      value: "00010",
    },
    {
      key: "00012",
      text: "Guidonia Montecelio [00012]",
      value: "00012",
    },
    {
      key: "00013",
      text: "Fonte Nuova [00013]",
      value: "00013",
    },
  ]);
  const [privacyPolicy, setPrivacyPolicy] = useState([]);
  let mandIndex = 0;
  let optIndex = 2;

  useEffect(() => {
    if (props.signUpInfo.signupRes) {
      console.log(props.signUpInfo.signupRes);
      if (props.signUpInfo.signupRes.data.responseCode === 0) {
        if (isNewsLoading) {
          setIsNewsLoading(false);
          props.onSuccessfulSignUp();
        }

        closeModal();
      } else {
        const errors = {};
        // errors.email = 'Email already exist';
        errors.email = t("userSignupValidation.emailExist");
        setValues({ ...values, errors });
      }
    }
  }, [props.signUpInfo.signupRes]);

  useEffect(() => {
    if (props.allCities && props.allCities.length) {
      const cityWithoutAllZips = props.allCities.filter(
        (city) => !city.zipRange
      );
      setCitiesOptions(cityWithoutAllZips);
      // setCitiesOptions(props.allCities);
    }
  }, []);

  useEffect(() => {
    // axios.get(getCities).then(
    //   res => {
    //     const cityArray = [];
    //     if (res && res.data && res.data.data) {
    //       res.data.data.sort((a, b) => a.id - b.id);
    //       res.data.data.forEach(item => {
    //         cityArray.push({
    //           key: item.id,
    //           value: item.id,
    //           text: `${item.city} [${item.id}]`,
    //         });
    //       });
    //       setCitiesOptions(cityArray);
    //     }
    //   },
    //   err => {
    //     console.log(err);
    //   },
    // );

    // Get privacy policy

    axios.get(getPrivacyPolicy).then(
      (res) => {
        setPrivacyPolicy(res.data.data);
        console.log(res.data.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const handleChangeForms = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSelectPrivacyPoints = (e) => {
    setValues({ ...values, [e.target.name]: e.target.checked });
  };
  function convertDateFormat(str) {
    const date2 = new Date(str);
    const mnth = `0${date2.getMonth() + 1}`.slice(-2);
    const day = `0${date2.getDate()}`.slice(-2);
    return [day, mnth, date2.getFullYear()].join("-");
  }

  const onSubmit = (evt) => {
    evt.preventDefault();
    const errors = validateData(values);
    setValues({ ...values, errors });
    const payload = {};
    const profile = {};

    profile.firstName = values.name;
    profile.lastName = values.lastName;
    profile.email = values.email;
    if (values.gender) {
      profile.gender = values.gender;
    }
    console.log(values.date);
    if (values.date && values.date !== "") {
      const newBirthDate = convertDateFormat(values.date);
      console.log(newBirthDate);
      profile.birthDate = newBirthDate.split("-").reverse().join("-");
    } else {
      profile.birthDate = null;
    }
    // profile.birthDate = values.date
    //   .split('-')
    //   .reverse()
    //   .join('-');
    profile.language = "it";
    if (profile.homePhone) {
      profile.homePhone = values.homePhone;
    }
    profile.preferredcityid = selectedCity.value;

    // profile.accept1 = true;
    // profile.accept2 = true;
    profile.accept1 = values.type1;
    profile.accept2 = values.type2;
    profile.accept3 = values.type3;
    profile.accept4 = values.type4;
    profile.cards = [];
    profile.preferences = {};
    profile.address = {};

    payload.profile = profile;
    payload.username = values.email;
    payload.password = values.password;

    if (Object.keys(errors).length === 0) {
      setValues({ ...values, loading: true });
      setIsNewsLoading(true);
      props.onSubmit(payload);
      // props.onSuccessfulSignUp();
    }
  };

  const validateData = (data) => {
    const errors = {};
    // if (!isEmail(data.email)) errors.email = 'Invalid email';
    console.log(data);
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (data.email) {
      if (!regEmail.test(data.email)) {
        // errors.email = 'Invalid email';
        errors.email = t("userSignupValidation.emailInvalid");
      }
    } else {
      // errors.email = "Email can't be empty";
      errors.email = t("userSignupValidation.emptyEmail");
    }

    // if (!data.password) errors.password = "Password can't be empty";
    if (!data.password)
      errors.password = t("userSignupValidation.passwordEmpty");

    if (data.confirmPassword !== data.password)
      // errors.confirmPassword = 'Not same as password';
      errors.confirmPassword = t("userSignupValidation.confrmPasswordNotSame");

    // if (!data.name) errors.name = "Name can't be empty";
    if (!data.name) errors.name = t("userSignupValidation.name");

    // if (!data.lastName) errors.lastName = "Last name Can't be empty";
    if (!data.lastName) errors.lastName = t("userSignupValidation.lastName");

    // if (!data.gender) errors.gender = t("userSignupValidation.gender");

    // if (!data.homePhone) errors.homePhone = "Phone can't be empty";
    // if (!data.homePhone) errors.homePhone = t("userSignupValidation.phone");

    if (
      data.homePhone &&
      (data.homePhone.length < 8 || data.homePhone.length > 14)
    ) {
      errors.homePhone = t("userSignupValidation.phoneValidation");
    }

    // if (!selectedCity) errors.selectedCity = "City can't be empty";
    if (!selectedCity.value)
      errors.selectedCity = t("userSignupValidation.city");

    if (!data.type1) errors.type1 = t("userSignupValidation.privacy");
    if (!data.type2) errors.type2 = t("userSignupValidation.privacy");

    // Age calculater
    if (data.date) {
      // const d = new Date(data.date);
      // const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      // const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
      // const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

      // const dob = new Date(ye, mo, da);
      // const diffMs = Date.now() - dob.getTime();
      // const ageDt = new Date(diffMs);

      // const finalAge = Math.abs(ageDt.getUTCFullYear() - 1970);

      const today = new Date();
      const birthDate = new Date(data.date);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
      }
      const finalAge = age;
      // if (finalAge < 18) errors.date = 'Age must be greater than 18';
      if (finalAge < 18) errors.date = t("userSignupValidation.dobValidate");
    } else {
      // errors.date = 'Age is compulsary';
      // errors.date = t('userSignupValidation.dob');
    }
    // Password validation
    if (data.password.length) {
      const paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/.test(
        data.password
      );
      if (!paswd) {
        // errors.password =
        //   'Password must contain atleast 8 characters, should contain atleast one special character and one uppercase character';
        errors.password = t("userSignupValidation.passwordValid");
      }
    }
    if (!data.type1) errors.type1 = t("userSignupValidation.privacy");

    if (!data.type2) errors.type2 = t("userSignupValidation.privacy");
    return errors;
  };

  // const handleDateChange = (event, { name, value }) => {
  //   setValues({ ...values, [name]: value });
  // };

  const closeModal = () => {
    setValues({ ...values, showModal: false });
    setValues({
      name: "",
      lastName: "",
      date: "",
      homePhone: "",
      email: "",
      password: "",
      confirmPassword: "",
      errors: {
        name: "",
      },
      showPassword: false,
      type1: false,
      type2: false,
      type3: false,
      type4: false,
    });
    props.closeModal();
  };

  const {
    name,
    lastName,
    date,
    homePhone,
    email,
    password,
    confirmPassword,
    errors,
  } = values;

  const headerStyle = {
    fontSize: "22px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.14",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#333333",
    borderBottom: "none",
  };
  const options = [
    { key: "m", text: "Male", value: "male" },
    { key: "f", text: "Female", value: "female" },
    { key: "o", text: "Other", value: "other" },
  ];

  return (
    <Modal
      closeIcon={{
        style: { top: "1.0535rem", right: "1rem" },
        color: "black",
        name: "close",
      }}
      onClose={closeModal}
      open={props.showSignUpModal}
      style={{ maxWidth: "746px" }}
      // trigger={
      //   <Button onClick={() => setValues({ ...values, showModal: true })}>
      //     Sign up
      //   </Button>
      // }
      className="signUpModal"
    >
      {/* <Modal.Header style={headerStyle}>Registrazione Utente</Modal.Header> */}
      <Modal.Header style={headerStyle}>
        {t("userSignUpModalHeading")}
      </Modal.Header>
      <Modal.Content>
        <Form autoComplete="off" onSubmit={onSubmit}>
          <Grid>
            <Grid.Column width={8} stretched verticalAlign="middle">
              <div style={{ position: "relative", margin: "0 0 10px 0" }}>
                <Form.Input
                  name="name"
                  placeholder="Nome*"
                  value={name}
                  onChange={handleChangeForms}
                />
                {errors.name && (
                  <span
                    // style={{
                    //   position: 'absolute',
                    //   top: '52px',
                    //   left: '27px',
                    //   color: '#cfa3a2',
                    // }}
                    className="error_message_sign_up"
                  >
                    {errors.name}
                  </span>
                )}
              </div>
              <div
                style={{ position: "relative" }}
                className="date_container_user"
              >
                {/* <DateInput
                  name="date"
                  placeholder="Data di nascita*"
                  value={date}
                  iconPosition="right"
                  onChange={handleDateChange}
                  closeOnMouseLeave
                  animation="none"
                  closable
                  icon="calendar alternate outline"
                  maxDate={new Date()}
                /> */}
                <DatePicker
                  placeholderText="Data di nascita"
                  selected={date}
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  onChange={(dateOfBirth) => {
                    setValues({ ...values, date: dateOfBirth });
                  }}
                  locale="it"
                />

                <img
                  src={calendar}
                  alt="calendar"
                  className="calendar_image_user"
                />
                {errors.date && (
                  <span
                    // style={{
                    //   position: 'absolute',
                    //   top: '52px',
                    //   left: '27px',
                    //   color: '#cfa3a2',
                    // }}
                    className="error_message_sign_up"
                  >
                    {errors.date}
                  </span>
                )}
              </div>
            </Grid.Column>
            <Grid.Column
              width={8}
              stretched
              verticalAlign="middle"
              style={{ paddingTop: "30px" }}
            >
              <div style={{ position: "relative", margin: "0 0 10px 0" }}>
                <Form.Input
                  name="lastName"
                  placeholder="Cognome*"
                  value={lastName}
                  onChange={handleChangeForms}
                />
                {errors.lastName && (
                  <span className="error_message_sign_up">
                    {errors.lastName}
                  </span>
                )}
              </div>
              <div style={{ position: "relative", margin: "0 0 10px 0" }}>
                <Form.Select
                  fluid
                  options={options}
                  placeholder="Sesso"
                  icon="angle down"
                  className="gender_dropdown"
                  onChange={(e, { value }) => {
                    setValues({
                      ...values,
                      gender: value.toLocaleUpperCase(),
                    });
                  }}
                />
                {errors.gender && (
                  <span className="error_message_sign_up">{errors.gender}</span>
                )}
              </div>
            </Grid.Column>
          </Grid>
          <div>
            <div
              style={{ position: "relative", margin: "0 0 10px 0" }}
              className="user_reg_phone_container"
            >
              <Form.Input
                name="homePhone"
                placeholder="Cellulare"
                type="number"
                value={homePhone}
                maxLength="12"
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength) {
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  }
                }}
                onChange={handleChangeForms}
                style={{ maxWidth: "48.7%", margin: "10px 0 0 0" }}
              />
              {errors.homePhone && (
                <span
                  style={{
                    position: "absolute",
                    top: "63px",
                  }}
                  className="error_message_sign_up city_field"
                >
                  {errors.homePhone}
                </span>
              )}
            </div>
            <div
              style={{
                marginBottom: "25px",
                position: "relative",
                marginRight: 21,
                marginLeft: 10,
              }}
            >
              <CityDropdown
                cities={citiesOptions}
                placeholder="Città o comune preferito*"
                selectedOption={selectedCity.value ? selectedCity : null}
                onClearCity={() => {
                  setSlectedCity({ value: "", label: "" });
                }}
                handleChange={(selectedOption) => {
                  setSlectedCity(selectedOption);
                }}
              />
              {/* <Dropdown
                placeholder="Città o comune preferito*"
                fluid
                search
                selection
                clearable
                value={selectedCity}
                options={citiesOptions}
                onChange={(e, { value }) => {
                  setSlectedCity(value);
                }}
              /> */}
              {errors.selectedCity && (
                <span
                  style={{
                    position: "absolute",
                    top: "52px",
                  }}
                  className="error_message_sign_up"
                >
                  {errors.selectedCity}
                </span>
              )}
            </div>
            {/* <Form.Input
              name="homePhone"
              placeholder="Città o comune preferito*"
              value={homePhone}
              onChange={handleChangeForms}
              style={{ maxWidth: '48.7%', margin: '10px 0 0 0' }}
            /> */}
          </div>
          <div style={{ position: "relative", margin: "0 10px 10px 0" }}>
            <Form.Input
              name="email"
              placeholder="indirizzo email*"
              value={email}
              type="text"
              onChange={handleChangeForms}
            />
            {errors.email && (
              <span
                // style={{
                //   position: 'absolute',
                //   top: '52px',
                //   left: '27px',
                //   color: '#cfa3a2',
                // }}
                className="error_message_sign_up"
              >
                {errors.email}
              </span>
            )}
          </div>

          <Grid>
            <Grid.Column width={8} stretched verticalAlign="middle">
              <div style={{ position: "relative" }}>
                <Form.Input
                  name="password"
                  placeholder="Password*"
                  value={password}
                  type={values.showPassword ? "text" : "password"}
                  onChange={handleChangeForms}
                  autoComplete="new-password"
                />
                {!values.showPassword && (
                  // <button
                  //   type="button"
                  //   onClick={() => setValues({ ...values, showPassword: true })}
                  // >
                  <i
                    className="eye slash icon"
                    style={{
                      position: "absolute",
                      top: "16px",
                      right: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setValues({ ...values, showPassword: true })}
                  />
                  // </button>
                )}

                {values.showPassword && (
                  // <button
                  //   type="button"
                  //   onClick={() =>
                  //     setValues({ ...values, showPassword: false })
                  //   }
                  // >
                  <i
                    className="eye icon"
                    style={{
                      position: "absolute",
                      top: "16px",
                      right: "20px",
                    }}
                    onClick={() =>
                      setValues({ ...values, showPassword: false })
                    }
                  />
                  // </button>
                )}

                {errors.password && (
                  <span
                    // style={{
                    //   position: 'absolute',
                    //   top: '56px',
                    //   left: '27px',
                    //   color: '#cfa3a2',
                    // }}
                    className="error_message_sign_up"
                  >
                    {errors.password}
                  </span>
                )}
              </div>
            </Grid.Column>
            <Grid.Column width={8} stretched verticalAlign="middle">
              <div>
                <div style={{ position: "relative" }}>
                  <Form.Input
                    name="confirmPassword"
                    placeholder="Conferma password*"
                    value={confirmPassword}
                    type={values.showConfirmPassword ? "text" : "password"}
                    onChange={handleChangeForms}
                    autoComplete="off"
                  />
                  {!values.showConfirmPassword && (
                    // <button
                    //   type="button"
                    //   onClick={() =>
                    //     setValues({ ...values, showConfirmPassword: true })
                    //   }
                    // >
                    <i
                      className="eye slash icon"
                      style={{
                        position: "absolute",
                        top: "16px",
                        right: "20px",
                      }}
                      onClick={() =>
                        setValues({ ...values, showConfirmPassword: true })
                      }
                    />
                    // </button>
                  )}

                  {values.showConfirmPassword && (
                    // <button
                    //   type="button"
                    //   onClick={() =>
                    //     setValues({ ...values, showConfirmPassword: false })
                    //   }
                    // >
                    <i
                      className="eye icon"
                      style={{
                        position: "absolute",
                        top: "16px",
                        right: "20px",
                      }}
                      onClick={() =>
                        setValues({ ...values, showConfirmPassword: false })
                      }
                    />
                    // </button>
                  )}
                  {errors.confirmPassword && (
                    <span
                      // style={{
                      //   position: 'absolute',
                      //   top: '66px',
                      //   left: '42px',
                      //   color: '#cfa3a2',
                      // }}
                      className="error_message_sign_up conf_pass_sign_up"
                    >
                      {errors.confirmPassword}
                    </span>
                  )}
                </div>
              </div>
            </Grid.Column>
          </Grid>
          <p className="privacy_points">Privacy e condizioni di utilizzo</p>
          <p className="legal_info">
            Leggi l&apos; informativa al trattamento dei dati personali prima di
            procedere con la registrazione.
            {/* <span
              className="privacy_policy"
              onClick={() => window.open(`/privacy-policy`)}
            >
              INFORMATIVA SULLA PRIVACY
            </span> */}
          </p>
          {privacyPolicy &&
            privacyPolicy.length > 0 &&
            privacyPolicy.map((policy) => {
              let linkTo;
              if (policy.id === "1f434744-117c-44bd-89d1-4e8bb3c48c44") {
                linkTo = "/tcu";
              }
              if (
                policy.id === "c2c96ed4-7f8e-4847-ad40-1d8e6e152a5d" ||
                policy.id === "4bf75d23-ce1e-4807-b832-b4b768a21b50" ||
                policy.id === "46e4a17a-83d8-4dda-9deb-428b25a3af65"
              ) {
                linkTo = "/privacy";
              }
              if (policy.mandatory) {
                mandIndex = mandIndex + 1;
                return (
                  <div className="checkbox_points">
                    <input
                      type="checkbox"
                      id={`type${mandIndex}`}
                      name={`type${mandIndex}`}
                      value={`type${mandIndex}`}
                      className="checkbox_tick"
                      // defaultChecked={policy.mandatory}
                      onChange={onSelectPrivacyPoints}
                    />
                    <label htmlFor={`type${mandIndex}`}>
                      <Link
                        to={linkTo}
                        target="_blank"
                        className="checkbox-text"
                      >
                        {policy.text}
                      </Link>
                    </label>
                    {errors["type" + mandIndex] && (
                      <span
                        style={{
                          position: "absolute",
                          top: "20px",
                          left: "30px",
                        }}
                        className="error_message_sign_up city_field"
                      >
                        {errors["type" + mandIndex]}
                      </span>
                    )}
                  </div>
                );
              }
              return null;
            })}

          {privacyPolicy &&
            privacyPolicy.length > 0 &&
            privacyPolicy.map((policy) => {
              let linkTo;
              if (policy.id === "1f434744-117c-44bd-89d1-4e8bb3c48c44") {
                linkTo = "/tcu";
              }
              if (
                policy.id === "c2c96ed4-7f8e-4847-ad40-1d8e6e152a5d" ||
                policy.id === "4bf75d23-ce1e-4807-b832-b4b768a21b50" ||
                policy.id === "46e4a17a-83d8-4dda-9deb-428b25a3af65"
              ) {
                linkTo = "/privacy";
              }
              if (!policy.mandatory) {
                optIndex = optIndex + 1;
                return (
                  <div className="checkbox_points">
                    <input
                      type="checkbox"
                      id={`type${optIndex}`}
                      name={`type${optIndex}`}
                      value={`type${optIndex}`}
                      className="checkbox_tick"
                      // defaultChecked={policy.mandatory}
                      onChange={onSelectPrivacyPoints}
                    />
                    <label htmlFor={`type${optIndex}`}>
                      <Link
                        to={linkTo}
                        target="_blank"
                        className="checkbox-text"
                      >
                        {policy.text}
                      </Link>
                    </label>
                    {errors["type" + optIndex] && (
                      <span
                        style={{
                          position: "absolute",
                          top: "20px",
                          left: "30px",
                        }}
                        className="error_message_sign_up city_field"
                      >
                        {errors["type" + optIndex]}
                      </span>
                    )}
                  </div>
                );
              }
              return null;
            })}
          {/* <div className="checkbox_points">
            <input
              type="checkbox"
              id="type1"
              name="type1"
              value="type1"
              className="checkbox_tick"
              defaultChecked
              onChange={onSelectPrivacyPoints}
            />
            <label htmlFor="type1">
              {' '}
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus
            </label>
          </div>
          <div className="checkbox_points">
            <input
              type="checkbox"
              id="type2"
              name="type2"
              value="type2"
              className="checkbox_tick"
              defaultChecked
              onChange={onSelectPrivacyPoints}
            />
            <label htmlFor="type2">
              {' '}
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus
            </label>
          </div>
          <div className="checkbox_points">
            <input
              type="checkbox"
              id="type3"
              name="type3"
              value="type3"
              className="checkbox_tick"
              onChange={onSelectPrivacyPoints}
            />
            <label htmlFor="type3">
              {' '}
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus
            </label>
          </div>
          <div className="checkbox_points">
            <input
              type="checkbox"
              id="type4"
              name="type4"
              value="type4"
              className="checkbox_tick"
              onChange={onSelectPrivacyPoints}
            />
            <label htmlFor="type4">
              {' '}
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus
            </label>
          </div> */}

          <Button className="register_button" type="submit">
            {isNewsLoading ? (
              <Loader active inline className="login_loader ladingFormLoader" />
            ) : (
              "REGISTRATI"
            )}
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

SignupForm.propTypes = {
  onSubmit: PropTypes.func,
  signUpInfo: PropTypes.object,
  showSignUpModal: PropTypes.bool,
  closeModal: PropTypes.func,
  onSuccessfulSignUp: PropTypes.func,
  allCities: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (payload) => dispatch({ type: "SIGN_UP_USER", payload }),
  };
}
export function mapStateToProps(state) {
  return { signUpInfo: state.signup, allCities: state.homePageFav.cities };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: "yourcomponent", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(SignupForm);
