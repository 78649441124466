import React, {useState} from 'react';
import {Modal} from "semantic-ui-react";
import NewLogin from "./NewLogin";
import NewRegistration from "./NewRegistration";

const NewLoginPopup = (props) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
    validationErrors: {
      emailError: "",
      passwordError: "",
    },
  });
  const closeModal = () => {
    setValues({email: "", password: "", error: "", showModal: false});
    props.closeModal();
  };

  return (
    <Modal
      closeIcon={{
        style: {top: "1.0535rem", right: "1rem"},
        color: "black",
        name: "close",
      }}
      onClose={closeModal}
      open={props.showRegistrationModal || props.showLoginModal}
      // trigger={
      //   <Button onClick={() => setValues({ ...values, showModal: true })}>
      //     Login
      //   </Button>
      // }
      className="signup_modal"
      style={{maxWidth: "90vw"}}
    >
      <Modal.Content>
        <div className="login-modal-mob" style={{display: "flex"}}>
          <div style={{flexBasis: "50%"}}>
            <NewLogin {...props} />
          </div>
          <div style={{flexBasis: "50%"}}>
            <NewRegistration {...props} />
          </div>
        </div>
      </Modal.Content>
    </Modal>)
}

export default NewLoginPopup;
