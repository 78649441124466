/* eslint-disable */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { androidIcon, appleIcon } from "../../assets/images";
import { useMediaQuery } from "react-responsive/src";
import CookieConsent from "react-cookie-consent";

import "./Footer.scss";

function Footer(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  var linkIOS = "https://apps.apple.com/it/app/my-stores/id1490231492"
  var linkANDROID = "https://play.google.com/store/apps/details?id=com.retapps.mystores"
  var text = "Scarica l’APP:";
  console.log(props.location.pathname);
  if (
    props.location.pathname === "/register-store" ||
    props.location.pathname === "/merchant"
  ) {
    linkIOS = "https://apps.apple.com/it/app/my-stores-pos/id1534153879"
    linkANDROID = "https://play.google.com/store/apps/details?id=com.retapps.mystores.pos"
    text = "Scarica l’APP ESERCENTI:";
  }
  return (
    <div className="common_footer">
      <CookieConsent
        location={isMobile ? "top" : "bottom"}
        buttonText="OK"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}>
        Utilizziamo cookie per migliorare la tua esperienza con il nostro servizio, per analisi e per il corretto funzionamento del sito.
      </CookieConsent>
      <footer className="landing_footer">
        <div className="footer_link_container">
          <div className="footer_links_column_one">
            <div className="footer_link">
              <Link to="/privacy" style={{ color: "#ffffff" }}>
                Privacy Policy utente
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/privacy/merchant" style={{ color: "#ffffff" }}>
                Privacy Policy esercente
              </Link>
            </div>
          </div>
          <div className="footer_links_column_two">
            <div className="footer_link">
              <Link to="/tcu" style={{ color: "#ffffff" }}>
                Condizioni generali utente
              </Link>
            </div>
            <div className="footer_link">
              <Link to="/cge" style={{ color: "#ffffff" }}>
                Condizioni generali esercente
              </Link>
            </div>
          </div>
          <div className="footer_links_column_three">
            <div className="footer_link">
              <Link to="/cookies" style={{ color: "#ffffff" }}>
                COOKIES POLICY
              </Link>
            </div>
          </div>
          {isMobile &&
            <div className="footer_links_column_four">
              <div
                className="footer_link footer_button_link"
                style={{ fontWeight: "normal" }}
              >
                {text}
              </div>
              <div className="footer_links_column_btn">
                <div>
                  <a
                    href={linkIOS}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="playstore_button footer_button">
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                          <img
                            src={appleIcon}
                            alt="apple-icon"
                            style={{ margin: '0 5px 3px 0' }}
                          />
                        </div>
                        {" "}
                        <div style={{ marginTop: "3px" }}>
                          APP STORE
                        </div>
                      </div>
                    </Button>
                  </a>
                </div>
                <div>
                  <a
                    href={linkANDROID}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="playstore_button footer_button">
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                          <img
                            src={androidIcon}
                            alt="apple-icon"
                            style={{ margin: "0 5px 3px 0" }}
                          />
                        </div>
                        {" "}
                        <div style={{ marginTop: "3px" }}>
                          PLAY STORE
                </div>
                      </div>
                    </Button>
                  </a>
                </div>
              </div>
            </div>}
        </div>
        <div className="footer_copyright_contiainer">
          <div className="all_rights">My-Stores - All right reserved 2021</div>
          <div className="all_rights powered_by">Made by RetAPPs</div>
        </div>
      </footer>
    </div>
  );
}

export default withRouter(Footer);
