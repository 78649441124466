import React, { useEffect } from "react";
import { merchantGif, offerte, mystores_puoi, card } from "../../assets/images";
import { ReactComponent as SeparatorArrow } from "../../assets/images/separator_arrow.svg";
import history from "../../utils/history";
import { useMediaQuery } from "react-responsive";

import LandingGif from "../LandingPageContainer/LandingGif/LandingGif";
import BusinessNavbar from "./BusinessNavbar/BusinessNavbar";
import BusinessContactUs from "./BusinessContactUs/BusinessContactUs";

import "../LandingPageContainer/new-landing-page.styles.scss";
import "../../containers/MerchantPage/merchant-page.styles.scss";
import "./business-page.styles.scss";

export function BusinessPage(props) {
    const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });
    const gtag = window.gtag;
    const fbq = window.fbq;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="new-landing">
            <BusinessNavbar />
            <LandingGif
                style={{ maxWidth: "80%", margin: "auto" }}
                title="DAI FORZA ALLA TUA ATTIVITÀ CON STRUMENTI DIGITALI EFFICACI. Competi sul mercato ad armi pari.">
                <img src={merchantGif} alt="" />
            </LandingGif>
            <div className="merchant-container business-container">
                <div className="merchant-container-block1">
                    <div className="text-block">
                        Con <b>My-Stores</b> hai a disposizione strumenti digitali efficaci per <b>continuare a vendere ai tuoi clienti di sempre, trovarne nuovi </b>
                        e <b><span className="pink"> costruire un vero e proprio centro commerciale di prossimità</span></b>
                        , insieme alle attività della tua zona.
                        {/* <div className="subtext-block">Contattaci per maggiori informazioni</div>
                        <div className="">
                            <button className="contact-button"
                                onClick={() => {
                                    const anchor = document.querySelector("#business-contact-us");
                                    anchor.scrollIntoView({ behavior: "smooth", block: "start" });
                                    gtag('event', 'Click sul bottone', {
                                        'event_category': 'Bottoni CTA',
                                        'event_label': 'Entra in My-Stores 1'
                                    });
                                    fbq('trackCustom', 'Conversione', {
                                        content_name: 'Entra_in_MyStores'
                                    });
                                }}>
                                Entra subito in My-Stores
                            </button>
                        </div> */}
                        <div className="text-block" style={{ margin: "20px 0" }}>
                            <b>Inizia subito a dare più visibilità al tuo negozio!</b>
                        </div>
                        <div className="text-center">
                            <button className="yellow-button" style={{ marginTop: "unset" }}
                                onClick={() => {
                                    fbq('trackCustom', 'Conversione', {
                                        content_name: 'Entra_in_MyStores'
                                    });
                                    history.push("/register-store")
                                }}>
                                Registrati gratis
                        </button>
                        </div>
                    </div>
                </div>
                <div className="merchant-content bg-yellow">
                    <div className="landing-content reverse">
                        <div className="content1">
                            <div className="text-title pink" style={{ whiteSpace: isMobileView ? "" : "nowrap", marginBottom: "10px" }}>
                                La registrazione è sempre gratuita
                            </div>
                            <p className="subtext">
                                ma puoi acquistare quando vuoi i servizi aggiuntivi per promuovere e gestire la tua attività.
                            </p>
                            <div className="subtext pink">
                                <b>Con i moduli personalizzabili potrai:</b>
                            </div>
                            <ul>
                                <li className="subtext">
                                    Consentire ai tuoi clienti di
                                    <b> prenotare ed acquistare prodotti e servizi direttamente da casa. </b>
                                </li>
                                <li className="subtext">
                                    <b>Restare in contatto con i tuoi clienti.</b>
                                </li>
                                <li className="subtext">
                                    Inviare <b>offerte e promozioni</b> e creare la tua <b>fidelity card.</b>
                                </li>
                                <li className="subtext">
                                    Creare la tua <b>carta fedeltà.</b>
                                </li>
                            </ul>
                        </div>
                        <div className="content2">
                            <img src={offerte} alt="" />

                        </div>
                    </div>
                    {/* <div className="landing-content">
                        <div className="content1 d-flex h-80">
                            <div className="subtext">
                                <b>Costruire un vero e proprio centro commerciale di prossimità </b>
                                insieme alle attività commerciali della tua strada o del tuo quartiere.
                            </div>
                        </div>
                        <div className="content2">
                            <img src={mystores_puoi} alt="" />
                        </div>
                    </div> */}
                    <div className="text-block">
                        <b><span className="pink"> Vuoi attivare altri servizi per potenziare la tua attività?</span></b>
                        <div className="subtext">
                            Scopri i moduli opzionali a partire da <b>9,90€</b> al mese.
                        </div>
                        <button className="yellow-button" style={{ marginTop: "20px" }}
                            onClick={() => {
                                window.open("/pricing", "_blank")
                                // history.push("/pricing")
                            }}>
                            Scopri di più
                        </button>
                    </div>
                </div>
                <div className="merchant-content" style={{ backgroundColor: "#faebc1" }}>
                    <div className="pink-title" style={{ marginBottom: "20px" }}>
                        L’UNIONE CHE FA LA FORZA
                    </div>
                    <div className="landing-content">
                        <div className="content1">
                            <div className="subtext">
                                <b>Costruire un vero e proprio centro commerciale di prossimità </b>
                                insieme alle attività commerciali della tua strada o del tuo quartiere.
                            </div>
                        </div>
                        <div className="content2">
                            <img src={mystores_puoi} alt="" />
                        </div>
                    </div>
                    <div className="subtext text-center" style={{ backgroundColor: "#faebc1", marginBottom: "20px" }}>
                        Insieme agli esercenti della tua zona puoi costruire un vero e proprio centro commerciale di prossimità: più attività sono coinvolte,
                        più clienti saranno attirati dalle vostre offerte commerciali. E ricorda che con My-Stores aumenti gratuitamente la visibilità del tuo negozio.
                    </div>
                    <div className="text-center">
                        <button className="yellow-button"
                            onClick={() => {
                                fbq('trackCustom', 'Conversione', {
                                    content_name: 'Entra_in_MyStores'
                                });
                                history.push("/register-store")
                            }}>
                            Registrati subito, è gratis!
                        </button>
                    </div>
                </div>
                {/* <div className="merchant-container-block1" style={{ paddingTop: "unset" }}>
                    <div className="text-block">
                        <div className="subtext-block">Tutto questo in poco tempo e con un investimento contenuto ed accessibile.</div>
                        <div className="">
                            <button className="contact-button"
                                onClick={() => {
                                    const anchor = document.querySelector("#business-contact-us");
                                    anchor.scrollIntoView({ behavior: "smooth", block: "start" });
                                    gtag('event', 'Click sul bottone', {
                                        'event_category': 'Bottoni CTA',
                                        'event_label': 'Entra in My-Stores 2'
                                    });
                                    fbq('trackCustom', 'Conversione', {
                                        content_name: 'Entra_in_MyStores'
                                    });
                                }}>
                                Entra subito in My-Stores
                            </button>
                        </div>
                    </div>
                </div> */}
                {/* <div className="merchant-content bg-lightblue">
                    <div className="landing-content" style={{ marginBottom: "unset" }}>
                        <div className="content1">
                            <img src={card} alt="" />
                        </div>
                        <div className="content2">
                            <div className="text-title pink" style={{ marginBottom: "30px" }}>
                                Una carta fedeltà:
                            </div>
                            <div className="subtext">
                                Grazie a My-Stores avrai una carta fedeltà dove i tuoi clienti possono accumulare punti e guadagnare vantaggi in modo semplice e veloce!
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="separator">
                    <div class='dotted-gradient'>&nbsp;</div>
                    <SeparatorArrow />
                    <div class='dotted-gradient'>&nbsp;</div>
                </div>
                <BusinessContactUs />
            </div>
        </div>
    );
}

export default BusinessPage;
