/* eslint-disable operator-assignment */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import ItemsCarousel from "react-items-carousel";
import { Icon, Dropdown, Loader } from "semantic-ui-react";
// import history from '../../utils/history';
import {
  // getAllOffer,
  getAllFavOffer,
  shopGallaryResource,
} from "../../Helpers/api";
import { userDetails } from "../../Helpers/UserDetails";
import PromoProductModal from "./promoProductModal/promoProductModal";
import { promoRightIcon, promoItemImage, sortLogo } from "../../assets/images";

import "./promoPage.scss";

function PromoPage() {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [isPromodataLoading, setIsPromodataLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [promoDetails, setPromoDetails] = useState([]);
  const [activityOffers, setActivityOffers] = useState(0);
  // const [activeOffersLengthDisplay, setActiveOffersLengthDisplay] = useState(0);
  const [apiResponseAllPromo, setApiResponseAllPromo] = useState([]);
  const [distanceValue, setDistanceValue] = useState("");
  const [sortedValue, setSortedValue] = useState("");
  const [currentGeoLocation, setCurrentGeoLocation] = useState({
    lat: 45.49719,
    long: 10.16602,
  });
  const [isPromoImageFailed, setIsPromoImageFailed] = useState(false);
  const [filterOptionsFirst] = useState([
    {
      key: 1,
      text: "A-Z",
      value: "1",
    },
    {
      key: 2,
      text: "Z-A",
      value: "2",
    },
  ]);
  const [filterOptionsDistance] = useState([
    {
      key: 1,
      text: "100 km",
      value: "100",
    },
    {
      key: 2,
      text: "150 km",
      value: "150",
    },
    {
      key: 3,
      text: "200 km",
      value: "200",
    },
  ]);
  const chevronWidth = 250;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    // const getPromoUrl = localStorage.getItem('token')
    //   ? getAllFavOffer
    //   : getAllOffer;
    let axiosParams = {};
    // if (localStorage.getItem('token')) {
    //   axiosParams = {
    //     method: 'get',
    //     url: `${getAllFavOffer}`,
    //     auth: {
    //       username: userDetails().email,
    //       password: userDetails().password,
    //     },
    //   };
    // } else {
    //   axiosParams = {
    //     method: 'get',
    //     url: `${getAllFavOffer}`,
    //   };
    // }

    axiosParams = {
      method: "get",
      url: `${getAllFavOffer}`,
      auth: {
        username: userDetails().email,
        password: userDetails().password,
      },
    };
    setIsPromodataLoading(true);
    axios(axiosParams).then(
      (res) => {
        setIsPromodataLoading(false);
        let offers = [];
        if (res && res.data && res.data.data) {
          offers = res.data.data;
          const copOffers = [...offers];
          const newOffers = copOffers.filter(
            (item) =>
              new Date(item.offer.publishDate).valueOf() <=
                new Date().valueOf() &&
              new Date(item.offer.validTo).valueOf() >= new Date().valueOf() &&
              item.offer.enabled === true
          );
          setApiResponseAllPromo(newOffers);
        }
        const groupedOffers = [];
        const duplicates = [];
        if (offers && offers.length) {
          offers.forEach((offer, index) => {
            const group = [];
            if (duplicates.includes(index)) return;
            const type = offer.offer.type.toLowerCase();
            const sameShopOffers = offers.filter((groupOffer, groupIndex) => {
              const groupType = groupOffer.offer.type.toLowerCase();
              if (
                groupType === type &&
                groupOffer[groupType].id === offer[type].id
              ) {
                duplicates.push(groupIndex);
                return true;
              }
              return false;
            });
            for (let i = 0; i < sameShopOffers.length; i += 2) {
              const innerGroup = [];
              innerGroup.push(sameShopOffers[i]);
              if (sameShopOffers.length - i !== 1)
                innerGroup.push(sameShopOffers[i + 1]);
              group.push(innerGroup);
            }
            groupedOffers.push(group);
          });
          // setPromoDetails(groupedOffers);
          groupedOffers.forEach((group, index) => {
            const newArray = Array.prototype.concat.apply([], group);
            groupedOffers[index] = newArray;
          });
          groupedOffers.map((group, index) => {
            const filteredOffer = group.filter(
              (item) =>
                new Date(item.offer.publishDate).valueOf() <=
                  new Date().valueOf() &&
                new Date(item.offer.validTo).valueOf() >=
                  new Date().valueOf() &&
                item.offer.enabled === true
            );
            groupedOffers[index] = filteredOffer;
            return group;
          });
          setPromoDetails(groupedOffers);

          // Totoal Active oofers
          // let activeOffersLength = 0;
          // if (groupedOffers && groupedOffers.length) {
          //   groupedOffers.forEach((group) => {
          //     activeOffersLength = activeOffersLength + group.length;
          //   });
          //   setActiveOffersLengthDisplay(activeOffersLength);
          // }
        }
      },
      (err) => {
        console.log(err);
        setIsPromodataLoading(false);
      }
    );

    // Get geo location on first render

    navigator.geolocation.getCurrentPosition((location) => {
      console.log(location);
      setCurrentGeoLocation({
        lat: location.coords.latitude,
        long: location.coords.longitude,
        // lat: 45.49719,
        // long: 10.16602,
      });
    });
  }, []);

  const handleModalOpen = (payload) => {
    setShowPromoModal(true);
    setModalData(payload);
    console.log(payload);
  };

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  // Promo distance filter

  const handleDistanceFilter = (e, { value }) => {
    setDistanceValue(value);
    const filterdPromoArray = [];
    apiResponseAllPromo.forEach((item) => {
      const dist = getDistanceFromLatLonInKm(
        currentGeoLocation.lat,
        currentGeoLocation.long,
        item.store.address.latitude,
        item.store.address.longitude
      );
      if (dist <= value) {
        filterdPromoArray.push(item);
      }
    });
    const offers = filterdPromoArray;
    const groupedOffers = [];
    const duplicates = [];
    offers.forEach((offer, index) => {
      const group = [];
      if (duplicates.includes(index)) return;
      const type = offer.offer.type.toLowerCase();
      const sameShopOffers = offers.filter((groupOffer, groupIndex) => {
        const groupType = groupOffer.offer.type.toLowerCase();
        if (groupType === type && groupOffer[groupType].id === offer[type].id) {
          duplicates.push(groupIndex);
          return true;
        }
        return false;
      });
      for (let i = 0; i < sameShopOffers.length; i += 2) {
        const innerGroup = [];
        innerGroup.push(sameShopOffers[i]);
        if (sameShopOffers.length - i !== 1)
          innerGroup.push(sameShopOffers[i + 1]);
        group.push(innerGroup);
      }
      groupedOffers.push(group);
    });
    groupedOffers.forEach((group, index) => {
      const newArray = Array.prototype.concat.apply([], group);
      groupedOffers[index] = newArray;
    });
    setPromoDetails(groupedOffers);

    if (value === "") {
      // const params3 = new URLSearchParams(history.location.search);
      // params3.delete('distance');
      // history.push({
      //   pathname: history.location.pathname,
      //   search: params3.toString(),
      // });
      const offersEmpty = apiResponseAllPromo;
      const groups = [];
      const duplicateGroups = [];
      offersEmpty.forEach((offer, index) => {
        const group = [];
        if (duplicateGroups.includes(index)) return;
        const type = offer.offer.type.toLowerCase();
        const sameShopOffers = offersEmpty.filter((groupOffer, groupIndex) => {
          const groupType = groupOffer.offer.type.toLowerCase();
          if (
            groupType === type &&
            groupOffer[groupType].id === offer[type].id
          ) {
            duplicateGroups.push(groupIndex);
            return true;
          }
          return false;
        });
        for (let i = 0; i < sameShopOffers.length; i += 2) {
          const innerGroup = [];
          innerGroup.push(sameShopOffers[i]);
          if (sameShopOffers.length - i !== 1)
            innerGroup.push(sameShopOffers[i + 1]);
          group.push(innerGroup);
        }
        groups.push(group);
      });
      groups.forEach((group, index) => {
        const newArray = Array.prototype.concat.apply([], group);
        groups[index] = newArray;
      });
      setPromoDetails(groups);
      setPromoDetails(groups);
    }
  };

  // Handle sorted order

  const handleSortedFilter = (e, { value }) => {
    setSortedValue(value);
    let arrayToSort = [];
    if (value === "1") {
      const filterdPromoArray = apiResponseAllPromo;
      filterdPromoArray.sort((a, b) => {
        const nameA = a.store.name.toLowerCase();
        const nameB = b.store.name.toLowerCase();
        if (nameA < nameB)
          // sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; // default return value (no sorting)
      });
      arrayToSort = filterdPromoArray;
    } else if (value === "2") {
      const filterdPromoArray = apiResponseAllPromo;
      filterdPromoArray.sort((a, b) => {
        const nameA = a.store.name.toLowerCase();
        const nameB = b.store.name.toLowerCase();
        if (nameA > nameB)
          // sort string descending
          return -1;
        if (nameA < nameB) return 1;
        return 0; // default return value (no sorting)
      });
      arrayToSort = filterdPromoArray;
    }

    const offers = arrayToSort;
    const groupedOffers = [];
    const duplicates = [];
    offers.forEach((offer, index) => {
      const group = [];
      if (duplicates.includes(index)) return;
      const type = offer.offer.type.toLowerCase();
      const sameShopOffers = offers.filter((groupOffer, groupIndex) => {
        const groupType = groupOffer.offer.type.toLowerCase();
        if (groupType === type && groupOffer[groupType].id === offer[type].id) {
          duplicates.push(groupIndex);
          return true;
        }
        return false;
      });
      for (let i = 0; i < sameShopOffers.length; i += 2) {
        const innerGroup = [];
        innerGroup.push(sameShopOffers[i]);
        if (sameShopOffers.length - i !== 1)
          innerGroup.push(sameShopOffers[i + 1]);
        group.push(innerGroup);
      }
      groupedOffers.push(group);
    });
    setPromoDetails(groupedOffers);
    groupedOffers.forEach((group, index) => {
      const newArray = Array.prototype.concat.apply([], group);
      groupedOffers[index] = newArray;
    });

    if (value === "") {
      setPromoDetails(apiResponseAllPromo);
    }
  };

  useEffect(() => {
    let length = 0;
    promoDetails.forEach((promo) => {
      length = length + promo.length;
      return length;
    });
    setActivityOffers(length);
  }, [promoDetails]);

  return (
    <div className="promp_page">
      <header className="promo_header">
        <div className="promo_header_text">Le tue offerte</div>
      </header>
      <div className="promo_content_section">
        <div className="promo_offers_heading">
          <div className="promo_heading_text">
            {`Hai ${activityOffers}`}{" "}
            {activityOffers === 1 ? ` offerta attiva` : ` offerte attive`}
          </div>
          <div className="promo_filters">
            <div className="a_z_filter">
              <img src={sortLogo} alt="" />
              <div className="a_z_text">
                <Dropdown
                  placeholder="A-Z"
                  selection
                  options={filterOptionsFirst}
                  onChange={handleSortedFilter}
                  value={sortedValue}
                  // onChange={(e, data) => console.log(data.value)}
                  icon={<Icon name="angle down" />}
                />
              </div>
            </div>
            <div className="a_z_filter">
              <img src={sortLogo} alt="" />
              <div className="distance_text">
                <div className="a_z_text">
                  <Dropdown
                    placeholder="Distanza"
                    selection
                    options={filterOptionsDistance}
                    value={distanceValue}
                    clearable
                    onChange={handleDistanceFilter}
                    // onChange={(e, data) => console.log(data.value)}
                    icon={<Icon name="angle down" />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {isPromodataLoading ? (
          <Loader active inline className="promo_loader" />
        ) : promoDetails && promoDetails.length ? (
          promoDetails.map(
            (offers, i) =>
              offers &&
              offers.length > 0 && (
                <div
                  // style={{ padding: `40px ${chevronWidth}px` }}
                  className="items_carouesl_main"
                  key={i}
                >
                  {offers[0].store && offers[0].store.name ? (
                    <p className="promo_section_heading">
                      {`${offers[0].store.name} `}
                      <span className="promo_offers_active">
                        {`(${offers.length}`}
                        {offers.length === 1
                          ? ` offerta attiva)`
                          : ` offerte attive)`}
                      </span>
                    </p>
                  ) : (
                    <p className="promo_section_heading">
                      {`${offers[0].mall.name} `}
                      <span className="promo_offers_active">
                        {`(${offers.length} offerte attive)`}
                      </span>
                    </p>
                  )}
                  <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={isTabletOrMobile ? 3 : 4}
                    // infiniteLoop={true}
                    gutter={20}
                    rightChevron={
                      <img src={promoRightIcon} alt="promo right icon" />
                    }
                    leftChevron={
                      <img
                        src={promoRightIcon}
                        style={{ transform: "rotate(180deg)" }}
                        alt="left"
                      />
                    }
                    // alwaysShowChevrons
                    classes={{
                      wrapper: "main_wrapper_cards_mall_promo",
                      itemWrapper: "mall_card_item_wrapper_promo",
                      rightChevronWrapper: "right_button_promo",
                      leftChevronWrapper: "left_button",
                    }}
                    outsideChevron
                    chevronWidth={chevronWidth}
                  >
                    {offers.map((activeOffer, index) => (
                      <div
                        className="shoppping_promo_card"
                        key={index}
                        onClick={() => handleModalOpen(activeOffer)}
                      >
                        <img
                          src={activeOffer?.offer?.imageUrl}
                          // src={promoItemImage}
                          alt="shopping centre"
                          className="promo_mage"
                          onError={(e) => {
                            e.target.src = promoItemImage;
                            setIsPromoImageFailed(true);
                          }}
                        />
                        <p className="promo_name">{activeOffer.offer.title}</p>
                      </div>
                    ))}
                  </ItemsCarousel>
                </div>
              )
          )
        ) : (
          !isPromodataLoading && (
            <h1 className="no_data_promo">No data found</h1>
          )
        )}
      </div>
      <PromoProductModal
        showPromoModal={showPromoModal}
        setShowPromoModal={setShowPromoModal}
        modalData={modalData}
        isPromoImageFailed={isPromoImageFailed}
      />
    </div>
  );
}

export default PromoPage;
