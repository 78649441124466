import React from "react";
import {Button, Header} from "semantic-ui-react";
import history from "../../utils/history";
import PropTypes from "prop-types";
import {friendship, shop} from "../../assets/images";



const NewRegistration = (props) => {
  const headerStyle = {
    fontSize: "22px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.14",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#333333",
    borderBottom: "none",
  };
  return (
    <div
      className="content-new">
      <div>
        <div style={{margin: "10px"}}>

          <div style={headerStyle}>Registrati</div>
          <div className="content-mob">
            <img src={friendship} alt=""/>
            <div style={{display: "flex", flexDirection: "column"}}>
              <div
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "18px",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "0.14px",
                  color: "#333333",
                  textAlign: "left"
                }}
              >
                Come utente
              </div>
              <p className="register-text">
                Da qui potrai cercare e visualizzare i negozi di tuo interesse
                gestendo quelli preferiti
              </p>
            </div>
          </div>
          <div>
            <Button
              className="registerButton"
              // style={{
              //   marginTop: "10px",
              // }}
              // type="submit"
              onClick={() => {
                props.setShowSignUpModal(true);
                props.setShowRegistrationModal && props.setShowRegistrationModal(false);
                props.setShowLoginModal && props.setShowLoginModal(false);
              }}
            >
              REGISTRATI COME UTENTE
            </Button>
          </div>
          <div style={{marginTop: "30px"}}>
            <div className="content-2">
              <div className="content-mob">
                <img src={shop} alt=""/>
                <div>
                  <div
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "0.14px",
                      color: "#333333",
                      textAlign: "left",
                    }}
                  >
                    Come esercente
                  </div>
                  <p className="register-text">
                    Creare e gestire direttamente la comunicazione e le promozioni del
                    tuo punto vendita
                  </p>
                </div>
              </div>
              <Button
                className="registerButton"
                style={{marginTop: "10px",}}
                // type="submit"
                onClick={() => {
                  if (history.location.pathname !== "/register-store") {
                    history.push("/register-store");
                  }
                  props.setShowRegistrationModal(false);
                }}
              >
                REGISTRATI COME ESERCENTE
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>);
}

NewRegistration.propTypes = {
  showRegistrationModal: PropTypes.bool,
  setShowRegistrationModal: PropTypes.func,
  setShowSignUpModal: PropTypes.func,
};

export default NewRegistration;
