import React, { Component, useRef } from "react";
import { FixedSizeList as List } from "react-window";
import Select, { createFilter } from "react-select";
import { crossSearch } from "../../assets/images";
import "./CityDropdown.scss";

const height = 35;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const CityDropdown = ({
  cities,
  selectedOption,
  handleChange,
  onClearCity,
  placeholder,
}) => {
  let selectRef = useRef(null);
  const clearValue = () => {
    selectRef.select.clearValue();
    onClearCity();
  };
  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "start",
  };

  return (
    <div className="cities_main_container">
      <Select
        components={{ MenuList }}
        ref={(ref) => {
          selectRef = ref;
        }}
        options={cities}
        onChange={handleChange}
        value={selectedOption}
        defaultValue={selectedOption}
        placeholder={placeholder}
        formatOptionLabel={({ label, inputValue }) => (
          <div>
            {label &&
            label.split("[").length &&
            label.split("[")[0] &&
            label.split("[")[1] ? (
              <>
                {label.split("[")[0]}
                <span
                  style={{
                    fontWeight: label.split("[")[1].includes("Tutti")
                      ? "bold"
                      : "normal",
                  }}
                >
                  [{label.split("[")[1]}
                </span>
              </>
            ) : (
              label
            )}
          </div>
        )}
        filterOption={createFilter(filterConfig)}
      />
      {selectedOption && (
        <button className="button_city_clear" onClick={clearValue}>
          <img src={crossSearch} />
        </button>
      )}
    </div>
  );
};

export default CityDropdown;
