/* eslint-disable */
import React, { useState } from 'react';
import { Form, TextArea, Loader } from 'semantic-ui-react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { useSnackbar } from 'react-simple-snackbar';
import { writeToUs } from '../../Helpers/api';

import './landingForms.scss';

const recaptchaRef = React.createRef();
function LandingForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    messageSubject: '',
    messageText: '',
  });
  const [disableButton, setDisableButton] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar] = useSnackbar({
    // position: 'top-center',
    style: {
      position: 'absolute',
      backgroundColor: 'green',
      border: '2px solid lightgreen',
      color: 'lightblue',
      fontFamily: 'Menlo, monospace',
      fontSize: '20px',
      textAlign: 'center',
      bottom: '100%',
      left: 0,
    },
    closeStyle: {
      color: 'lightcoral',
      fontSize: '16px',
    },
  });
  const [openSnackbarRed] = useSnackbar({
    style: {
      position: 'absolute',
      backgroundColor: 'red',
      border: '2px solid lightgreen',
      color: 'lightblue',
      fontFamily: 'Menlo, monospace',
      fontSize: '20px',
      textAlign: 'center',
      bottom: '100%',
      left: 0,
    },
    closeStyle: {
      color: 'lightcoral',
      fontSize: '16px',
    },
  });
  const onSubmit = () => {
    const errors = validateFormData(formData);
    if (Object.keys(errors).length === 0) {
      const recaptcha = recaptchaRef.current.getValue();
      let config = {
        headers: { "g-recaptcha-response": recaptcha, }
      }
      setIsLoading(true);
      axios.post(writeToUs, formData, config).then(
        res => {
          setIsLoading(false);
          setFormData({
            name: '',
            email: '',
            messageSubject: '',
            messageText: '',
          });
          openSnackbar('Thank you !');
        },
        err => {
          openSnackbarRed('Something went wrong !');
          setIsLoading(false);
        },
      );
    }
  };
  const handleChangeForms = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };
  const validateFormData = formValues => {
    const error = {};
    if (!formValues.name) {
      error.name = 'Please enter name';
    }
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (formValues.email) {
      if (!regEmail.test(formValues.email)) {
        error.email = 'Invalid email';
      }
    } else {
      error.email = "Email can't be empty";
    }
    if (!formValues.messageSubject) {
      error.messageSubject = 'Please enter message';
    }
    if (!formValues.messageText) {
      error.messageText = 'Please enter message description';
    }
    setFormErrors(error);
    return error;
  };
  return (
    <div className="landing_form_container">
      <Form
        autoComplete="off"
        onSubmit={onSubmit}
        className="landing_form"
      // style={{ border: '2px solid black' }}
      >
        <h1 className="d-web">Scrivici</h1>
        <div className="writeToInputContainer">
          <Form.Input
            name="name"
            placeholder="Nome e cognome"
            value={formData.name}
            type="text"
            // style={{ width: '498px', height: '50px' }}
            className="landing_form_fields"
            onChange={handleChangeForms}
          />
          {formErrors && formErrors.name && (
            <span className="ladingFormError">{formErrors.name}</span>
          )}
        </div>

        <div className="writeToInputContainer">
          <Form.Input
            // style={{ width: '498px', height: '50px' }}
            className="landing_form_fields"
            name="email"
            placeholder="La tua email"
            value={formData.email}
            type="text"
            onChange={handleChangeForms}
          />
          {formErrors && formErrors.email && (
            <span className="ladingFormError">{formErrors.email}</span>
          )}
        </div>

        <div className="writeToInputContainer">
          <Form.Input
            // style={{ width: '498px', height: '50px' }}
            className="landing_form_fields"
            name="messageSubject"
            placeholder="Oggetto messaggio"
            value={formData.messageSubject}
            type="text"
            onChange={handleChangeForms}
          />
          {formErrors && formErrors.messageSubject && (
            <span className="ladingFormError">{formErrors.messageSubject}</span>
          )}
        </div>

        <div className="writeToInputContainer">
          <TextArea
            placeholder="Scrivi qui il tuo messaggio"
            name="messageText"
            value={formData.messageText}
            style={{ minHeight: 150 }}
            className="landingFormTextArea"
            onChange={handleChangeForms}
          />
          {formErrors && formErrors.messageText && (
            <span className="ladingFormError ladingFormErrorTextMessage">
              {formErrors.messageText}
            </span>
          )}
        </div>

        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA}
          ref={recaptchaRef}
          onChange={() => setDisableButton(false)}
          onExpired={() => setDisableButton(true)}
        />

        <button
          style={{ margin: '20px 0 0 9px' }}
          disabled={disableButton}
        // type="submit"
        // onClick={() => setShowSignUpModal(true)}
        >
          {isLoading ? (
            <Loader active inline className="login_loader ladingFormLoader" />
          ) : (
            'INVIA'
          )}
        </button>
      </Form>
    </div>
  );
}

LandingForm.propTypes = {
  //   className: PropTypes.string,
  //   height: PropTypes.string,
  //   width: PropTypes.number,
  //   fill: PropTypes.string,
};
export default LandingForm;
