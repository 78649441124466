/* eslint-disable indent */
// /* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable operator-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Icon, Dropdown, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { compose } from "redux";
import injectSaga from "../../utils/injectSaga";
import { DAEMON } from "../../utils/constants";
import {
  shopCategories,
  getAllOffer,
  shopGallaryResource,
} from "../../Helpers/api";
import saga from "../Homepage/saga";
// import ShopCard from "../../common/ShopCard/ShopCard";
import StoreCard from "../../common/StoreCard/StoreCard";
// import Footer from "../../components/footer/Footer";
import history from "../../utils/history";
// import NavBar from "../../components/NavBar/NavBar";
import {
  //   heartCard,
  // shoppingCentre,
  // locator,
  mapView,
  filter,
  sortLogo,
  // heartFilled,
  // store6,
  // shop,
} from "../../assets/images";
import "./FavouritePage.scss";
// import { values } from 'lodash';

function FavouritePage(props) {
  const [categorySelected, setCategorySelected] = useState([]);
  const [subCatSelected, setSubCatSelected] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [favStoresInitial, setFavStoresInitial] = useState([]);
  const [favMallsInitial, setFavMallsInitial] = useState([]);
  const [favStores, setFavStores] = useState([]);
  const [favMalls, setFavMalls] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isCreateLoding, setIsCreateLoding] = useState(false);
  const [distanceValue, setDistanceValue] = useState("");
  const [currentGeoLocation, setCurrentGeoLocation] = useState({
    lat: 45.49719,
    long: 10.16602,
  });
  const [categories, setCategories] = useState([
    // { id: 1, name: 'Abbigliamneto', isSelected: false },
    // { id: 2, name: 'Alimentari', isSelected: false },
    // { id: 3, name: 'Abbigliamneto', isSelected: false },
    // { id: 4, name: 'Abbigliamneto', isSelected: false },
    // { id: 5, name: 'Categoria 1', isSelected: false },
    // { id: 6, name: 'Categoria 2', isSelected: false },
    // { id: 7, name: 'Categoria 2', isSelected: false },
    // { id: 8, name: 'Categoria 2', isSelected: false },
    // { id: 9, name: 'Categoria 2', isSelected: false },
    // { id: 10, name: 'Categoria 2', isSelected: false },
    // { id: 11, name: 'Abbigliamneto', isSelected: false },
    // { id: 12, name: 'Categoria 1', isSelected: false },
    // { id: 13, name: 'Categoria 2', isSelected: false },
    // { id: 14, name: 'Abbigliamneto', isSelected: false },
    // { id: 15, name: 'Categoria 2', isSelected: false },
    // { id: 16, name: 'Categoria 2', isSelected: false },
    // { id: 17, name: 'Categoria 2', isSelected: false },
    // { id: 18, name: 'Categoria 2', isSelected: false },
    // { id: 19, name: 'Categoria 2', isSelected: false },
    // { id: 20, name: 'Categoria 2', isSelected: false },
  ]);
  const [initialSubCategories, setInitialSubCategories] = useState([]);
  const [activeOffersLengthDisplay, setActiveOffersLengthDisplay] = useState(0);

  const [subCategories, setSubCategories] = useState([
    // { id: 1, name: 'Uomo', isSelected: false },
    // { id: 2, name: 'Donna', isSelected: false },
    // { id: 3, name: 'Bambino', isSelected: false },
  ]);
  const [filterOptionsDistance] = useState([
    {
      key: 1,
      text: "50 km",
      value: "50",
    },
    {
      key: 2,
      text: "100 km",
      value: "100",
    },
    {
      key: 3,
      text: "150 km",
      value: "150",
    },
    {
      key: 4,
      text: "200 km",
      value: "200",
    },
  ]);

  /*
  useEffect(() => {
    // Get All active offers

    axios.get(getAllOffer).then(
      (res) => {
        const offers = res.data.data;
        const groupedOffers = [];
        const duplicates = [];
        if (offers && offers.length) {
          offers.forEach((offer, index) => {
            const group = [];
            if (duplicates.includes(index)) return;
            const type = offer.offer.type.toLowerCase();
            const sameShopOffers = offers.filter((groupOffer, groupIndex) => {
              const groupType = groupOffer.offer.type.toLowerCase();
              if (
                groupType === type &&
                groupOffer[groupType].id === offer[type].id
              ) {
                duplicates.push(groupIndex);
                return true;
              }
              return false;
            });
            for (let i = 0; i < sameShopOffers.length; i += 2) {
              const innerGroup = [];
              innerGroup.push(sameShopOffers[i]);
              if (sameShopOffers.length - i !== 1)
                innerGroup.push(sameShopOffers[i + 1]);
              group.push(innerGroup);
            }
            groupedOffers.push(group);
          });
          // Totoal Active oofers
          let activeOffersLength = 0;
          if (groupedOffers && groupedOffers.length) {
            groupedOffers.forEach((group) => {
              activeOffersLength = activeOffersLength + group.length;
            });
            setActiveOffersLengthDisplay(activeOffersLength);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

*/

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((location) => {
      console.log(location);
      if (
        location &&
        location.coords &&
        location.coords.latitude &&
        location.coords.longitude
      ) {
        setCurrentGeoLocation({
          lat: location.coords.latitude,
          long: location.coords.longitude,
          // lat: 45.49719,
          // long: 10.16602,
        });
      }
    });
    const params = new URLSearchParams(history.location.search);
    const urlcategory = params.get("categories");
    const urlDistance = params.get("distance");
    if (urlcategory) {
      console.log(urlcategory);
    }
    if (urlDistance) {
      console.log(urlDistance);
    }
  }, []);

  useEffect(() => {
    // Update the document title using the browser API
    document.addEventListener("scroll", () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 1 && !isScrolled) {
        setIsScrolled(true);
      } else if (isScrolled && scrolled < 1) {
        setIsScrolled(false);
      }
    });

    return () => {
      document.removeEventListener("scroll", () => {});
    };
  });

  useEffect(() => {
    props.getFavStores();

    // Get categtory list

    const params = new URLSearchParams(history.location.search);
    const urlDistance = params.get("distance");
    if (urlDistance) {
      setDistanceValue(urlDistance);
    }

    axios({
      method: "get",
      url: shopCategories,
    })
      .then((res) => {
        const filterCategories = [];
        const subCategoriesFilter = [];
        res.data.forEach((item) => {
          const obj = {};
          obj.id = item.id;
          obj.name = item.description;
          obj.isSelected = false;
          filterCategories.push(obj);

          // Sub categories
          const subCategObj = {};
          subCategObj.id = item.id;
          if (item.items) {
            subCategObj.subCategories = item.items;
          }
          subCategoriesFilter.push(subCategObj);
        });
        setInitialSubCategories(subCategoriesFilter);
        setCategories(filterCategories);

        const urlcategory = params.get("categories");
        const urlcategoryArray = urlcategory.split(",");
        let mainCategory = "";
        if (urlcategoryArray && urlcategoryArray.length) {
          mainCategory = urlcategoryArray[0];
          if (mainCategory) {
            const objItem = filterCategories.find(
              (item) => item.name === mainCategory
            );
            setCategorySelected([objItem.id]);
            const subCategObj = subCategoriesFilter.find(
              (item) => item.id === objItem.id
            );
            const finalSubcategories = [];
            if (
              subCategObj &&
              subCategObj.subCategories &&
              subCategObj.subCategories.length
            ) {
              subCategObj.subCategories.forEach((item) => {
                const obj = {};
                obj.id = item.id;
                obj.name = item.description;
                obj.isSelected = false;
                finalSubcategories.push(obj);
              });
              setSubCategories(finalSubcategories);
            }
            const subCatArray = subCategoriesFilter.find(
              (sub) => sub.id === objItem.id
            );
            const filterSub = subCatArray.subCategories.filter((item) =>
              urlcategoryArray.includes(item.description)
            );
            const selectedSubCateIds = [];
            filterSub.forEach((item) => {
              selectedSubCateIds.push(item.id);
            });
            setSubCatSelected(selectedSubCateIds);
          }
        }
        // // Selected category by default
        // let urlcategory = params.get('categories');
        // const newUrlcategory = urlcategory.split(',');
        // if (newUrlcategory && newUrlcategory.length) {
        //   urlcategory = newUrlcategory[0];
        // }
        // if (urlcategory) {
        //   const objItem = filterCategories.find(
        //     item => item.name === urlcategory,
        //   );
        //   setCategorySelected([objItem.id]);
        //   console.log(objItem);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const deleteFavStore = (storeId, type) => {
    setIsCreateLoding(true);
    props.deleteFavStores({ storeId, type });
    setTimeout(() => props.getFavStores(), 500);
  };

  useEffect(() => {
    setIsCreateLoding(false);
  }, [props.createFavStoresSuccess, props.deletedFavStoreSuccess]);

  useEffect(() => {
    if (props.favStores && props.favStores.data) {
      setFavStoresInitial(props.favStores.data.data.stores);
      setFavMallsInitial(props.favStores.data.data.malls);
      const params = new URLSearchParams(history.location.search);
      const urlDistance = params.get("distance");
      const urlCategory = params.get("categories");
      console.log(favMallsInitial);
      if (urlDistance && !urlCategory) {
        const filterdFavStoreArray = [];
        props.favStores.data.data.stores.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (dist <= urlDistance) {
            filterdFavStoreArray.push(item);
          }
        });
        setFavStores(filterdFavStoreArray);
        // Malls
        const filterdFavmallsArray = [];
        props.favStores.data.data.malls.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (dist <= urlDistance) {
            filterdFavmallsArray.push(item);
          }
        });
        setFavMalls(filterdFavmallsArray);
      } else if (!urlDistance && urlCategory) {
        const filterdFavStoreArray = [];
        props.favStores.data.data.stores.forEach((item) => {
          if (
            item.categoryId &&
            item.categoryId.toLowerCase() === urlCategory.toLowerCase()
          ) {
            filterdFavStoreArray.push(item);
          }
        });
        setFavStores(filterdFavStoreArray);
        // Malls
        const filterdFavMallsArray = [];
        props.favStores.data.data.malls.forEach((item) => {
          if (
            item.categoryId &&
            item.categoryId.toLowerCase() === urlCategory.toLowerCase()
          ) {
            filterdFavMallsArray.push(item);
          }
        });
        setFavMalls(filterdFavMallsArray);
      } else if (urlDistance && urlCategory) {
        const filterdFavStoreArray = [];
        props.favStores.data.data.stores.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (
            dist <= urlDistance &&
            item.categoryId.toLowerCase() === urlCategory.toLowerCase()
          ) {
            filterdFavStoreArray.push(item);
          }
        });
        setFavStores(filterdFavStoreArray);
        // Malls
        const filterdFavMallsArray = [];
        props.favStores.data.data.malls.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (
            dist <= urlDistance &&
            item.categoryId.toLowerCase() === urlCategory.toLowerCase()
          ) {
            filterdFavMallsArray.push(item);
          }
        });
        setFavMalls(filterdFavMallsArray);
      } else {
        setFavStores(props.favStores.data.data.stores);
        setFavMalls(props.favStores.data.data.malls);
      }
    }
  }, [props.favStores]);

  const onMapViewCick = () => {
    history.push({
      pathname: "/fav-map",
      search: history.location.search,
    });
    // props.setIsHomeActive(false);
    // props.setIsPromoActive(false);
    // props.setIsFidelityActive(false);
    // props.setShowListingPage(false);
    // props.setShowMapView(true);
  };
  const handleCategories = (id) => {
    if (categorySelected.indexOf(id) === -1 && categorySelected.length < 1) {
      // SET SUB CATEG BASED ON CATEGORY SELECTED
      let params = new URLSearchParams(history.location.search);
      let filterCategories = params.get("categories");
      const subCategObj = initialSubCategories.find((item) => item.id === id);
      const finalSubcategories = [];
      if (
        subCategObj &&
        subCategObj.subCategories &&
        subCategObj.subCategories.length
      ) {
        subCategObj.subCategories.forEach((item) => {
          const obj = {};
          obj.id = item.id;
          obj.name = item.description;
          obj.isSelected = false;
          finalSubcategories.push(obj);
        });
        setSubCategories(finalSubcategories);
      }

      // Category handles
      setCategorySelected([...categorySelected, id]);
      const obj = categories.find((item) => item.id === id);
      history.push({
        search: `${history.location.search}&categories=${obj.name}`,
      });
      params = new URLSearchParams(history.location.search);
      const distt = params.get("distance");
      filterCategories = params.get("categories");

      if (!distt && filterCategories) {
        const newFavStoresToFilter = favStoresInitial;
        const catFilteredArray = newFavStoresToFilter.filter((item) => {
          if (
            item.categoryId &&
            obj.name &&
            item.categoryId.toLowerCase() === obj.name.toLowerCase()
          ) {
            return true;
          }
        });
        setFavStores(catFilteredArray);
        // malls
        const newFavmallsToFilter = favMallsInitial;
        const catFilteredArrayMalls = newFavmallsToFilter.filter((item) => {
          if (
            item.categoryId &&
            obj.name &&
            item.categoryId.toLowerCase() === obj.name.toLowerCase()
          ) {
            return true;
          }
        });
        setFavMalls(catFilteredArrayMalls);
      }

      if (distt && filterCategories) {
        const filterdFavStoreArray = [];
        favStoresInitial.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (
            dist <= distt &&
            item.categoryId.toLowerCase() === filterCategories.toLowerCase()
          ) {
            filterdFavStoreArray.push(item);
          }
        });
        setFavStores(filterdFavStoreArray);
        // Malls
        const filterdFavMallsArray = [];
        favMallsInitial.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (
            dist <= distt &&
            item.categoryId.toLowerCase() === filterCategories.toLowerCase()
          ) {
            filterdFavMallsArray.push(item);
          }
        });
        setFavMalls(filterdFavMallsArray);
      }
    } else {
      setSubCategories([]);
      const index = categorySelected.indexOf(id);
      if (index > -1) {
        categorySelected.splice(index, 1);
        const params = new URLSearchParams(history.location.search);
        params.delete("categories");
        history.push({
          pathname: history.location.pathname,
          search: params.toString(),
        });
        const distt = params.get("distance");
        if (distt) {
          const filterdFavStoreArray = [];
          favStoresInitial.forEach((item) => {
            const dist = getDistanceFromLatLonInKm(
              currentGeoLocation.lat,
              currentGeoLocation.long,
              item.address.latitude,
              item.address.longitude
            );
            if (dist <= distt) {
              filterdFavStoreArray.push(item);
            }
          });
          setFavStores(filterdFavStoreArray);
          // malls
          const filterdFavMallsArray = [];
          favMallsInitial.forEach((item) => {
            const dist = getDistanceFromLatLonInKm(
              currentGeoLocation.lat,
              currentGeoLocation.long,
              item.address.latitude,
              item.address.longitude
            );
            if (dist <= distt) {
              filterdFavMallsArray.push(item);
            }
          });
          setFavMalls(filterdFavMallsArray);
        } else {
          setFavStores(favStoresInitial);
          setFavMalls(favMallsInitial);
        }
      }
    }
  };
  /*

  // Handle sub categories

  const handleSubCategories = (id, name) => {
    if (subCatSelected.indexOf(id) === -1) {
      const catHistory = [];
      setSubCatSelected([...subCatSelected, id]);

      // Sub Category handles
      const obj = subCategories.find(item => item.id === id);

      let params = new URLSearchParams(history.location.search);
      const distt = params.get('distance');
      let filterCategories = params.get('categories');
      catHistory.push(filterCategories);
      // params.delete('categories');

      // history.push({
      //   pathname: history.location.pathname,
      //   search: params.toString(),
      // });
      if (distt) {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filterCategories},${
            obj.name
          }&distance=${distt}`,
        });
      } else {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filterCategories},${obj.name}`,
        });
      }

      params = new URLSearchParams(history.location.search);
      filterCategories = params.get('categories');
      if (!distt && filterCategories) {
        const newFavStoresToFilter = favStoresInitial;

        const newFilterCategoriesArray = filterCategories.split(',');
        const finalFilterdArrray = [];
        // newFilterCategoriesArray.forEach(category => {
        const catFilteredArray = newFavStoresToFilter.filter(item => {
          if (
            item.categoryId &&
            newFilterCategoriesArray.includes(item.categoryId.toLowerCase())
          ) {
            return true;
          }
          return false;
        });
        // console.log(category);
        console.log(catFilteredArray);
        // finalFilterdArrray.concat(catFilteredArray);
        // });
        setFavStores(finalFilterdArrray);

        // const catFilteredArray = newFavStoresToFilter.filter(
        //   item => item.categoryId.toLowerCase() === obj.name.toLowerCase(),
        // );
        // console.log(catFilteredArray);
        // setFavStores(catFilteredArray);
      }

      if (distt && filterCategories) {
        const newFilterCategoriesArray = filterCategories.split(',');
        const filterdFavStoreArray = [];
        favStoresInitial.forEach(item => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude,
          );
          if (
            dist <= distt &&
            newFilterCategoriesArray.includes(item.categoryId.toLowerCase())
          ) {
            filterdFavStoreArray.push(item);
          }
        });
        setFavStores(filterdFavStoreArray);
      }
    } else {
      const index = subCatSelected.indexOf(id);
      const params = new URLSearchParams(history.location.search);
      const catgg = params.get('categories');
      const distt = params.get('distance');
      const catggArray = catgg.split(',');
      const arrayTobeFilter = [...catggArray];
      console.log('Initial sub categories', initialSubCategories);
      const filteredArray = arrayTobeFilter.filter(item => item !== name);
      history.push({
        pathname: history.location.pathname,
        search: `categories=${filteredArray.toString()}&distance=${distt}`,
      });
      // console.log(object);
      console.log(catgg);
      if (index > -1) {
        subCatSelected.splice(index, 1);
        setSubCatSelected([...subCatSelected]);
      }
    }
  };
  */

  const deleteSelCategories = (id) => {
    const index = categorySelected.indexOf(id);
    if (index > -1) {
      categorySelected.splice(index, 1);
      setCategorySelected([...categorySelected]);

      const params = new URLSearchParams(history.location.search);
      params.delete("categories");
      history.push({
        pathname: history.location.pathname,
        search: params.toString(),
      });
      const distt = params.get("distance");
      if (distt) {
        const filterdFavStoreArray = [];
        favStoresInitial.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (dist <= distt) {
            filterdFavStoreArray.push(item);
          }
        });
        setFavStores(filterdFavStoreArray);
        // Malls
        const filterdFavMallsArray = [];
        favMallsInitial.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (dist <= distt) {
            filterdFavMallsArray.push(item);
          }
        });
        setFavMalls(filterdFavMallsArray);
      } else {
        setFavStores(favStoresInitial);
        setFavMalls(favMallsInitial);
      }
      // setFavStores(favStoresInitial);
    }
  };

  const deleteSelSubCategories = (id, name) => {
    const index = subCatSelected.indexOf(id);
    if (index > -1) {
      const params = new URLSearchParams(history.location.search);
      const catgg = params.get("categories");
      const dist2 = params.get("distance");
      const catggArray = catgg.split(",");
      const arrayTobeFilter = [...catggArray];
      const filteredArray = arrayTobeFilter.filter((item) => item !== name);
      if (dist2) {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filteredArray.toString()}&distance=${dist2}`,
        });
      } else {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filteredArray.toString()}`,
        });
      }

      const newFavStoresToFilter = favStoresInitial;

      let finalFilterdArrray = [];
      const params3 = new URLSearchParams(history.location.search);
      const dist3 = params3.get("distance");
      const catgg3 = params3.get("categories");
      const catggArray3 = catgg3.split(",");
      if (catggArray3 && catggArray3.length && !dist3) {
        finalFilterdArrray = newFavStoresToFilter.filter((item) =>
          subCatSelected.includes(item.categoryId)
        );
        // console.log(category);
        setFavStores(finalFilterdArrray);
      }
      if (catggArray3 && catggArray3.length && dist3) {
        const filterdFavStoreArray = [];
        favStoresInitial.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (dist <= dist3 && catggArray3.includes(item.categoryId)) {
            filterdFavStoreArray.push(item);
          }
        });
        setFavStores(filterdFavStoreArray);
      }

      subCatSelected.splice(index, 1);
      setSubCatSelected([...subCatSelected]);
    }
  };

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  // Handle distance filter

  const handleDistanceFilter = (e, { value }) => {
    setDistanceValue(value);
    const params2 = new URLSearchParams(history.location.search);
    params2.delete("distance");
    history.push({
      pathname: history.location.pathname,
      search: params2.toString(),
    });
    history.push({
      search: `${history.location.search}&distance=${value}`,
    });
    const params = new URLSearchParams(history.location.search);
    const filterCategories = params.get("categories");
    const distance = params.get("distance");
    if (distance && !filterCategories) {
      const filterdFavStoreArray = [];
      favStoresInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value) {
          filterdFavStoreArray.push(item);
        }
      });
      setFavStores(filterdFavStoreArray);
      // Malls
      const filterdFavMallsArray = [];
      favMallsInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value) {
          filterdFavMallsArray.push(item);
        }
      });
      setFavMalls(filterdFavMallsArray);
    }
    if (!distance && filterCategories) {
      const filterdFavStoreArray = [];
      favStoresInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value) {
          filterdFavStoreArray.push(item);
        }
      });
      setFavStores(filterdFavStoreArray);
      // Malls
      const filterdFavMallsArray = [];
      favMallsInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value) {
          filterdFavMallsArray.push(item);
        }
      });
      setFavMalls(filterdFavMallsArray);
    }

    if (distance && filterCategories) {
      const filterdFavStoreArray = [];
      favStoresInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value && subCatSelected.includes(item.categoryId)) {
          filterdFavStoreArray.push(item);
        }
      });
      setFavStores(filterdFavStoreArray);
      // malls
      const filterdFavMallArray = [];
      favMallsInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value && subCatSelected.includes(item.categoryId)) {
          filterdFavMallArray.push(item);
        }
      });
      setFavMalls(filterdFavMallArray);
    }

    if (value === "") {
      if (filterCategories) {
        setFavStores(favStores);
        setFavMalls(favMalls);
      } else {
        const params3 = new URLSearchParams(history.location.search);
        params3.delete("distance");
        history.push({
          pathname: history.location.pathname,
          search: params3.toString(),
        });
        setFavStores(favStoresInitial);
        setFavMalls(favMallsInitial);
      }
    }
  };

  return (
    <div>
      {/* <NavBar isScrolled={isScrolled} /> */}
      <div className="landing_page_root landing_page_root_fav">
        <div className="list_page_headings list_page_headings_fav">
          <p className="components_container_main_heading">
            Home &gt;
            <span className="components_container_main_heading_product">
              I TUOI PREFERITI
            </span>
          </p>
          <p className="components_container_second_heading">
            {/* Hai cercato:{' '} */}
            <span className="components_container_second_heading_two">
              I TUOI PREFERITI
            </span>
          </p>
        </div>

        <div className="search_filter_container search_filter_container_fav">
          <div className="text_and_selected">
            {/* <p className="offers_active_text">{`Hai ${activeOffersLengthDisplay} offerte attive`}</p> */}
            <p className="offers_active_text">{`I tuoi ${
              favStores.length + favMalls.length
            } negozi preferiti`}</p>
            {categories.map((category) => {
              if (categorySelected.includes(category.id)) {
                return (
                  <button
                    type="button"
                    className={
                      !categorySelected.includes(category.id)
                        ? "categories_item map_view_button"
                        : "categories_item categories_item_selected map_view_button"
                    }
                    key={category.id}
                    onClick={() => deleteSelCategories(category.id)}
                  >
                    {category.name}
                    {categorySelected.includes(category.id) && (
                      <span className="close_selected_category">x</span>
                    )}
                  </button>
                );
              }
            })}
            {subCategories.map((category) => {
              if (subCatSelected.includes(category.id)) {
                return (
                  <button
                    type="button"
                    className={
                      !subCatSelected.includes(category.id)
                        ? "categories_item map_view_button"
                        : "categories_item categories_item_selected map_view_button"
                    }
                    key={category.id}
                    onClick={() =>
                      deleteSelSubCategories(category.id, category.name)
                    }
                  >
                    {category.name}
                    {subCatSelected.includes(category.id) && (
                      <span className="close_selected_category">x</span>
                    )}
                  </button>
                );
              }
            })}
          </div>
          <div className="filter_items_columns">
            <div className="map_view_text_container">
              <button
                className="map_view_button"
                type="button"
                onClick={onMapViewCick}
              >
                <img src={mapView} alt="" />
                <p className="map_view_text">Visualizza mappa</p>
              </button>
            </div>
            <div className="search_filter_text">
              <button
                type="button"
                className="filter_plus map_view_button"
                onClick={() => setShowFilterModal(true)}
              >
                <img src={filter} alt="" />
                <p className="search_filter">Filtri +</p>
              </button>
            </div>
            <div className="a_z_filter">
              <img src={sortLogo} alt="" />
              <div className="distance_text">
                <div className="a_z_text">
                  <Dropdown
                    placeholder="Distanza"
                    selection
                    clearable
                    options={filterOptionsDistance}
                    value={distanceValue}
                    onChange={handleDistanceFilter}
                    icon={<Icon name="angle down" />}
                  />
                </div>
                {/* Distanza <Icon className="angle down" /> */}
              </div>
            </div>
          </div>
          {showFilterModal && (
            <div className="search_filter_modal">
              <div
                // open={showFilterModal}
                // style={{
                //   width: 'calc(100vw - 250px)',
                //   top: '193px',
                //   left: '105px',
                // }}
                // onClose={() => setShowFilterModal(false)}
                className="search_fav_filter_modal"
              >
                <div className="listing_filters_popup listing_filters_popup_fav">
                  <div className="listing_filters_popup_search_filter listing_filters_popup_search_filter_fav">
                    <div>
                      <p className="offers_active_text">
                        {`I tuoi ${
                          favStores.length + favMalls.length
                        } negozi preferiti`}
                      </p>
                    </div>
                    <div className="filter_items_columns">
                      <div className="map_view_text_container">
                        <button
                          className="map_view_button"
                          type="button"
                          onClick={onMapViewCick}
                        >
                          <img src={mapView} alt="" />
                          <p className="map_view_text">Visualizza mappa</p>
                        </button>
                      </div>
                      <div className="search_filter_text">
                        <button
                          onClick={() => setShowFilterModal(false)}
                          type="button"
                          className="filter_plus map_view_button"
                        >
                          <img src={filter} alt="" />
                          <p className="search_filter">
                            Filtri x
                            {/* <button
                            type="button"
                            className="filter_close  map_view_button "
                            onClick={() => setShowFilterModal(false)}
                          >
                            +
                          </button> */}
                          </p>
                        </button>
                      </div>
                      <div className="a_z_filter">
                        <img src={sortLogo} alt="" />
                        <div className="distance_text">
                          <div className="a_z_text">
                            <Dropdown
                              placeholder="Distanza"
                              selection
                              clearable
                              options={filterOptionsDistance}
                              value={distanceValue}
                              onChange={handleDistanceFilter}
                              icon={<Icon name="angle down" />}
                            />
                          </div>
                          {/* Distanza <Icon className="angle down" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="categories_conatainer">
                    <p className="categories_text">Categoria</p>
                    <div className="categories_items">
                      {categories.map((category) => (
                        <button
                          type="button"
                          className={
                            !categorySelected.includes(category.id)
                              ? "categories_item map_view_button"
                              : "categories_item categories_item_selected map_view_button"
                          }
                          disabled={
                            categorySelected.length > 0 &&
                            !categorySelected.includes(category.id)
                          }
                          key={category.id}
                          onClick={() => handleCategories(category.id)}
                        >
                          {category.name}
                          {categorySelected.includes(category.id) && (
                            <span className="close_selected_category">x</span>
                          )}
                        </button>
                      ))}
                    </div>
                  </div>
                  {/* Sub Categories */}

                  {/* <div className="categories_conatainer">
                    <p className="categories_text">SOTTOCATEGORIA</p>
                    <div className="categories_items">
                      {subCategories.map(category => (
                        <button
                          type="button"
                          className={
                            !subCatSelected.includes(category.id)
                              ? 'categories_item map_view_button'
                              : 'categories_item categories_item_selected map_view_button'
                          }
                          key={category.id}
                          onClick={() =>
                            handleSubCategories(category.id, category.name)
                          }
                        >
                          {category.name}
                          {subCatSelected.includes(category.id) && (
                            <span className="close_selected_category">x</span>
                          )}
                        </button>
                      ))}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="listing_container listing_container_fav">
          {/* {[...Array(8)].map((e, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="shoppping_centre_card" key={i}>
              <div className="card_heart_container">
                <img src={heartFilled} alt="heart" />
              </div>
              <img src={shoppingCentre} alt="shopping centre" />
              <p className="shopping_centre_name">
                NOME SU DUE RIGHE CENTRO CENTRO COMMERCIALE
              </p>
              <div className="shopping_center_address_container">
                <img src={locator} alt="locator" />
                <p className="shopping_centre_address">
                  Via A. Manzoni 20, 00000, Chieri (TO)
                </p>
              </div>
            </div>
          ))} */}
          {favMalls.map((mall) => (
            <StoreCard
              key={mall.id}
              shopId={mall.id}
              isCreateFav
              shop={mall}
              isCreateLoding={isCreateLoding}
              deleteFavStore={deleteFavStore}
              type="mall"
            />
            // eslint-disable-next-line react/no-array-index-key
            // <div className="shoppping_centre_card" key={shop.id}>
            //   <div className="card_heart_container">
            //     <button
            //       className="heart_button"
            //       type="button"
            //       onClick={() =>
            //         !isCreateLoding && deleteFavStore(shop.id, "mall")
            //       }
            //     >
            //       <img src={heartFilled} alt="heart" />
            //     </button>
            //   </div>
            //   <img
            //     src={shop.image}
            //     alt="shopping centre"
            //     onClick={() => history.push(`/mallDetails/${shop.id}`)}
            //     onError={(e) => {
            //       e.target.src = shoppingCentre;
            //     }}
            //   />
            //   <p
            //     className="shopping_centre_name"
            //     onClick={() => history.push(`/mallDetails/${shop.id}`)}
            //   >
            //     {shop.name}
            //   </p>
            //   <div className="shopping_center_address_container">
            //     <img src={locator} alt="locator" />
            //     <p className="shopping_centre_address">
            //       {`${shop.address.address},  ${shop.address.city},  ${shop.address.state},  ${shop.address.zip}, ${shop.address.county}`}
            //     </p>
            //   </div>
            // </div>
          ))}

          {favStores.map((shop) => (
            <StoreCard
              key={shop.id}
              isCreateFav
              shop={shop}
              shopId={shop.id}
              isCreateLoding={isCreateLoding}
              deleteFavStore={deleteFavStore}
              type="store"
            />
          ))}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
FavouritePage.propTypes = {
  setShowListingPage: PropTypes.func,
  setIsHomeActive: PropTypes.func,
  setIsPromoActive: PropTypes.func,
  setIsFidelityActive: PropTypes.func,
  setShowMapView: PropTypes.func,
  getFavStores: PropTypes.func,
  favStores: PropTypes.object,
  deleteFavStores: PropTypes.object,
  createFavStoresSuccess: PropTypes.object,
  deletedFavStoreSuccess: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
  return {
    getMallsStores: (payload) =>
      dispatch({ type: "GET_MALLS_STORES", payload }),
    getFavStores: (payload) =>
      dispatch({ type: "GET_FAVOURITE_STORES", payload }),
    deleteFavStores: (payload) =>
      dispatch({ type: "DELETE_FAVOURITE_STORES", payload }),
  };
}
export function mapStateToProps(state) {
  return {
    favStores: state.homePageFav.favStores,
    createFavStoresSuccess: state.homePageFav.createdFavStore,
    deletedFavStoreSuccess: state.homePageFav.deletedFavStore,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: "mallsStores", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(FavouritePage);
