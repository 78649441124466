/* eslint-disable */
/* eslint-disable prefer-destructuring */
/* eslint-disable operator-assignment */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, memo } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import injectSaga from "../../utils/injectSaga";
import { DAEMON } from "../../utils/constants";
import saga from "../Homepage/saga";
import { Icon, Dropdown, Modal, Loader } from "semantic-ui-react";
import { useSnackbar } from "react-simple-snackbar";
import {
  shopCategories,
  getAllOffer,
  shopGallaryResource,
} from "../../Helpers/api";
import history from "../../utils/history";
// import ShopCard from "../../common/ShopCard/ShopCard";
import StoreCard from "../../common/StoreCard/StoreCard";
import {
  heartCard,
  shoppingCentre,
  locator,
  mapView,
  filter,
  sortLogo,
  store6,
  heartFilled,
} from "../../assets/images";
import "./ListingPage.scss";

function ListingPage(props) {
  const { t } = useTranslation();
  const [categorySelected, setCategorySelected] = useState([]);
  const [subCatSelected, setSubCatSelected] = useState([]);
  const [initialSubCategories, setInitialSubCategories] = useState([]);
  const [mallsData, setMallsData] = useState([]);
  const [storesData, setStoresData] = useState([]);
  const [distanceValue, setDistanceValue] = useState("");
  const [serachedText, setSerachedText] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [favStoresIds, setFavStoresId] = useState([]);
  const [favMallsIds, setFavMallsIds] = useState([]);
  const [activeOffersLengthDisplay, setActiveOffersLengthDisplay] = useState(0);
  const [categories, setCategories] = useState([]);
  const [openSnackbar] = useSnackbar({
    // position: 'top-center',
    style: {
      position: "absolute",
      backgroundColor: " baby blue",
      // border: '2px solid lightgreen',
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });
  const [subCategories, setSubCategories] = useState([]);
  const [isCreateLoding, setIsCreateLoding] = useState(false);
  const [filterOptionsDistance] = useState([
    {
      key: 1,
      text: "20 km",
      value: "20",
    },
    {
      key: 2,
      text: "40 km",
      value: "40",
    },
    {
      key: 3,
      text: "60 km",
      value: "60",
    },
    {
      key: 4,
      text: "80 km",
      value: "80",
    },
    {
      key: 5,
      text: "100 km",
      value: "100",
    },
  ]);
  const [currentGeoLocation, setCurrentGeoLocation] = useState({
    lat: 45.49719,
    long: 10.16602,
  });

  const craeteFavStore = (storeId, type) => {
    const payload = {
      storeId,
      type,
    };
    setIsCreateLoding(true);
    props.createFavStores(payload);
    setTimeout(() => props.getFavStores(), 1000);
  };
  const deleteFavStore = (storeId, type) => {
    setIsCreateLoding(true);
    props.deleteFavStores({ storeId, type });
    setTimeout(() => props.getFavStores(), 100);
  };

  /*

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((location) => {
      if (
        location &&
        location.coords &&
        location.coords.latitude &&
        location.coords.longitude
      ) {
        setCurrentGeoLocation({
          lat: location.coords.latitude,
          long: location.coords.longitude,
          // lat: 45.49719,
          // long: 10.16602,
        });
      }
    });
    // const params = new URLSearchParams(history.location.search);
    // const urlcategory = params.get("categories");
    // const urlDistance = params.get("distance");
  }, []);

  */

  useEffect(() => {
    props.getFavStores();
    const params = new URLSearchParams(history.location.search);
    const searchText = params.get("text");
    const zipCode = params.get("city");
    const filterCategories = params.get("categories");
    const urlDistance = params.get("distance");
    if (searchText) {
      setSerachedText(searchText);
    }
    /*
    navigator.geolocation.getCurrentPosition((location) => {
      if (
        location &&
        location.coords &&
        location.coords.latitude &&
        location.coords.longitude
      ) {
        setCurrentGeoLocation({
          lat: location.coords.latitude,
          long: location.coords.longitude,
          // lat: 45.49719,
          // long: 10.16602,
        });
      }
      props.searchMallsStores({
        zipCode,
        searchText,
        categories: filterCategories,
        distance: urlDistance,
        lat: location.coords.latitude,
        long: location.coords.latitude,
      });
    });
    */
    if (JSON.parse(localStorage.getItem("zipRange"))) {
      props.searchMallsStores({
        zipCode,
        searchText,
        categories: filterCategories,
        distance: urlDistance,
        lat: currentGeoLocation.lat,
        long: currentGeoLocation.long,
        zipRange: JSON.parse(localStorage.getItem("zipRange")),
        istat: localStorage.getItem("istat"),
      });
    } else {
      props.searchMallsStores({
        zipCode,
        searchText,
        categories: filterCategories,
        distance: urlDistance,
        lat: currentGeoLocation.lat,
        long: currentGeoLocation.long,
      });
    }
  }, []);
  useEffect(() => {}, [localStorage.getItem("searchedItem")]);
  // Getting the categories for filters

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const urlDistance = params.get("distance");
    if (urlDistance) {
      setDistanceValue(urlDistance);
    }

    axios({
      method: "get",
      url: shopCategories,
    })
      .then((res) => {
        const filterCategories = [];
        const subCategoriesFilter = [];

        res.data.forEach((item) => {
          const obj = {};
          obj.id = item.id;
          obj.name = item.description;
          obj.isSelected = false;
          filterCategories.push(obj);
          // Sub categories
          const subCategObj = {};
          subCategObj.id = item.id;
          if (item.items) {
            subCategObj.subCategories = item.items;
          }
          subCategoriesFilter.push(subCategObj);
        });
        setInitialSubCategories(subCategoriesFilter);
        setCategories(filterCategories);

        // Selected category by default
        // const params = new URLSearchParams(history.location.search);
        const urlcategory = params.get("categories");
        const urlcategoryArray = urlcategory.split(",");
        let mainCategory = "";
        if (urlcategoryArray && urlcategoryArray.length) {
          mainCategory = urlcategoryArray[0];
          if (mainCategory) {
            const objItem = filterCategories.find(
              (item) => item.name === mainCategory
            );
            setCategorySelected([objItem.id]);
            const subCategObj = subCategoriesFilter.find(
              (item) => item.id === objItem.id
            );
            const finalSubcategories = [];
            if (
              subCategObj &&
              subCategObj.subCategories &&
              subCategObj.subCategories.length
            ) {
              subCategObj.subCategories.forEach((item) => {
                const obj = {};
                obj.id = item.id;
                obj.name = item.description;
                obj.isSelected = false;
                finalSubcategories.push(obj);
              });
              setSubCategories(finalSubcategories);
            }
            const subCatArray = subCategoriesFilter.find(
              (sub) => sub.id === objItem.id
            );
            const filterSub = subCatArray.subCategories.filter((item) =>
              urlcategoryArray.includes(item.description)
            );
            const selectedSubCateIds = [];
            filterSub.forEach((item) => {
              selectedSubCateIds.push(item.id);
            });
            setSubCatSelected(selectedSubCateIds);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // Get All active offers
    /*
    axios.get(getAllOffer).then(
      (res) => {
        const offers = res.data.data;
        const groupedOffers = [];
        const duplicates = [];
        if (offers && offers.length) {
          offers.forEach((offer, index) => {
            const group = [];
            if (duplicates.includes(index)) return;
            const type = offer.offer.type.toLowerCase();
            const sameShopOffers = offers.filter((groupOffer, groupIndex) => {
              const groupType = groupOffer.offer.type.toLowerCase();
              if (
                groupType === type &&
                groupOffer[groupType].id === offer[type].id
              ) {
                duplicates.push(groupIndex);
                return true;
              }
              return false;
            });
            for (let i = 0; i < sameShopOffers.length; i += 2) {
              const innerGroup = [];
              innerGroup.push(sameShopOffers[i]);
              if (sameShopOffers.length - i !== 1)
                innerGroup.push(sameShopOffers[i + 1]);
              group.push(innerGroup);
            }
            groupedOffers.push(group);
          });
          // Totoal Active oofers
          let activeOffersLength = 0;
          if (groupedOffers && groupedOffers.length) {
            groupedOffers.forEach((group) => {
              activeOffersLength = activeOffersLength + group.length;
            });
            setActiveOffersLengthDisplay(activeOffersLength);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    */
  }, []);

  // Get Fav stores and mall Ids

  useEffect(() => {
    if (props.favStores) {
      const filterFavStoresId = [];
      const filterFavMallsId = [];
      if (
        props.favStores &&
        props.favStores.data &&
        props.favStores.data.data &&
        props.favStores.data.data.stores
      ) {
        props.favStores.data.data.stores.forEach((item) =>
          filterFavStoresId.push(item.id)
        );
      }
      if (
        props.favStores &&
        props.favStores.data &&
        props.favStores.data.data &&
        props.favStores.data.data.malls
      ) {
        props.favStores.data.data.malls.forEach((item) =>
          filterFavMallsId.push(item.id)
        );
      }
      setFavStoresId(filterFavStoresId);
      setFavMallsIds(filterFavMallsId);
    }
  }, [props.favStores]);

  useEffect(() => {
    setIsCreateLoding(false);
  }, [props.createFavStoresSuccess, props.deletedFavStoreSuccess]);

  useEffect(() => {
    if (
      props.searchedMallsAndStores &&
      props.searchedMallsAndStores.getMallsAndStoresSuccess &&
      props.searchedMallsAndStores.getMallsAndStoresSuccess.data &&
      props.searchedMallsAndStores.getMallsAndStoresSuccess.data.data
    ) {
      setMallsData(
        props.searchedMallsAndStores.getMallsAndStoresSuccess.data.data.malls
      );
      setStoresData(
        props.searchedMallsAndStores.getMallsAndStoresSuccess.data.data.stores
      );
    }
  }, [props.searchedMallsAndStores]);
  const onMapViewCick = () => {
    history.push({
      pathname: "/map-view",
      // data: props.searchedMallsAndStores.getMallsAndStoresSuccess.data.data, // your data array of objects
      search: history.location.search,
    });
    // history.push('/map-view');

    // props.setIsHomeActive(false);
    // props.setIsPromoActive(false);
    // props.setIsFidelityActive(false);
    // props.setShowListingPage(false);
    // props.setShowMapView(true);
  };
  const handleCategories = (id) => {
    if (categorySelected.indexOf(id) === -1 && categorySelected.length < 1) {
      // SET SUB CATEG BASED ON CATEGORY SELECTED
      const subCategObj = initialSubCategories.find((item) => item.id === id);
      const finalSubcategories = [];
      if (
        subCategObj &&
        subCategObj.subCategories &&
        subCategObj.subCategories.length
      ) {
        subCategObj.subCategories.forEach((item) => {
          const obj = {};
          obj.id = item.id;
          obj.name = item.description;
          obj.isSelected = false;
          finalSubcategories.push(obj);
        });
        setSubCategories(finalSubcategories);
      }
      setCategorySelected([...categorySelected, id]);
      const obj = categories.find((item) => item.id === id);
      history.push({
        search: `${history.location.search}&categories=${obj.name}`,
      });
      const params = new URLSearchParams(history.location.search);
      const searchText = params.get("text");
      const zipCode = params.get("city");
      const dist = params.get("distance");
      if (JSON.parse(localStorage.getItem("zipRange"))) {
        props.searchMallsStores({
          zipCode,
          searchText,
          categories: obj.name,
          distance: dist,
          lat: currentGeoLocation.lat,
          long: currentGeoLocation.long,
          zipRange: JSON.parse(localStorage.getItem("zipRange")),
          istat: localStorage.getItem("istat"),
        });
      } else {
        props.searchMallsStores({
          zipCode,
          searchText,
          categories: obj.name,
          distance: dist,
          lat: currentGeoLocation.lat,
          long: currentGeoLocation.long,
        });
      }
    } else {
      setSubCategories([]);
      const index = categorySelected.indexOf(id);
      if (index > -1) {
        categorySelected.splice(index, 1);
        setCategorySelected([...categorySelected]);
        const params = new URLSearchParams(history.location.search);
        params.delete("categories");
        history.push({
          pathname: history.location.pathname,
          search: params.toString(),
        });
        const searchText = params.get("text");
        const zipCode = params.get("city");
        const filterCategories = params.get("categories");
        const dist = params.get("distance");
        if (JSON.parse(localStorage.getItem("zipRange"))) {
          props.searchMallsStores({
            searchText,
            zipCode,
            categories: filterCategories,
            distance: dist,
            lat: currentGeoLocation.lat,
            long: currentGeoLocation.long,
            zipRange: JSON.parse(localStorage.getItem("zipRange")),
            istat: localStorage.getItem("istat"),
          });
        } else {
          props.searchMallsStores({
            searchText,
            zipCode,
            categories: filterCategories,
            distance: dist,
            lat: currentGeoLocation.lat,
            long: currentGeoLocation.long,
          });
        }
      }
    }
  };
  const handleSubCategories = (id, name) => {
    if (subCatSelected.indexOf(id) === -1) {
      setSubCatSelected([...subCatSelected, id]);

      // Sub Category handles

      const obj = subCategories.find((item) => item.id === id);
      const catHistory = [];
      let params = new URLSearchParams(history.location.search);
      const distt = params.get("distance");
      const city = params.get("city");
      let filterCategories = params.get("categories");
      catHistory.push(filterCategories);
      if (distt && !city) {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filterCategories},${obj.name}&distance=${distt}`,
        });
      } else if (city) {
        history.push({
          pathname: history.location.pathname,
          search: `city=${city}&categories=${filterCategories},${obj.name}`,
        });
      } else {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filterCategories},${obj.name}`,
        });
      }

      // history.push({
      //   pathname: history.location.pathname,
      //   search: `categories=${filterCategories},${obj.name}&distance=${distt}`,
      // });
      params = new URLSearchParams(history.location.search);

      filterCategories = params.get("categories");
      const searchText = params.get("text");
      const zipCode = params.get("city");
      const dist = params.get("distance");
      if (JSON.parse(localStorage.getItem("zipRange"))) {
        props.searchMallsStores({
          searchText,
          zipCode,
          categories: filterCategories,
          distance: dist,
          lat: currentGeoLocation.lat,
          long: currentGeoLocation.long,
          zipRange: JSON.parse(localStorage.getItem("zipRange")),
          istat: localStorage.getItem("istat"),
        });
      } else {
        props.searchMallsStores({
          searchText,
          zipCode,
          categories: filterCategories,
          distance: dist,
          lat: currentGeoLocation.lat,
          long: currentGeoLocation.long,
        });
      }
    } else {
      const index = subCatSelected.indexOf(id);
      // SUB CATEGORY DELETE
      const params = new URLSearchParams(history.location.search);
      const catgg = params.get("categories");
      const dist = params.get("distance");
      const city = params.get("city");
      const catggArray = catgg.split(",");
      const arrayTobeFilter = [...catggArray];
      const filteredArray = arrayTobeFilter.filter((item) => item !== name);
      if (dist) {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filteredArray.toString()}&distance=${dist}`,
        });
      } else if (city) {
        history.push({
          pathname: history.location.pathname,
          search: `city=${city}&categories=${filteredArray.toString()}`,
        });
      } else {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filteredArray.toString()}`,
        });
      }

      const params2 = new URLSearchParams(history.location.search);
      const catgg2 = params2.get("categories");
      const distt2 = params2.get("distance");
      const searchText = params.get("text");
      const zipCode = params.get("city");
      if (JSON.parse(localStorage.getItem("zipRange"))) {
        props.searchMallsStores({
          searchText,
          zipCode,
          categories: catgg2,
          distance: distt2,
          lat: currentGeoLocation.lat,
          long: currentGeoLocation.long,
          zipRange: JSON.parse(localStorage.getItem("zipRange")),
          istat: localStorage.getItem("istat"),
        });
      } else {
        props.searchMallsStores({
          searchText,
          zipCode,
          categories: catgg2,
          distance: distt2,
          lat: currentGeoLocation.lat,
          long: currentGeoLocation.long,
        });
      }

      if (index > -1) {
        subCatSelected.splice(index, 1);
        setSubCatSelected([...subCatSelected]);
      }
    }
  };
  const deleteSelCategories = (id) => {
    const index = categorySelected.indexOf(id);
    if (index > -1) {
      const params = new URLSearchParams(history.location.search);
      params.delete("categories");
      history.push({
        pathname: history.location.pathname,
        search: params.toString(),
      });
      const searchText = params.get("text");
      const zipCode = params.get("city");
      const filterCategories = params.get("categories");
      const dist = params.get("distance");
      if (JSON.parse(localStorage.getItem("zipRange"))) {
        props.searchMallsStores({
          searchText,
          zipCode,
          categories: filterCategories,
          distance: dist,
          lat: currentGeoLocation.lat,
          long: currentGeoLocation.long,
          zipRange: JSON.parse(localStorage.getItem("zipRange")),
          istat: localStorage.getItem("istat"),
        });
      } else {
        props.searchMallsStores({
          searchText,
          zipCode,
          categories: filterCategories,
          distance: dist,
          lat: currentGeoLocation.lat,
          long: currentGeoLocation.long,
        });
      }

      categorySelected.splice(index, 1);
      setCategorySelected([...categorySelected]);
    }
  };

  const deleteSelSubCategories = (id, name) => {
    const index = subCatSelected.indexOf(id);
    if (index > -1) {
      let params = new URLSearchParams(history.location.search);
      const catgg = params.get("categories");
      const dist2 = params.get("distance");
      const city = params.get("city");
      const catggArray = catgg.split(",");
      const arrayTobeFilter = [...catggArray];
      const filteredArray = arrayTobeFilter.filter((item) => item !== name);
      if (dist2) {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filteredArray.toString()}&distance=${dist2}`,
        });
      } else if (city) {
        history.push({
          pathname: history.location.pathname,
          search: `city=${city}&categories=${filteredArray.toString()}`,
        });
      } else {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filteredArray.toString()}`,
        });
      }
      params = new URLSearchParams(history.location.search);
      const searchText = params.get("text");
      const zipCode = params.get("city");
      const filterCategories = params.get("categories");
      const dist = params.get("distance");
      if (JSON.parse(localStorage.getItem("zipRange"))) {
        props.searchMallsStores({
          searchText,
          zipCode,
          categories: filterCategories,
          distance: dist,
          lat: currentGeoLocation.lat,
          long: currentGeoLocation.long,
          zipRange: JSON.parse(localStorage.getItem("zipRange")),
          istat: localStorage.getItem("istat"),
        });
      } else {
        props.searchMallsStores({
          searchText,
          zipCode,
          categories: filterCategories,
          distance: dist,
          lat: currentGeoLocation.lat,
          long: currentGeoLocation.long,
        });
      }
      subCatSelected.splice(index, 1);
      setSubCatSelected([...subCatSelected]);
    }
  };

  const hanldeFilterModalOnClose = () => {
    setShowFilterModal(false);
  };

  const handleDistanceFilter = (e, { value }) => {
    setDistanceValue(value);
    const params2 = new URLSearchParams(history.location.search);
    params2.delete("distance");
    history.push({
      pathname: history.location.pathname,
      search: params2.toString(),
    });
    history.push({
      search: `${history.location.search}&distance=${value}`,
    });
    const params = new URLSearchParams(history.location.search);
    const searchText = params.get("text");
    const zipCode = params.get("city");
    const zipRange = JSON.parse(localStorage.getItem("zipRange"));
    const filterCategories = params.get("categories");
    navigator.geolocation.getCurrentPosition((location) => {
      console.log(location);
      setCurrentGeoLocation({
        lat: location.coords.latitude,
        long: location.coords.longitude,
      });
      if (zipRange) {
        props.searchMallsStores({
          // zipCode: selectedCity,
          zipCode,
          searchText,
          categories: filterCategories,
          distance: value,
          lat: location.coords.latitude,
          long: location.coords.longitude,
          zipRange,
          istat: localStorage.getItem("istat"),
        });
      } else {
        props.searchMallsStores({
          // zipCode: selectedCity,
          zipCode,
          searchText,
          categories: filterCategories,
          distance: value,
          lat: location.coords.latitude,
          long: location.coords.longitude,
        });
      }
    });

    if (value === "") {
      const params3 = new URLSearchParams(history.location.search);
      params3.delete("distance");
      history.push({
        pathname: history.location.pathname,
        search: params3.toString(),
      });
      const searchText2 = params3.get("text");
      const zipCode2 = params3.get("city");
      const filterCategories2 = params3.get("categories");
      if (JSON.parse(localStorage.getItem("zipRange"))) {
        props.searchMallsStores({
          zipCode: zipCode2,
          istat: localStorage.getItem("istat"),
          zipRange: JSON.parse(localStorage.getItem("zipRange")),
          searchText: searchText2,
          categories: filterCategories2,
        });
      } else {
        props.searchMallsStores({
          zipCode: zipCode2,
          searchText: searchText2,
          categories: filterCategories2,
        });
      }
    }
  };

  return (
    <div className="landing_page_root">
      <div className="list_page_headings">
        <p className="components_container_main_heading">
          Home &gt; Ricerca &gt;{" "}
          <span className="components_container_main_heading_product">
            {/* {serachedText} */}
            {localStorage.getItem("searchedItem")}
          </span>
        </p>
        <p className="components_container_second_heading">
          Hai cercato:{" "}
          <span className="components_container_second_heading_two">
            {localStorage.getItem("searchedItem")}
            {/* {serachedText} */}
          </span>
        </p>
      </div>

      <div className="search_filter_container">
        <div className="text_and_selected">
          <p className="offers_active_text">{`${
            mallsData.length + storesData.length
          } attivà nella zona indicata`}</p>
          {categories.map((category) => {
            if (categorySelected.includes(category.id)) {
              return (
                <button
                  type="button"
                  className={
                    !categorySelected.includes(category.id)
                      ? "categories_item map_view_button"
                      : "categories_item categories_item_selected map_view_button"
                  }
                  key={category.id}
                  onClick={() => deleteSelCategories(category.id)}
                >
                  {category.name}
                  {categorySelected.includes(category.id) && (
                    <span className="close_selected_category">x</span>
                  )}
                </button>
              );
            }
          })}
          {subCategories.map((category) => {
            if (subCatSelected.includes(category.id)) {
              return (
                <button
                  type="button"
                  className={
                    !subCatSelected.includes(category.id)
                      ? "categories_item map_view_button"
                      : "categories_item categories_item_selected map_view_button"
                  }
                  key={category.id}
                  onClick={() =>
                    deleteSelSubCategories(category.id, category.name)
                  }
                >
                  {category.name}
                  {subCatSelected.includes(category.id) && (
                    <span className="close_selected_category">x</span>
                  )}
                </button>
              );
            }
          })}
        </div>
        <div className="filter_items_columns">
          <div className="map_view_text_container">
            <button
              className="map_view_button"
              type="button"
              onClick={onMapViewCick}
            >
              <img src={mapView} alt="" />
              <p className="map_view_text">Visualizza mappa</p>
            </button>
          </div>
          <div className="search_filter_text">
            <button
              type="button"
              className="filter_plus map_view_button"
              onClick={() => {
                setShowFilterModal(true);
              }}
            >
              <button type="button" className="filter_plus map_view_button">
                <img src={filter} alt="" />
                <p className="search_filter">
                  Filtri <span className="filter_toggke">+</span>
                </p>
              </button>
            </button>
          </div>
          <div className="a_z_filter">
            <img src={sortLogo} alt="" />
            <div className="distance_text">
              <div className="a_z_text">
                <Dropdown
                  placeholder="Distanza"
                  selection
                  clearable
                  value={distanceValue}
                  options={filterOptionsDistance}
                  onChange={handleDistanceFilter}
                  // onChange={(e, data) => console.log(data.value)}
                  icon={<Icon name="angle down" />}
                />
              </div>
              {/* Distanza <Icon className="angle down" /> */}
            </div>
          </div>
          {showFilterModal && (
            <div className="search_filter_modal">
              <div
              // open={showFilterModal}
              // style={{
              //   width: "calc(100vw - 250px)",
              //   top: "193px",
              //   left: "105px",
              // }}
              // onClose={hanldeFilterModalOnClose}
              >
                <div className="listing_filters_popup">
                  <div className="listing_filters_popup_search_filter">
                    <div>
                      <p className="offers_active_text">{`${
                        mallsData.length + storesData.length
                      } attivà nella zona indicata`}</p>
                    </div>
                    <div className="filter_items_columns">
                      <div className="map_view_text_container">
                        <button
                          className="map_view_button"
                          type="button"
                          onClick={onMapViewCick}
                        >
                          <img src={mapView} alt="" />
                          <p className="map_view_text">Visualizza mappa</p>
                        </button>
                      </div>
                      <div
                        className="search_filter_text"
                        onClick={() => setShowFilterModal(false)}
                      >
                        <img src={filter} alt="" />
                        <p className="search_filter">
                          Filtri <span className="filter_toggke">x</span>
                          {/* <button
                          type="button"
                          className="filter_close  map_view_button "
                          onClick={() => setShowFilterModal(false)}
                        >
                          +
                        </button> */}
                        </p>
                      </div>
                      <div className="a_z_filter">
                        <img src={sortLogo} alt="" />
                        <div className="distance_text">
                          <div className="a_z_text">
                            <Dropdown
                              placeholder="Distanza"
                              selection
                              options={filterOptionsDistance}
                              onChange={handleDistanceFilter}
                              icon={<Icon name="angle down" />}
                            />
                          </div>
                          {/* Distanza <Icon className="angle down" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="categories_conatainer">
                    <p className="categories_text">Categoria</p>
                    <div className="categories_items">
                      {categories.map((category) => (
                        <button
                          type="button"
                          className={
                            !categorySelected.includes(category.id)
                              ? "categories_item map_view_button"
                              : "categories_item categories_item_selected map_view_button"
                          }
                          disabled={
                            categorySelected.length > 0 &&
                            !categorySelected.includes(category.id)
                          }
                          key={category.id}
                          onClick={() => {
                            handleCategories(category.id);
                          }}
                        >
                          {category.name}
                          {categorySelected.includes(category.id) && (
                            <span className="close_selected_category">x</span>
                          )}
                        </button>
                      ))}
                    </div>
                  </div>
                  {/* Sub Categories */}
                  <div className="categories_conatainer">
                    <p className="categories_text">SOTTOCATEGORIA</p>
                    <div className="categories_items">
                      {subCategories.map((category) => (
                        <button
                          type="button"
                          className={
                            !subCatSelected.includes(category.id)
                              ? "categories_item map_view_button"
                              : "categories_item categories_item_selected map_view_button"
                          }
                          key={category.id}
                          onClick={() =>
                            handleSubCategories(category.id, category.name)
                          }
                        >
                          {category.name}
                          {subCatSelected.includes(category.id) && (
                            <span className="close_selected_category">x</span>
                          )}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {!props.searchedMallsAndStores.getMallsAndStoresLoading ? (
        <div className="listing_container">
          {mallsData.map((mall) => (
            // <ShopCard
            //   key={mall.id}
            //   isCreateFav={
            //     favMallsIds.includes(mall.id) && localStorage.getItem("token")
            //   }
            //   shop={mall}
            //   isCreateLoding={isCreateLoding}
            //   deleteFavStore={deleteFavStore}
            //   craeteFavStore={craeteFavStore}
            //   openSnackbar={openSnackbar}
            //   type="mall"
            // />
            <StoreCard
              key={mall.id}
              isCreateFav={
                favMallsIds.includes(mall.id) && localStorage.getItem("token")
              }
              shop={mall}
              isCreateLoding={isCreateLoding}
              deleteFavStore={deleteFavStore}
              craeteFavStore={craeteFavStore}
              openSnackbar={openSnackbar}
              type="mall"
            />
          ))}

          {storesData.map((shop) => (
            // <ShopCard
            //   key={shop.id}
            //   isCreateFav={
            //     favStoresIds.includes(shop.id) && localStorage.getItem("token")
            //   }
            //   shop={shop}
            //   isCreateLoding={isCreateLoding}
            //   deleteFavStore={deleteFavStore}
            //   craeteFavStore={craeteFavStore}
            //   openSnackbar={openSnackbar}
            //   type="store"
            // />
            <StoreCard
              key={shop.id}
              isCreateFav={
                favStoresIds.includes(shop.id) && localStorage.getItem("token")
              }
              shop={shop}
              isCreateLoding={isCreateLoding}
              deleteFavStore={deleteFavStore}
              craeteFavStore={craeteFavStore}
              openSnackbar={openSnackbar}
              type="store"
            />
          ))}
        </div>
      ) : (
        <div className="list_page_loader">
          <Loader active inline className="mall_loader" />
        </div>
      )}
    </div>
  );
}
ListingPage.propTypes = {
  setShowListingPage: PropTypes.func,
  setIsHomeActive: PropTypes.func,
  setIsPromoActive: PropTypes.func,
  setIsFidelityActive: PropTypes.func,
  setShowMapView: PropTypes.func,
  searchedMallsAndStores: PropTypes.object,
  searchMallsStores: PropTypes.func,
  favStores: PropTypes.object,
  getFavStores: PropTypes.object,
  deleteFavStores: PropTypes.object,
  createFavStores: PropTypes.object,
  createFavStoresSuccess: PropTypes.object,
  deletedFavStoreSuccess: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
  return {
    getMallsStores: (payload) =>
      dispatch({ type: "GET_MALLS_STORES", payload }),
    searchMallsStores: (payload) =>
      dispatch({ type: "SEARCH_MALLS_STORES", payload }),
    getFavStores: (payload) =>
      dispatch({ type: "GET_FAVOURITE_STORES", payload }),
    deleteFavStores: (payload) =>
      dispatch({ type: "DELETE_FAVOURITE_STORES", payload }),
    createFavStores: (payload) =>
      dispatch({ type: "CREATE_FAVOURITE_STORE", payload }),
  };
}
export function mapStateToProps(state) {
  return {
    mallsAndStores: state.homePageFav.mallsStores,
    favStores: state.homePageFav.favStores,
    searchedMallsAndStores: state.homePageFav,
    createFavStoresSuccess: state.homePageFav.createdFavStore,
    deletedFavStoreSuccess: state.homePageFav.deletedFavStore,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: "mallsStores", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(ListingPage);
