/* eslint-disable */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
/* eslint-disable operator-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-useless-escape */
/* eslint-disable indent */
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import moment from 'moment';
import axios from 'axios';
import { Accordion, Button, Dropdown, Form, Icon, Input, Loader, } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import { DateInput } from 'semantic-ui-calendar-react';
// import { it } from 'moment/locale/it';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import it from 'date-fns/locale/it';

import { useSnackbar } from 'react-simple-snackbar';
import { useMediaQuery } from "react-responsive";
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from '../../utils/injectSaga';
import { DAEMON } from '../../utils/constants';
// import history from '../../utils/history';
import * as API from '../../Helpers/api';
import saga from './saga';
// import { isEmail } from 'validator';
import EmailVerificationModal from '../LandingPage/EmailVerificationModal';
import history from '../../utils/history';
import Popup from "../Popup/Popup";

import './StoreRegisterForm.scss';

registerLocale('it', it);

const StoreRegisterForm = props => {
    const { t } = useTranslation();
    //   const [activeIndex, setActiveIndex] = useState(0);
    const isMobileView = useMediaQuery({ query: "(max-width: 768px)" });
    const [activeIndex, setActiveIndex] = useState([3]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCity, setSlectedCity] = useState('');
    const [citiesOptions, setCitiesOptions] = useState([
        // {
        //   key: '00010',
        //   text: 'Casape [00010]',
        //   value: '00010',
        // },
        // {
        //   key: '00012',
        //   text: 'Guidonia Montecelio [00012]',
        //   value: '00012',
        // },
        // {
        //   key: '00013',
        //   text: 'Fonte Nuova [00013]',
        //   value: '00013',
        // },
    ]);
    const [initialCityData, setInitialCityData] = useState([
        // {
        //   id: '00010',
        //   city: 'Casape',
        //   istat: '58021',
        //   province: 'RM',
        //   lng: 12.886139,
        //   lat: 41.906406,
        // },
        // {
        //   id: '00012',
        //   city: 'Guidonia Montecelio',
        //   istat: '58047',
        //   province: 'RM',
        //   lng: 12.726386,
        //   lat: 42.000078,
        // },
        // {
        //   id: '00013',
        //   city: 'Fonte Nuova',
        //   istat: '58122',
        //   province: 'RM',
        //   lng: 12.618873,
        //   lat: 41.996273,
        // },
    ]);
    // const [newDate, setNewDate] = useState();

    const [businessCateg, setBusinessCateg] = useState([]);
    const [showSignUpSuccesModal, setShowSignUpSuccesModal] = useState(false);
    const [cardType, setCardType] = useState('Invalid');
    const [selectedBuisnessType, setSelectedBuisnessType] = useState('');
    const [privacyPolicy, setPrivacyPolicy] = useState([]);
    let mandIndex = 0;
    let optIndex = 2;

    const [variableModules, setVariableModules] = useState(null)
    const [fixedModules, setFixedModules] = useState(null)
    const [duration, setDuration] = useState("MONTH")
    const [selectedModules, setSelectedModules] = useState(["BASE_MODULE"])
    const [baseModule, setBaseModule] = useState("BASE_MODULE")
    const [totalInfo, setTotalInfo] = useState(null)
    const [couponCode, setCouponCode] = useState("")
    const [confirmedCoupon, setConfirmedCoupon] = useState(null)

    const [formValues, setFormValues] = useState({
        name: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
        password: '',
        confirmPassword: '',
        musicName: '',
        subDomainName: '',
        businessType: '',
        businessCategory: '',
        companyName: '',
        vatNumber: '',
        fiscalCode: '',
        phone: '',
        mobilePhone: '',
        address: '',
        streetNumber: '',
        postalCode: '',
        city: '',
        province: '',
        billingEmail: '',
        pec: '',
        recCode: '',
        cardHolderName: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
        promoCode: '',
        type1: false,
        type2: false,
        type3: false,
        type4: false,
    });
    const [validationErros, setValidationErros] = useState({});
    //   const [isMonthlyPlanSelected, setIsMonthlyPlanSelected] = useState(false);
    const [isYearlyPlanSelected, setIsYearlyPlanSelected] = useState(false);
    // const [openSnackbar] = useSnackbar();
    const [openSnackbar] = useSnackbar({
        // position: 'top-center',
        style: {
            position: 'absolute',
            backgroundColor: 'green',
            border: '2px solid lightgreen',
            color: 'lightblue',
            fontFamily: 'Menlo, monospace',
            fontSize: '20px',
            textAlign: 'center',
            bottom: '100%',
            left: 0,
        },
        closeStyle: {
            color: 'lightcoral',
            fontSize: '16px',
        },
    });
    const [openSnackbarRed] = useSnackbar({
        // position: 'top-center',
        style: {
            position: 'absolute',
            backgroundColor: 'red',
            border: '2px solid lightgreen',
            color: 'lightblue',
            fontFamily: 'Menlo, monospace',
            fontSize: '20px',
            textAlign: 'center',
            bottom: '100%',
            left: 0,
        },
        closeStyle: {
            color: 'lightcoral',
            fontSize: '16px',
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        axios({
            method: 'get',
            url: API.shopCategories,
            // auth: {
            //   username: 'g.scirea',
            //   password: 'testtest',
            // },
        })
            .then(res => {
                const categories = [];
                res.data.forEach(item => {
                    const obj = {};
                    obj.key = item.id;
                    // obj.text = item.description;
                    if (item.description) {
                        obj.text = `${item.description
                            .charAt(0)
                            .toUpperCase()}${item.description.slice(1)}`;
                    }
                    obj.value = item.id;
                    categories.push(obj);
                });
                setBusinessCateg(categories);
            })
            .catch(err => {
                console.log(err);
            });

        // Get privacy policy

        axios.get(API.getPrivacyPolicy).then(
            res => {
                setPrivacyPolicy(res.data.data);
            },
            err => {
                console.log(err);
            },
        );

        // Get cities

        axios.get(API.getCities).then(
            res => {
                const cityArray = [];
                if (res && res.data && res.data.data) {
                    res.data.data.sort((a, b) => a.id - b.id);
                    res.data.data.forEach(item => {
                        cityArray.push({
                            key: item.id,
                            value: item.id,
                            text: `${item.city} [${item.id}]`,
                        });
                    });
                }
                setCitiesOptions(cityArray);
                console.log(cityArray);
                setInitialCityData(res.data.data);
            },
            err => {
                console.log(err);
            },
        );
    }, []);

    useEffect(() => {
        // props.getFavStores();
        if (props.operatorSignUpError) {
            openSnackbar('Something went wrong');
        }
        //  else {
        //   setShowSignUpSuccesModal(true);
        // }
    }, [props.operatorSignUpError]);

    useEffect(() => {
        axios({
            method: 'get',
            url: API.modulesPricing,
            params: {
                // duration: duration,
                duration: "MONTH",
                tidy: true
            }
        })
            .then(response => {
                setFixedModules(response.data.data.fixedModules)
                setVariableModules(response.data.data.variableModules)
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios({
            method: 'post',
            url: API.calculatePricing,
            data: {
                duration: duration,
                modules: selectedModules,
                couponCode: confirmedCoupon
            }
        })
            .then(response => {
                setTotalInfo(response.data.data)
            })
            .catch(error => {
                console.log(error);
            });
    }, [duration, selectedModules, confirmedCoupon]);

    const handleBaseModule = (module) => {
        if (module !== baseModule) {
            setBaseModule(module)
            let updatedModules = [];
            updatedModules.push(module)
            setSelectedModules([...updatedModules])
        }
    }

    const handleModules = (module) => {
        const index = selectedModules.indexOf(module)
        if (index > -1) {
            selectedModules.splice(index, 1)
        } else {
            selectedModules.push(module)
        }
        if (selectedModules.length === (variableModules.length + 1)) {
            setBaseModule("PREMIUM")
            setSelectedModules(["PREMIUM"])
        } else {
            setSelectedModules([...selectedModules]);
        }
    }

    const couponChange = (e) => setCouponCode(e.target.value);

    const handleCoupon = (coupon) => {
        axios({
            method: 'get',
            url: `${API.verifyCoupon}/${coupon}/verify`,
        })
            .then(response => {
                if (response.data.responseCode === 0) {
                    setConfirmedCoupon(coupon)
                } else {
                    openSnackbarRed('Coupon non valido!');
                }
            })
    }

    const handleFormValueChanges = (event, { name, value }) => {
        if (name === 'mobilePhone') {
            if (value.length > 0 && !value.match(/^[+]{0,1}[(]{0,1}[0-9]{0,4}[)]{0,1}[-\s\./0-9]*$/)) return;
        }
        setFormValues({ ...formValues, [name]: value });
    };
    const handleCardNumberChanges = values => {
        setFormValues({ ...formValues, cardNumber: values.value });
        // Check Card Type
        let typeCheck = values.value.substring(0, 2);
        let cType = 'Invalid';
        if (typeCheck.length === 2) {
            typeCheck = parseInt(typeCheck, 10);
            if (typeCheck >= 40 && typeCheck <= 49) {
                cType = 'Visa';
            } else if (typeCheck >= 51 && typeCheck <= 55) {
                cType = 'Master Card';
            } else if ((typeCheck >= 56 && typeCheck <= 69) || typeCheck === 50) {
                cType = 'Maestro';
            } else if (typeCheck === 34 || typeCheck === 37) {
                cType = 'American Express';
            } else {
                cType = 'Invalid';
            }
        }
        setCardType(cType);
    };
    const handleCardExpiryDate = values => {
        setFormValues({ ...formValues, expiryDate: values.value });
    };
    const cardExpiry = val => {
        const month = limit(val.substring(0, 2), '12');
        const date = limit(val.substring(2, 4), '31');

        // eslint-disable-next-line prefer-template
        return month + (date.length ? '/' + date : '');
    };
    function limit(value, max) {
        let val = value;
        if (val.length === 1 && val[0] > max[0]) {
            // eslint-disable-next-line prefer-template
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                // eslint-disable-next-line spaced-comment
                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }
    function convertDateFormat(str) {
        const date2 = new Date(str);
        const mnth = `0${date2.getMonth() + 1}`.slice(-2);
        const day = `0${date2.getDate()}`.slice(-2);
        return [day, mnth, date2.getFullYear()].join('-');
    }

    const hanldeCategorySelect = (event, { name, value }) => {
        console.log(name, value);
        setSelectedBuisnessType(value);
    };

    const handleSubmit = evt => {
        evt.preventDefault();
        const errors = validateData(formValues);
        setValidationErros(errors);
        const activeTabs = [0];
        if (
            errors.musicName ||
            errors.subDomainName ||
            errors.mobilePhone ||
            errors.businessType ||
            errors.selectedBuisnessType
        ) {
            activeTabs.push(1);
        }
        if (
            errors.fiscalCode ||
            errors.phone ||
            errors.address ||
            errors.streetNumber ||
            errors.postalCode ||
            errors.city
        ) {
            activeTabs.push(2);
        }
        if (errors.type1 || errors.type2) {
            activeTabs.push(6);
        }
        setActiveIndex(activeTabs);

        // props.operatorSignUp(formValues);
        console.log(errors);
        if (Object.keys(errors).length === 0) {
            setIsLoading(true);
            // setShowSignUpSuccesModal(true);
            const payload = {};
            // credentials objects
            const credentials = {};
            credentials.username = formValues.email;
            credentials.encodedPassword = formValues.password;
            // Address object
            const address = {};
            // address.billingName = formValues.companyName;
            address.vatCode = formValues.vatNumber;
            address.fiscalCode = formValues.fiscalCode;
            address.fax = formValues.phone;
            address.phone = formValues.phone;
            address.mobilePhone = formValues.mobilePhone;
            address.address = formValues.address;
            address.streetNumber = formValues.streetNumber;
            address.zip = selectedCity;

            const fiilteredCityObj = initialCityData.find(
                item => item.id === selectedCity,
            );

            address.city = fiilteredCityObj.city;
            // address.province = fiilteredCityObj.province;
            address.province = formValues.province;
            address.email = formValues.billingEmail;
            address.pec = formValues.pec;
            address.destinationCode = formValues.recCode;
            payload.storeName = formValues.musicName;
            payload.subDomainName = formValues.subDomainName;
            address.county = 'Italy';
            address.state = 'Milan';
            address.nation = 'Italy';

            address.latitude = fiilteredCityObj.lat;
            address.longitude = fiilteredCityObj.lng;
            address.name = formValues.companyName;

            // address.latitude = 75.11594;
            // address.longitude = 26.168262;

            // Final payload

            // Basic details

            payload.firstName = formValues.name;
            payload.lastName = formValues.lastName;
            payload.mobilePhone = formValues.mobilePhone;
            if (formValues.dateOfBirth && formValues.dateOfBirth !== "") {
                const newBirthDate = convertDateFormat(formValues.dateOfBirth);
                payload.birthDate = newBirthDate
                    .split('-')
                    .reverse()
                    .join('-');
            } else {
                payload.birthDate = null
            }

            // payload.birthDate = formValues.dateOfBirth
            //   .split('/')
            //   .reverse()
            //   .join('/');
            payload.email = formValues.email;
            payload.credentials = credentials;

            // Business Info
            // Need to pass the fields such as musicName, businessType(shop or mall)
            payload.description = formValues.musicName;
            payload.subDomainName = formValues.subDomainName;
            // payload.type = formValues.businessType.toUpperCase();
            payload.type = 'STORE';
            payload.categoryId = selectedBuisnessType;

            // Billing Address details

            // Fiels need to pass: companyName, vatNumber,fiscalCode ,postalCode,province,billingEmail,pec,recCode
            payload.homePhone = formValues.phone;
            payload.address = address;

            // payload.accept1 = true;
            // payload.accept2 = true;
            payload.accept1 = formValues.type1;
            payload.accept2 = formValues.type2;

            payload.accept3 = formValues.type3;
            payload.accept4 = formValues.type4;

            payload.code = new Date();
            payload.ssnCode = new Date();
            // payload.storeId = '1232';
            payload.roles = '3123';
            payload.image = '';
            payload.attrib1 = 'at1';
            payload.attrib2 = 'at2';
            payload.attrib3 = 'at3';
            payload.attrib4 = 'at4';
            payload.enabled = true;

            payload.language = 'it';

            // Price object
            const price = {};
            price.modules = selectedModules;
            price.duration = duration;
            price.couponCode = confirmedCoupon
            payload.price = price;

            // props.operatorSignUp(payload);
            console.log(payload);
            axios.post(API.operatorSignUp, payload).then(
                res => {
                    setIsLoading(false);
                    if (res.data.responseCode === 0) {
                        openSnackbar('Sign up successfull!!');
                        setShowSignUpSuccesModal(true);
                        setFormValues({
                            name: '',
                            lastName: '',
                            dateOfBirth: '',
                            subDomainName: '',
                            email: '',
                            password: '',
                            confirmPassword: '',
                            musicName: '',
                            businessType: '',
                            businessCategory: '',
                            companyName: '',
                            vatNumber: '',
                            fiscalCode: '',
                            phone: '',
                            mobilePhone: '',
                            address: '',
                            streetNumber: '',
                            postalCode: '',
                            city: '',
                            province: '',
                            billingEmail: '',
                            pec: '',
                            recCode: '',
                            cardHolderName: '',
                            cardNumber: '',
                            expiryDate: '',
                            cvv: '',
                            promoCode: '',
                            showPassword: false,
                            showConfirmPassword: false,
                            selectedBuisnessType: '',
                            type1: false,
                            type2: false,
                            type3: false,
                            type4: false,
                        });
                        setSlectedCity('');
                        setSelectedBuisnessType('');
                        setValidationErros({});
                    } else if (res.data.responseCode === 1) {
                        openSnackbarRed('Email già esistente !');
                    } else if (res.data.responseCode === 4030) {
                        openSnackbarRed('Nome dominio già in uso');
                    }
                    else {
                        openSnackbarRed('Something went wrong !');
                    }
                },
                err => {
                    setIsLoading(false);
                    openSnackbarRed('Something went wrong !');

                    console.log(err);
                },
            );
        }
    };

    const validateData = data => {
        console.log(data);
        // const data = formValues;
        const errors = {};
        // if (!data.name) errors.name = "Name can't be empty";
        if (!data.name) errors.name = t('userSignupValidation.name');

        // if (!data.lastName) errors.lastName = "Last name Can't be empty";
        if (!data.lastName) errors.lastName = t('userSignupValidation.lastName');

        // Age calculater
        if (data.dateOfBirth) {
            // const d = new Date(data.dateOfBirth);
            // const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            // const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
            // const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

            // const dob = new Date(ye, mo, da);
            // const diffMs = Date.now() - dob.getTime();
            // const ageDt = new Date(diffMs);

            // const finalAge = Math.abs(ageDt.getUTCFullYear() - 1970);
            // console.log(finalAge);
            const today = new Date();
            const birthDate = new Date(data.dateOfBirth);
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age -= 1;
            }
            const finalAge = age;

            // if (finalAge < 18) errors.dateOfBirth = 'Age must be greater than 18';
            if (finalAge < 18)
                errors.dateOfBirth = t('userSignupValidation.dobValidate');
        } else {
            // errors.dateOfBirth = 'Age is compulsary';
            // errors.dateOfBirth = t('userSignupValidation.dob');
        }
        // if (!isEmail(data.email)) errors.email = 'Invalid email';
        const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (data.email) {
            if (!regEmail.test(data.email)) {
                errors.email = t('userSignupValidation.emailInvalid');
            }
        } else {
            // errors.email = "Email can't be empty";
            errors.email = t('userSignupValidation.emptyEmail');
        }
        // if (!data.password) errors.password = "Password can't be empty";
        if (!data.password)
            errors.password = t('userSignupValidation.passwordEmpty');

        // Password validation
        if (data.password.length) {
            const paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,12}$/.test(
                data.password,
            );
            if (!paswd) {
                errors.password = t('userSignupValidation.passwordValid');
            }
        }

        // Phone validation
        /*
        ^                           # beginning of line
    (\((00|\+)39\)|(00|\+)39)?  # country code or trunk code, with or without parentheses
    (                           # followed by one of the following
     32[89]|                    # 328 or 329
     33[013-9]|                 # 33x where x != 2
     34[04-9]|                  # 34x where x not in 1,2,3
     35[01]|                    # 350 or 351
     36[068]|                   # 360 or 366 or 368
     37[019]                    # 370 or 371 or 379
     38[089])                   # 380 or 388 or 389
    \d{6,7}                     # ... followed by 6 or 7 digits
    $                           # and end of line
    */
        // if (data.phone.length) {
        //   const phone = /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/.test(
        //     data.phone,
        //   );
        //   if (!phone) {
        //     errors.phone = 'Phone number not valid';
        //   }
        // }

        if (data.confirmPassword !== data.password)
            // errors.confirmPassword = 'Not same as password';
            errors.confirmPassword = t('userSignupValidation.confrmPasswordNotSame');

        // if (!data.companyName) errors.companyName = "Company name can't be empty";
        if (!data.companyName) errors.companyName = t('operatorSignUp.companyName');

        // if (!data.vatNumber) errors.vatNumber = "Vat number can't be empty";
        if (!data.vatNumber) errors.vatNumber = t('operatorSignUp.vatNumber');

        // if (!data.fiscalCode) errors.fiscalCode = "Fiscal code can't be empty";
        if (!data.fiscalCode) errors.fiscalCode = t('operatorSignUp.fiscalCode');

        // if (!data.phone) errors.phone = "Phone can't be empty";
        if (!data.phone) errors.phone = t('operatorSignUp.phone');

        // if (!data.address) errors.address = "address can't be empty";
        if (!data.address) errors.address = t('operatorSignUp.address');

        // if (!data.streetNumber) errors.streetNumber = 'Field is compulsory';
        if (!data.streetNumber)
            errors.streetNumber = t('operatorSignUp.streetNumber');

        // if (!data.postalCode) errors.postalCode = 'Field is compulsory';
        if (!data.postalCode) errors.postalCode = t('operatorSignUp.postalCode');

        // if (!data.city) errors.city = "City can't be empty";
        // if (!data.type3 || !data.type4)
        //   // errors.type = 'These two fields are mandatory';
        //   errors.type = t('operatorSignUp.type');
        if (!data.type1)
            errors.type1 = t('operatorSignUp.type');

        if (!data.type2)
            errors.type2 = t('operatorSignUp.type');
        // if (!data.province) errors.province = 'Field is compulsory';
        if (!data.province) errors.province = t('operatorSignUp.province');

        // if (!data.billingEmail) errors.billingEmail = "Email can't be empty";
        if (data.billingEmail) {
            if (!regEmail.test(data.billingEmail)) {
                // errors.billingEmail = 'Invalid email';
                errors.billingEmail = t('userSignupValidation.emailInvalid');
            }
        } else {
            errors.billingEmail = t('userSignupValidation.emptyEmail');
        }

        // if (!data.musicName) errors.musicName = 'Field is compulsory';
        if (!data.musicName) errors.musicName = t('operatorSignUp.musicName');
        //if (!data.subDomainName) errors.subDomainName = t('operatorSignUp.subDomainName');
        // if (!data.businessType) errors.businessType = 'Please select type';
        /* if (!data.businessType)
           errors.businessType = t('operatorSignUp.businessType'); */

        if (!selectedBuisnessType)
            // errors.selectedBuisnessType = 'Please select category';
            errors.selectedBuisnessType = t('operatorSignUp.selectedBuisnessType');

        // if (!selectedCity) errors.selectedCity = "City can't be empty";
        if (!selectedCity) errors.selectedCity = t('operatorSignUp.selectedCity');

        // if (!data.pec) errors.pec = "Field can't be empty";
        if (!data.pec) errors.pec = t('operatorSignUp.pec');

        // if (!data.recCode) errors.recCode = "Field can't be empty";
        if (!data.recCode) errors.recCode = t('operatorSignUp.recCode');

        // if (!data.cardHolderName) errors.cardHolderName = 'Name is required';
        // if (!data.cardNumber) errors.cardNumber = 'Card number is required';
        // if (!data.expiryDate) errors.expiryDate = 'Date is required';
        // if (!data.cvv) errors.cvv = 'CVV is required';

        // setValidationErros(errors);
        return errors;
    };
    const closeSuccessfulSignUp = () => {
        // setShowSignUpModal(false);
        setShowSignUpSuccesModal(false);
        history.push('/');
    };

    const onSelectPrivacyPoints = e => {
        setFormValues({ ...formValues, [e.target.name]: e.target.checked });
    };

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        // const newIndex = activeIndex === index ? -1 : index;
        const newIndex =
            activeIndex.indexOf(index) === -1
                ? [...activeIndex, index]
                : activeIndex.filter(item => item !== index);

        setActiveIndex(newIndex);
    };
    const iconName = activeIndex.includes(0) ? 'angle down' : 'angle up';
    const iconName1 = activeIndex.includes(1) ? 'angle down' : 'angle up';
    const iconName2 = activeIndex.includes(2) ? 'angle down' : 'angle up';
    const iconName3 = activeIndex.includes(3) ? 'angle down' : 'angle up';
    const iconName4 = activeIndex.includes(4) ? 'angle down' : 'angle up';
    const iconName5 = activeIndex.includes(5) ? 'angle down' : 'angle up';
    const iconName6 = activeIndex.includes(6) ? 'angle down' : 'angle up';

    return (
        <div>
            <Form className="store_registration_form_container" autoComplete="off">
                {' '}
                <div className="store_registration_heading" style={{ padding: "10px" }}>
                    {/* Registra la tua attività */}
                </div>
                <Accordion
                    className="store_register_accordian"
                    exclusive={false}
                    activeIndex={activeIndex}
                >
                    {/* Fee Section */}
                    <div className="fee_details_section accordian_box_style">
                        <Accordion.Title
                            active={activeIndex === 3}
                            index={3}
                            // onClick={handleClick}
                            style={activeIndex.includes(3) ? { boxShadow: 'none' } : null}
                        >
                            {/* <Icon
                                name={iconName3}
                                className="store_register_accordian_icon"
                            /> */}
                            <span className="store_register_accordian_heading">
                                Scelta dei Servizi
                            </span>
                        </Accordion.Title>
                        <Accordion.Content
                            active={activeIndex.includes(3)}
                            style={{ padding: isMobileView ? '15px' : '30px', paddingTop: "unset" }}
                        >
                            <p className="fee_description">
                                Seleziona le funzioni più adatte alle tue esigenze, decidendo anche se preferisci pagare mensilmente, oppure l’intera annualità anticipata ad un prezzo particolarmente scontato. La sottoscrizione di Base è sempre gratuita e ti permette di restare comunque in contatto con i tuoi clienti.
                            </p>
                            <div className="fee-subtitle">Seleziona il pacchetto</div>
                            <div className="plan_container">
                                {fixedModules && fixedModules.map(item => (
                                    <button
                                        type="button"
                                        className="yearly_payment_info monthly_payment_info"
                                        onClick={() => handleBaseModule(item.module)}
                                    >
                                        {item.module === "PREMIUM" &&
                                            <div className="promo">PROMO</div>
                                        }
                                        {item.module === "BASE_MODULE" &&
                                            <div className="promo">GRATUITO</div>
                                        }
                                        <div className={item.module === baseModule
                                            ? 'monthly_payment_info yearly_color'
                                            : 'monthly_payment_info'
                                        }>
                                            <span className="module-title">
                                                {t(`module-${item.module}`)}
                                            </span>
                                            <Popup
                                                title={t(`module-${item.module}`)}
                                                description={item.moduleDescription}
                                                button={t("save")}
                                                trigger={
                                                    <div className="module-description">
                                                        {item.module === "PREMIUM" ?
                                                            "La sottoscrizione Premium comprende..." :
                                                            "il Modulo Base consente di pubblicare le principali informazioni del tuo esercizio commerciale..."
                                                        }
                                                    </div>
                                                }
                                            />
                                            {item.module === "PREMIUM" &&
                                                <>
                                                    <span className="module-fee">
                                                        Costo di attivazione*
                                                    {item.module === "PREMIUM" ?
                                                            <>
                                                                <b className="old_price"> {`${item?.undiscountedActivationFee.toFixed(2)} \u20AC`}</b>
                                                                <b className="new_price"> {`${item?.activationFee.toFixed(2)} \u20AC`} </b>
                                                                <span className="iva"> + iva</span>
                                                            </>
                                                            :
                                                            <>
                                                                <b> {`${item.activationFee.toFixed(2)} \u20AC`} </b>
                                                                <span className="iva"> + iva</span>
                                                            </>
                                                        }
                                                    </span>
                                                    <span className="module-fee">
                                                        Canone**
                                                    {item.module === "PREMIUM" ?
                                                            <>
                                                                <b className="old_price"> {`${item?.undiscountedFee.toFixed(2)} \u20AC`}</b>
                                                                <b className="new_price"> {`${item?.fee.toFixed(2)} \u20AC`} </b> al mese
                                                        <span className="iva"> + iva</span>
                                                            </>
                                                            :
                                                            <>
                                                                <b> {`${item.fee.toFixed(2)} \u20AC`} </b> al mese
                                                        <span className="iva"> + iva</span>
                                                            </>
                                                        }
                                                    </span>
                                                </>
                                            }
                                        </div>
                                    </button>
                                ))}
                            </div>
                            <div className="fee-subtitle">Personalizza il tuo pacchetto</div>
                            <div className="plan_grid">
                                {variableModules && variableModules.map(item => (
                                    <button
                                        type="button"
                                        className="yearly_payment_info monthly_payment_info"
                                        onClick={() => handleModules(item.module)}
                                        disabled={baseModule === "PREMIUM"}
                                    >
                                        {/* <div className="promo">PROMO</div> */}
                                        <div className={(selectedModules.includes(item.module) || baseModule === "PREMIUM")
                                            ? 'monthly_payment_info yearly_color'
                                            : 'monthly_payment_info'
                                        }>
                                            <span className="module-title">
                                                {t(`module-${item.module}`)}
                                            </span>
                                            <Popup
                                                title={t(`module-${item.module}`)}
                                                description={item.moduleDescription}
                                                button={t("save")}
                                                trigger={
                                                    <div className="module-description">
                                                        il {t(`module-${item.module}`)} consente di...
                                                    </div>
                                                }
                                            />
                                            <span className="module-fee">
                                                Costo di attivazione*
                                                <b> {`${item.activationFee.toFixed(2)} \u20AC`} </b>
                                                <span className="iva"> + iva</span>
                                            </span>
                                            <span className="module-fee">
                                                Canone** <b> {`${item.fee.toFixed(2)} \u20AC`} </b> al mese
                                            <span className="iva"> + iva</span>
                                            </span>
                                        </div>
                                    </button>
                                ))}
                            </div>

                            <div className="fee-subtitle">Modalità pagamento canone</div>
                            <div className="plan_container">
                                <button
                                    type="button"
                                    className="yearly_payment_info monthly_payment_info duration-button"
                                    onClick={() => setDuration("MONTH")}
                                >
                                    <div className={duration === "MONTH"
                                        ? 'monthly_payment_info yearly_color'
                                        : 'monthly_payment_info'}>
                                        <span className="module-title">
                                            MENSILE
                                        </span>
                                    </div>
                                </button>
                                <button
                                    type="button"
                                    className="yearly_payment_info monthly_payment_info duration-button"
                                    onClick={() => setDuration("YEAR")}
                                >
                                    <div className="promo">PROMO</div>
                                    <div className={duration === "YEAR"
                                        ? 'monthly_payment_info yearly_color'
                                        : 'monthly_payment_info'}>
                                        <span className="module-title">
                                            ANNUALE
                                    </span>
                                        <span className="module-subtitle">(Due mesi gratis)</span>
                                    </div>
                                </button>
                            </div>
                            <p className="payment_info">
                                *da sostenersi una sola volta, all’attivazione del modulo corrispondente.
                            </p>
                            <p className="payment_info">
                                **il canone mensile prevede comunque una sottoscrizione annuale, che viene scontata in caso di pagamento anticipato.
                            </p>
                            <hr />
                            <span className="store_register_accordian_heading">
                                Totale
                            </span>
                            {/* Total info */}
                            <div className="total-info">
                                <div className="total-info-row">
                                    <div>
                                        <div className="total-info-label">Costo attivazione <small>(Una tantum)</small></div>
                                        <div className="total-info-description">
                                            ({selectedModules?.map((item, index) => (
                                            (selectedModules.length !== index + 1) ?
                                                `${t(`module-${item}`)}, ` : t(`module-${item}`)
                                        ))})
                                        </div>
                                    </div>
                                    <div className="total-info-price">
                                        {`${totalInfo?.activationFee.toFixed(2)} \u20AC`}
                                    </div>
                                </div>
                                <div className="total-info-row">
                                    <div>
                                        <div className="total-info-label">Canone</div>
                                        <div className="total-info-description">
                                            ({`Pagamento ${duration === "YEAR" ? "annuale" : "mensile"}`})
                                    </div>
                                    </div>
                                    <div className="total-info-price">{`${totalInfo?.fee.toFixed(2)} \u20AC`}</div>
                                </div>
                                <div className="total-info-row">
                                    <div className="total-info-label">Iva</div>
                                    <div className="total-info-price">{`${totalInfo?.vat.toFixed(2)} \u20AC`}</div>
                                </div>
                                {totalInfo && totalInfo.couponDiscount &&
                                    <div className="total-info-row">
                                        <div className="total-info-label">Sconto di {`${totalInfo?.couponDiscount.toFixed(2)} \u20AC`} </div>
                                        <div className="total-info-price">{`- ${totalInfo?.couponDiscount.toFixed(2)} \u20AC`}</div>
                                    </div>
                                }
                                <hr />
                                <div className="total-info-row">
                                    <div className="total-info-label" style={{ fontSize: "18px" }}>Totale</div>
                                    <div className="total-info-price" style={{ fontSize: "18px" }}>
                                        {`${totalInfo?.total.toFixed(2)} \u20AC`}
                                    </div>
                                </div>
                            </div>
                            <div className="text-block" style={{ margin: "20px 0" }}>
                                <b>Inizia subito a dare più visibilità al tuo negozio!</b>
                            </div>
                            <div className="text-center">
                                <button className="yellow-button" style={{ marginTop: "unset" }}
                                    onClick={() => {
                                        fbq('trackCustom', 'Conversione', {
                                            content_name: 'Entra_in_MyStores'
                                        });
                                        history.push("/register-store")
                                    }}>
                                    Registrati gratis
                                </button>
                            </div>
                            {/* Coupon */}
                            {/* <div className="checkout-coupons">
                                <div className="checkout-coupons-title">
                                    Applica coupons
                                </div>
                                <div className="checkout-coupons-input">
                                    <form onSubmit={e => {
                                        e.preventDefault();
                                        handleCoupon(couponCode);
                                    }}>
                                        <input
                                            onChange={couponChange} value={couponCode}
                                            placeholder="Inserisci qui il codice coupons"></input>
                                        <button type="submit" className="landing_login_button">
                                            <span>APPLICA</span>
                                        </button>
                                    </form>
                                </div>
                            </div> */}
                        </Accordion.Content>
                    </div>
                </Accordion>
            </Form>
            <EmailVerificationModal
                showSignUpSuccesModal={showSignUpSuccesModal}
                closeSuccessfulSignUp={closeSuccessfulSignUp}
            />
        </div>
    );
};
StoreRegisterForm.propTypes = {
    // operatorSignUp: PropTypes.func,
    operatorSignUpError: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
    return {
        operatorSignUp: payload =>
            dispatch({ type: 'OPERATOR_SIGNNED_UP', payload }),
    };
}
export function mapStateToProps(state) {
    return {
        operatorSignUpError: state.operatorSignUp.operatorSignUpError,
        // favStores: state.homePageFav.favStores,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

const withSaga = injectSaga({ key: 'operatorSignUp', saga, mode: DAEMON });

export default compose(
    withConnect,
    memo,
    withSaga,
)(StoreRegisterForm);
