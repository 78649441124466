import React from "react";
import { withRouter } from "react-router";
import NavBar from "../NavBar/NavBar";
import Footer from "../footer/Footer";
import "./LayoutComponent.scss";

function LayoutComponent(props) {
  return (
    <div className="main_container">
      {/*  Don't add default navbar for home page*/}
      {props.location.pathname === "/" ||
        props.location.pathname === "/merchant" ||
        props.location.pathname === "/migliora-il-tuo-business" ||
        props.location.pathname === "/thank-you-page" ||
        props.location.pathname === "/pricing" ||
        props.location.pathname.includes("register-store") ||
        props.location.pathname.includes("verify-email") ? null : (
        // <div className="common_navbar">
        <NavBar
          showFullNav={
            !props.location.pathname.includes(`home`) &&
            !props.location.pathname.includes(`promo`) &&
            !props.location.pathname.includes(`fidelity`)
          }
        />
        // </div>
      )}

      <div className="page_content">{props.children}</div>

      {/* Don't add footer for map view */}

      {!props.location.pathname.includes("map-view") &&
        !props.location.pathname.includes("fav-map") &&
        props.location.pathname !== "/pricing" && (
          <div className="main_footer">
            <Footer />
          </div>
        )}
    </div>
  );
}

export default withRouter(LayoutComponent);
