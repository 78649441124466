/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { booking } from "../../Helpers/api";
import { userDetails } from "../../Helpers/UserDetails";
import moment from 'moment'
import 'moment/locale/it'
moment.locale('it')

import "./reservationsPage.scss";
import ConfirmPopup from "../../components/ConfirmPopup/ConfirmPopup";

function ReservationsPage(props) {
    const [reservations, setReservations] = useState([]);
    const [selectedReservation, setSelectedReservation] = useState(null);
    const [bookingStatus, setBookingStatus] = useState("")

    useEffect(() => {
        const url = `${booking}?fromToday=true&bookingStatus=${bookingStatus}&s=999&sort=date`;
        axios(url, {
            method: "GET",
            auth: {
                username: userDetails().email,
                password: userDetails().password,
            },
        })
            .then((res) => {
                setReservations(res.data.data);
                if (res.data.data && res.data.data.length > 0) {
                    setSelectedReservation(res.data.data[0])
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [bookingStatus]);

    const abortReservation = (reservationId) => {
        axios(`${booking}/${reservationId}/abort`, {
            method: "GET",
            auth: {
                username: userDetails().email,
                password: userDetails().password,
            },
        })
            .then((res) => {
                setSelectedReservation(res.data.data);
                const url = `${booking}?fromToday=true&bookingStatus=${bookingStatus}&s=999&sort=date`;
                axios(url, {
                    method: "GET",
                    auth: {
                        username: userDetails().email,
                        password: userDetails().password,
                    },
                })
                    .then((res) => {
                        setReservations(res.data.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const { t } = useTranslation();
    return (
        <div>
            <header className="reservations_header">
                <div className="reservations_header_text">I tuoi appuntamenti</div>
            </header>
            <div className="reservations">
                <div className="reservations-card">
                    <div className="reservations-card-header">
                        <div className="title">Appuntamenti</div>
                        <div className="filters">
                            <span className={`${bookingStatus === "" ? "selected" : ""}`} onClick={() => setBookingStatus("")}>
                                Tutti
                            </span>
                            <span className={`${bookingStatus === "ACCEPTED" ? "selected" : ""}`} onClick={() => setBookingStatus("ACCEPTED")}>
                                Confermati
                            </span>
                            <span className={`${bookingStatus === "REFUSED" ? "selected" : ""}`} onClick={() => setBookingStatus("REFUSED")}>
                                Rifiutati
                            </span>
                            <span className={`${bookingStatus === "PENDING" ? "selected" : ""}`} onClick={() => setBookingStatus("PENDING")}>
                                In attesa
                            </span>
                            <span className={`${bookingStatus === "ABORTED" ? "selected" : ""}`} onClick={() => setBookingStatus("ABORTED")}>
                                Annullati
                            </span>
                        </div>
                        <hr />
                    </div>
                    <div className="reservations-list">
                        {reservations && reservations.map(item => (
                            <div key={item.id} className="element"
                                onClick={() => setSelectedReservation(item)}
                                style={item.id === selectedReservation?.id ? { backgroundColor: "#ffffff" } : null}>
                                <div className="date">
                                    {moment(item?.date).format("LL")} - {moment(item?.date).format("LT")}
                                </div>
                                <div className="storeName">Nome attività</div>
                                <span className={`booking-status-${item.bookingStatus}`}>
                                    {t(`booking-status-${item.bookingStatus}`)}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                {selectedReservation ?
                    <div className="reservations-section">
                        <div className="reservations-section-name">
                            <div>
                                <span className="reservations-section-label">Nome attività:</span>
                                <span className="reservations-section-text"> {selectedReservation?.storeDescription}</span>
                            </div>
                            <span className={`booking-status-${selectedReservation?.bookingStatus}`}>
                                {t(`booking-status-${selectedReservation?.bookingStatus}`)}
                            </span>
                        </div>
                        <div className="reservations-section-info">
                            <span className="reservations-section-label">Giorno e ora di prenotazione: </span>
                            <span className="reservations-section-text">
                                {moment(selectedReservation?.date).format("LL")} - {moment(selectedReservation?.date).format("LT")}
                            </span>
                        </div>
                        {selectedReservation && selectedReservation.personnelName &&
                            <div className="reservations-section-info">
                                <span className="reservations-section-label">Operatore scelto:</span>
                                <span className="reservations-section-text"> {selectedReservation.personnelName}</span>
                            </div>
                        }
                        {selectedReservation && selectedReservation.activitiesDescriptionStringJoin &&
                            <div className="reservations-section-info">
                                <span className="reservations-section-label">Servizio:</span>
                                <span className="reservations-section-text"> {selectedReservation?.activitiesDescriptionStringJoin}</span>
                            </div>
                        }
                        {selectedReservation && selectedReservation.consumerNote &&
                            <div className="reservations-section-info">
                                <div className="reservations-section-text" style={{ marginBottom: "5px" }}>Le tue note</div>
                                <span className="reservations-section-label">
                                    {selectedReservation?.consumerNote}
                                </span>
                            </div>
                        }
                        {selectedReservation && selectedReservation.merchantNote &&
                            <div className="reservations-section-info">
                                <div className="reservations-section-text" style={{ marginBottom: "5px" }}>Note dell'esercente</div>
                                <span className="reservations-section-label">
                                    {selectedReservation?.merchantNote}
                                </span>
                            </div>
                        }
                        {selectedReservation &&
                            (selectedReservation.bookingStatus === "PENDING" ||
                                selectedReservation.bookingStatus === "ACCEPTED") &&
                            <div className="reservations-button-content">
                                <ConfirmPopup
                                    action={() => abortReservation(selectedReservation?.id)}
                                    title="Annulla prenotazione"
                                    content="Sei sicuro di voler annullare la tua prenotazione?"
                                    button="Conferma"
                                    trigger={
                                        <button class="reservations-button">
                                            <span>Annulla prenotazione</span>
                                        </button>
                                    }
                                />
                            </div>
                        }
                    </div>
                    :
                    <div className="reservations-section">
                        <span className="reservations-section-text">
                            Nessuna prenotazione effettuata
                        </span>
                    </div>
                }
            </div>
        </div>
    );
}

export default ReservationsPage;
