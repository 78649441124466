/* eslint-disable operator-assignment */
/* eslint-disable prefer-template */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import "./ShopDetailModal.scss";
import axios from "axios";
import { promoModalSubmit } from "../../../assets/images";
import { userDetails } from "../../../Helpers/UserDetails";
import history from "../../../utils/history";
import {
  allPointsTransInShop,
  getBonusWithShopAndUser,
  completedBonus,
} from "../../../Helpers/api";
// import { userDetails } from '../../Helpers/UserDetails';

function ShopDetailModal(props) {
  const [shopBasicDetail, setShopBasicDetail] = useState({});
  const [shopBonus, setShopBonus] = useState([]);
  // const [shopTotalPoints, setShopTotalPoints] = useState(0);
  const [fidelityShopDetails, setFidelityShopDetails] = useState([]);

  const formBonusData = (res) => {
    const userBonusArray = [];
    const finalBonusArray = [];
    if (res && res.data && res.data.length) {
      res.data.forEach((item) => {
        const bonusObj = {};
        if (item.bonus && item.userbonus) {
          bonusObj.id = item.userbonus.bonus_id;
          bonusObj.name = item.bonus.name;
          bonusObj.description = item.bonus.description;
          bonusObj.no_of_steps = item.bonus.no_of_steps;
          bonusObj.current_step = item.userbonus.current_step;
          userBonusArray.push(bonusObj);
        }
      });
      const userBonusIds = [];
      if (userBonusArray && userBonusArray.length) {
        userBonusArray.forEach((item) => {
          if (!userBonusIds.includes(item.id)) {
            userBonusIds.push(item.id);
          }
        });
      }
      if (userBonusIds && userBonusIds.length) {
        userBonusIds.forEach((bonusId) => {
          const prevData = {
            current_step: "0",
          };
          userBonusArray.forEach((item) => {
            let bonusObj = {};
            if (
              item.id === bonusId &&
              parseInt(item.current_step, 10) >
                parseInt(prevData.current_step, 10)
            ) {
              bonusObj = item;
              prevData.id = item.id;
              prevData.current_step = item.current_step;
              finalBonusArray.push(bonusObj);
            }
          });
        });
      }
    }
    return finalBonusArray;
  };

  useEffect(() => {
    const url = `${allPointsTransInShop}?userId=${
      userDetails().userId
    }&shopId=${props.shopData.owner_entity_id}`;
    axios(url, {
      method: "GET",
      auth: {
        username: userDetails().email,
        password: userDetails().password,
      },
      // data: payload,
    })
      .then((res) => {
        setFidelityShopDetails(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    const completedBonusUrl = `${completedBonus}?userid=${
      userDetails().userId
    }&shopid=${props.shopData.owner_entity_id}`;

    const bonusUrl = `${getBonusWithShopAndUser}?userid=${
      userDetails().userId
    }&shopid=${props.shopData.owner_entity_id}`;
    const promise1 = axios.get(completedBonusUrl, {
      auth: {
        username: userDetails().email,
        password: userDetails().password,
      },
    });

    const promise2 = axios.get(bonusUrl, {
      auth: {
        username: userDetails().email,
        password: userDetails().password,
      },
    });
    Promise.all([promise1, promise2]).then((values) => {
      let bonusArray = [];
      if (values && values[1]) {
        bonusArray = formBonusData(values[1]);
      }
      const completedBonusArray = [];
      if (values && values[0]) {
        values[0].data.forEach((item) => {
          const obj = Object.assign({}, item, { ...item.bonus });
          completedBonusArray.push(obj);
        });
      }
      setShopBonus(bonusArray.concat(completedBonusArray));
    });
  }, [props.shopData.owner_entity_id]);

  useEffect(() => {
    setShopBasicDetail({
      name: props.shopData.shopName,
      id: props.shopData.shop_id,
      remainingPoints: props.shopData.points_remaining
        ? props.shopData.points_remaining.toFixed(0)
        : 0,
      cashback: props.shopData.points_remaining
        ? props.shopData.points_remaining.toFixed(0) / 10
        : 0,
    });
  }, [props.shopData]);

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return {
      dt,
      month,
      year,
    };
  };

  return (
    <Modal
      style={{
        // width: '786px',
        // height: '524px',
        borderRadius: "8px",
        boxShadow: "0 0 34px 0 rgba(0, 0, 0, 0.5)",
        backgroundColor: "#ffffff",
      }}
      className="shop_detail_modal"
      closeIcon={{
        style: { top: "8px", right: "0", fontSize: "10px" },
        color: "black",
        name: "close",
      }}
      open={props.showShopDetailModal}
      onClose={() => props.closeShopDetailModal(false)}
    >
      {shopBasicDetail && (
        <Modal.Content style={{ textAlign: "center" }}>
          <div className="shop_modal_header">
            <div className="header_text">{shopBasicDetail.name}</div>
            <button
              className="mall_view_go_to_site fidelity_modal"
              type="button"
              id="button-id"
              onClick={() => history.push(`/shopDetails/${shopBasicDetail.id}`)}
            >
              VAI AL NEGOZIO
            </button>
            {/* <button
              type="button"
              className="go_to_shop_fidlity"
              onClick={() => history.push(`/shopDetails/${shopBasicDetail.id}`)}
            >
              <img src={promoModalSubmit} alt="" />
            </button> */}
          </div>
          <div className="shop_detail_column_container">
            <div className="bonus_facility_column">
              <div className="bonus_section">
                <p className="bonus_heading">I TUOI BONUS</p>
                <div className="bonus_points" style={{ marginRight: "10px" }}>
                  {" "}
                  <p className="bonus_points_number">
                    {shopBasicDetail.remainingPoints} {/* {shopTotalPoints} */}
                    <span className="bonus_pt">pt</span>
                    <span className="points_text">PUNTI</span>
                  </p>
                </div>
                <div className="equal_circle">
                  <span className="equal_operator">=</span>
                </div>
                <div className="bonus_points">
                  <p className="bonus_points_number">
                    {shopBasicDetail.cashback} {/* {shopTotalPoints / 10} */}
                    <span className="bonus_pt">€</span>
                    <span className="cashback_text">CASHBACK</span>
                  </p>
                </div>
              </div>
              {shopBonus && shopBonus.length > 0 && (
                <div className="bonus_cards_main_container">
                  {shopBonus.map((bonus) => (
                    <div className="bonus_bar_container">
                      <p className="bonus_bar_heading">{bonus.name}</p>
                      <p className="bonus_bar_content">{bonus.description}</p>
                      <div className="progress_line_container">
                        {[...Array(parseInt(bonus.no_of_steps, 10))].map(
                          (e, i) => (
                            <div
                              style={{
                                display: "inline-block",
                                position: "relative",
                              }}
                            >
                              <div
                                style={
                                  i < parseInt(bonus.current_step, 10)
                                    ? {
                                        backgroundImage:
                                          "linear-gradient(66deg, #3b58e0 0%, #6bb5fc 100%)",
                                      }
                                    : null
                                }
                                className={
                                  i === parseInt(bonus.no_of_steps, 10) - 1
                                    ? "progress1circle lastcircle"
                                    : "progress1circle"
                                }
                              >
                                <span
                                  style={
                                    i === parseInt(bonus.no_of_steps, 10) - 1
                                      ? {
                                          position: "absolute",
                                          top: "29px",
                                          left: "-8px",
                                          width: "46px",
                                        }
                                      : { position: "absolute", top: "20px" }
                                  }
                                >
                                  {i !== parseInt(bonus.no_of_steps, 10) - 1
                                    ? i + 1
                                    : "Bonus"}
                                </span>
                              </div>
                              {i !== parseInt(bonus.no_of_steps, 10) - 1 ? (
                                <div
                                  className="progress1line"
                                  style={
                                    i <
                                    parseInt(
                                      bonus.current_step - 1,
                                      parseInt(bonus.no_of_steps, 10)
                                    )
                                      ? {
                                          backgroundImage:
                                            "linear-gradient(66deg, #3b58e0 0%, #6bb5fc 100%)",
                                        }
                                      : null
                                  }
                                />
                              ) : null}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="history_transactional_column">
              <div className="transaction_header_container">
                <p className="history_transaction_text">STORICO TRANSAZIONI</p>
                <div
                  className="transaction_header"
                  style={{ paddingRight: "35px" }}
                >
                  <span className="transaction_date_header header_data_flex_size">
                    DATA
                  </span>
                  <span className="transaction_date_header header_price_flex_size">
                    SPESA
                  </span>
                  <span className="transaction_date_header header_points_flex_size">
                    SALDO
                  </span>
                </div>
              </div>
              <div
                style={{ maxHeight: "360px", overflowY: "scroll" }}
                className="transaction_data_container"
              >
                {fidelityShopDetails.map((shop, i) => (
                  <div
                    className="transaction_data"
                    // eslint-disable-next-line react/no-array-index-key
                    key={shop.id}
                    style={
                      (i + 1) % 2 === 0
                        ? {
                            backgroundColor: "#ffffff",
                            width: "100%",
                            padding: "16px 20px",
                          }
                        : { width: "100%", padding: "16px 20px" }
                    }
                  >
                    <span className="transaction_data_date">{`${
                      dateFormat(shop.updated).dt
                    }/${dateFormat(shop.updated).month}/${
                      dateFormat(shop.updated).year
                    }`}</span>
                    {shop.mallId && shop.mallId !== "" ? (
                      <span className="transaction_data_price">CC</span>
                    ) : (
                      <span className="transaction_data_price">
                        {shop.points_type === "earned"
                          ? shop.net_cart_amount.toFixed(0)
                          : +shop.net_cart_amount - +shop.points / 10}{" "}
                        €
                      </span>
                    )}
                    {/* <span className="transaction_data_price">
                      {shop.points_type === 'earned'
                        ? shop.net_cart_amount.toFixed(0)
                        : +shop.net_cart_amount - +shop.points / 10}{' '}
                      €
                    </span> */}
                    <span
                      style={{
                        color: shop.points_type !== "earned" ? "red" : "",
                      }}
                      className="transaction_data_points transaction_data_points_new"
                    >
                      {shop.points_type !== "earned" && "-"}{" "}
                      {shop.points.toFixed(0)} pt
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Content>
      )}
    </Modal>
  );
}
ShopDetailModal.propTypes = {
  showShopDetailModal: PropTypes.bool,
  closeShopDetailModal: PropTypes.func,
  shopData: PropTypes.object,
};

export default ShopDetailModal;
