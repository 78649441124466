import React, { memo, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { connect } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { useMediaQuery } from "react-responsive";
import { compose } from "redux";
import { Dropdown } from "semantic-ui-react";
import injectSaga from "../../utils/injectSaga";
import { DAEMON } from "../../utils/constants";
import saga from "../Homepage/saga";
import { baseUrl } from "../../Helpers/api";
import axios from "axios";
import CityDropdown from "../../common/CityDropdown";

import {
  consumerGif,
  searchImg,
  offerte,
  card,
  attivita,
  appleIcon,
  androidIcon,
} from "../../assets/images";

import "./new-landing-page.styles.scss";

import LoginForm from "../../components/Login/Login";
import SignupForm from "../../components/SignupForm";
import EmailVerificationModal from "../../components/LandingPage/EmailVerificationModal";
import RegistrationModal from "./RegistrationModal/RegistrationModal";
import history from "../../utils/history";
import ResetPasswordModal from "./ResetPasswordModal/ResetPasswordModal";
import LandingNavbar from "./LandingNavbar/LandingNavbar";
import LandingGif from "./LandingGif/LandingGif";
import LandingCarousel from "./LandingCarousel/LandingCarousel";
import LandingWriteUs from "./LandingWriteUs/LandingWriteUs";
import LandingNewsLetter from "./LandingNewsLetter/LandingNewsLetter";
import EmailConformModal from "../../components/EmailConfirmModal/EmailConformModal";

export function LandingPage(props) {
  const fbq = window.fbq;
  const [showLoginModal, setShowLoginModal] = useState(false);
  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });
  const isTabletView = useMediaQuery({ query: "(max-width: 1024px)" });

  const [showMailConformModal, setShowMailConformModal] = useState(false);
  const [isMailConfirmed, setIsMailConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showSignUpSuccesModal, setShowSignUpSuccesModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showResetPassordModal, setShowResetPassordModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedCity, setSlectedCity] = useState({ value: "", label: "" });
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [openSnackbar] = useSnackbar({
    style: {
      position: "absolute",
      backgroundColor: "green",
      border: "2px solid lightgreen",
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });
  const [openSnackbarRed] = useSnackbar({
    style: {
      position: "absolute",
      backgroundColor: "red",
      border: "2px solid lightgreen",
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
      bottom: "100%",
      left: 0,
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    props.getCities();
  }, []);

  useEffect(() => {
    if (props.allCities && props.allCities.length) {
      //   const cityArray = [];
      //   props.allCities.data.data.sort((a, b) => a.id - b.id);
      //   props.allCities.data.data.forEach((item) => {
      //     cityArray.push({
      //       key: item.id,
      //       value: item.id,
      //       text: `${item.city} [${item.id}]`,
      //     });
      //   });
      setCitiesOptions(props.allCities);
    }
  }, [props.allCities]);

  useEffect(() => {
    if (!_.isEmpty(props.match.params)) {
      setShowMailConformModal(true);
    } else {
      setShowMailConformModal(false);
    }
    const ConfirmApiUrl = `${baseUrl}/cli/profiles/${props.match.params.profileId}/otp/${props.match.params.otpId}`;
    axios.post(ConfirmApiUrl).then(
      (result) => {
        setIsLoading(false);
        setIsMailConfirmed(true);
        console.log(result);
      },
      (err) => {
        setIsMailConfirmed(false);
        setIsLoading(false);
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    // Update the document title using the browser API
    document.addEventListener("scroll", () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 1 && !isScrolled) {
        setIsScrolled(true);
      } else if (isScrolled && scrolled < 1) {
        setIsScrolled(false);
      }
    });
    return () => {
      document.removeEventListener("scroll", () => { });
    };
  });

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };
  const closeSignUpModal = () => {
    setShowSignUpModal(false);
  };
  const onSuccessfulSignUp = () => {
    setShowSignUpModal(false);
    setShowSignUpSuccesModal(true);
  };
  const closeSuccessfulSignUp = () => {
    setShowSignUpModal(false);
    setShowSignUpSuccesModal(false);
  };

  return (
    <div className="new-landing">
      <LandingNavbar
        setShowRegistrationModal={setShowRegistrationModal}
        setShowLoginModal={setShowLoginModal}
      />
      <LandingGif title="Entra nei tuoi negozi preferiti comodamente da casa tua.">
        <img src={consumerGif} alt="" />
      </LandingGif>
      <div className="new-landing-block1">
        <div className="text-block">
          Con My-Stores hai la comodità di entrare nei{" "}
          <b>negozi del tuo quartiere</b> e fare acquisti comodamente da casa
          tua.
        </div>
      </div>
      <div className="new-landing-block2">
        <div className="text-block">
          <span className="pink">
            <b>Scarica l'app My-Stores</b> e costruisci il tuo centro
            commerciale personalizzato
          </span>{" "}
          per ricevere offerte dai tuoi negozi preferiti, prenotare servizi o
          fare la spesa con un semplice tap.
        </div>
        {isMobileView && (
          <div className="app-download">
            <a
              href="https://apps.apple.com/it/app/my-stores/id1490231492"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={appleIcon}
                    alt="apple-icon"
                    style={{ margin: "0 5px 3px 0" }}
                  />
                  <div style={{}}>App Store</div>
                </div>
              </button>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.retapps.mystores"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={androidIcon}
                    alt="android-icon"
                    style={{ margin: "0 5px 3px 0" }}
                  />
                  <div style={{}}>Play Store</div>
                </div>
              </button>
            </a>
          </div>
        )}
      </div>
      <div className="new-landing-search" id="store_section_id">
        <div className="grid1">
          <img src={searchImg} alt="" />
        </div>
        <div className="grid2 d-flex">
          <div className="text-title pink">
            {isTabletView ? (
              <span>Cerca i negozi della tua zona</span>
            ) : (
              <nobr>Cerca i negozi della tua zona</nobr>
            )}
          </div>
          <div className="subtext">
            Scopri i prodotti e i servizi dei negozianti della tua zona e
            approfitta delle offerte.
            <br />
            <b>
              Salva i tuoi preferiti e personalizza il tuo centro commerciale
              virtuale!
            </b>
          </div>
          <div className="search-container d-tablet">
            {/* <Dropdown
                            placeholder={isTabletView ? "Città" : "Inserisci il nome della città"}
                            fluid
                            search
                            selection
                            clearable
                            className=""
                            value={selectedCity}
                            options={citiesOptions}
                            onChange={(e, { value }) => {
                                setSlectedCity(value);
                            }}
                        /> */}
            <CityDropdown
              cities={citiesOptions}
              placeholder={
                isTabletView ? "Città" : "Inserisci il nome della città"
              }
              selectedOption={selectedCity.value ? selectedCity : null}
              onClearCity={() => {
                setSlectedCity({ value: "", label: "" });
              }}
              handleChange={(selectedOption) => {
                setSlectedCity(selectedOption);
              }}
            />
            <button
              type="button"
              className="landing_page_city_button"
              disabled={selectedCity.value === ""}
              onClick={() => {
                if (selectedCity && selectedCity.value) {
                  history.push("/home");
                  localStorage.setItem("zipCode", selectedCity.value);
                  localStorage.setItem(
                    "homeCity",
                    JSON.stringify({
                      zipCode: selectedCity.value,
                      zipRange: selectedCity.zipRange,
                      istat: selectedCity.istat,
                    })
                  );
                }
              }}
            >
              CERCA
            </button>
          </div>
          {isMobileView && (
            <div className="app-download">
              <a
                href="https://apps.apple.com/it/app/my-stores/id1490231492"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={appleIcon}
                      alt="apple-icon"
                      style={{ margin: "0 5px 3px 0" }}
                    />
                    <div style={{}}>App Store</div>
                  </div>
                </button>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.retapps.mystores"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={androidIcon}
                      alt="android-icon"
                      style={{ margin: "0 5px 3px 0" }}
                    />
                    <div style={{}}>Play Store</div>
                  </div>
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="landing-content bg-yellow reverse" id="offer_section_id">
        <div className="content1 d-flex">
          <div className="text-title pink">Le offerte da non perdere</div>
          <div className="subtext">
            <b>
              Con My-Stores ricevi tutte le offerte dei tuoi negozi preferiti:
            </b>{" "}
            non fartele scappare!
          </div>
          <button
            className="yellow-button d-web"
            onClick={() => setShowRegistrationModal(true)}
          >
            Registrati
          </button>
        </div>
        <div className="content2">
          <img src={offerte} alt="" style={{ width: "80%" }} />
        </div>
      </div>
      <div className="landing-content bg-lightblue" id="card_section_id">
        <div className="content1">
          <img src={card} alt="" />
        </div>
        <div className="content2">
          <div className="text-title pink" style={{ marginBottom: "30px" }}>
            Una carta fedeltà unica per tutti i negozi
          </div>
          <div className="subtext">
            Grazie a My-Stores avrai una carta fedeltà unica valida in tutti i
            negozi:
            <br />
            <b>
              accumuli punti e guadagni vantaggi in modo più semplice e veloce!
            </b>
          </div>
        </div>
        {/* <div className="content2" style={{ marginTop: "30px" }}>
                    <button className="pink-button d-mobile">
                        Scarica l'<b>APP</b>
                    </button>
                </div> */}
      </div>
      <LandingCarousel
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
      <div className="landing-content bg-pink">
        <div className="content1 d-flex" style={{ height: "70%" }}>
          <div className="text-title pink">Hai un'attività commerciale?</div>
          <div className="subtext">
            <b>Scopri tutti i vantaggi di entrare a far parte di My-Stores</b>
          </div>
          <button
            className="yellow-button d-web"
            onClick={() => {
              fbq('trackCustom', 'Conversione', {
                content_name: 'Come_Funziona'
              });
              history.push("/merchant")
            }}
          >
            Come funziona
          </button>
        </div>
        <div className="content2">
          <img src={attivita} alt="" />
        </div>
      </div>
      <LandingWriteUs />
      <LandingNewsLetter
        openSnackbar={openSnackbar}
        openSnackbarRed={openSnackbarRed}
      />
      <LoginForm
        showLoginModal={showLoginModal}
        closeModal={closeLoginModal}
        setShowResetPassordModal={setShowResetPassordModal}
        setShowLoginModal={setShowLoginModal}
      />
      <SignupForm
        showSignUpModal={showSignUpModal}
        closeModal={closeSignUpModal}
        onSuccessfulSignUp={onSuccessfulSignUp}
      />
      <EmailVerificationModal
        showSignUpSuccesModal={showSignUpSuccesModal}
        closeSuccessfulSignUp={closeSuccessfulSignUp}
      />
      <RegistrationModal
        showRegistrationModal={showRegistrationModal}
        setShowRegistrationModal={setShowRegistrationModal}
        closeSuccessfulSignUp={closeSuccessfulSignUp}
        setShowSignUpModal={setShowSignUpModal}
      />
      <ResetPasswordModal
        showResetPassordModal={showResetPassordModal}
        setShowResetPassordModal={setShowResetPassordModal}
      />
      <EmailConformModal
        showMailConformModal={showMailConformModal}
        setShowMailConformModal={setShowMailConformModal}
        closeSuccessfulSignUp={closeSuccessfulSignUp}
        isMailConfirmed={isMailConfirmed}
        isLoading={isLoading}
      />
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    allCities: state.homePageFav.cities,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCities: (payload) => dispatch({ type: "GET_CITIES", payload }),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: "cities", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(LandingPage);
