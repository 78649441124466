import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  heartCard,
  heartFilled,
  locator,
  shoppingCentre,
  store6,
} from "../../assets/images";
import history from "../../utils/history";
import { shopGallaryResource } from "../../Helpers/api";
import "./StoreCard.scss";
import NewLoginPopup from "../../components/Login/NewLoginPopup";

function StoreCard(props) {
  const { t } = useTranslation();

  const {
    shop,
    isCreateLoding,
    type,
    deleteFavStore,
    craeteFavStore,
    isCreateFav,
    openSnackbar,
  } = props;

  const [loginModal, setloginModal] = useState(false);

  const closeLoginModal = () => setloginModal(false);

  return (
    <div className="store-card">
      {loginModal && (
        <NewLoginPopup showLoginModal={true} closeModal={closeLoginModal} />
      )}
      <div className="card-heart-container-new">
        <button
          className="heart-button-new"
          onClick={() =>
            isCreateFav
              ? deleteFavStore &&
                !isCreateLoding &&
                deleteFavStore(shop.id, type)
              : localStorage.getItem("token")
              ? craeteFavStore &&
                !isCreateLoding &&
                craeteFavStore(shop.id, type)
              : setloginModal(true)
          }
        >
          <img src={isCreateFav ? heartFilled : heartCard} alt="heart" />
        </button>
      </div>
      <div className="store-card-container">
        <div
          style={{
            backgroundImage: `url(${shop.imageUrl}),url(${
              type === "mall" ? shoppingCentre : store6
            })`,
          }}
          className="store-image"
          onClick={() => {
            if (type === "mall") {
              history.push(`/mallDetails/${shop.id}`);
            } else if (type === "store") {
              history.push(`/shopDetails/${shop.id}`);
            }
          }}
        ></div>
        <div className="card_tag_container">
          {shop.tags &&
            shop.tags.length &&
            shop.tags
              .split(",")
              .map((tag) => (tag ? `#${tag.trimStart()} ` : ""))}
        </div>
        <div
          className="name-description"
          onClick={() => {
            if (type === "mall") {
              history.push(`/mallDetails/${shop.id}`);
            } else if (type === "store") {
              history.push(`/shopDetails/${shop.id}`);
            }
          }}
        >
          <span>{shop.name}</span>
        </div>
        {/* <div className="shop_card_locator">
          <img src={locator} alt="locator" />
        </div> */}
        <div className="address-description">
          <img src={locator} alt="locator" />
          {shop.address && (
            <div>
              <div>{shop.address.address}</div>
              <div>
                {`${shop.address.zip} ${shop.address.city} (${shop.address.county})`}
              </div>
            </div>
            // <span>
            //   {`${shop.address.address}, ${shop.address.zip},${shop.address.city}, (${shop.address.county})`}
            // </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default StoreCard;
