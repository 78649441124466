import React, { useState } from "react";
import { Button, Input, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { createNewsLetter } from "../../../Helpers/api";

function LandingNewsLetter(props) {
    const { t } = useTranslation();
    const fbq = window.fbq;
    const [newLetterEmail, setNewLetterEmail] = useState("");
    const [newsLetterError, setNewsLetterError] = useState({});
    const [isNewsLoading, setIsNewsLoading] = useState(false);
    const [isNewsChecked, setIsNewsChecked] = useState(false);

    const { openSnackbar, openSnackbarRed } = props;

    const submitNewsEmail = () => {
        fbq('trackCustom', 'Conversione', {
            content_name: 'Iscriviti_Newsletter'
        });
        const errors = validateFormData();
        if (Object.keys(errors).length === 0) {
            setIsNewsLoading(true);
            axios.post(createNewsLetter, { email: newLetterEmail }).then(
                (res) => {
                    console.log(res);
                    setIsNewsLoading(false);
                    console.log(res.data, res.data.responseCode);
                    if (res.data.responseCode === 0) {
                        openSnackbar(t("thankYou"));
                        setNewLetterEmail("");
                    }
                    if (res.data.responseCode === 1) {
                        openSnackbarRed(t("userSignupValidation.emailExist"));
                    }
                },
                (err) => {
                    openSnackbarRed(t("somethingWentWrong"));
                    setIsNewsLoading(false);
                    console.log(err);
                }
            );
        }
    };

    const validateFormData = () => {
        const error = {};
        const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (newLetterEmail) {
            if (!regEmail.test(newLetterEmail)) {
                error.email = t("userSignupValidation.emailInvalid");
            }
        } else {
            error.email = t("userSignupValidation.emptyEmail");
        }
        if (!isNewsChecked) {
            error.checkbox = t("userSignupValidation.checkbox");
        }
        console.log(error)
        setNewsLetterError(error);
        return error;
    };

    return (
        <div className="news_letter_container">
            <div className="news_letter">
                <div className="news_letter_heading">
                    Rimaniamo in contatto!
                </div>
                <div className="subtext">
                    Iscriviti alla nostra news letter per rimanere aggiornato su tutte le ultime novità: <br />
                    <b>ti manderemo solo pochi aggiornamenti e nessuno spam, promesso!</b>
                </div>
                <div className="news_leter_input">
                    <Input
                        placeholder="Inserisci qui la tua mail"
                        value={newLetterEmail}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                            setNewLetterEmail(e.target.value);
                        }}
                    />
                    {newsLetterError && newsLetterError.email && (
                        <span className="ladingFormError ladingFormNewsError">
                            {newsLetterError.email}
                        </span>
                    )}
                </div>
                <div className="checkbox_points news_checkbox_content_landing">
                    <input
                        type="checkbox"
                        id="type1"
                        name="type1"
                        value="type1"
                        className="checkbox_tick"
                        style={{ width: "40px" }}
                        checked={isNewsChecked}
                        onChange={e => setIsNewsChecked(e.target.checked)}
                    // defaultChecked
                    />
                    <label htmlFor="type1" className="news_checkbox_content">
                        {" "}
                        Dichiaro di aver preso visione dell’Informativa ai sensi dell’art 13 del Regolamento UE 2016/679
                        sulla protezione dei dati personali (GDPR) e ne accetto i contenuti.
                    </label>
                    {newsLetterError && newsLetterError.checkbox && (
                        <span className="ladingFormError ladingFormNewsError-checkbox">
                            {newsLetterError.checkbox}
                        </span>
                    )}
                </div>
                <button className="pink-button"
                    onClick={submitNewsEmail}
                >
                    {isNewsLoading ? (
                        <Loader
                            active
                            inline
                            className="login_loader ladingFormLoader"
                        />
                    ) : (
                        "ISCRIVITI"
                    )}
                </button>
            </div>
        </div>
    );
}

export default LandingNewsLetter;



