import React from "react";

import "./landing-gif.styles.scss";

function LandingGif(props) {
    return (
        <div className="new-landing-gif" id="landing-intro">
            <div className="title" style={{ ...props.style }}>
                {props.title}
            </div>
            {props.children}
        </div>
    );
}

export default LandingGif;
