import React from "react";
import { logoIcon2 } from "../../../assets/images";
import history from "../../../utils/history";

import "./landing-navbar.styles.scss";
import "../LandingPage.scss";

function LandingNavbar(props) {
    const fbq = window.fbq;
    const { setShowLoginModal, setShowRegistrationModal } = props;
    return (
        <div className="new-landing-navbar" id="new-landing-navbar">
            <div className="logo">
                <img src={logoIcon2} alt="My-Stores" style={{ cursor: "pointer" }}
                    onClick={() => {
                        if (history.location.pathname !== "/merchant") {
                            const anchor = document.querySelector("#landing-intro");
                            anchor.scrollIntoView({ behavior: "smooth", block: "start" });
                        } else {
                            history.push("/")
                        }
                    }}
                />
            </div>
            <div className="link-container">
                <button type="button" style={history.location.pathname !== "/merchant" ? null : { display: "none" }}
                    className="d-web"
                    onClick={() => {
                        if (history.location.pathname !== "/merchant") {
                            const anchor = document.querySelector("#store_section_id");
                            anchor.scrollIntoView({ behavior: "smooth", block: "start" });
                        } else {
                            history.push("/")
                        }
                    }}
                >
                    Negozi
                </button>
                <button type="button" style={history.location.pathname !== "/merchant" ? null : { display: "none" }}
                    className="d-web"
                    onClick={() => {
                        if (history.location.pathname !== "/merchant") {
                            const anchor = document.querySelector("#offer_section_id");
                            anchor.scrollIntoView({ behavior: "smooth", block: "start" });
                        } else {
                            history.push("/")
                        }
                    }}
                >
                    Offerte
                </button>
                <button type="button" style={history.location.pathname !== "/merchant" ? null : { display: "none" }}
                    className="d-web"
                    onClick={() => {
                        if (history.location.pathname !== "/merchant") {
                            const anchor = document.querySelector("#card_section_id");
                            anchor.scrollIntoView({ behavior: "smooth", block: "start" });
                        } else {
                            history.push("/")
                        }
                    }}
                >
                    Fidelity
                </button>
                <button type="button"
                    onClick={() => {
                        if (history.location.pathname !== "/merchant") {
                            history.push("/merchant")
                        } else {
                            history.push("/")
                        }
                    }}
                >
                    <span
                    // style={history.location.pathname === "/merchant" ? { color: "#285780" } : null}
                    ><b>
                            {history.location.pathname !== "/merchant" ? "Vai all'area Esercenti" : "Vai all'area Clienti"}
                        </b>
                    </span>
                </button>
            </div>
            <div className="button-container">
                <button
                    className="signup-button"
                    type="submit"
                    onClick={() => setShowRegistrationModal(true)}>
                    Registrati
                    </button>
                <button
                    className="login-button"
                    type="submit"
                    onClick={() => {
                        fbq('trackCustom', 'Conversione', {
                            content_name: 'Accedi'
                        });
                        setShowLoginModal(true)
                    }}>
                    Accedi
                </button>
            </div>
        </div>
    );
}

export default LandingNavbar;
