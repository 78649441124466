/**
 *
 * SignupForm
 *
 */

import React, {memo, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Button, Form, Icon, Loader} from "semantic-ui-react";
import {useAlert} from "react-alert";
import "./Login.scss";
import {connect} from "react-redux";
import {compose} from "redux";
import injectSaga from "../../utils/injectSaga";
// import history from 'utils/history';
import {DAEMON} from "../../utils/constants";
// import isEmail from 'validator/lib/isEmail';
import saga from "./saga";
import {userIcon} from "../../assets/images";
import {consoleUrl} from "../../Helpers/api";
import "./NewRegistration.scss";
// import styled from 'styled-components';

// import { FormattedMessage } from 'react-intl';
// import messages from './messages';

const LoginForm = (props) => {
  const {t} = useTranslation();
  const alert = useAlert();
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
    validationErrors: {
      emailError: "",
      passwordError: "",
    },
  });

  // useEffect(() => {
  //   alert.show('Oh look, an alert!');
  // });

  useEffect(() => {
    if (props.loginStatus.loginError && !props.loginStatus.loginInfo) {
      // alert.error('Authentication Failed !');
      alert.error(t("login.authFailed"));
      // openSnackbarRed('Authentication Failed !');
    } else if (props.loginStatus.loginInfo) {
      props.setShowLoginModal(false);
    }
  }, [props.loginStatus.loginError, props.loginStatus.loginInfo]);

  const handleChangeForms = (e) => {
    setValues({...values, [e.target.name]: e.target.value});
  };

  const onSubmit = (evt) => {
    const payload = {};
    evt.preventDefault();
    payload.email = values.email;
    payload.password = values.password;
    const validationErrors = {};

    if (values.email.length === 0) {
      validationErrors.emailError = t("login.username");
    }
    if (values.password.length === 0) {
      validationErrors.passwordError = t("login.password");
    }
    setValues({...values, validationErrors});
    if (values.email.length && values.password.length) {
      props.onSubmit(payload);
    }
  };


  const {email, password} = values;

  const headerStyle = {
    fontSize: "22px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.14",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: "#333333",
    borderBottom: "none",
  };

  return (
    <div style={{margin: "10px"}}>
      <div style={headerStyle}>Login</div>
      <Form autoComplete="off" onSubmit={onSubmit}>
        <div style={{position: "relative", margin: "0 0 10px 0"}}>
          <img
            src={userIcon}
            alt="user icon"
            style={{
              position: "absolute",
              zIndex: "2",
              top: "13px",
              left: "20px",
            }}
          />
          <Icon
            disabled
            name="key"
            style={{
              position: "absolute",
              left: "24px",
              top: "90px",
              zIndex: "10",
            }}
          />
          <div style={{position: "relative", margin: "0 0 10px 0"}}>
            <Form.Input
              name="email"
              placeholder="Email*"
              value={email}
              type="text"
              onChange={handleChangeForms}
              autoComplete="off"
            />
            {values.validationErrors && values.validationErrors.emailError && (
              <span
                style={{
                  position: "absolute",
                  top: "52px",
                  left: "18px",
                  color: "red",
                }}
              >
                  {values.validationErrors.emailError}
                </span>
            )}
          </div>
        </div>
        <div style={{position: "relative", margin: "0 0 10px 0"}}>
          <Form.Input
            name="password"
            placeholder="Password*"
            value={password}
            autoComplete="off"
            type={
              values.showPassword || password.length === 0
                ? "text"
                : "password"
            }
            onChange={handleChangeForms}
            // autoComplete="new-password"
          />
          {values.validationErrors && values.validationErrors.passwordError && (
            <span
              style={{
                position: "absolute",
                top: "52px",
                left: "18px",
                color: "red",
              }}
            >
                {values.validationErrors.passwordError}
              </span>
          )}
        </div>
        {!values.showPassword && (
          <div>
            <button
              style={{border: "none"}}
              type="button"
              onClick={() => setValues({...values, showPassword: true})}
            >
              <i
                className="eye slash icon"
                style={{
                  position: "absolute",
                  top: "90px",
                  right: "20px",
                  color: "#ec6338",
                }}
              />
            </button>
          </div>
        )}

        {values.showPassword && (
          <button
            style={{border: "none"}}
            type="button"
            onClick={() => setValues({...values, showPassword: false})}
          >
            <i
              className="eye icon"
              style={{
                position: "absolute",
                top: "90px",
                right: "20px",
                color: "#ec6338",
              }}
            />
          </button>
        )}
        <div style={{display: "flex", flexDirection: "column", textAlign: "center"}}>
          <div>
            <Button className="register-button" type="submit">
              {/* ACCEDI */}
              {props.loginStatus.loginLoading ? (
                <Loader active inline className="login_loader"/>
              ) : (
                "ACCEDI"
              )}
            </Button>
          </div>

          <div
            style={{
              marginTop: "0",
              fontFamily: "Open sans",
              fontSize: "12px",
              marginLeft: "0 !important",
            }}
          >
            Password dimenticata?{" "}
            <button
              type="button"
              className="password_reset_button password_reset_button_login"
              onClick={() => {
                props.setShowLoginModal(false);
                props.setShowResetPassordModal(true);
              }}
            >
              Clicca qui
            </button>
          </div>
        </div>
        <hr style={{margin: "30px 12px 0 20px"}}/>
        <div
          style={{
            fontFamily: "Open sans",
            fontSize: "18px",
            textAlign: "center",
            color: "#333333",
            fontWeight: "bold",
            marginTop: "30px",
          }}
        >
          Hai un'attività già registrata?
        </div>
        <div
          style={{
            fontFamily: "Open sans",
            lineHeight: "1.5",
            letterSpacing: "0.09px",
            textAlign: "center",
            color: "#333333",
            padding: "2px 45px",
          }}
        >
          Clicca qui per accedere alla sezione dedicata ai propretari di
          attività commerciali.
        </div>
        <button
          type="button"
          className="register-button-merchant"
          onClick={() => window.open(consoleUrl)}
        >
          <p>
            ACCEDI COME ESERCENTE</p>
        </button>
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  showLoginModal: PropTypes.bool,
  closeModal: PropTypes.func,
  setShowLoginModal: PropTypes.func,
  setShowResetPassordModal: PropTypes.func,
  loginStatus: PropTypes.object,
};

export function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (payload) => dispatch({type: "LOGGED_IN", payload}),
  };
}

export function mapStateToProps(state) {
  return {
    loginStatus: state.signIn,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({key: "Login", saga, mode: DAEMON});

export default compose(withConnect, memo, withSaga)(LoginForm);
