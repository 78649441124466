/* eslint-disable indent */
import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Dropdown, Button, Input, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { compose } from "redux";
import injectSaga from "../../utils/injectSaga";
import { DAEMON } from "../../utils/constants";
import saga from "../../containers/Homepage/saga";
import { getCities } from "../../Helpers/api";
import history from "../../utils/history";
import { crossSearch } from "../../assets/images";
import CityDropdown from "../../common/CityDropdown";
import "./HomeSearch.scss";

function HomeSearch(props) {
  const [searchText, setSearchText] = useState("");
  const [selectedCity, setSlectedCity] = useState({
    value: "",
    label: "",
  });
  const [recentSearches, setRecentSearches] = useState([]);
  // const [suggestions, setSuggestions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);

  useEffect(() => {
    if (
      props.searchedMallsAndStores &&
      props.searchedMallsAndStores.getMallsAndStoresLoading
    ) {
      props.setIsOpenSearchModal(false);
    }
  }, [props.searchedMallsAndStores]);

  useEffect(() => {
    // if (history.location.search) {
    // const params = new URLSearchParams(history.location.search);
    // const searchTextUrl = params.get('text');
    // if (searchTextUrl) {
    //   setCitiesOptions(searchTextUrl);
    // }
    // const zipCode = params.get('city');
    // if (zipCode) {
    //   setDefaultCity(zipCode);
    // }
    // }

    // if (localStorage.getItem('zipCode') && !localStorage.getItem('token')) {
    //   setSlectedCity(localStorage.getItem('zipCode'));
    // }

    // if (localStorage.getItem("zipCode")) {
    //   setSlectedCity(localStorage.getItem("zipCode"));
    // }
    if (localStorage.getItem("zipCode")) {
      const defaultCity = props.allCities.find(
        (city) => city.value === localStorage.getItem("zipCode")
      );
      setSlectedCity(defaultCity);
    }
    // axios.get(getCities).then(
    //   (res) => {
    //     const cityArray = [];
    //     if (res && res.data && res.data.data) {
    //       res.data.data.sort((a, b) => a.id - b.id);
    //       res.data.data.forEach((item) => {
    //         cityArray.push({
    //           key: item.id,
    //           value: item.id,
    //           text: `${item.city} [${item.id}]`,
    //         });
    //       });
    //       setCitiesOptions(cityArray);
    //     }
    //     const params = new URLSearchParams(history.location.search);
    //     const searchTextUrl = params.get("text");
    //     if (searchTextUrl) {
    //       setSearchText(searchTextUrl);
    //     }
    //     const zipCode = params.get("city");
    //     if (zipCode) {
    //       setSlectedCity(zipCode);
    //     }
    //     // { key: 'Rom', value: 'Rom', text: 'Rom' },
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
    if (props.allCities && props.allCities.length) {
      setCitiesOptions(props.allCities);
      const params = new URLSearchParams(history.location.search);
      const searchTextUrl = params.get("text");
      if (searchTextUrl) {
        setSearchText(searchTextUrl);
      }
      const zipCode = params.get("city");
      if (zipCode) {
        const defaultCity = props.allCities.find(
          (city) => city.value === zipCode
        );
        setSlectedCity(defaultCity);
        // setSlectedCity(zipCode);
      }
    }
    // Set recent search if any
    const finalSearch = JSON.parse(localStorage.getItem("searchHistory"));
    if (finalSearch && finalSearch.length) {
      setRecentSearches(finalSearch.reverse());
    }
  }, []);

  const searchHandler = () => {
    localStorage.setItem("searchedItem", searchText);
    if (selectedCity.value) {
      localStorage.setItem("zipRange", selectedCity.zipRange);
      localStorage.setItem("zipCode", selectedCity.value);
      localStorage.setItem("istat", selectedCity.istat);
    }
    if (searchText) {
      let recentFourSearches = [];
      const searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
      recentFourSearches = searchHistory;
      if (!recentFourSearches) {
        recentFourSearches = [];
        recentFourSearches.push(searchText);
      } else {
        recentFourSearches.push(searchText);
      }
      if (recentFourSearches.length > 5) {
        recentFourSearches = recentFourSearches.slice(
          Math.max(searchHistory.length - 5, 1)
        );
      }
      localStorage.setItem("searchHistory", JSON.stringify(recentFourSearches));
    }
    if (selectedCity.zipRange) {
      props.searchMallsStores({
        zipCode: selectedCity.value,
        searchText,
        zipRange: selectedCity.zipRange,
        istat: selectedCity.istat,
      });
    } else {
      props.searchMallsStores({
        zipCode: selectedCity.value,
        searchText,
      });
    }

    // if (history.location.pathname !== '/home/4') {
    let queryUrl = "";
    if (searchText && !selectedCity.value) {
      queryUrl = `text=${searchText}`;
    }
    if (!searchText && selectedCity.value) {
      queryUrl = `city=${selectedCity.value}`;
    }
    if (searchText && selectedCity.value) {
      queryUrl = `text=${searchText}&city=${selectedCity.value}`;
    }
    if (history.location.pathname !== `/search-results?${queryUrl}`) {
      // history.push('/home/4');
      history.push({
        pathname: `/search-results`,
        data: {
          zipCode: selectedCity.value,
          searchText,
        },
        search: queryUrl,
      });
    }
    // }
  };

  const setRecentSearchItem = (text, index) => {
    setSearchText(text);
    const newArray = [...recentSearches];
    newArray.splice(index, 1);
    setRecentSearches(newArray);
  };

  return (
    <div>
      <button
        type="button"
        // eslint-disable-next-line react/prop-types
        onClick={() => {
          props.handleSearchIconClick(false);
          setSearchText("");
        }}
        className="product_searh_close_icon"
      >
        <img
          src={crossSearch}
          alt=""
          className={
            props.isScrolled || props.showMapView
              ? "close_search_icon_image_scrolled"
              : "close_search_icon_image"
          }
        />
      </button>
      <div>
        <div
          className={
            props.isScrolled && !props.showMapView
              ? "search_box_main_container search_box_main_container_padding"
              : "search_box_main_container"
          }
          // style={props.showMapView ? { padding: "45px 80px 45px 100px" } : null}
        >
          <div
            className={
              props.isScrolled ? "search_item" : "search_item tab_screen_half"
            }
          >
            <Input
              type="text"
              placeholder="Di cosa hai bisogno?"
              className="search_input_home"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="cities_header_dropdown">
            <CityDropdown
              cities={citiesOptions}
              placeholder="Seleziona Città o Comune"
              selectedOption={
                selectedCity && selectedCity.value ? selectedCity : null
              }
              onClearCity={() => {
                setSlectedCity({ value: "", label: "" });
              }}
              handleChange={(selectedOption) => {
                setSlectedCity(selectedOption);
              }}
            />
          </div>

          {/* <div className="city_search_container">
        <Dropdown
            placeholder="Seleziona Città o Comune"
            fluid
            search
            selection
            clearable
            value={selectedCity}
            options={citiesOptions}
            onChange={(e, { value }) => {
              setSlectedCity(value);
            }}
          />
        </div> */}
          <Button
            className="landing_login_button homeSearchButton"
            type="submit"
            onClick={searchHandler}
            disabled={selectedCity.value === ""}
          >
            {props.searchedMallsAndStores &&
            props.searchedMallsAndStores.getMallsAndStoresLoading ? (
              <Loader active inline className="searchLoader" />
            ) : (
              "SCOPRI"
            )}
          </Button>
        </div>
        {recentSearches && recentSearches.length > 0 && (
          <p
            className={
              props.isScrolled
                ? "recent_search_heading recent_search_heading_padding"
                : "recent_search_heading"
            }
          >
            LE TUE ULTIME RICERCHE
          </p>
        )}

        <div
          className={
            props.isScrolled
              ? "recent_items_container"
              : "recent_items_container recent_items_container_tab_half"
          }
        >
          {recentSearches &&
            recentSearches.length > 0 &&
            recentSearches.map((serach, index) => (
              <button
                className="recent_item"
                type="button"
                onClick={() => setRecentSearchItem(serach, index)}
              >
                {serach}{" "}
                <span
                  style={{ position: "absolute", right: "12px", top: "6px" }}
                >
                  x
                </span>
              </button>
            ))}
        </div>
      </div>
    </div>
  );
}
HomeSearch.propTypes = {
  isScrolled: PropTypes.bool,
  handleSearchIconClick: PropTypes.func,
  showMapView: PropTypes.bool,
  searchMallsStores: PropTypes.func,
  searchedMallsAndStores: PropTypes.object,
  setIsOpenSearchModal: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    searchMallsStores: (payload) =>
      dispatch({ type: "SEARCH_MALLS_STORES", payload }),
  };
}
export function mapStateToProps(state) {
  return {
    searchedMallsAndStores: state.homePageFav,
    allCities: state.homePageFav.cities,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: "mallsStores", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(HomeSearch);
