import React, { useState, useEffect } from "react";
import LandingForm from "../../../components/LandingPage/LandingForm";
import { Accordion, Icon } from "semantic-ui-react";
import axios from "axios";
import { getFAQs } from "../../../Helpers/api";

function LandingWriteUs(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        //  Get FAQs
        axios.get(getFAQs).then(
            (res) => {
                if (res && res.data && res.data.data) setFaqs(res.data.data);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    const handleAccordianTitleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        setActiveIndex(newIndex);
    };

    return (
        <div className="form_accordian_container_main">
            <div className="form_accordian_container_header">
                Vuoi saperne di più?
          </div>
            <div className="form_accordian_container">
                <LandingForm />
                <div className="landing_accordian_container">
                    {faqs && faqs.length > 0 && (
                        <div className="accordian_heading">
                            <h1 className="d-tablet">Domande frequenti</h1>
                        </div>
                    )}
                    <Accordion
                        className="landing_accordian"
                        activeIndex={activeIndex}
                    >
                        {faqs &&
                            faqs.length > 0 &&
                            faqs.map((item, index) => (
                                <div className="landing_accordian_box_style">
                                    <Accordion.Title
                                        active={activeIndex === index}
                                        index={index}
                                        onClick={handleAccordianTitleClick}
                                        style={
                                            activeIndex === index
                                                ? {
                                                    boxShadow: "none",
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                    color: "#ffffff",
                                                    backgroundColor: "#2D386E"
                                                        
                                                }
                                                : null
                                        }
                                    >
                                        <Icon name={activeIndex === index ? "angle up" : "angle down"}
                                            className="landing_register_accordian_icon"
                                        />
                                        <div
                                            className="landing_accordian_heading"
                                            style={
                                                activeIndex === index ? { color: "#ffffff" } : null
                                            }
                                        >
                                            {item.question}
                                        </div>
                                    </Accordion.Title>
                                    <Accordion.Content
                                        active={activeIndex === index}
                                        style={{ padding: "25px" }}
                                    >
                                        <p className="frequently_answers">{item.answer}</p>
                                    </Accordion.Content>
                                </div>
                            ))}
                    </Accordion>
                </div>
            </div>
        </div>
    );
}

export default LandingWriteUs;








