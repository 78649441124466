import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as ArrowRight } from "../../images/combined-shape-copy.svg";
import { ReactComponent as ArrowLeft } from "../../images/combined-shape.svg";
import { Modal, ModalGateway } from "react-images";
import { Carousel } from "react-responsive-carousel";
import { Icon } from "semantic-ui-react";

import "./ShopImageSlider.scss";
import "./ShopDetails.scss";

class ShopImageSlider extends Component {
  state = {
    currentImage: 0,
    viewerIsOpen: false,
  };

  openLightbox = (index) => {
    this.setState({
      currentImage: index,
      viewerIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      viewerIsOpen: false,
    });
  };
  next = () => {
    this.slider.slickNext();
  };
  previous = () => {
    this.slider.slickPrev();
  };

  settings = {
    infinite: false,
    variableWidth: true,
    speed: 500,
    // slidesToShow: 3,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: 3,
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          variableWidth: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          draggable: true,
          dots: true,
        },
      },
    ],
  };

  render() {
    return (
      <div style={{ width: "100vw", margin: "none" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {this.props.images.length > 3 && (
            <div className="shop-image-slick-arrows left_arrow">
              <ArrowRight onClick={this.previous} />
            </div>
          )}
          <div style={{ flexGrow: 1 }}>
            <Slider {...this.settings} ref={(c) => (this.slider = c)}>
              {this.props.images &&
                this.props.images.map((x, index) => (
                  <div className="carousel-photo">
                    <div>
                      <img
                        src={x.src}
                        className="carousel-shop-image"
                        onClick={() => this.openLightbox(index)}
                      />
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
          {this.props.images.length > 3 && (
            <div className="shop-image-slick-arrows right_arrow">
              <ArrowLeft onClick={this.next} />
            </div>
          )}
        </div>

        <ModalGateway>
          {this.state.viewerIsOpen ? (
            <Modal onClose={this.closeLightbox}>
              <div className="gallary_large_modal">
                <Icon
                  onClick={this.closeLightbox}
                  name="close"
                  style={{
                    position: "absolute",
                    left: 50,
                    top: 50,
                    zIndex: "10",
                    color: "white",
                    fontSize: 30,
                    cursor: "pointer",
                  }}
                />
                <Carousel
                  // autoPlay
                  showThumbs={false}
                  showArrows={true}
                  showStatus={true}
                  infiniteLoop
                  interval={5000}
                  transitionTime={1000}
                  selectedItem={this.state.currentImage}
                >
                  {this.props.images.map((x) => (
                    <div
                      // onClick={this.closeLightbox}
                      className="carousel-photo-modal"
                    >
                      <img src={x.src} className="shop-image-open" />
                    </div>
                  ))}
                </Carousel>
              </div>
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  }
}

export default ShopImageSlider;
