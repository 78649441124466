/* eslint-disable */
/* eslint-disable prefer-destructuring */
/* eslint-disable operator-assignment */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { Map, Marker, TileLayer, ZoomControl, Popup } from "react-leaflet";
import { Modal, Dropdown, Icon as DropdownIcon } from "semantic-ui-react";
import { Icon } from "leaflet";
import { connect } from "react-redux";
import { compose } from "redux";
import axios from "axios";
import injectSaga from "../../utils/injectSaga";
import { DAEMON } from "../../utils/constants";
import {
  shopCategories,
  getAllOffer,
  shopGallaryResource,
} from "../../Helpers/api";
import saga from "../../containers/Homepage/saga";
import history from "../../utils/history";
// import NavBar from "../NavBar/NavBar";

import {
  mapLocator,
  shoppingCentre,
  locator,
  // heartCard,
  filter,
  mapClose,
  // store1,
  largerLocator,
  locationReload,
  sortLogo,
  heartFilled,
  store6,
} from "../../assets/images";
import "leaflet/dist/leaflet.css";

function FilterMapView(props) {
  const [markersData, setMarkersData] = React.useState();
  const [mapCentre, setMapCentre] = React.useState([45.822617, 8.749668]);
  const [activePark, setActivePark] = React.useState(null);
  const [activeIcon, setActiveIcon] = React.useState();
  const [isRotate, setIsRotate] = React.useState(false);
  const [categorySelected, setCategorySelected] = useState([]);
  const [initialSubCategories, setInitialSubCategories] = useState([]);
  const [subCatSelected, setSubCatSelected] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [favStoresInitial, setFavStoresInitial] = useState([]);
  const [favMallsInitial, setFavMallsInitial] = useState([]);
  const [mallsData, setMallsData] = useState([]);
  const [storesData, setStoresData] = useState([]);
  const [mallStoreData, setMallStoreData] = useState([]);
  const [currentGeoLocation, setCurrentGeoLocation] = useState({});
  const [activeOffersLengthDisplay, setActiveOffersLengthDisplay] = useState(0);
  const [mallStoreType, setMallStoreType] = useState("mall");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([
    // { id: 1, name: 'Uomo', isSelected: false },
    // { id: 2, name: 'Donna', isSelected: false },
    // { id: 3, name: 'Bambino', isSelected: false },
  ]);
  const [distanceValue, setDistanceValue] = useState("");
  const [filterOptionsDistance] = useState([
    {
      key: 1,
      text: "20 km",
      value: "20",
    },
    {
      key: 2,
      text: "40 km",
      value: "40",
    },
    {
      key: 3,
      text: "60 km",
      value: "60",
    },
    {
      key: 4,
      text: "80 km",
      value: "80",
    },
    {
      key: 5,
      text: "100 km",
      value: "100",
    },
  ]);

  /*
  useEffect(() => {
    // Get All active offers

    axios.get(getAllOffer).then(
      (res) => {
        const offers = res.data.data;
        const groupedOffers = [];
        const duplicates = [];
        if (offers && offers.length) {
          offers.forEach((offer, index) => {
            const group = [];
            if (duplicates.includes(index)) return;
            const type = offer.offer.type.toLowerCase();
            const sameShopOffers = offers.filter((groupOffer, groupIndex) => {
              const groupType = groupOffer.offer.type.toLowerCase();
              if (
                groupType === type &&
                groupOffer[groupType].id === offer[type].id
              ) {
                duplicates.push(groupIndex);
                return true;
              }
              return false;
            });
            for (let i = 0; i < sameShopOffers.length; i += 2) {
              const innerGroup = [];
              innerGroup.push(sameShopOffers[i]);
              if (sameShopOffers.length - i !== 1)
                innerGroup.push(sameShopOffers[i + 1]);
              group.push(innerGroup);
            }
            groupedOffers.push(group);
          });
          // Totoal Active oofers
          let activeOffersLength = 0;
          if (groupedOffers && groupedOffers.length) {
            groupedOffers.forEach((group) => {
              activeOffersLength = activeOffersLength + group.length;
            });
            setActiveOffersLengthDisplay(activeOffersLength);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

*/

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  useEffect(() => {
    if (props.favStores && props.favStores.data) {
      setFavStoresInitial(props.favStores.data.data.stores);
      setFavMallsInitial(props.favStores.data.data.malls);
      const params = new URLSearchParams(history.location.search);
      const urlDistance = params.get("distance");
      const urlCategory = params.get("categories");
      console.log(favMallsInitial);
      if (urlDistance && !urlCategory) {
        const filterdFavStoreArray = [];
        props.favStores.data.data.stores.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (dist <= urlDistance) {
            filterdFavStoreArray.push(item);
          }
        });
        setStoresData(filterdFavStoreArray);
        const filterdFavMallsArray = [];
        props.favStores.data.data.malls.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (dist <= urlDistance) {
            filterdFavMallsArray.push(item);
          }
        });
        setMallsData(filterdFavMallsArray);
        setMallStoreData([...filterdFavStoreArray, ...filterdFavMallsArray]);
      } else if (!urlDistance && urlCategory) {
        const filterdFavStoreArray = [];
        props.favStores.data.data.stores.forEach((item) => {
          if (
            item.categoryId &&
            item.categoryId.toLowerCase() === urlCategory.toLowerCase()
          ) {
            filterdFavStoreArray.push(item);
          }
        });
        setStoresData(filterdFavStoreArray);
        const filterdFavMallsArray = [];
        props.favStores.data.data.malls.forEach((item) => {
          if (
            item.categoryId &&
            item.categoryId.toLowerCase() === urlCategory.toLowerCase()
          ) {
            filterdFavMallsArray.push(item);
          }
        });
        setMallsData(filterdFavMallsArray);
        setMallStoreData([...filterdFavStoreArray, ...filterdFavMallsArray]);
      } else if (urlDistance && urlCategory) {
        const filterdFavStoreArray = [];
        props.favStores.data.data.stores.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (
            dist <= urlDistance &&
            item.categoryId.toLowerCase() === urlCategory.toLowerCase()
          ) {
            filterdFavStoreArray.push(item);
          }
        });
        setStoresData(filterdFavStoreArray);
        const filterdFavMallsArray = [];
        props.favStores.data.data.malls.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (
            dist <= urlDistance &&
            item.categoryId.toLowerCase() === urlCategory.toLowerCase()
          ) {
            filterdFavMallsArray.push(item);
          }
        });
        setMallsData(filterdFavMallsArray);
        setMallStoreData([...filterdFavStoreArray, ...filterdFavMallsArray]);
      } else {
        setStoresData(props.favStores.data.data.stores);
        setMallsData(props.favStores.data.data.malls);
        setMallStoreData([
          ...props.favStores.data.data.stores,
          ...props.favStores.data.data.malls,
        ]);
      }
    }
  }, [props.favStores]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((location) => {
      console.log(location);
      if (
        location &&
        location.coords &&
        location.coords.latitude &&
        location.coords.longitude
      ) {
        setCurrentGeoLocation({
          lat: location.coords.latitude,
          long: location.coords.longitude,
          // lat: 45.49719,
          // long: 10.16602,
        });
      }

      props.getFavStores();
    });
  }, []);

  // Getting the categories for filters

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const urlDistance = params.get("distance");
    if (urlDistance) {
      setDistanceValue(urlDistance);
    }

    axios({
      method: "get",
      url: shopCategories,
    })
      .then((res) => {
        const filterCategories = [];
        const subCategoriesFilter = [];
        res.data.forEach((item) => {
          const obj = {};
          obj.id = item.id;
          obj.name = item.description;
          obj.isSelected = false;
          filterCategories.push(obj);
          // Sub categories
          const subCategObj = {};
          subCategObj.id = item.id;
          if (item.items) {
            subCategObj.subCategories = item.items;
          }
          subCategoriesFilter.push(subCategObj);
        });
        setCategories(filterCategories);
        setInitialSubCategories(subCategoriesFilter);

        const urlcategory = params.get("categories");
        const urlcategoryArray = urlcategory.split(",");
        let mainCategory = "";
        if (urlcategoryArray && urlcategoryArray.length) {
          mainCategory = urlcategoryArray[0];
          if (mainCategory) {
            const objItem = filterCategories.find(
              (item) => item.name === mainCategory
            );
            setCategorySelected([objItem.id]);
            const subCategObj = subCategoriesFilter.find(
              (item) => item.id === objItem.id
            );
            const finalSubcategories = [];
            if (
              subCategObj &&
              subCategObj.subCategories &&
              subCategObj.subCategories.length
            ) {
              subCategObj.subCategories.forEach((item) => {
                const obj = {};
                obj.id = item.id;
                obj.name = item.description;
                obj.isSelected = false;
                finalSubcategories.push(obj);
              });
              setSubCategories(finalSubcategories);
            }
            const subCatArray = subCategoriesFilter.find(
              (sub) => sub.id === objItem.id
            );
            const filterSub = subCatArray.subCategories.filter((item) =>
              urlcategoryArray.includes(item.description)
            );
            const selectedSubCateIds = [];
            filterSub.forEach((item) => {
              selectedSubCateIds.push(item.id);
            });
            setSubCatSelected(selectedSubCateIds);
          }
        }
        // const urlcategory = params.get('categories');
        // // const urlDistance = params.get('distance');

        // if (urlcategory) {
        //   const objItem = filterCategories.find(
        //     item => item.name.toLowerCase() === urlcategory.toLowerCase(),
        //   );
        //   setCategorySelected([objItem.id]);
        //   // setDistanceValue(urlDistance);
        //   console.log(objItem);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Handle distance filter

  const handleDistanceFilter = (e, { value }) => {
    setDistanceValue(value);
    const params2 = new URLSearchParams(history.location.search);
    params2.delete("distance");
    history.push({
      pathname: history.location.pathname,
      search: params2.toString(),
    });
    history.push({
      search: `${history.location.search}&distance=${value}`,
    });
    const params = new URLSearchParams(history.location.search);
    const filterCategories = params.get("categories");
    const distance = params.get("distance");

    if (distance && !filterCategories) {
      const filterdFavStoreArray = [];
      favStoresInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value) {
          filterdFavStoreArray.push(item);
        }
      });
      setStoresData(filterdFavStoreArray);
      // Malls
      const filterdFavMallArray = [];
      favMallsInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value) {
          filterdFavMallArray.push(item);
        }
      });
      setMallsData(filterdFavMallArray);
      setMallStoreData([...filterdFavStoreArray, ...filterdFavMallArray]);
    }
    if (!distance && filterCategories) {
      const filterdFavStoreArray = [];
      favStoresInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value) {
          filterdFavStoreArray.push(item);
        }
      });
      setStoresData(filterdFavStoreArray);
      // Malls
      const filterdFavMallsArray = [];
      favMallsInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value) {
          filterdFavMallsArray.push(item);
        }
      });
      setMallsData(filterdFavMallsArray);
      setMallStoreData([...filterdFavStoreArray, ...filterdFavMallsArray]);
    }
    if (distance && filterCategories) {
      const filterdFavStoreArray = [];
      favStoresInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value && item.categoryId === filterCategories) {
          filterdFavStoreArray.push(item);
        }
      });
      setStoresData(filterdFavStoreArray);
      // Malls
      const filterdFavMallsArray = [];
      favMallsInitial.forEach((item) => {
        const dist = getDistanceFromLatLonInKm(
          currentGeoLocation.lat,
          currentGeoLocation.long,
          item.address.latitude,
          item.address.longitude
        );
        if (dist <= value && item.categoryId === filterCategories) {
          filterdFavMallsArray.push(item);
        }
      });
      setMallsData(filterdFavMallsArray);
      setMallStoreData([...filterdFavStoreArray, ...filterdFavMallsArray]);
    }

    if (value === "") {
      if (filterCategories) {
        setStoresData(storesData);
        setMallsData(mallsData);
      } else {
        const params3 = new URLSearchParams(history.location.search);
        params3.delete("distance");
        history.push({
          pathname: history.location.pathname,
          search: params3.toString(),
        });
        setStoresData(favStoresInitial);
        setMallsData(favMallsInitial);
        setMallStoreData([...favStoresInitial, ...favMallsInitial]);
      }
    }
  };

  //   const handleDistanceFilter = (e, { value }) => {
  //     setDistanceValue(value);
  //     console.log(value);
  //     const params2 = new URLSearchParams(history.location.search);
  //     params2.delete('distance');
  //     history.push({
  //       pathname: history.location.pathname,
  //       search: params2.toString(),
  //     });
  //     history.push({
  //       search: `${history.location.search}&distance=${value}`,
  //     });
  //     const params = new URLSearchParams(history.location.search);
  //     const searchText = params.get('text');
  //     const zipCode = params.get('city');
  //     const filterCategories = params.get('categories');
  //     // navigator.geolocation.getCurrentPosition(location => {
  //     //   console.log(location);
  //     //   setCurrentGeoLocation({
  //     //     lat: location.coords.latitude,
  //     //     long: location.coords.longitude,
  //     //   });
  //     // });
  //     console.log(currentGeoLocation);
  //     props.searchMallsStores({
  //       // zipCode: selectedCity,
  //       zipCode,
  //       searchText,
  //       categories: filterCategories,
  //       distance: value,
  //       lat: currentGeoLocation.lat,
  //       long: currentGeoLocation.long,
  //     });

  //     if (value === '') {
  //       const params3 = new URLSearchParams(history.location.search);
  //       params3.delete('distance');
  //       history.push({
  //         pathname: history.location.pathname,
  //         search: params3.toString(),
  //       });
  //       const searchText2 = params3.get('text');
  //       const zipCode2 = params3.get('city');
  //       const filterCategories2 = params3.get('categories');
  //       props.searchMallsStores({
  //         zipCode: zipCode2,
  //         searchText: searchText2,
  //         categories: filterCategories2,
  //       });
  //     }
  //   };

  //   useEffect(() => {
  //     // if (props.location.data) {
  //     //   console.log(props.location.data);
  //     //   setMallsData(props.location.data.malls);
  //     //   setStoresData(props.location.data.stores);
  //     // }

  //     if (
  //       props.mallsAndStores.getMallsAndStoresSuccess &&
  //       props.mallsAndStores.getMallsAndStoresSuccess.data &&
  //       props.mallsAndStores.getMallsAndStoresSuccess.data.data
  //     ) {
  //       console.log(props.mallsAndStores.getMallsAndStoresSuccess.data.data);
  //       setMallsData(
  //         props.mallsAndStores.getMallsAndStoresSuccess.data.data.malls,
  //       );
  //       setStoresData(
  //         props.mallsAndStores.getMallsAndStoresSuccess.data.data.stores,
  //       );
  //     }
  //   }, [props.mallsAndStores]);

  const skater = new Icon({
    iconUrl: mapLocator,
    iconSize: [28, 35],
  });
  const skaterlargerLocator = new Icon({
    iconUrl: largerLocator,
    iconSize: [62, 62],
  });
  const handleCloseMap = () => {
    history.push("/favourites");
    // props.setShowListingPage(true);
    // props.setShowMapView(false);
  };
  const handleSearchInLocation = () => {
    setIsRotate(true);
    const myNearbyMarkers = [];
    // eslint-disable-next-line func-names
    navigator.geolocation.getCurrentPosition(function (location) {
      markersData.forEach((marker) => {
        let distance = getDistance(
          [location.coords.latitude, location.coords.longitude],
          [marker.geometry.coordinates[1], marker.geometry.coordinates[0]]
        );
        distance /= 1000;
        if (distance < 10) {
          myNearbyMarkers.push(marker);
        }
      });
      setMarkersData(myNearbyMarkers);
      setMapCentre([location.coords.latitude, location.coords.longitude]);
    });
  };
  function getDistance(origin, destination) {
    // return distance in meters
    const lon1 = toRadian(origin[1]);
    const lat1 = toRadian(origin[0]);
    const lon2 = toRadian(destination[1]);
    const lat2 = toRadian(destination[0]);

    const deltaLat = lat2 - lat1;
    const deltaLon = lon2 - lon1;

    const a =
      // eslint-disable-next-line no-restricted-properties
      Math.pow(Math.sin(deltaLat / 2), 2) +
      // eslint-disable-next-line no-restricted-properties
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
    const c = 2 * Math.asin(Math.sqrt(a));
    const EARTH_RADIUS = 6371;
    return c * EARTH_RADIUS * 1000;
  }
  function toRadian(degree) {
    return (degree * Math.PI) / 180;
  }
  const handleCategories = (id) => {
    if (categorySelected.indexOf(id) === -1 && categorySelected.length < 1) {
      // SET SUB CATEG BASED ON CATEGORY SELECTED
      let params = new URLSearchParams(history.location.search);
      let filterCategories = params.get("categories");
      console.log(filterCategories);
      const subCategObj = initialSubCategories.find((item) => item.id === id);
      const finalSubcategories = [];
      if (
        subCategObj &&
        subCategObj.subCategories &&
        subCategObj.subCategories.length
      ) {
        subCategObj.subCategories.forEach((item) => {
          const obj = {};
          obj.id = item.id;
          obj.name = item.description;
          obj.isSelected = false;
          finalSubcategories.push(obj);
        });
        setSubCategories(finalSubcategories);
      }
      // Category handles
      setCategorySelected([...categorySelected, id]);
      const obj = categories.find((item) => item.id === id);
      history.push({
        search: `${history.location.search}&categories=${obj.name}`,
      });
      params = new URLSearchParams(history.location.search);
      const distt = params.get("distance");
      filterCategories = params.get("categories");

      if (!distt && filterCategories) {
        const newFavStoresToFilter = favStoresInitial;
        console.log(newFavStoresToFilter);
        const catFilteredArray = newFavStoresToFilter.filter((item) => {
          if (
            item.categoryId &&
            item.categoryId.toLowerCase() === obj.name.toLowerCase()
          ) {
            return true;
          }
          return false;
        });
        console.log(catFilteredArray);
        setStoresData(catFilteredArray);

        const newFavMallsToFilter = favMallsInitial;
        const catFilteredArrayMall = newFavMallsToFilter.filter((item) => {
          if (
            item.categoryId &&
            item.categoryId.toLowerCase() === obj.name.toLowerCase()
          ) {
            return true;
          }
          return false;
        });
        setMallsData(catFilteredArrayMall);
        setMallStoreData([...catFilteredArray, ...catFilteredArrayMall]);
      }

      if (distt && filterCategories) {
        // const newFavStoresToFilter = favStoresInitial;
        // console.log(newFavStoresToFilter);
        // const catFilteredArray = newFavStoresToFilter.filter(
        //   item => item.categoryId.toLowerCase() === obj.name.toLowerCase(),
        // );
        // console.log(catFilteredArray);
        // setFavStores(catFilteredArray);

        const filterdFavStoreArray = [];
        favStoresInitial.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (
            dist <= distt &&
            item.categoryId.toLowerCase() === filterCategories.toLowerCase()
          ) {
            filterdFavStoreArray.push(item);
          }
        });
        setStoresData(filterdFavStoreArray);
        // For Malls
        const filterdFavMallsArray = [];
        favMallsInitial.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (
            dist <= distt &&
            item.categoryId.toLowerCase() === filterCategories.toLowerCase()
          ) {
            filterdFavMallsArray.push(item);
          }
        });
        setMallsData(filterdFavMallsArray);
        setMallStoreData([...filterdFavStoreArray, ...filterdFavMallsArray]);
      }
    } else {
      setSubCategories([]);
      const index = categorySelected.indexOf(id);
      if (index > -1) {
        categorySelected.splice(index, 1);
        setCategorySelected([...categorySelected]);

        const params = new URLSearchParams(history.location.search);
        params.delete("categories");
        history.push({
          pathname: history.location.pathname,
          search: params.toString(),
        });
        const distt = params.get("distance");
        if (distt) {
          const filterdFavStoreArray = [];
          favStoresInitial.forEach((item) => {
            const dist = getDistanceFromLatLonInKm(
              currentGeoLocation.lat,
              currentGeoLocation.long,
              item.address.latitude,
              item.address.longitude
            );
            if (dist <= distt) {
              filterdFavStoreArray.push(item);
            }
          });
          setStoresData(filterdFavStoreArray);
          // Malls
          const filterdFavMallsArray = [];
          favMallsInitial.forEach((item) => {
            const dist = getDistanceFromLatLonInKm(
              currentGeoLocation.lat,
              currentGeoLocation.long,
              item.address.latitude,
              item.address.longitude
            );
            if (dist <= distt) {
              filterdFavMallsArray.push(item);
            }
          });
          setMallsData(filterdFavMallsArray);
          setMallStoreData([...filterdFavStoreArray, ...filterdFavMallsArray]);
        } else {
          setStoresData(favStoresInitial);
          setMallsData(favMallsInitial);
          setMallStoreData([...favStoresInitial, ...favMallsInitial]);
        }
      }
    }
  };
  //   const handleCategories = id => {
  //     if (categorySelected.indexOf(id) === -1 && categorySelected.length < 1) {
  //       setCategorySelected([...categorySelected, id]);
  //       const obj = categories.find(item => item.id === id);
  //       history.push({
  //         search: `${history.location.search}&categories=${obj.name}`,
  //       });
  //       const params = new URLSearchParams(history.location.search);
  //       const searchText = params.get('text');
  //       const zipCode = params.get('city');
  //       const dist = params.get('distance');
  //       props.searchMallsStores({
  //         zipCode,
  //         searchText,
  //         categories: obj.name,
  //         distance: dist,
  //         lat: currentGeoLocation.lat,
  //         long: currentGeoLocation.long,
  //       });
  //     } else {
  //       const index = categorySelected.indexOf(id);
  //       if (index > -1) {
  //         categorySelected.splice(index, 1);
  //         setCategorySelected([...categorySelected]);
  //         const params = new URLSearchParams(history.location.search);
  //         params.delete('categories');
  //         history.push({
  //           pathname: history.location.pathname,
  //           search: params.toString(),
  //         });
  //         const searchText = params.get('text');
  //         const zipCode = params.get('city');
  //         const filterCategories = params.get('categories');
  //         const dist = params.get('distance');
  //         props.searchMallsStores({
  //           searchText,
  //           zipCode,
  //           filterCategories,
  //           distance: dist,
  //           lat: currentGeoLocation.lat,
  //           long: currentGeoLocation.long,
  //         });
  //       }
  //     }
  //   };

  /*
  const handleSubCategories = (id, name) => {
    if (subCatSelected.indexOf(id) === -1) {
      const catHistory = [];
      setSubCatSelected([...subCatSelected, id]);
      // Sub Category handles
      const obj = subCategories.find(item => item.id === id);

      let params = new URLSearchParams(history.location.search);
      const distt = params.get('distance');
      let filterCategories = params.get('categories');
      catHistory.push(filterCategories);
      console.log('Helllo', filterCategories);
      // params.delete('categories');

      // history.push({
      //   pathname: history.location.pathname,
      //   search: params.toString(),
      // });
      if (distt) {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filterCategories},${
            obj.name
          }&distance=${distt}`,
        });
      } else {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filterCategories},${obj.name}`,
        });
      }

      params = new URLSearchParams(history.location.search);
      filterCategories = params.get('categories');
      console.log(filterCategories);
      if (!distt && filterCategories) {
        const newFavStoresToFilter = favStoresInitial;
        console.log(newFavStoresToFilter);

        const newFilterCategoriesArray = filterCategories.split(',');
        console.log(newFilterCategoriesArray);
        const finalFilterdArrray = [];
        // newFilterCategoriesArray.forEach(category => {
        const catFilteredArray = newFavStoresToFilter.filter(item => {
          if (
            item.categoryId &&
            newFilterCategoriesArray.includes(item.categoryId.toLowerCase())
          ) {
            return true;
          }
          return false;
        });
        // console.log(category);
        console.log(catFilteredArray);
        // finalFilterdArrray.concat(catFilteredArray);
        // });
        setStoresData(finalFilterdArrray);

        // const catFilteredArray = newFavStoresToFilter.filter(
        //   item => item.categoryId.toLowerCase() === obj.name.toLowerCase(),
        // );
        // console.log(catFilteredArray);
        // setFavStores(catFilteredArray);
      }

      if (distt && filterCategories) {
        console.log(filterCategories);
        const newFilterCategoriesArray = filterCategories.split(',');
        const filterdFavStoreArray = [];
        favStoresInitial.forEach(item => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude,
          );
          if (
            dist <= distt &&
            newFilterCategoriesArray.includes(item.categoryId.toLowerCase())
          ) {
            filterdFavStoreArray.push(item);
          }
        });
        setStoresData(filterdFavStoreArray);
      }
    } else {
      const index = subCatSelected.indexOf(id);
      const params = new URLSearchParams(history.location.search);
      console.log('Sub Category', id);
      const catgg = params.get('categories');
      const distt = params.get('distance');
      const catggArray = catgg.split(',');
      const arrayTobeFilter = [...catggArray];
      console.log(name);
      console.log('Initial sub categories', initialSubCategories);
      const filteredArray = arrayTobeFilter.filter(item => item !== name);
      history.push({
        pathname: history.location.pathname,
        search: `categories=${filteredArray.toString()}&distance=${distt}`,
      });
      // console.log(object);
      console.log(catgg);
      if (index > -1) {
        subCatSelected.splice(index, 1);
        setSubCatSelected([...subCatSelected]);
      }
    }
  };
*/

  const deleteSelCategories = (id) => {
    const index = categorySelected.indexOf(id);
    if (index > -1) {
      categorySelected.splice(index, 1);
      setCategorySelected([...categorySelected]);

      const params = new URLSearchParams(history.location.search);
      params.delete("categories");
      history.push({
        pathname: history.location.pathname,
        search: params.toString(),
      });
      const distt = params.get("distance");
      if (distt) {
        const filterdFavStoreArray = [];
        favStoresInitial.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (dist <= distt) {
            filterdFavStoreArray.push(item);
          }
        });
        setStoresData(filterdFavStoreArray);
        // Malls
        const filterdFavMallArray = [];
        favMallsInitial.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (dist <= distt) {
            filterdFavMallArray.push(item);
          }
        });
        setMallsData(filterdFavMallArray);
        setMallStoreData([...filterdFavStoreArray, ...filterdFavMallArray]);
      } else {
        setStoresData(favStoresInitial);
        setMallsData(favMallsInitial);
        setMallStoreData([...favStoresInitial, ...favMallsInitial]);
      }
      // setFavStores(favStoresInitial);
    }
  };
  //   const deleteSelCategories = id => {
  //     const index = categorySelected.indexOf(id);
  //     if (index > -1) {
  //       const params = new URLSearchParams(history.location.search);
  //       params.delete('categories');
  //       history.push({
  //         pathname: history.location.pathname,
  //         search: params.toString(),
  //       });
  //       const searchText = params.get('text');
  //       const zipCode = params.get('city');
  //       const filterCategories = params.get('categories');
  //       const dist = params.get('distance');
  //       props.searchMallsStores({
  //         searchText,
  //         zipCode,
  //         filterCategories,
  //         distance: dist,
  //         lat: currentGeoLocation.lat,
  //         long: currentGeoLocation.long,
  //       });
  //       categorySelected.splice(index, 1);
  //       setCategorySelected([...categorySelected]);
  //     }
  //   };

  const deleteSelSubCategories = (id, name) => {
    const index = subCatSelected.indexOf(id);
    if (index > -1) {
      const params = new URLSearchParams(history.location.search);
      console.log("Sub Category", id);
      const catgg = params.get("categories");
      const dist2 = params.get("distance");
      const catggArray = catgg.split(",");
      const arrayTobeFilter = [...catggArray];
      console.log(name);
      console.log("Initial sub categories", initialSubCategories);
      const filteredArray = arrayTobeFilter.filter((item) => item !== name);
      if (dist2) {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filteredArray.toString()}&distance=${dist2}`,
        });
      } else {
        history.push({
          pathname: history.location.pathname,
          search: `categories=${filteredArray.toString()}`,
        });
      }

      console.log(filteredArray);
      const newFavStoresToFilter = favStoresInitial;
      console.log(newFavStoresToFilter);

      let finalFilterdArrray = [];
      const params3 = new URLSearchParams(history.location.search);
      const dist3 = params3.get("distance");
      const catgg3 = params3.get("categories");
      const catggArray3 = catgg3.split(",");
      console.log(catggArray3);
      console.log(subCatSelected);
      console.log(newFavStoresToFilter);
      if (catggArray3 && catggArray3.length && !dist3) {
        finalFilterdArrray = newFavStoresToFilter.filter((item) =>
          subCatSelected.includes(item.categoryId)
        );
        // console.log(category);
        console.log(finalFilterdArrray);
        console.log(finalFilterdArrray);
        setStoresData(finalFilterdArrray);
      }
      if (catggArray3 && catggArray3.length && dist3) {
        const filterdFavStoreArray = [];
        favStoresInitial.forEach((item) => {
          const dist = getDistanceFromLatLonInKm(
            currentGeoLocation.lat,
            currentGeoLocation.long,
            item.address.latitude,
            item.address.longitude
          );
          if (dist <= dist3 && catggArray3.includes(item.categoryId)) {
            filterdFavStoreArray.push(item);
          }
        });
        setStoresData(filterdFavStoreArray);
      }

      subCatSelected.splice(index, 1);
      setSubCatSelected([...subCatSelected]);
    }
  };
  const handleStoreMallTop = (id) => {
    const neArray = [...mallStoreData];
    const obj = neArray.find((item) => item.id === id);
    const index = neArray.indexOf(obj);
    if (index > -1) {
      neArray.splice(index, 1);
    }
    neArray.unshift(obj);
    setMallStoreData(neArray);
  };
  return (
    <div>
      <div className="map_main_container">
        {/* <NavBar isScrolled mapview /> */}
        <div className="components_container">
          <div>
            {/* <p className="components_container_main_heading">
              Home &gt; Ricerca &gt;{' '}
              <span className="components_container_main_heading_product">
                SCAPRE
              </span>
            </p>
            <p className="components_container_second_heading">
              Hai cercato:{' '}
              <span className="components_container_second_heading_two">
                SCARPE
              </span>
            </p> */}
            <div className="mapview_filter_container">
              <div>
                {/* <p className="mapview_filter_text">{`Hai ${activeOffersLengthDisplay} offerte attive`}</p> */}
                <p className="mapview_filter_text">{`I tuoi ${
                  storesData.length + mallsData.length
                } negozi preferiti`}</p>
                {categories.map((category) => {
                  if (categorySelected.includes(category.id)) {
                    return (
                      <button
                        type="button"
                        className={
                          !categorySelected.includes(category.id)
                            ? "categories_item map_view_button"
                            : "categories_item categories_item_selected map_view_button"
                        }
                        key={category.id}
                        onClick={() => deleteSelCategories(category.id)}
                      >
                        {category.name}
                        {categorySelected.includes(category.id) && (
                          <span className="close_selected_category">x</span>
                        )}
                      </button>
                    );
                  }
                })}
                {subCategories.map((category) => {
                  if (subCatSelected.includes(category.id)) {
                    return (
                      <button
                        type="button"
                        className={
                          !subCatSelected.includes(category.id)
                            ? "categories_item map_view_button"
                            : "categories_item categories_item_selected map_view_button"
                        }
                        key={category.id}
                        onClick={() =>
                          deleteSelSubCategories(category.id, category.name)
                        }
                      >
                        {category.name}
                        {subCatSelected.includes(category.id) && (
                          <span className="close_selected_category">x</span>
                        )}
                      </button>
                    );
                  }
                })}
              </div>
              <div className="filter_container">
                <button
                  className="filter_map_view"
                  type="button"
                  onClick={() => setShowFilterModal(true)}
                >
                  <img src={filter} alt="filter" />
                </button>
                <span className="filter_text" />
                Filtri
              </div>
            </div>
            {showFilterModal && (
              <div className="search_filter_modal_map_view">
                <Modal
                  open={showFilterModal}
                  style={{
                    width: "48%",
                    left: "17px",
                    top: "120px",
                  }}
                  onClose={() => setShowFilterModal(false)}
                  // className="search_filter_modal"
                >
                  <div className="listing_filters_popup_map_view">
                    <div className="listing_filters_popup_search_filter_map_view">
                      <div>
                        <p className="offers_active_text">
                          {/* {`Hai ${activeOffersLengthDisplay} offerte attive`} */}
                          {`I tuoi ${
                            storesData.length + mallsData.length
                          } negozi preferiti`}
                        </p>
                      </div>
                      <div>
                        <div
                          className="search_filter_text"
                          onClick={() => setShowFilterModal(false)}
                        >
                          <img src={filter} alt="" />
                          <p className="search_filter">
                            Filtri x
                            {/* <button
                              type="button"
                              className="filter_close  map_view_button "
                              onClick={() => setShowFilterModal(false)}
                            >
                              +
                            </button> */}
                          </p>
                          <div className="a_z_filter">
                            <img src={sortLogo} alt="" />
                            <div className="distance_text">
                              <div className="a_z_text">
                                <Dropdown
                                  placeholder="Distanza"
                                  selection
                                  clearable
                                  options={filterOptionsDistance}
                                  value={distanceValue}
                                  onChange={handleDistanceFilter}
                                  icon={<DropdownIcon name="angle down" />}
                                />
                                {/* <Select
                                placeholder="Distanza"
                                options={filterOptionsDistance}
                                // icon={null}
                                icon={<DropdownIcon name="angle down" />}
                              /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="categories_conatainer">
                      <p className="categories_text">Categoria</p>
                      <div className="categories_items">
                        {categories.map((category) => (
                          <button
                            type="button"
                            className={
                              !categorySelected.includes(category.id)
                                ? "categories_item map_view_button"
                                : "categories_item categories_item_selected map_view_button"
                            }
                            key={category.id}
                            onClick={() => handleCategories(category.id)}
                          >
                            {category.name}
                            {categorySelected.includes(category.id) && (
                              <span className="close_selected_category">x</span>
                            )}
                          </button>
                        ))}
                      </div>
                    </div>
                    {/* Sub Categories */}
                    {/* <div className="categories_conatainer">
                      <p className="categories_text">SOTTOCATEGORIA</p>
                      <div className="categories_items">
                        {subCategories.map(category => (
                          <button
                            type="button"
                            className={
                              !subCatSelected.includes(category.id)
                                ? 'categories_item map_view_button'
                                : 'categories_item categories_item_selected map_view_button'
                            }
                            key={category.id}
                            onClick={() =>
                              handleSubCategories(category.id, category.name)
                            }
                          >
                            {category.name}
                            {subCatSelected.includes(category.id) && (
                              <span className="close_selected_category">x</span>
                            )}
                          </button>
                        ))}
                      </div>
                    </div> */}
                  </div>
                </Modal>
              </div>
            )}

            {mallStoreData.map((store) => (
              // eslint-disable-next-line react/no-array-index-key
              <div
                className="mapview_store_card"
                key={store.id}
                onClick={() => {
                  if (store.storeList) {
                    history.push(`/mallDetails/${store.id}`);
                  } else {
                    history.push(`/shopDetails/${store.id}`);
                  }
                }}
                onMouseEnter={() => {
                  if (store.storeList) {
                    setMallStoreType("mall");
                  } else {
                    setMallStoreType("store");
                  }
                  setActivePark(store);
                  setActiveIcon({
                    lat: store.address.latitude,
                    lng: store.address.longitude,
                  });
                }}
                onMouseOut={() => {
                  setActivePark(null);
                  setActiveIcon(null);
                }}
                onBlur={() => {
                  setActivePark(null);
                  setActiveIcon(null);
                }}
              >
                <div
                  className="mapview_store_card_image"
                  // Separate applied onMouseEnter event on image and text as well because hover  effect was not working on the single card div
                  onMouseEnter={() => {
                    setActivePark(store);
                    setActiveIcon({
                      lat: store.address.latitude,
                      lng: store.address.longitude,
                    });
                  }}
                  onMouseOut={() => {
                    setActivePark(null);
                    setActiveIcon(null);
                  }}
                  onBlur={() => {
                    setActivePark(null);
                    setActiveIcon(null);
                  }}
                >
                  <img
                    src={store.imageUrl}
                    alt="shoping centre"
                    onError={(e) => {
                      if (store.storeList) {
                        e.target.src = shoppingCentre;
                      } else {
                        e.target.src = store6;
                      }
                    }}
                  />
                </div>
                <div
                  className="mapview_store_card_content"
                  onMouseEnter={() => {
                    setActivePark(store);
                    setActiveIcon({
                      lat: store.address.latitude,
                      lng: store.address.longitude,
                    });
                  }}
                  onMouseOut={() => {
                    setActivePark(null);
                    setActiveIcon(null);
                  }}
                  onBlur={() => {
                    setActivePark(null);
                    setActiveIcon(null);
                  }}
                >
                  <div className="map_large_card_tag_container">
                    {store.tags &&
                      store.tags.length &&
                      store.tags
                        .split(",")
                        .map((tag) => (tag ? `#${tag.trimStart()} ` : ""))}
                  </div>
                  <p
                    className="mapview_store_card_content_heading"
                    onMouseEnter={() => {
                      setActivePark(store);
                      setActiveIcon({
                        lat: store.address.latitude,
                        lng: store.address.longitude,
                      });
                    }}
                    onMouseOut={() => {
                      setActivePark(null);
                      setActiveIcon(null);
                    }}
                    onBlur={() => {
                      setActivePark(null);
                      setActiveIcon(null);
                    }}
                  >
                    {store.name}
                  </p>
                  <div
                    className="mapview_store_card_content_address"
                    onMouseEnter={() => {
                      setActivePark(store);
                      setActiveIcon({
                        lat: store.address.latitude,
                        lng: store.address.longitude,
                      });
                    }}
                    onMouseOut={() => {
                      setActivePark(null);
                      setActiveIcon(null);
                    }}
                    onBlur={() => {
                      setActivePark(null);
                      setActiveIcon(null);
                    }}
                  >
                    <img src={locator} alt="locator" />
                    <div className="mapview_store_card_content_address_text">
                      {`${store.address.address}`}
                      <div>
                        {`${store.address.zip} ${store.address.city} (${store.address.county})`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mapview_store_card_faviourite">
                  <img src={heartFilled} alt="faviourite" />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="map_container">
          <div>
            <button
              className="close_map_view"
              type="button"
              onClick={handleCloseMap}
            >
              <img src={mapClose} alt=" map close" />
            </button>
            <button
              className="search_in_your_area"
              type="button"
              onClick={handleSearchInLocation}
            >
              <img
                src={locationReload}
                alt=" map close"
                className={isRotate ? "rotate_location_icon" : null}
              />
              <span className="search_in_the_zone_text">
                Cerca in questa zona
              </span>
            </button>
            <Map
              center={mapCentre}
              zoom={8}
              zoomControl={false}
              scrollWheelZoom={false}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <ZoomControl position="topright" />
              {mallsData.map((store) => (
                <Marker
                  key={store.address.id}
                  position={[
                    store.address.latitude ? store.address.latitude : 0,
                    store.address.longitude ? store.address.longitude : 0,
                  ]}
                  icon={skater}
                  onClick={(e) => {
                    setActiveIcon(e.latlng);
                    setActivePark(store);
                    handleStoreMallTop(store.id);
                  }}
                />
              ))}
              {
                // markersData &&
                //   markersData.length > 0 &&

                storesData.map((store) => (
                  <Marker
                    key={store.address.id}
                    position={[
                      store.address.latitude ? store.address.latitude : 0,
                      store.address.longitude ? store.address.longitude : 0,
                    ]}
                    icon={skater}
                    onClick={(e) => {
                      setActiveIcon(e.latlng);
                      setActivePark(store);
                      handleStoreMallTop(store.id);
                    }}
                  />
                ))
              }

              {activePark && activeIcon && activeIcon.lat && (
                <Marker
                  key={1}
                  position={[
                    activeIcon.lat ? activeIcon.lat : 0,
                    activeIcon.lng ? activeIcon.lng : 0,
                  ]}
                  icon={skaterlargerLocator}
                />
              )}
              {console.log(mallStoreType)}

              {activePark && (
                <Popup
                  position={[
                    activePark.address.latitude
                      ? activePark.address.latitude
                      : 0,
                    activePark.address.longitude
                      ? activePark.address.longitude
                      : 0,
                  ]}
                  onClose={() => {
                    setActivePark(null);
                  }}
                  maxWidth="50px"
                >
                  <div className="map_shop_card">
                    <div className="map_view_card_heart_container map_card_heart_container">
                      <img src={heartFilled} alt="heart" />
                    </div>
                    {mallStoreType === "mall" && (
                      <img
                        src={activePark.imageUrl}
                        alt="shopping centre"
                        className="map_shop_image"
                        onError={(e) => {
                          e.target.src = shoppingCentre;
                        }}
                      />
                    )}
                    {mallStoreType === "store" && (
                      <img
                        src={activePark.imageUrl}
                        alt="shopping centre"
                        className="map_shop_image"
                        onError={(e) => {
                          e.target.src = store6;
                        }}
                      />
                    )}
                    <div className="map_card_tag_container">
                      {activePark.tags &&
                        activePark.tags.length &&
                        activePark.tags
                          .split(",")
                          .map((tag) => (tag ? `#${tag.trimStart()} ` : ""))}
                    </div>
                    <p className="map_view_shopping_centre_name map_shopping_centre_name">
                      {activePark.name}
                    </p>
                    <div className="mapview_shopping_center_address_container map_shopping_center_address_container">
                      <img src={locator} alt="locator" />
                      <p className="mapview_shopping_centre_address map_shopping_centre_address">
                        {`${activePark.address.address}`}
                        <div>
                          {`${activePark.address.zip} ${activePark.address.city} (${activePark.address.county})`}
                        </div>
                      </p>
                    </div>
                  </div>
                  {/* <div>
                <h2>{activePark.properties.NAME}</h2>
                <p>{activePark.properties.DESCRIPTIO}</p>
              </div> */}
                </Popup>
              )}
            </Map>
          </div>
        </div>
      </div>
      {/* <div className="map_view_footer">
        <Footer />
      </div> */}
    </div>
  );
}
FilterMapView.propTypes = {
  //   mallsAndStores: PropTypes.object,
  //   searchMallsStores: PropTypes.func,
  getFavStores: PropTypes.func,
  favStores: PropTypes.object,
  // setShowMapView: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    // getMallsStores: payload => dispatch({ type: 'GET_MALLS_STORES', payload }),
    searchMallsStores: (payload) =>
      dispatch({ type: "SEARCH_MALLS_STORES", payload }),
    getFavStores: (payload) =>
      dispatch({ type: "GET_FAVOURITE_STORES", payload }),
  };
}

export function mapStateToProps(state) {
  return {
    mallsAndStores: state.homePageFav,
    favStores: state.homePageFav.favStores,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: "mallsStores", saga, mode: DAEMON });

export default compose(withConnect, memo, withSaga)(FilterMapView);
