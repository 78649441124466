import React from "react";
import { Carousel } from "react-responsive-carousel";
import { aboutArrowLeft, aboutArrowRight, diconoDiNoi, } from "../../../assets/images";

function LandingCarousel(props) {
    const { currentSlide, setCurrentSlide } = props;

    const next = () => {
        setCurrentSlide(currentSlide + 1);
    };

    const prev = () => {
        setCurrentSlide(currentSlide - 1);
    };
    const updateCurrentSlide = (index) => {
        if (currentSlide !== index) {
            setCurrentSlide(index);
        }
    };

    return (
        <div className="about_section d-web">
            <div className="about_us_heading pink">Dicono di noi</div>
            <div className="arrow_container">
                <button
                    type="button"
                    onClick={prev}
                    className="about_arrow_left button_style"
                >
                    <img src={aboutArrowLeft} alt="Arrow left" />
                </button>
                <div className="carousel_container">
                    <Carousel
                        autoPlay
                        showThumbs={false}
                        showArrows={false}
                        showStatus={false}
                        infiniteLoop
                        interval={5000}
                        transitionTime={2000}
                        selectedItem={currentSlide}
                        onChange={updateCurrentSlide}
                    >
                        <div className="about_card">
                            <div className="about_image_container">
                                <img
                                    src={diconoDiNoi}
                                    alt="background"
                                    className="about_dashed_backgroung"
                                />
                            </div>
                            <div className="about_text">
                                <div className="about_text_content">
                                    La tecnologia digitale, con sito dedicato ed eCommerce
                                    integrati, può rivelarsi un grande supporto allo sviluppo
                                    del retail tradizionale di prossimità dando vita ad un
                                    sistema «retail-centrico» che valorizza il Punto Vendita
                                    fisico, rivolgendosi ai consumatori che gravitano
                                    nel bacino d’utenza
                        </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <button
                    type="button"
                    onClick={next}
                    className="about_arrow_right button_style"
                >
                    <img src={aboutArrowRight} alt="Arrow left" />
                </button>
            </div>
        </div>
    );
}

export default LandingCarousel;






