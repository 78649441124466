import React from "react";
import { logoIcon2 } from "../../../assets/images";
import history from "../../../utils/history";

import "../../LandingPageContainer/LandingNavbar/landing-navbar.styles.scss";
import "../../LandingPageContainer/LandingPage.scss";

function BusinessNavbar(props) {
    return (
        <div className="new-landing-navbar" id="new-landing-navbar">
            <div className="logo">
                <img src={logoIcon2} alt="My-Stores" style={{ cursor: "pointer" }}
                    onClick={() => history.push("/")}
                />
            </div>
            {/* <button
                className="contact-button"
                type="submit"
                onClick={() => {
                    const anchor = document.querySelector("#business-contact-us");
                    anchor.scrollIntoView({ behavior: "smooth", block: "start" });
                }}
            >
                Contattaci
            </button> */}
        </div>
    );
}

export default BusinessNavbar;
