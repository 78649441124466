import React, { useEffect } from "react";
import BusinessNavbar from "../BusinessNavbar/BusinessNavbar";

import "./thank-you-page.styles.scss";

export function ThankYouPage(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            props.history.push("");
        }, 5000);
    }, []);

    return (
        <div className="thank-you">
            <BusinessNavbar />
            <div className="thank-you-content">
                <div className="thank-you-title">Grazie per aver chiesto informazioni.</div>
                <div className="thank-you-subtitle">Entro pochi secondi sarai rediretto alla pagina principale.</div>
            </div>
        </div>
    );
}

export default ThankYouPage;
