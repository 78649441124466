// export const baseUrl = 'https://retapps-dev.demos.classicinformatics.com/api';
export const baseUrl = process.env.REACT_APP_API_URL;
//export const baseUrl = 'https://be-stage.my-stores.it/api';
// export const baseUrl = 'https://be.my-stores.it/api';

// Image resource url
export const shopGallaryResource = process.env.REACT_APP_IMAGE_URL;
// export const shopGallaryResource = `https://retapps-dev.demos.classicinformatics.com/ui/resources/`;
//export const shopGallaryResource = `https://be-stage.my-stores.it/resources/`;
// export const shopGallaryResource = `https://be.my-stores.it/resources/`;

// export const consoleUrl =
// "https://retapps-dev.demos.classicinformatics.com/ui/login";
// export const consoleUrl = 'https://be-stage.my-stores.it/ui/login';
//export const consoleUrl = 'https://be.my-stores.it/ui/login';
export const consoleUrl = process.env.REACT_APP_CONSOLE_URL;

export const userLogin = `${baseUrl}/cli/profiles/login`;
export const getFavStores = `${baseUrl}/cli/favorite/stores`;
export const createFavStores = `${baseUrl}/cli/favorite/stores/create`;
export const deleteFavStores = `${baseUrl}/cli/favorite/stores/delete`;
export const getMallsStores = `${baseUrl}/cli/search`;
export const operatorSignUp = `${baseUrl}/cli/operators/`;
export const updateUserProfile = `${baseUrl}/cli/profiles`;
export const shopCategories = `${baseUrl}/cli/categories/shop`;
export const getCities = `${baseUrl}/cli/cities/search`;
export const searchMallsStores = `${baseUrl}/cli/search`;
export const getMallDetail = `${baseUrl}/cli/malls`;
export const getShopDetail = `${baseUrl}/cli/stores`;
// export const getShopGallary = `${baseUrl}/cli/cms/shopGallery`;
export const getShopGallary = `${baseUrl}/cli/cms/shopGallery/get`;
export const getShopProducts = `${baseUrl}/cli/ecommerce/products`;
export const getAllOffer = `${baseUrl}/cli/offer/getAllOffer`;
export const getAllFavOffer = `${baseUrl}/cli/offer/getAllOfferFromFavorite`;
// export const getStoreOffers = `${baseUrl}/cli/offer/findOfferStore`;
export const getStoreOffers = `${baseUrl}/cli/offer/as`;
export const setPrefferedCity = `${baseUrl}/cli/profiles/setcity`;
export const updateUserPassword = `${baseUrl}/cli/profiles/password/change`;
export const getPrivacyPolicy = `${baseUrl}/cli/privacy`;
export const homePageBanners = `${baseUrl}/cli/cms/banners`;
export const shopBanners = `${baseUrl}/cli/shopbanner/get`;

export const fidelityShopPoints = `${baseUrl}/cli/cms/campaignPoints/pointsByUser`;
// export const fidelityShopPoints = `${baseUrl}/cli/cms/campaignPoints/readByUserIdAll`;

export const fidelityShopDetail = `${baseUrl}/cli/cms/campaignPoints/pointsByShop`;
export const allPointsTransInShop = `${baseUrl}/cli/cms/campaignPoints/allPointsForShopAndUser`;
export const getAllOrders = `${baseUrl}/cli/ecommerce/orders`;
export const getBonusWithShopAndUser = `${baseUrl}/cli/userbonus/pointbyshopidanduser`;
// export const getBonusWithShopAndUser = `${baseUrl}/cli/cms/campaignPoints/pointsByShopId`;
export const completedBonus = `${baseUrl}/cli/userbonus/completedpoint`;

export const writeToUs = `${baseUrl}/cli/WriteToUs/createWriteToUs`;
export const createNewsLetter = `${baseUrl}/cli/NewsLetter/createNewsLetter`;
export const cancelOrder = `${baseUrl}/cli/ecommerce/cart/abort`;
export const getFAQs = `${baseUrl}/cli/faq/getAllFaq`;
export const resetPasswprd = `${baseUrl}/cli/profiles/password`;
export const deletePassword = `${baseUrl}/cli/profiles/delete`;
export const getUserData = `${baseUrl}/cli/profiles/password/reset/getprofile`;
export const operatorPasswordReset = `${baseUrl}/cli/profiles/password/reset`;
export const lastFiveTrans = `${baseUrl}/cli/cms/campaignPoints/lastFiveTrans`;
export const userSignUp = `${baseUrl}/cli/profiles/signup`;

export const booking = `${baseUrl}/cli/booking`;
export const getToken = `${baseUrl}/cli/profiles/authToken`;
export const modulesPricing = `${baseUrl}/cli/modulesPricing`;
export const calculatePricing = `${baseUrl}/cli/modulesPricing/calculate`;
export const verifyCoupon = `${baseUrl}/cli/subscriptionCoupons`;
export const contactUs = `${baseUrl}/cli/contact`;
